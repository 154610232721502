import { BrowserView, MobileView } from "react-device-detect"
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState, useEffect, useRef } from "react";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css/pagination';
import constant from '../../Component/Services/constant';
import { ApiService } from "../../Component/Services/apiservices";
import Skeleton from 'react-loading-skeleton'

const Recomendedcategory = () => {
    const [recomendedcateg, setrecomendedcate] = useState([])
    const [categoryImgurl, setcategoryimgurl] = useState('')
    const [loading, setloading] = useState(true)
    const didMountRef = useRef(true);
    const skeletonArray = Array.from({ length: 4 });
    useEffect(() => {
        if (didMountRef.current) {
            getrecomendedcategdata()
        }
        didMountRef.current = false;
    }, [])

    const getrecomendedcategdata = () => {
        try {
            ApiService.fetchData('/recommended-category').then((res) => {
                if (res?.status == 'success') {
                    setrecomendedcate(res.resCategory);
                    setcategoryimgurl(res.category_image_path)
                    setloading(false)
                } else {
                    setloading(false)
                }
            });
        } catch (error) {
            setloading(false)
        }
    };
    return (<>
        <BrowserView>
            {loading ?
                <section className="section-gap-medium services">
                    <div className="container">
                        <div className="row" key={1}>
                            <div className="col-md-6 p-0 animate-box fadeInLeft animated">
                                <div className="img left">
                                    <a href="">
                                        <Skeleton height={'500px'} />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 p-0 bg-white valign animate-box fadeInRight animated">
                                <div className="content">
                                    <div className="section-title">
                                        <span className="pre-title"> <Skeleton /></span>
                                        <h2> <Skeleton /></h2>
                                    </div>
                                    <p> <Skeleton /></p>
                                    <Skeleton width={'200px'} />
                                </div>

                            </div>
                        </div>
                        <div className="row" key={2}>
                            <div className="col-md-6 p-0 bg-white valign animate-box fadeInRight animated">
                                <div className="content">
                                    <div className="section-title">
                                        <span className="pre-title"><Skeleton /></span>
                                        <h2><Skeleton /></h2>
                                    </div>
                                    <p><Skeleton /></p>
                                    <Skeleton width={'200px'} />
                                </div>

                            </div>
                            <div className="col-md-6 p-0 animate-box fadeInLeft animated">
                                <div className="img left">
                                    <a href="#">
                                        <Skeleton height={'500px'} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row" key={3}>
                            <div className="col-md-6 p-0 animate-box fadeInLeft animated">
                                <div className="img left">
                                    <a href="#">
                                        <Skeleton height={'500px'} />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 p-0 bg-white valign animate-box fadeInRight animated">
                                <div className="content">
                                    <div className="section-title">
                                        <span className="pre-title"><Skeleton /></span>
                                        <h2><Skeleton /></h2>
                                    </div>
                                    <p><Skeleton /></p>
                                    <Skeleton width={'200px'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                : <>
                    {recomendedcateg && recomendedcateg.length > 0 ? <>
                        <section className="section-gap-medium services">
                            <div className="container">
                                {recomendedcateg && recomendedcateg.map((item, index) => {
                                    if (index % 2 !== 0) {
                                        return (<>
                                            <div className="row" key={index}>
                                                <div className="col-md-6 p-0 bg-white valign animate-box fadeInRight animated">
                                                    <div className="content">
                                                        <div className="section-title mb-10">
                                                            <span className="pre-title">{item.cat_subtitle}</span>
                                                            <h2>{item.cat_name}</h2>
                                                        </div>
                                                        <p>{item.cat_desc}</p>
                                                        <a className="btn-line mt-10" style={{display:"inline-block"}} href={`/collection/category/${item.cat_slug}`}><span>Explore More</span></a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 p-0 animate-box fadeInLeft animated">
                                                    <div className="img left">
                                                        <a href={`/collection/category/${item.cat_slug}`}>
                                                            <img src={item.cat_image ? categoryImgurl + item.cat_image : constant.DEFAULT_IMAGE}></img>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    } else {
                                        return (<>
                                            <div className="row" key={index}>
                                                <div className="col-md-6 p-0 animate-box fadeInLeft animated">
                                                    <div className="img left">
                                                        <a href={`/collection/category/${item.cat_slug}`}>
                                                            <img src={item.cat_image ? categoryImgurl + item.cat_image : constant.DEFAULT_IMAGE}></img>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 p-0 bg-white valign animate-box fadeInRight animated">
                                                    <div className="content">
                                                        <div className="section-title mb-10">
                                                            <span className="pre-title">{item.cat_subtitle}</span>
                                                            <h2>{item.cat_name}</h2>
                                                        </div>
                                                        <p>{item.cat_desc}</p>
                                                        <a className="btn-line mt-10" style={{display:"inline-block"}} href={`/collection/category/${item.cat_slug}`}><span>Explore More</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    }
                                })}
                            </div>
                        </section>
                    </> : null
                    }
                </>
            } 
        </BrowserView>
        <MobileView>
            {loading ? <>
                <section className="section-gap-medium services">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    navigation={false}
                                    loop={false}
                                    pagination={{ clickable: true }}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        768: {
                                            slidesPerView: 1,
                                        },
                                        992: {
                                            slidesPerView: 1,
                                        },
                                    }}
                                >
                                    {skeletonArray.map((_, index) => {
                                        return (<>
                                            <SwiperSlide key={index}>
                                                <div className="service" key={index}>
                                                    <div className="animate-box fadeInLeft animated">
                                                        <div className="img left">
                                                            <a href="#">
                                                                <Skeleton height={'300px'}></Skeleton>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="bg-white valign animate-box fadeInRight animated">
                                                        <div className="content">
                                                            <div className="section-title">
                                                                <span className="pre-title"><Skeleton></Skeleton></span>
                                                                <h2><Skeleton></Skeleton></h2>
                                                            </div>
                                                            <p><Skeleton></Skeleton></p>
                                                            <Skeleton width={'100px'}></Skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </>)
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>
            </> : <>
                {recomendedcateg.length > 0 ? <>
                    <section className="section-gap-medium services">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Swiper
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        navigation={false}
                                        loop={false}
                                        pagination={{ clickable: true }}
                                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 1,
                                            },
                                            992: {
                                                slidesPerView: 1,
                                            },
                                        }}
                                    >
                                        {recomendedcateg && recomendedcateg.map((item, index) => {
                                            return (<>
                                                <SwiperSlide key={index}>
                                                    <div className="service" key={index}>
                                                        <div className="animate-box fadeInLeft animated">
                                                            <div className="img left">
                                                                <a href={`/collection/category/${item.cat_slug}`}>
                                                                    <img src={item.cat_image ? categoryImgurl + item.cat_image : constant.DEFAULT_IMAGE}></img>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="bg-white valign animate-box fadeInRight animated">
                                                            <div className="content">
                                                                <div className="section-title mb-10">
                                                                    <span className="pre-title">{item.cat_subtitle}</span>
                                                                    <h2>{item.cat_name}</h2>
                                                                </div>
                                                                <p>{item.cat_desc}</p>
                                                                <a className="btn-line mt-10" style={{display:'inline-block'}} href={`/collection/category/${item.cat_slug}`}><span>Explore More</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </>)
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </section>
                </> : null
                }
            </>}
        </MobileView>
    </>)
}

export default Recomendedcategory