import React, { useEffect, useRef, useState, useContext } from 'react';
import DataContext from '../Elements/context'
import Loader from "react-js-loader";
import { ApiService } from '../Services/apiservices';
import sessionCartData from '../Elements/cart_session_data';
import Modal from 'react-bootstrap/Modal';
import multiCurrency from '../Elements/multi_currrency';
function CouponModal() {
    const didMountRef = useRef(true);
    const dataArray = sessionCartData();
    const contextValues = useContext(DataContext);
    //const [couponsList, setCouponsList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [CoupanCode, setCoupanCode] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [proccessLoader, setProccessLoader] = useState(false);
    useEffect(() => {
        if (didMountRef.current) {
            couponsListData()
        }
        didMountRef.current = false;
    }, []);
    const couponModal = () => {
        contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
    }

    const couponsListData = () => {
        const productData = {
            sessionItemsSummary: []
        };
        contextValues.setSpinnerCubLoader(1)
        ApiService.postData("couponsList", productData).then((res) => {
            if (res.status === 'success') {
                contextValues.setCouponsList(res.resCouponsData)
                contextValues.setSpinnerCubLoader(0)
            } else {
                contextValues.setSpinnerCubLoader(0)
            }
        }).catch((error) => {
            contextValues.setSpinnerCubLoader(0)
        });
    }

    const applyCoupon = (couponValues) => {
        setErrorMessage('')
        setSuccessMessage('')
        if (contextValues.userToken == '' || contextValues.userToken == null) {
            setErrorMessage('Please login to apply this coupon');
            return
        }
        const productData = {
            promo_code: couponValues.promo_coupon_code,
            cart_total: contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? contextValues.cartSummary.sellingTotal : 0,
        };
        setProccessLoader(true)
        ApiService.postData("applyCoupon", productData).then((res) => {
            if (res.data.status === 'success') {
                setSuccessMessage(res.data.message)
                localStorage.removeItem("COUPON_SESSION");
                const couponSessionObj = {
                    discount_amount: res.data.discount_amount,
                    promo_id: res.data.promo_id,
                    promo_code: res.data.promo_code,
                };
                localStorage.setItem("COUPON_SESSION", JSON.stringify(couponSessionObj));
                contextValues.setCartSummary(dataArray[3])
                contextValues.setCouponSession(couponSessionObj)
                setProccessLoader(false)
                setTimeout(() => {
                    contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
                }, 500);
            } else {
                setErrorMessage(res.data.message)
                setProccessLoader(false)
            }
        }).catch((error) => {
            setErrorMessage(error)
            setProccessLoader(false)
        });
    }

    const handleApplyCoupan = () => {
        setErrorMessage('')
        setSuccessMessage('')
        if (CoupanCode == '') {
            setErrorMessage('Please enter the coupan code for apply');
        } else if (contextValues.couponsList && contextValues.couponsList.length > 0) {
            const matchedCoupon = contextValues.couponsList.find(coupon => coupon.promo_coupon_code === CoupanCode);
            if (matchedCoupon) {
                applyCoupon(matchedCoupon);
            }
            else {
                setErrorMessage('Please apply the correct coupan code');
            }
        }
        else {
            setErrorMessage('No coupons available.');
        }
    }
    return (
        <>
            <Modal className='couponModal' show={contextValues.toggleCouponModal} onHide={(e) => couponModal()}>
                <div className='couponsec'>
                    <div className='couponsecHeader'>
                        <h5 className='mb-0'>Coupons</h5>
                        <button type='button' className='btnClose' onClick={(e) => couponModal()}> <i className="ri-close-line ri-2x"></i></button>
                    </div>

                    {contextValues.spinnerCubLoader > 0 ? <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} /> :
                        <div className='couponsec-body'>
                            <div className='couponApply'>
                                <input type='text' placeholder='Enter coupon code here' onChange={(e) => { setCoupanCode(e.target.value); setErrorMessage('') }}></input>
                                <button type='button' className='applybutton' onClick={() => { handleApplyCoupan() }}>Apply</button>
                            </div>

                            {errorMessage && (
                                <div className="alert alert-danger" role="alert">
                                    {errorMessage}
                                </div>
                            )}
                            {successMessage && (
                                <div className="alert alert-success" role="alert">
                                    {successMessage}
                                </div>
                            )}
                            {proccessLoader && <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} />}
                            {contextValues.couponsList && contextValues.couponsList.length > 0 ? (
                                contextValues.couponsList.map((value, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="coupon bg-white rounded mb-3 d-flex justify-content-between" key={index}>
                                                <div className="tengah p-3 d-flex w-100 justify-content-start" key={index}>
                                                    <div>
                                                        <h6 className='mb-1'>{value.promo_name}</h6>
                                                        <p className="text-muted mb-0 tx-12">{value.promo_description}</p>
                                                        {value.promo_max_amount > 0 && <p className="tx-primary fw600 mb-0 tx-12">Save {multiCurrency(value.promo_max_amount)} on this order!</p>}
                                                    </div>
                                                </div>
                                                <div className="kanan">
                                                    <div className="info d-flex align-items-center">
                                                        <a href="javascript:;" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary btn-full" onClick={(e) => applyCoupon(value)}>Apply</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <div className="tengah p-3 d-flex w-100 justify-content-start">No coupons available.</div>
                            )}
                        </div>
                    }
                </div>
            </Modal>
        </>
    )
}

export default CouponModal