import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import { ApiService } from "../Services/apiservices";
import DataContext from "../Elements/context";
function Footer() {
    const [footerdata, setfooterdata] = useState({})
    const { settingData } = useContext(DataContext)
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [newsletteremail, setnewsletteremail] = useState("");

    const didMountRef = useRef(true);

    useEffect(() => {
        if (didMountRef.current) {
            getfooterdata()
        }
        didMountRef.current = false;
    }, [])

    const getfooterdata = () => {
        try {
            ApiService.fetchData('/footer').then((res) => {
                if (res.status == 'success') {
                    setfooterdata(res?.footerData);
                }
            });
        } catch (error) {
            console.error('Error fetching footer data:', error);
        }

    }

    const newsLetter = (e) => {
        setErrorMessage("");
        if (newsletteremail == "" || newsletteremail == null) {
            setErrorMessage('Please enter email address')
            setTimeout(() => {
                setErrorMessage('')
            }, [2000])
            return;
        }

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newsletteremail)) {
            const email = newsletteremail.toLowerCase();
            let datastring = { newsletter_email: email };
            ApiService.postData('/newsletter-process', datastring).then((res) => {
                if (res?.status == "success") {
                    setSuccessMessage(res?.message)
                    setnewsletteremail("");
                    setTimeout(() => {
                        setSuccessMessage('')
                    }, [2000])

                } else {
                    setErrorMessage(res?.message)
                    setnewsletteremail("");
                    setTimeout(() => {
                        setErrorMessage('')
                    }, [2000])
                }
            });
        } else {
            setErrorMessage("Invalid email address. Please enter valid email")
            setTimeout(() => {
                setErrorMessage('')
            }, [2000])
            return;
        }
    };
    return (
        <>
            <BrowserView>
                <div className="footerIocn">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon">
                                        <img src="/img/free-delivery.png" alt="Shipping Icon" />
                                    </div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Free Shipping</h6>
                                        <p className="tx-gray mb-0">Order above Rs.999</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon">
                                        <img src="/img/profit.png" alt="save money" />
                                    </div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Save Money</h6>
                                        <p className="tx-gray mb-0">All products at lowest price</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon">
                                        <img src="/img/1deal.png" alt="best deal" />
                                    </div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Best Deal Offer</h6>
                                        <p className="tx-gray mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon">
                                        <img src="/img/quality.png" alt="quality assurance" />
                                    </div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Quality assurance</h6>
                                        <p className="tx-gray mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer" style={{ background: 'url(../img/fbg.jpg)' }}>
                    <div className="footerTop">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-4">
                                    <div>
                                        <p>{settingData.theme_about}</p>
                                    </div>
                                    <h6 className="footer-title mb-20">Registered Office Address</h6>
                                    <p>{settingData?.address}</p>
                                    <div className="footer-contact mb-20">
                                        <ul>

                                            <li><a href={`tel:${settingData?.admin_mobile}`}><i className="ri-phone-fill"></i><span className="font-third">{settingData?.admin_mobile}</span></a>
                                            </li>
                                            <li><a href={`mailto:${settingData?.admin_email}`}><i className="ri-mail-fill"></i><span className="font-third">{settingData?.admin_email}</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-6" >
                                    <h5 className="footer-title mb-20">Get to know us</h5>
                                    <div dangerouslySetInnerHTML={{ __html: footerdata.footer_desc3 }}></div>
                                </div>
                                <div className="col-lg-2 col-6" >
                                    <h5 className="footer-title mb-20">Can we help you?</h5>
                                    <div dangerouslySetInnerHTML={{ __html: footerdata.footer_desc2 }}>
                                    </div>
                                </div>


                                <div className="col-lg-4">
                                    <h5 className="footer-title mb-20">Stay in touch</h5>
                                    <p className="tx-13">Sign up for exclusive offers , orignal Stories, events and more</p>
                                    {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                                    {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                                    <div className="subscribe-form mb-15">

                                        <input className="email newsletterRequired" type="email" placeholder="Enter your email here.." name="newsletter_email" value={newsletteremail}
                                            required
                                            onChange={(e) => {
                                                setnewsletteremail(e.target.value);
                                                setErrorMessage("");
                                                setSuccessMessage("");
                                            }} />
                                        <div className="subscribe-button"><input id="mc-embedded-subscribe" className="button" type="button" name="subscribe" value="Subscribe" onClick={(e) => {
                                            newsLetter(e);
                                        }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerBottom">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <a href=""><img src='/img/logo.png' height="76px" width="150px" className="mb-20"></img></a>
                                    {/* <h6>Sprash Privated Limited</h6> */}
                                    <p>Have an idea to help us improve? Send feedback </p>
                                    <div className="footer-social">
                                        <ul>
                                            {settingData.facebook_url ? <li><a href={settingData.facebook_url} target="new"><i className="ri-facebook-fill" style={{ color: '#1877F2' }}></i></a></li> : null}
                                            {settingData.instagram_url ? <li><a href={settingData.instagram_url} target="new"><i className="ri-instagram-fill" style={{ color: '#8a49a1' }}></i></a></li> : null}
                                            {settingData.twitter_url ? <li><a href={settingData.twitter_url} target="new"><i className="ri-twitter-fill" style={{ color: '#1DA1F2' }}></i></a></li> : null}
                                            {settingData.pinterest_url ? <li><a href={settingData.pinterest_url} target="new"><i className="ri-pinterest-fill" style={{ color: '#E60023' }}></i></a></li> : null}
                                            {settingData.linkedin_url ? <li><a href={settingData.linkedin_url} target="new"><i className="ri-linkedin-fill" style={{ color: '#0077b5' }}></i></a></li> : null}
                                            {settingData.youtube_url ? <li><a href={settingData.youtube_url} target="new"><i className="ri-youtube-fill" style={{ color: '#FF0000' }}></i></a></li> : null}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">Copyright @ 2024 | All Right Reserved Sprash</div>
                                <div className="col-lg-6"><div className="paymet-icon">
                                    <img src="/img/payments.png" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </BrowserView>
            <MobileView>
                <div className="footerIocn">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon">
                                        <img src="/img/free-delivery.png" alt="Shipping Icon" />
                                    </div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Free Shipping</h6>
                                        <p className="tx-gray mb-0">Order above Rs.999</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon">
                                        <img src="/img/profit.png" alt="save money" />
                                    </div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Save Money</h6>
                                        <p className="tx-gray mb-0">All products at lowest price</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon">
                                        <img src="/img/1deal.png" alt="best deal" />
                                    </div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Best Deal Offer</h6>
                                        <p className="tx-gray mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="footerIocnBox">
                                    <div className="ficon">
                                        <img src="/img/quality.png" alt="quality assurance" />
                                    </div>
                                    <div className="ftext">
                                        <h6 className="mb-1">Quality assurance</h6>
                                        <p className="tx-gray mb-0">Grab Your Gear and Go</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="footer" style={{ background: 'url(../img/fbg.jpg)' }}>
                    <div className="footerBottom">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <a href=""><img src="/img/logo.png" height="76px" width="150px" className="mb-20"></img></a>
                                    {/* <h6>Sprash Privated Limited</h6> */}
                                    <p>Have an idea to help us improve? Send feedback </p>
                                    <div className="footer-social">
                                        <ul>
                                            {settingData.facebook_url ? <li><a href={settingData.facebook_url} target="new"><i className="ri-facebook-fill" style={{ color: '#1877F2' }}></i></a></li> : null}
                                            {settingData.instagram_url ? <li><a href={settingData.instagram_url} target="new"><i className="ri-instagram-fill" style={{ color: '#8a49a1' }}></i></a></li> : null}
                                            {settingData.twitter_url ? <li><a href={settingData.twitter_url} target="new"><i className="ri-twitter-fill" style={{ color: '#1DA1F2' }}></i></a></li> : null}
                                            {settingData.pinterest_url ? <li><a href={settingData.pinterest_url} target="new"><i className="ri-pinterest-fill" style={{ color: '#E60023' }}></i></a></li> : null}
                                            {settingData.linkedin_url ? <li><a href={settingData.linkedin_url} target="new"><i className="ri-linkedin-fill" style={{ color: '#0077b5' }}></i></a></li> : null}
                                            {settingData.youtube_url ? <li><a href={settingData.youtube_url} target="new"><i className="ri-youtube-fill" style={{ color: '#FF0000' }}></i></a></li> : null}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerTop">
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-4 text-center">
                                    <h5 className="footer-title mb-20">Stay in touch</h5>
                                    <p className="tx-16">Sign up for exclusive offers , orignal Stories, events and more</p>
                                    {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                                    {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                                    <div className="subscribe-form mb-15">

                                        <input className="email newsletterRequired" type="email" placeholder="Enter your email here.." name="newsletter_email" value={newsletteremail}
                                            required
                                            onChange={(e) => {
                                                setnewsletteremail(e.target.value);
                                                setErrorMessage("");
                                                setSuccessMessage("");
                                            }} />
                                        <div className="subscribe-button"><input id="mc-embedded-subscribe" className="button" type="button" name="subscribe" value="Subscribe" onClick={(e) => {
                                            newsLetter(e);
                                        }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 text-center">

                                    <p>{settingData.theme_about}</p>

                                    <h6 className="footer-title mb-20">Registered Office Address</h6>
                                    <p>{settingData?.address}</p>
                                    <div className="footer-contact mb-20">
                                        <ul>
                                            <li><a href={`tel:${settingData?.admin_mobile}`}><i className="ri-phone-fill"></i><span className="font-third">{settingData?.admin_mobile}</span></a>
                                            </li>
                                            <li><a href={`mailto:${settingData?.admin_email}`}><i className="ri-mail-fill"></i><span className="font-third">{settingData?.admin_email}</span></a></li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="p-3">
                                    <Accordion>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Get to know us</Accordion.Header>
                                            <Accordion.Body dangerouslySetInnerHTML={{ __html: footerdata.footer_desc3 }}>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="0">

                                            <Accordion.Header>Can we help you?</Accordion.Header>
                                            <Accordion.Body dangerouslySetInnerHTML={{ __html: footerdata.footer_desc2 }}>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">Copyright @ 2024 | All Right Reserved Sprash</div>
                                <div className="col-lg-6">
                                    <div className="paymet-icon mt-10">
                                        <img src="/img/payments.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </MobileView>
        </>
    )
}
export default Footer;