import './App.css';
import Home from './Container/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogsList from './Container/blogslist';
import Blogdetail from './Container/blogdetail';
import ContactUS from './Container/contact-us';
import BookAppointment from './Container/book-appointment';
import Pages from './Container/pages';
import Collection from './Container/collection/index';
import Explore from './Container/explore';
import Productdetail from './Container/product-detail';
import Checkout from './Container/cart/checkout';
import Thankyou from './Container/cart/thankyou';
import MyOrder from './Container/account/my-order';
import Myaccount from './Container/account/my-account';
import Orderdetail from './Container/account/order-detail';
import Address from './Container/account/address';
import HelpSupport from './Container/account/help-support';
import Profile from './Container/account/profile';
import Wishlist from './Container/account/whishlist';
import Aboutus from './Container/account/about-us';
import PagenotFound from './Container/pages/pagenotfound';
import Feedbackform from './Container/feedbackform';
import Cart from './Container/cart';
import CartAddress from './Container/cart/cart-address';


function App() {
  return (
    <div className="App">
    <Router>
        <Routes> 
          <Route path="/" activeClassName="active" element={<Home />} />
          <Route path="/blogs" activeClassName="active" element={<BlogsList />} />
          <Route path="/feedback-form" activeClassName="active" element={<Feedbackform />} />
          <Route path="/page-not-found" activeClassName="active" element={<PagenotFound />} />
          <Route path="/contact-us" activeClassName="active" element={<ContactUS />} />
          <Route path="/explore" activeClassName="active" element={<Explore />} />
          <Route path="/cart" activeClassName="active" element={<Cart />} />
          <Route path="/address" activeClassName="active" element={<CartAddress />} />
          <Route path="/account/my-order" activeClassName="active" element={<MyOrder />} />
          <Route path="/account/profile" activeClassName="active" element={<Profile />} />
          <Route path="/account/help-support" activeClassName="active" element={<HelpSupport />} />
          <Route path="/account/wishlist" activeClassName="active" element={<Wishlist />} />
          <Route path="/account/about-us" activeClassName="active" element={<Aboutus />} />
          <Route path="/my-account" activeClassName="active" element={<Myaccount />} />
          <Route path="/account/order-details/:id" activeClassName="active" element={<Orderdetail />} />
          <Route path="account/my-address" activeClassName="active" element={<Address />} />
          <Route path="/checkout" activeClassName="active" element={<Checkout />} />
          <Route path="/thankyou/:orderId" activeClassName="active" element={<Thankyou />} />
          <Route path="/collection/:slug" activeClassName="active" element={<Collection />} />
          <Route path="/product/:slug" activeClassName="active" element={<Productdetail />} />
          <Route path="/collection" activeClassName="active" element={<Collection />} />
          <Route path="/collection/:type/:slug" activeClassName="active" element={<Collection />} />
        
          <Route path="/book-an-appointment" activeClassName="active" element={<BookAppointment />} />
          <Route path="/:slug" activeClassName="active" element={<Pages />} />
          <Route path="/blog-detail/:slug" activeClassName="active" element={<Blogdetail />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
