import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import ProductBox from "../../Component/product-box";
import { useState, useRef, useEffect, useContext, } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import Skeleton from 'react-loading-skeleton'
import { BrowserView, MobileView } from "react-device-detect";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import MobileInnerheader from "../../Component/Header/mobile-header/inner";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import multiCurrency from "../../Component/Elements/multi_currrency";
import { Helmet } from "react-helmet";
import SpinnerLoader from "../../Component/Elements/spinner_loader";
import DataContext from "../../Component/Elements/context";
import { Modal } from "react-bootstrap";
const Collection = () => {
  const { type, slug } = useParams()
  const didMountRef = useRef(true);
  const skeletonArray = Array.from({ length: 8 });
  const contextValues = useContext(DataContext)
  const navigate = useNavigate()
  const [catwiseproduct, setcatwiseproduct] = useState([])
  const [CategoryList, setCategoryList] = useState([])
  const [ProductTypeList, setProductTypeList] = useState([])
  const [CollectionList, setCollectionList] = useState([])
  const [AttributesList, setAttributesList] = useState([])
  let typeset = type !== undefined ? type : 'collection'
  const [filterobject, setfilterobject] = useState({ [typeset]: [slug], Size: [] })
  const [Tempfilterobject, setTempfilterobject] = useState({ [typeset]: [slug], Size: [] })
  const [categorydetail, setcategorydetail] = useState({})
  const [totalproductcount, settotalproductcount] = useState('')
  const [loading, setloading] = useState(true)
  const [catloading, setcatloading] = useState(true)
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [imgUrl, setimgUrl] = useState('')
  const [fullSpinnerLoading, setFullSpinnerLoading] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [MobFilterModal, setMobFilterModal] = useState('');
  const [CollectionFilter, setCollectionFilter] = useState(localStorage.getItem('collection') || '')
  const [CategoryFilter, setCategoryFilter] = useState(localStorage.getItem('category') || '')
  const [selectedTab, setSelectedTab] = useState("tab-0");
  const [showType, setShowType] = useState('');
  const [CatMobile, setCatMobile] = useState('');
  const [FilterSortMob, setFilterSortMob] = useState();
  const [TagFilter, setTagFilter] = useState(localStorage.getItem('tag') || '')


  useEffect(() => {
    if (didMountRef.current) {
      getProductListSidebar()
      if (slug !== 'all') {
        getcategorydetail()
      }
    }
    didMountRef.current = false;
    getfilterproducts()

  }, [filterobject])

  useEffect(() => {
    if (contextValues?.settingData) {
      const { price_range_from, price_range_to } = contextValues.settingData;
      if (price_range_from !== undefined && price_range_to !== undefined) {
        setPriceRange([price_range_from, price_range_to]);
      }
    }
  }, [contextValues]);

  const getcategorydetail = () => {
    var typeset = ""
    if (type) {
      typeset = type
    } else {
      typeset = "collection"
    }
    setShowType(typeset);
    const dataString = {
      type: typeset,
      cat_slug: slug,
    };
    ApiService.postData("category-detail", dataString).then((res) => {
      if (res.status === "success") {
        setcategorydetail(res.hemlet)
        setimgUrl(res.imageurl)
        if (typeset == 'collection') {
        setCollectionFilter(res.hemlet.name)
      } else if (typeset == 'category') {
        setCategoryFilter(res.hemlet.name)
        setCatMobile(res.hemlet.slug)
      }
      else if (typeset == 'tag') {
        setTagFilter(res.hemlet.name)
      }
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const getProductListSidebar = async () => {
    const dataString = {
      type: typeset,
      cat_slug: slug,
      filterArray: slug == "all" && filterobject == null ? [] : filterobject,
    };
    const res = await ApiService.postData("get-product-list-sidebar", dataString);
    if (res.status === "success") {
      setCategoryList(res.aryCategoryList)
      setProductTypeList(res.resProductTypeList)
      setCollectionList(res.resCollectionList)
      setAttributesList(res.resAttributesList)
      const filteredItemsString = JSON.stringify(filterobject);
      localStorage.setItem('FILTER_SESSION', filteredItemsString);
      setTimeout(() => {
        setcatloading(false)
      }, [500])
    } else {
      setTimeout(() => {
        setcatloading(false)
      }, [500])
    }
  };

  const getfilterproducts = (sort = null, range) => {
    let pageNumber;
    if (catwiseproduct.length >= 12) {
      pageNumber = Math.ceil(catwiseproduct.length / 12) + 1;
    } else {
      pageNumber = 1
    }
    const dataString = {
      filterArray: slug == "all" && filterobject == null ? [] : filterobject,
      sort: sort || localStorage.getItem('SORT_SESSION') || '',
      priceRange: range
    };
    setFullSpinnerLoading(true)
    ApiService.filterPostData("getfilterproductsdata" + "?page=" + pageNumber, dataString).then((res) => {
      if (res.status === "success") {
        setcatwiseproduct(res.data);
        settotalproductcount(res.data.length)
        setTimeout(() => {
          setloading(false)
          setFullSpinnerLoading(false)
        }, [500])
      } else {
        setTimeout(() => {
          setloading(false)
          setFullSpinnerLoading(false)
        }, [500])
      }
    }).catch((error) => {
      setTimeout(() => {
        setloading(false)
        setFullSpinnerLoading(false)
      }, [500])
    });
  }

  const handlecheckboxfilter = (type, cat_slug) => {
    const getBaseUrl = () => window.location.origin;

    if (type === 'category') {
      window.location.href = `${getBaseUrl()}/collection/category/${cat_slug}`;
    }

    let filterSession = JSON.parse(localStorage.getItem('FILTER_SESSION')) || {};
    filterSession[type] = filterSession[type] || [];

    const index = filterSession[type].indexOf(cat_slug);
    if (index === -1) {
      filterSession[type].push(cat_slug);
    } else if (cat_slug !== slug) {
      filterSession[type].splice(index, 1);
    }
    localStorage.setItem('FILTER_SESSION', JSON.stringify(filterSession));
    setfilterobject(filterSession);
  };

  const handlecheckboxfiltermobile = (type, cat_slug) => {
    console.log('type',type , cat_slug);
    if (type === 'category') {
      setCatMobile(cat_slug);
      // window.location.href = `${getBaseUrl()}/collection/category/${cat_slug}`;
    }
    
    let filterSession = JSON.parse(localStorage.getItem('FILTER_SESSION')) || {};
    filterSession[type] = filterSession[type] || [];

    const index = filterSession[type].indexOf(cat_slug);
    if (index === -1) {
      filterSession[type].push(cat_slug);
    } else if (cat_slug !== slug) {
      filterSession[type].splice(index, 1);
    }
    localStorage.setItem('FILTER_SESSION', JSON.stringify(filterSession));
    setTempfilterobject(filterSession);
    console.log('PARSED',JSON.parse(localStorage.getItem('FILTER_SESSION')));

  };
  const handleApplyMobFilter = () => {
    const getBaseUrl = () => window.location.origin;
    let filterSession = JSON.parse(localStorage.getItem('FILTER_SESSION')) || {};
  if(filterSession){
    setfilterobject(filterSession)
    // localStorage.setItem('FILTER_SESSION', JSON.stringify(filterobject));
  }
    if((CatMobile != '' || CatMobile != null || CatMobile != undefined) && (CatMobile != slug) ){
      window.location.href = `${getBaseUrl()}/collection/category/${CatMobile}`;
    }else{
      setMobFilterModal(false);
      // window.location.reload();
    }
    
  }

  const handleClosesort = () => {
    setShowSort(false)
  };
  const handleShowSortModal = () => {
    setShowSort(true);
  };
  const handleTabChange = (id) => {
    setSelectedTab(id);
  };
  const setsortfilterMobile = (value) => {
    setFilterSortMob(value);
    localStorage.setItem('SORT_SESSION', value)
    getfilterproducts(value);
    setShowSort(false);

  };
  const handleShowFilterModal = () => {
    localStorage.setItem('FILTER_SESSION', JSON.stringify(filterobject));
    setTempfilterobject(filterobject)
    setMobFilterModal(true);
  }
  return (<>
    <Helmet>
      <title>{categorydetail.title}</title>
      <meta
        name="description"
        itemprop="description"
        content={
          categorydetail.description != null
            ? categorydetail.description
            : "Sparsh"
        }
      />
      {categorydetail.keywords != null ? (
        <meta
          name="keywords"
          content={categorydetail.keywords}
        />
      ) : (
        ""
      )}
      <link rel="canonical" href={window.location.href} />
      <meta
        property="og:title"
        content={categorydetail.title}
      />

      <meta
        property="og:image"
        content={imgUrl + categorydetail.image}
      />
      <meta property="og:url" content={window.location.href} />

      <meta
        property="og:description"
        content={
          categorydetail.description != null
            ? categorydetail.description
            : "Sparsh"
        }
      />
      <meta name="twitter:url" content={window.location.href} />
      <meta
        name="twitter:title"
        content={categorydetail.title}
      />

      <meta
        name="twitter:description"
        content={
          categorydetail.description != null
            ? categorydetail.description
            : "Sparsh"
        }
      />
      <meta
        property="twitter:image"
        content={imgUrl + categorydetail.image}
      />
    </Helmet>
    {fullSpinnerLoading && <SpinnerLoader />}
    <BrowserView>
      <WebsiteInnerheader />
      <section className="section-gap-small">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-3 siderbar-filter">
              {contextValues.settingData?.shop_by_category == 1 ? <>
                {catloading && <div className="filterbox mb-10">
                  <h2 className="filterbox-title"><Skeleton ></Skeleton></h2>
                  <div className="filterbox-body filter-common ">
                    <div className="filter-list">
                      <Skeleton height={'250px'} ></Skeleton>
                    </div>
                  </div>
                </div>}
                {CategoryList.length > 0 && !catloading && <div className="filterbox mb-10">
                  <h2 className="filterbox-title">Shop by Categories</h2>
                  <div className="filterbox-body filter-common ">
                    <div className="filter-list">
                      <ul>
                        {CategoryList && CategoryList.map((category, index) => {
                          const isChecked = JSON.parse(localStorage.getItem('FILTER_SESSION'))?.category?.includes(category.cat_slug);
                          return (<>
                            <li key={index}>
                              <input type="checkbox" checked={isChecked} onChange={() => { handlecheckboxfilter('category', category.cat_slug) }} />
                              <span>{category.cat_name}</span>
                            </li>
                          </>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>}
              </> : ''}
              {catloading && <div className="filterbox mb-10">
                <h2 className="filterbox-title"><Skeleton ></Skeleton></h2>
                <div className="filterbox-body filter-common ">
                  <div className="filter-list">
                    <Skeleton height={'250px'} ></Skeleton>
                  </div>
                </div>
              </div>}
              {AttributesList.length > 0 && !catloading && AttributesList.map((attr, index) => (
                <div className="filterbox mb-10" key={index}>
                  <h2 className="filterbox-title">Shop by {attr.attribute_name}</h2>
                  <div className="filterbox-body filter-common ">
                    <div className="filter-list">
                      <ul>
                        {attr.attributeterms && attr.attributeterms.length > 0 && attr.attributeterms.map((attrItem, subIndex) => {
                          const isChecked = JSON.parse(localStorage.getItem('FILTER_SESSION'))?.attributes?.includes(attrItem.terms_name);
                          if (attr.attribute_type == 1) { //Color
                            return (
                              <li key={subIndex}>
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handlecheckboxfilter('attributes', attrItem.terms_name)}
                                />
                                <span>{attrItem.terms_name}</span>
                              </li>
                            );
                          } else if (attr.attribute_type == 2) { //Image
                            return (
                              <li key={subIndex}>
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handlecheckboxfilter('attributes', attrItem.terms_name)}
                                />
                                <span>{attrItem.terms_name}</span>
                              </li>
                            );
                          } else if (attr.attribute_type == 3) { //Text
                            return (
                              <li key={subIndex}>
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => handlecheckboxfilter('attributes', attrItem.terms_name)}
                                />
                                <span>{attrItem.terms_name}</span>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
              {contextValues.settingData?.shop_by_price == 1 ? <>
                <div className="filterbox mb-10">
                  <h2 className="filterbox-title">Shop by Price</h2>
                  <div className="filterbox-body filter-common ">
                    <div style={{ margin: "5px" }}>
                      <Slider
                        range
                        min={contextValues?.settingData?.price_range_from}
                        max={contextValues?.settingData?.price_range_to}
                        value={priceRange}
                        onChange={(range) => setPriceRange(range)}
                        onAfterChange={(range) =>
                          getfilterproducts('', range)
                        }
                      />
                    </div>
                    <div>
                      <p className="mb-0 tx-12">
                        Selected Price Range: {multiCurrency(priceRange[0])} -
                        {multiCurrency(priceRange[1])}
                      </p>
                    </div>
                  </div>
                </div>



              </> : ''}
              {contextValues.settingData?.shop_by_collection == 1 ? <>
                {catloading && <div className="filterbox mb-10">
                  <h2 className="filterbox-title"><Skeleton ></Skeleton></h2>
                  <div className="filterbox-body filter-common ">
                    <div className="filter-list">
                      <Skeleton height={'250px'} ></Skeleton>
                    </div>
                  </div>
                </div>}
                {CollectionList.length > 0 && !catloading && <div className="filterbox mb-10">
                  <h2 className="filterbox-title">Shop by Collection</h2>
                  <div className="filterbox-body filter-common ">
                    <div className="filter-list">
                      <ul>
                        {CollectionList && CollectionList.map((category, index) => {
                          const isChecked = JSON.parse(localStorage.getItem('FILTER_SESSION'))?.collection?.includes(category.cat_slug);
                          return (<>
                            <li key={index}>
                              <input type="checkbox" checked={isChecked} onChange={() => { handlecheckboxfilter('collection', category.cat_slug) }} />
                              <span>{category.cat_name}</span>
                            </li>
                          </>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>}



              </> : ''}

              {contextValues.settingData?.shop_by_type == 1 ? <>
                {catloading && <div className="filterbox mb-10">
                  <h2 className="filterbox-title"><Skeleton ></Skeleton></h2>
                  <div className="filterbox-body filter-common ">
                    <div className="filter-list">
                      <Skeleton height={'250px'} ></Skeleton>
                    </div>
                  </div>
                </div>}
                {ProductTypeList.length > 0 && !catloading && <div className="filterbox mb-10">
                  <h2 className="filterbox-title">Shop by Type</h2>
                  <div className="filterbox-body filter-common ">
                    <div className="filter-list">
                      <ul>
                        {ProductTypeList && ProductTypeList.map((category, index) => {
                          const isChecked = JSON.parse(localStorage.getItem('FILTER_SESSION'))?.type?.includes(category.tag_slug);
                          return (<>
                            <li key={index}>
                              <input type="checkbox" checked={isChecked} onChange={() => { handlecheckboxfilter('type', category.tag_slug) }} />
                              <span>{category.tag_name}</span>
                            </li>
                          </>)
                        })}
                      </ul>
                    </div>
                  </div>
                </div>}


              </> : ''}

            </div>
            <div className="col-lg-9">
              <div className="pagesubheader">
                {loading ? <Skeleton width={'300px'}></Skeleton> : <>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {slug == 'all' ? 'Collection' : categorydetail?.name}
                    </Breadcrumb.Item>
                  </Breadcrumb>

                </>}
                <h1 className="pageTitle">{loading ? <Skeleton width={'300px'}></Skeleton> : slug == 'all' ? 'Collection' : categorydetail?.name} </h1>
                <div className="shortby mb-20">
                  {loading ? <Skeleton width={'200px'}></Skeleton> : <div className="productcount">Showing results of {totalproductcount} products</div>}
                  <div className="shortbydrop">
                    {loading ? <Skeleton width={'100px'}></Skeleton> : <>
                      <label>Sort by</label>
                      <select onChange={(e) => { getfilterproducts(e.target.value) }}>
                        <option value=''>Select</option>
                        <option value='ASC'>Price Low to High</option>
                        <option value='DESC'>Price High to Low</option>
                      </select>
                    </>}
                  </div>
                </div>

              </div>
              {loading ? <>
                <div className="row g-3 cols-xl-4 cols-sm-3 cols-md-4">
                  {skeletonArray && skeletonArray.map((_, index) => {
                    return (<>
                      <div className="product text-center" key={index}>
                        <figure className="product-media">
                          <Skeleton height={'300px'}></Skeleton>
                        </figure>
                        <div className="product-details">
                          <h2 className="product-name"> <Skeleton></Skeleton></h2>
                          <div className="product-price">
                            <ins className="new-price"> <Skeleton width={'100px'}></Skeleton></ins>
                            <del className="old-price"> <Skeleton width={'100px'}></Skeleton></del>
                          </div>
                          <Skeleton></Skeleton>
                        </div>
                      </div>
                    </>)
                  })}
                </div>
              </> :
                <>
                  {catwiseproduct && catwiseproduct.length > 0 ?
                    <div className="row g-3 cols-xl-4 cols-sm-3 cols-md-4">
                      {catwiseproduct && catwiseproduct.map((item, index) => {
                        return (<>
                          <ProductBox productdetail={item} key={index}></ProductBox>
                        </>)
                      })}
                    </div> : <h3>Article coming soon</h3>}
                </>
              }
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </BrowserView>
    <MobileView>
      <MobileInnerheader headertitle={slug == 'all' ? 'Collection' : categorydetail?.name} gobackroute={'/'} />
      <section className="section-gap-small mt-4 pt-5 pb-5">
        <div className="container">
          {loading ? <>
            <div className="row g-3 cols-2 cols-sm-3 cols-md-4">
              {skeletonArray && skeletonArray.map((_, index) => {
                return (<>
                  <div className="product text-center" key={index}>
                    <figure className="product-media">
                      <Skeleton height={'300px'}></Skeleton>
                    </figure>
                    <div className="product-details">
                      <h2 className="product-name"> <Skeleton></Skeleton></h2>
                      <div className="product-price">
                        <ins className="new-price"> <Skeleton width={'100px'}></Skeleton></ins>
                        <del className="old-price"> <Skeleton width={'100px'}></Skeleton></del>
                      </div>
                      <Skeleton></Skeleton>
                    </div>
                  </div>
                </>)
              })}
            </div>
          </> :
            <>
              {catwiseproduct && catwiseproduct.length > 0 ?
                <div className="row g-3 cols-2 cols-sm-3 cols-md-4">
                  {catwiseproduct && catwiseproduct.map((item, index) => {
                    return (<>
                      <ProductBox productdetail={item} key={index}></ProductBox>
                    </>)
                  })}
                </div> : <h3>Article coming soon</h3>}
            </>
          }
        </div>
      </section>
      {/* <Footer /> */}
      <div className="mfb-section">
        <div className="mfb-section-inner">
          <div class="mfb-box border-right" onClick={handleShowSortModal}><i class="ri-arrow-up-down-line mr-10"></i>Sort By</div>
          <div class="mfb-box" onClick={handleShowFilterModal}><i class="ri-filter-line mr-10" ></i>Filter</div>
        </div>
      </div>
      <Modal
        show={MobFilterModal}
        onHide={() => setMobFilterModal(false)}
        className="filterModal bottom"
      >
        <div className="filterModal-section">
          <div className="filterModal-header">
            <h6 className="tx-14 mb-0">Filters</h6>
            <a href="javascript:void(0)"
               onClick={() => window.location.reload()}
              className="tx-12 tx-theme">
              CLEAR ALL
            </a>
          </div>

          <div className="filterModal-body filter-height">
          <div className="filter_tabs">
          {contextValues.settingData?.shop_by_category == 1 ? <>
              <div className="filter_tab">
                  <input
                    type="radio"
                    id="tab-0"
                    name="tab-group-1"
                    checked={selectedTab === "tab-0"}
                    onChange={() => handleTabChange("tab-0")}
                  />
                  <label for="tab-0">Category</label>
                  <div className="filter_content">
                    <div className="fiter_content_list">
                      <ul>
                        {CategoryList.length > 0 &&
                          CategoryList.map((value) => (
                            <li
                              key={value.cat_id}
                              onChange={() => { handlecheckboxfiltermobile('category', value.cat_slug) }}
                            >
                              <span>{value.cat_name}</span>
                              <input
                                type="checkbox"
                                checked={CatMobile == value.cat_slug}
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                </> : ''}

                {AttributesList.length > 0 && !catloading && AttributesList.map((value, index) => (
                    <div className="filter_tab">
                      <input
                        type="radio"
                        id={"tab-" + index + 1}
                        name="tab-group-1"
                        checked={selectedTab === "tab-" + (index + 1)}
                        onChange={() => handleTabChange("tab-" + (index + 1))}
                      />
                      <label for={"tab-" + index + 1}>
                        {value.attribute_name}
                      </label>
                      <div className="filter_content">
                        <div className="fiter_content_list">
                          <ul>
                            {value.attributeterms &&
                              value.attributeterms.length > 0 &&
                              value.attributeterms.map((subvalue) => (
                                <li
                                  key={subvalue.terms_id}
                                  onClick={() => handlecheckboxfiltermobile('attributes', subvalue.terms_name)}
                                >
                                  <span>{subvalue.terms_name}</span>
                                  <input
                                    type="checkbox"
                                    checked={Tempfilterobject?.attributes?.includes(subvalue.terms_name)}
                                  />
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
          {contextValues.settingData?.shop_by_collection == 1 ? <>
                <div className="filter_tab">
                  <input
                    type="radio"
                    id="tab-collection"
                    name="tab-group-1"
                    checked={selectedTab === "tab-collection"}
                    onChange={() => handleTabChange("tab-collection")}
                  />
                  <label for="tab-collection">Collection</label>
                  <div className="filter_content">
                    <div className="fiter_content_list">
                      <ul>
                        {CollectionList.length > 0 &&
                          CollectionList.map((value) => (
                            <li key={value.cat_id} >
                              <span>{value.cat_name}</span>
                              <input
                                type="checkbox"
                                onClick={() => { handlecheckboxfiltermobile('collection', value.cat_slug) }}
                                checked={Tempfilterobject?.collection?.includes(value.cat_slug)}
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </> : ''}

           

                {contextValues.settingData?.shop_by_type == 1 ? <>
                <div className="filter_tab">
                  <input
                    type="radio"
                    id="tab-type"
                    name="tab-group-1"
                    checked={selectedTab === "tab-type"}
                    onChange={() => handleTabChange("tab-type")}
                  />
                  <label for="tab-type">Shop by Type</label>
                  <div className="filter_content">
                    <div className="fiter_content_list">
                      <ul>
                        {ProductTypeList.length > 0 &&
                          ProductTypeList.map((value) => (
                            <li key={value.tag_id}>
                              <span>{value.tag_name}</span>
                              <input
                                type="checkbox"
                                onClick={() => { handlecheckboxfiltermobile('type', value.tag_slug) }}
                                checked={Tempfilterobject?.type?.includes(value.tag_slug)}
                              />
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                </> : ''}
            </div>
            </div>



          <div className="filterModal-footer">
            <span className="border-right" onClick={() => setMobFilterModal(false)}>
              CLOSE
            </span>
            <span className="tx-theme"
             onClick={handleApplyMobFilter}
            >
              APPLY
            </span>
          </div>

        </div>




      </Modal>

    </MobileView>
    {showSort && (
      <Modal show={showSort} onHide={handleClosesort} className="sortModal bottom">
        <div className="sortModalbox">
          <button onClick={handleClosesort} className="pop-close"><i class="ri-close-line"></i></button>
          <ul>
            <li>
              <span>Price Low to High</span>
              <input type="checkbox" value="ASC" checked={FilterSortMob == 'ASC'} onClick={(e) => { setsortfilterMobile(e.target.value) }}/>
            </li>
            <li>
              <span>Price High to Low</span>
              <input type="checkbox" value="DESC" checked={FilterSortMob == 'DESC'} onClick={(e) => { setsortfilterMobile(e.target.value) }} />
            </li>


          </ul>
        </div>
      </Modal>
    )}




  </>)
}

export default Collection