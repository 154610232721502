import React from 'react';
import moment from "moment";
const Steps = ({ status, date, statustext }) => {
    const stepClasses = `col-3 bs-wizard-step ${status === "active" ? "active" : ""
        } ${status === "complete" ? "complete" : ""} ${status === "disabled" ? "disabled" : ""
        }`;

    return (
        <div className={stepClasses}>
            <div className="progress">
                <div className="progress-bar"></div>
            </div>
            <a href="#" className="bs-wizard-dot"></a>
            <div className="bs-wizard-info text-center">
                <p className="mb-0">{statustext}</p>
                {date ? (
                    <p className="mb-0 tx-12">{moment(date).format("DD MMM YYYY")}</p>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};
const orderStatusComponent = (value) => {
    if (value.trans_status == 1 && value.trans_accept_status == 0) {
        return (
            <div className="row bs-wizard mt-10">
                <Steps
                    status="complete"
                    date={value.created_at}
                    statustext="Order Placed"
                />
                <Steps
                    status="active"
                    date={''}
                    statustext="Processing"
                />
                <Steps
                    status="disabled"
                    date=""
                    statustext="Shipped"
                />
                <Steps
                    status="disabled"
                    date=""
                    statustext="Delivered"
                />
            </div>

        )

    }
    else if (value.trans_status == 1 && value.trans_accept_status > 0) {
        return (<div className="row bs-wizard mt-10">
            <Steps
                status="complete"
                date={value.created_at}
                statustext="Order Placed"
            />

            <Steps
                status="active"
                date={value.trans_confirmed_date}
                statustext="Order Confirmed"
            />
            <Steps
                status="disabled"
                date=""
                statustext="Shipped"
            />
            <Steps
                status="disabled"
                date=""
                statustext="Delivered"
            />
        </div>)

    }
    else if (value.trans_status == 2) {
        return (<div className="row bs-wizard mt-10">
            <Steps
                status="complete"
                date={value.created_at}
                statustext="Order Placed"
            />

            <Steps
                status="complete"
                date={value.trans_confirmed_date}
                statustext="Order Confirmed"
            />
            <Steps
                status="disabled"
                date=""
                statustext="Payment Pending"
            />

        </div>)
    }
    else if (value.trans_status == 3) {
        return (<div className="row bs-wizard mt-10">
            <Steps
                status="complete"
                date={value.created_at}
                statustext="Order Placed"
            />
            <Steps
                status="active"
                date={value.trans_onhold_date}
                statustext="On Hold"
            />
            <Steps
                status="disabled"
                date={value.trans_shiped_date}
                statustext="Shipped"
            />
            <Steps
                status="disabled"
                date={value.trans_delivered_date}
                statustext="Delivered"
            />
        </div>)


    }
    else if (value.trans_status == 4) {
        return (<div className="row bs-wizard mt-10">
            <Steps
                status="complete"
                date={value.created_at}
                statustext="Order Placed"
            />
            <Steps
                status="complete"
                date={value.trans_shiped_date}
                statustext="Shipped"
            />
            <Steps
                status="complete"
                date={value.trans_delivered_date}
                statustext="Delivered"
            />
        </div>)


    }
    else if (value.trans_status == 5) {
        return (<div className="row bs-wizard mt-10">
            <Steps
                status="complete"
                date={value.created_at}
                statustext="Order Placed"
            />
            <Steps
                status="complete"
                date={value.trans_cancellation_date}
                statustext="Cancelled"
            />
        </div>)

    }
    else if (value.trans_status == 6) {
        return (<div className="row bs-wizard mt-10">
            <Steps
                status="complete"
                date={value.created_at}
                statustext="Order Placed"
            />
            <Steps
                status="active"
                date={value.trans_shiped_date}
                statustext="Shipped"
            />
            <Steps
                status="disabled"
                date=""
                statustext="Delivered"
            />
        </div>)


    }
    else if (value.trans_status == 7) {
        return (<div className="row bs-wizard mt-10">
            <Steps
                status="complete"
                date={value.created_at}
                statustext="Order Placed"
            />
            <Steps
                status="active"
                date={value.trans_pickedup_date}
                statustext="Picked up"
            />
            <Steps
                status="disabled"
                date={value.trans_shiped_date}
                statustext="Shipped"
            />
            <Steps
                status="disabled"
                date={value.td_delivered_date}
                statustext="Delivered"
            />
        </div>)

    }

};

export default orderStatusComponent;
