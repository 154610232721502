
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Component/Services/apiservices";
import constant from "../../Component/Services/constant";
import Skeleton from "react-loading-skeleton";
import { BrowserView, MobileView } from "react-device-detect";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import MobileInnerheader from "../../Component/Header/mobile-header/inner";
import { Helmet } from "react-helmet";

const BlogsList = () => {
  const didMountRef = useRef(true);
  const [blogdata, setblogdata] = useState([])
  const [blogimgpath, setblogimgpath] = useState('')
  const [loading, setloading] = useState(false)
  const [pagecontent, setpagecontent] = useState({})
  const [headerImageUrl, setHeaderImageUrl] = useState("")

  useEffect(() => {
    if (didMountRef.current) {
      getpagescontent()
      getblogsdata()
    }
    didMountRef.current = false;
  }, [])

  const getpagescontent = () => {
    const datastring = {
      slug: 'blogs'
    }
    ApiService.postData('/page-content', datastring).then((res) => {
      if (res.status == 'success') {
        setpagecontent(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
      else if (res.status == 'error') {
        navigate('/page-not-found')
      }
    })
  }

  const skeletonArray = Array.from({ length: 3 });
  const navigate = useNavigate()
  const getblogsdata = () => {
    try {
      setloading(true);
      ApiService.fetchData('blog-list')
        .then((res) => {
          if (res?.status === 'success') {
            setblogdata(res.blogsData);
            setblogimgpath(res.blog_image_path);
            setloading(false);
          }
        })
        .catch((error) => {
          setloading(false);
          console.error('An error occurred while fetching blog data:', error);
        })

    } catch (error) {
      console.error('An error occurred in getblogsdata:', error);
      setloading(false);
    }
  };
  return (<>

<Helmet>
        <title>{pagecontent.page_meta_title}</title>
        <meta
          name="description"
          itemprop="description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
        />
        {pagecontent.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={pagecontent.page_meta_keyword}
          />
        ) : (
          ""
        )}
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content={pagecontent.page_meta_title}
        />
        
        <meta
          property="og:image"
          content={headerImageUrl + pagecontent.page_header_image}
        />
        <meta property="og:url" content={window.location.href} />

        <meta
          property="og:description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
        />
         <meta name="twitter:url" content={window.location.href} /> 
         <meta
          name="twitter:title"
          content={pagecontent.page_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
        />
        <meta
          property="twitter:image"
          content={headerImageUrl + pagecontent.page_header_image}
        /> 
      </Helmet>
    <BrowserView>
      <WebsiteInnerheader></WebsiteInnerheader>
      <div className="subheader">
        <Container>
          <Row>
            <Col lg={12}>
              <h1>News & Blog</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                <Breadcrumb.Item active>
                  News & Blog
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </div>


      {loading ? <>
        <section className="section-gap-small">
          <div className="container">
            <div className="col-lg-8">
              {skeletonArray.map((_, index) => {
                return (<>
                  <div className="bloglist" key={index}>
                    <div className="row">
                      <div className="col-lg-4">
                        <figure className="bloglistMedia">
                          <a href=''>
                            <Skeleton height={'200px'}></Skeleton>
                          </a>
                        </figure>
                      </div>
                      <div className="col-lg-8">
                        <h2 className="title"><a href=''> <Skeleton></Skeleton></a></h2>
                        <div className="shortdesc"> <Skeleton width={'400px'}></Skeleton>
                          <Skeleton width={'400px'}></Skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                </>)
              })}

            </div>
          </div>
        </section>

      </> : <>
        {blogdata && blogdata.length > 0 ? <>
          <section className="section-gap-small">
            <div className="container">
              <div className="col-lg-8">
                {blogdata.map((item, index) => {
                  return (<>
                    <div className="bloglist" key={index} onClick={() => { navigate(`/blog-detail/${item.blog_slug}`) }}>
                      <div className="row">
                        <div className="col-lg-4">
                          <figure className="bloglistMedia">
                            <a href='javascript:void(0)'>
                              <img src={item.blog_image ? blogimgpath + '/' + item.blog_image : constant.DEFAULT_IMAGE} alt={item?.blog_name}></img>
                            </a>
                          </figure>
                        </div>
                        <div className="col-lg-8">
                          <div className="bloglistContent">
                            <h2 className="title"><a href='javascript:void(0)'>{item?.blog_name}</a></h2>
                            <div className="shortdesc">{item.blog_short_description}</div>
                            <a href='javascript:void(0)' className="mt-20 btn-line small" style={{ display: 'inline-block' }}><span>Read More</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>)
                })}

              </div>
            </div>
          </section>
        </> :   <div className='noimg'>
                  <h6>No blogs are there right now</h6>
                </div>}
      </>}
    </BrowserView>
    <MobileView>
      <MobileInnerheader headertitle={'News & Blog'} gobackroute={'/'}></MobileInnerheader>
      <main>
        <div className="acpanel">
          <div className="acpanel-body">
            {loading ? <div className="col-lg-8">
              {skeletonArray.map((_, index) => {
                return (<>
                  <div className="bloglist" key={index}>
                    <div className="row">
                      <div className="col-lg-4">
                        <figure className="bloglistMedia">
                          <a href=''>
                            <Skeleton height={'200px'}></Skeleton>
                          </a>
                        </figure>
                      </div>
                      <div className="col-lg-8">
                        <h2 className="title"><a href=''> <Skeleton></Skeleton></a></h2>
                        <div className="shortdesc"> <Skeleton width={'400px'}></Skeleton>
                          <Skeleton width={'400px'}></Skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                </>)
              })}

            </div> : <>
              {blogdata && blogdata.length > 0 ? <>
                <div className="col-lg-8">
                  {blogdata.map((item, index) => {
                    return (<>
                      <div className="bloglist" key={index} onClick={() => { navigate(`/blog-detail/${item.blog_slug}`) }}>
                        <div className="row">
                          <div className="col-lg-4">
                            <figure className="bloglistMedia">
                              <a href='javascript:void(0)'>
                                <img src={item.blog_image ? blogimgpath + '/' + item.blog_image : constant.DEFAULT_IMAGE} alt={item?.blog_name}></img>
                              </a>
                            </figure>
                          </div>
                          <div className="col-lg-8">
                            <div className="bloglistContent">
                              <h2 className="title"><a href='javascript:void(0)'>{item?.blog_name}</a></h2>
                              <div className="shortdesc">{item.blog_short_description}</div>
                              <a href='javascript:void(0)' className="mt-20 btn-line small" style={{ display: 'inline-block' }}><span>Read More</span></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>)
                  })}

                </div>
              </> : <>
                <div className='noimg'>
                  <h6>No blogs are there right now</h6>
                </div>
              </>}
            </>}
          </div>
        </div>
      </main>
    </MobileView>
    <Footer></Footer>
  </>)
}

export default BlogsList