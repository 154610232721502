import { MobileView } from "react-device-detect"
import MobileaccountHeader from "../../Component/Header/mobile-header/account"


const Aboutus = () => {
    return (<>
        <MobileView>
            <MobileaccountHeader heardername={'About Us'} backroute={'/my-account'}></MobileaccountHeader>
            <main>
        <div className="acpanel">
          <div className="acpanel-body">
            <div className="row">
            <div className="mlist">
                <ul>
                    <li><a href="/terms-condition" target="new">Terms of Service<i class="ri-arrow-right-s-line"></i></a></li>
                    <li ><a href='/privacy-policy' target="new">Privacy Policy<i class="ri-arrow-right-s-line"></i></a></li>
                    <li><a href='/refund-policy' target="new">Refunds and Returns<i class="ri-arrow-right-s-line"></i></a></li>
                    <li><a href='/feedback-form'>Feedback Form<i class="ri-arrow-right-s-line"></i></a></li>
                    <li><a href='/contact-us'>Contact Us<i class="ri-arrow-right-s-line"></i></a></li>
                </ul>
            </div>
            </div>
          </div>
        </div>
      </main>
           
        </MobileView>
    </>)
}

export default Aboutus