import React, { useState, useContext, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import OtpInput from 'react-otp-input';
import { validEmail, validNumber } from '../Elements/Regex';
import { ApiService } from "../Services/apiservices";
import DataContext from '../Elements/context';
import constant from "../Services/constant";
import { useNavigate } from "react-router-dom";
import ReactPixel from "../Services/FacebookPixel";
function LoginModal() {
  const navigate = useNavigate()
  const contextValues = useContext(DataContext);
  const didMountRef = useRef(true);
  const [user_mobile, setuser_mobile] = useState('')
  const [otp, setOtp] = useState('')
  const [steps, setsteps] = useState(1)
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [resendTimer, setResendTimer] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  const [spinnerLoading, setspinnerLoading] = useState(false);
  const [registerDisable, setRegisterDisable] = useState(true);
  const [userRegDetails, setUserRegDetails] = useState({
    user_fname: "",
    user_email: "",
    user_mobile: "",
  });
  const loginmodal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }
  useEffect(() => {
    const { user_fname, user_email } = userRegDetails;
    const isEmailValid = validEmail.test(user_email);
    const shouldDisableRegister = !user_fname || !user_email || !isEmailValid;
    setRegisterDisable(shouldDisableRegister);
    didMountRef.current = false;
    const timerId = setInterval(() => {
      if (resendTimer > 0) {
        setResendTimer(resendTimer - 1);
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };

  }, [resendTimer, userRegDetails]);

  const goBack = () => {
    setErrorMessage("");
    setOtp("");
    setsteps(1);
  }
  const onChangeOpt = (otp) => {
    setErrorMessage("");
    setOtp(otp);
    if (otp.length === 4) {
      userLoginOtpProcess(otp);
    }
  };
  const userLogin = () => {
    if (!validNumber.test(user_mobile)) {
      setErrorMessage("Please enter valid Mobile Number");
      return false;
    }
    setIsLoading(true)
    const dataString = { user_mobile: user_mobile }
    ApiService.loginProccessPostData("logincheck", dataString).then((res) => {
      if (res.status === "success") {
        localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
        setsteps(2);
        setIsLoading(false)
        setResendTimer(30);
      } else {
        setErrorMessage(res.message);
        setIsLoading(false)
      }
    })
  };

  const resendOTP = () => {
    setErrorMessage("");
    setResendTimer(30);
    setOtp("");
    setspinnerLoading(true)
    const dataString = {
      user_token: '',
    }
    ApiService.loginProccessPostData('resendotp', dataString).then((res) => {
      if (res.status === "success") {
        setTimeout(() => {
          setspinnerLoading(false);
        }, 500);
      } else {
        setErrorMessage(res.message);
        setTimeout(() => {
          setspinnerLoading(false);
        }, 500);
      }
    });
  }

  const userLoginOtpProcess = (otp) => {
    setErrorMessage("");
    setspinnerLoading(true)
    const dataString = {
      user_otp: otp,
    };
    ApiService.loginProccessPostData("otpverify", dataString).then((res) => {
      if (res.status === "success") {
        if (res.user_status === 'registration_incomplete') {
          setTimeout(() => {
            localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
            setsteps(3)
            setspinnerLoading(false)
          }, 500);
        } else {
          setTimeout(() => {
            localStorage.setItem("USER_TOKEN", res.user_token);
            ReactPixel.track('Login', {
              em: res.user_data.user_email,
              fo: res.user_data.user_fname,
              ph: res.user_data.user_mobile,
            });
            if(contextValues.loginRedirect){
              contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
              navigate(contextValues.loginRedirect)
              if(contextValues.loginRedirect=='/cart'){
                window.location.reload();
              }
            }else{
              window.location.reload();
            }
          }, 500);
        }
      } else {
        setTimeout(() => {
          setErrorMessage(res.message);
          setspinnerLoading(false)
        }, 500);
      }
    });

  };

  const userRegisterProcess = () => {
    setErrorMessage("");
    if (userRegDetails.user_fname === '') {
      setErrorMessage("Please enter Full Name");
      return false;
    } else if (userRegDetails.user_email === '') {
      setErrorMessage("Please enter Email Address");
      return false;
    } else if (!validEmail.test(userRegDetails.user_email)) {
      setErrorMessage("Please enter valid Email Address");
      return false;
    }

    const dataString = {
      "user_fname": userRegDetails.user_fname,
      "user_email": userRegDetails.user_email,
    };
    setIsLoading(true)
    ApiService.loginProccessPostData("registerprocess", dataString).then((res) => {
      if (res.status === "success") {
        localStorage.setItem("USER_TOKEN", res.user_token);
        ReactPixel.track('CompleteRegistration', {
          content_name: res.user_data.user_fname,
          status: 1,
          currency: 'INR',
          value: 0,
          em: res.user_data.user_email,
          ph: res.user_data.user_mobile,
        });
        setSuccessMessage(res.message)
        setTimeout(() => {
          if(contextValues.loginRedirect){
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
            navigate(contextValues.loginRedirect)
          }else{
            window.location.reload();
          }
        }, 500);
      } else {
        setErrorMessage(res.message);
        setTimeout(() => {
          setIsLoading(false)
        }, 500);
      }
    });
  }
  const onTodoRegChange = (e) => {
    setErrorMessage('')
    setSuccessMessage('')
    const { name, value } = e.target;
    setUserRegDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const bannerImage = contextValues.bannerImage
  const sliderurl = contextValues.sliderurl

  return (
    <>
      <Modal show={contextValues.toggleLoginModal} onHide={loginmodal} className="loginModal">
        <div className="loginModalContent">
          <button onClick={() => { loginmodal() }} className="modalCloseButton"><i className="ri-close-line ri-2x"></i></button>
          <div className="left">
            <div className="loginModalimg">
              <img src={bannerImage.slider_image ? sliderurl + bannerImage.slider_image : constant.DEFAULT_IMAGE} alt={bannerImage.slider_name}></img>
            </div>
          </div>
          {/* loin with number */}
          {steps == 1 &&
            <div className="right">
              {/* <div className="text-center">
              <img src='./img/logo.png' height="64px" width="125px" ></img>
              </div> */}
             
              <h4 className="tx-primary">Login with Sparsh</h4>
              <p className="tx-12">For Better Experience, Order tracking & Regular updates</p>
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}

              <div className="form-group country-input mb-15">
                <input type="number" value={user_mobile} onChange={(e) => { setuser_mobile(e.target.value); setErrorMessage(''); setSuccessMessage('') }}></input>
                <span className="country-code">+91</span>
              </div>
              <button className="btn-theme btn-theme-lg btn-full mb-10" type="button" onClick={userLogin} disabled={user_mobile.length !== 10}><span> {isLoading ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Continue")}</span></button>
              <p className="text-center tx-12 mb-0">By continuing I agree with the <a href="/privacy-policy" className="tx-primary" target='_blank'>Privacy Policy</a> and <a href="/terms-condition" className="tx-primary" target='_blank'>Terms &amp; Conditions</a></p>
            </div>
          }
          {/* verify otp */}
          {steps == 2 && <div className="right">
            <h5 className="tx-primary">OTP Verification</h5>
            <p className="tx-14">We have sent a verification code to <span className="font-third">{user_mobile} </span><a href='javascript:;' className='tx-primary' onClick={(e) => goBack()}>Change</a></p>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            <div className='form-group mb-15 otp-input'>
              <OtpInput
                value={otp}
                onChange={onChangeOpt}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            {resendTimer === 0 ? (
              <p className='tx-gray text-center tx-12'>
                Did not receive OTP? <a href='javascript:;' className='tx-primary' onClick={(e) => resendOTP()}>Resend OTP</a>
              </p>
            ) : (
              <h6 className='tx-gray text-center tx-12'>Resend code in {resendTimer} sec</h6>
            )}
            {spinnerLoading && (
              <div className="siteloader">
                <img src="/img/loader.webp" alt="Loading..." width="30" height="30" />
              </div>
            )}
          </div>
          }

          {/* new register */}
          {steps == 3 && <div className="right">
            <h5 className="tx-primary">Complete Your Registration</h5>
            <p className="tx-12">Unlock Exclusive Deals and a World of Bedsheets.</p>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            <div className="form-group mb-15">
              <input
                type="text"
                name="user_fname"
                className="form-control registerRequired"
                value={userRegDetails.user_fname}
                onChange={(e) => onTodoRegChange(e)}
                placeholder="Full Name"
              />
            </div>
            <div className="form-group  mb-15">
              <input
                type="email"
                name="user_email"
                className="form-control registerRequired"
                value={userRegDetails.user_email}
                onChange={(e) => onTodoRegChange(e)}
                placeholder="Email Address"
              />
            </div>
            <button className='btn-theme btn-theme-lg btn-full mb-10' type='button' onClick={userRegisterProcess} disabled={registerDisable}>
              <span>
                {isLoading ? (
                  <img src="/img/loder01.gif" width="60px" height="11px" />
                ) : (
                  "Register"
                )}
              </span>
            </button>
          </div>
          }

        </div>
      </Modal>
    </>
  );
}
export default LoginModal;
