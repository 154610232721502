import { BrowserView, MobileView } from "react-device-detect";

const Homewhyus = () => {
    return (<>

        <section className="whyus" style={{ background: 'url(/img/whybg.jpg)' }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="whyusbox" style={{ background: 'url(https://indraprints.com/public/frontend_assets/assets/img/banner_garden_stories_1512x.webp)' }}>
                            <div className="whyusboxContent">
                                <h2>Sparsh Exclusives Bedding</h2>
                                <p>Sparsh is a home grown brand , we are manufacturers of bedsheets, dohars , quilts , comforters.</p>
                                <button className="btn-line-second mt-20"><span>Shop Now</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default Homewhyus