
import Modal from 'react-bootstrap/Modal';
import React, { useContext } from 'react';
import DataContext from '../Elements/context';
import { useNavigate } from 'react-router-dom';
import sessionCartData from '../Elements/cart_session_data';
import multiCurrency from '../Elements/multi_currrency';
import constant from '../Services/constant';
import { addToCart, minusToCart } from '../Elements/add_to_cart';
import Loader from "react-js-loader";
import { ApiService } from '../Services/apiservices';
import { trackInitiateCheckout } from '../Services/facebookTracking';
const CartModal = () => {
  const navigate = useNavigate()
  const contextValues = useContext(DataContext);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const parsedCartSummary = dataArray[3];
  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }

  const checkoutModal = () => {
    localStorage.removeItem("COUPON_SESSION");
    localStorage.removeItem("ADDRESS_SESSION");
    checkoutOrderSummary()
  }

  const checkoutOrderSummary = () => {
    const productData = {
      sessionItemsSummary: parsedCartSession
    };
    ApiService.postData("checkOrderSummary", productData).then((res) => {
      if (res.status === 'success') {
        localStorage.removeItem("CART_SESSION");
        localStorage.setItem("CART_SESSION", JSON.stringify(res.updatedSessionData));
        trackInitiateCheckout(res.updatedSessionData)
        contextValues.setAddressSession(dataArray[0])
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCouponSession(dataArray[2])
        contextValues.setCartSummary(dataArray[3])
        contextValues.setCartCount(dataArray[1].length)
        const hasItemWithStatusTrue = res.updatedSessionData.some((item) => {
          return (item.product_item_status === true);
        });
        if (!hasItemWithStatusTrue) {
          cartModal()
          navigate('/address')
        }
      } else {
      }
    }).catch((error) => {
    });
  }

  const plusToCart = async (productValue,index) => {
    contextValues.setSpinnerCubLoader(index)
    const productData = {
      product_id: Number(productValue.product_id),
      product_name: productValue.product_name,
      product_slug: productValue.product_slug,
      product_image: productValue.product_image
        ? productValue.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(productValue.product_type),
      product_price: Number(productValue.product_price),
      product_selling_price: Number(productValue.product_selling_price),
      product_discount: productValue.product_discount,
      product_variation: productValue.product_variation,
      product_category_id: productValue.product_category_id,
    };
    const updateStatus = await addToCart(productData, 1, contextValues);
    if (updateStatus) {
      contextValues.setSpinnerCubLoader(0)
    } else {
      contextValues.setSpinnerCubLoader(0)
    }
  };

  const removeToCart = (productValue,index) => {
    contextValues.setSpinnerCubLoader(index)
    if (minusToCart(productValue, productValue.product_variation, contextValues)) {
      setTimeout(() => {
        contextValues.setSpinnerCubLoader(0)
      }, 500);
    } else {
      setTimeout(() => {
        contextValues.setSpinnerCubLoader(0)
      }, 500);
    }
  };

  const loginModal = () => {
    cartModal()
    contextValues.setLoginRedirect('/address')
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  } 

  return (<>
    <Modal className="right cartmodal" show={contextValues.toggleCartModal} onHide={(e) => { cartModal() }}>
      {parsedCartSession.length > 0 ? (
        <div className='cartSection'>
          <div className='cartHeader'>
            <h6 className='mb-0'>Shopping Cart</h6>
            <button type="button" className="cart-close" onClick={(e) => cartModal()}><i className="ri-close-line ri-xl"></i></button>
          </div>
          <div className='cartScoller'>
            {parsedCartSession.map((value, index) => {
              return (
                <div className='cartProduct' key={index} >
                  <figure className='cartProduct-media'>
                    <a href={"/product/" + value.product_slug}>
                      <img src={value.product_image}></img>
                    </a>
                  </figure>
                  <div className='cartProduct-details'>
                    <a className='cartProductName' href={"/product/" + value.product_slug}>{value.product_name}</a>
                    {value.product_variation && value.product_variation.length > 0 ? <div className='cartProductSize'>{value.product_variation.join(", ")}</div> : null}
                    <div className='cartProduct-footer'>
                      <div className='cartProductPrice'>
                        <ins className="new-price">{multiCurrency(value.product_selling_price)}</ins>
                        <del className="old-price">{multiCurrency(value.product_price)}</del>
                      </div>
                      <div className='cartProductqty'>
                        {contextValues.spinnerCubLoader == index+Number(1) ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> : <>
                          <span onClick={(e) => removeToCart(value,index+Number(1))}><i className="ri-subtract-line"></i></span>
                          <span>{value.quantity}</span>
                          <span onClick={(e) => plusToCart(value,index+Number(1))}><i className="ri-add-line"></i></span>
                        </>
                        }
                      </div>
                    </div>
                    {value.product_item_status ? <div style={{ 'color': 'red' }}>{value.product_item_status_message}</div> : null}
                  </div>
                </div>
              );
            })}
          </div>
          <div className='cartFooter'>
            <div className='d-flex mb-20 justify-content-between'>
              <div>
                <h6 className='mb-1'>Estimated Total </h6>
                <p className='mb-0 tx-12'>Shipping & taxes calculated at checkout.</p>
              </div>
              <div className='d-flex justify-content-end'>
                <div className='carttotle'>{parsedCartSummary && parsedCartSummary.sellingTotal ? multiCurrency(parsedCartSummary.sellingTotal) : multiCurrency(0)}</div>
              </div>
            </div> 
            <div className='text-center'>
            <button type='button' className="cartbox-action-btn" onClick={(e) => navigate('/cart')}><span>View Cart</span></button>
            {contextValues.userToken ? <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => checkoutModal()}><span>Checkout</span></button> : <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => loginModal()}><span>Checkout</span></button>}
          </div>
          </div>
        </div>
      ) : (
        <div className='noimg'>
          <img src='/img/emplty-cart-removebg-preview.png' className='wd-200 mb-20'></img>
          <h6>No Products in Cart</h6>
          <p className='tx-gray tx-14'>Look like you have not added anythings to you cart. Go agead & explore top categories</p>
          <a href='/' className='btn-line'><span>Start Shopping</span></a>
        </div>
      )}
    </Modal>
  </>)
}

export default CartModal