
import { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import DataContext from '../Elements/context';
import { useNavigate } from 'react-router-dom';
import { ApiService } from '../Services/apiservices';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';


const SideMenu = () => {
  const navigate = useNavigate()
  const didMountRef = useRef(true);
  let sessionToken = localStorage.getItem("USER_TOKEN")
  const contextValues = useContext(DataContext)
  const [showHelpSubMenu, setShowHelpSubMenu] = useState(-1);
  const [showHelpFirstSubMenu, setShowHelpFirstSubMenu] = useState(-1);
  useEffect(() => {
    if (didMountRef.current) {
      if (sessionToken && sessionToken !== '') {
        getUserdata()
       
      }
    }
    didMountRef.current = false;


  }, []);
  const [openCategoryIndices, setOpenCategoryIndices] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggleCategory = (item, index) => {

    if (item.children.length > 0) {
      setOpenCategoryIndices(prevIndices => {
        if (prevIndices.includes(index)) {
          return prevIndices.filter(i => i !== index);
        } else {
          return [...prevIndices, index];
        }
      });
    }
    else {
      childnavigation(item)
    }

  };
  const getUserdata = () => {
    ApiService.fetchData('get-user-data').then((res) => {
      if (res.status == 'success') {
        contextValues.setRowUserData(res.rowUserData)
      }
      else if (res.status == 'session_expired') {
        localStorage.removeItem('USER_TOKEN')
        localStorage.removeItem('TEMP_USER_TOKEN')
        navigate('/')
      }
      else {
        toast.error(res.message)
      }
    }).catch((error) => {

    })
  }

  const sidemenuModalModal = () => {
    contextValues.setToggleSidemenuModal(!contextValues.toggleSidemenuModal)
  }
  const accountModal = () => {

    if (!sessionToken || sessionToken == null) {
      contextValues.setLoginRedirect('/my-account')
      contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
    } else {
      navigate('/my-account')
    }
  }
  const childnavigation = (item) => {
    if (item.menu_pageid > 0) {
      navigate(item.pages.page_url)
    }
    else if (item.menu_categoryid > 0) {
      navigate(`/collection/category/${item.categories.cat_slug}`)
    }
    else {
      navigate(item.menu_customlink)
    }

  }
  const goToPage = (route) => {
    navigate(route);
  };
  const clearSession =()=>{
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('RANGE_SESSION')
    
  }
  const toggleHelpSubMenu = (index) => {
    setShowHelpSubMenu((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const toggleHelpFirstSubMenu = (index) => {
    setShowHelpFirstSubMenu((prevIndex) => (prevIndex === index ? -1 : index));
  };
const accountModalopen=(path)=>{
  contextValues.setLoginRedirect(path)
  contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
}

  return (<>
    <Modal className="left menuModal" show={contextValues.toggleSidemenuModal} onHide={(e) => { sidemenuModalModal() }}>
     

      {sessionToken ? (
        <div
          className="msprofile d-flex align-items-center"
          onClick={(e) => goToPage("/my-account")}
        >
          <div className="msprofile-media">
            <img src="/img/user.png" />
          </div>
          <div className="msprofile-content">
            <h6 className="mb-0 tx-14">Hi! {contextValues.rowUserData.user_fname}</h6>
            <p className="tx-13 tx-color-02 mb-0">{contextValues.rowUserData.user_email}</p>
          </div>
          <div className="msprofile-arrow">
          <i className="ri-arrow-right-s-line ri-xl" ></i>
          </div>
        </div>
      ) : (
        <div
          className="msprofile d-flex align-items-center"
         onClick={()=>{accountModal()}}
        >
          <div className="msprofile-media">
            <img src="/img/user.png" />
          </div>
          <div className="msprofile-content">
            <h6 className="mb-0 tx-14">Hi Guest!</h6>
            <p className="tx-13 tx-color-02 mb-0">Login / Register</p>
          </div>
          <div className="msprofile-arrow">
          <i className="ri-arrow-right-s-line ri-xl" ></i>
          </div>
        </div>
      )}
      <div className="sidemenu">
        <ul>
        {loading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <li key={index} className="skeleton-menu-item">
              <div className="skeleton-menu-item-inner">
                <Skeleton variant="text" width={100} />
              </div>
            </li>
          ))
        ) : (
          contextValues.menuList.map((parent, index) => {
          const hasChildren = parent.children && parent.children.length > 0;
          const isSubMenuOpen = showHelpSubMenu === index;
          return (
            <li className={hasChildren ? 'sub-menu' : ''} key={parent.menu_slug}>
              <div className='sub-menu-inner'   onClick={clearSession}  >
                {parent.menu_categoryid > 0 ? 
                <a href={"/collection/category/" + parent.menu_slug} >
                  {parent.menu_name}
                </a>:parent.menu_collectionid>0?<a href={"/collection/collection/" + parent.menu_slug} >
                  {parent.menu_name}
                </a>:parent.menu_pageid > 0 ? <a href={"/" + parent.pages.page_url} >
                  {parent.menu_name}
                </a>:<a href={parent.menu_customlink} >
                  {parent.menu_name}
                </a>}
               
                {hasChildren && (
                  <div className='right' onClick={() => toggleHelpSubMenu(index)}>
                    {/* <FontAwesomeIcon icon={isSubMenuOpen ? faCaretUp : faCaretDown} /> */}
                    {isSubMenuOpen? <i className="ri-arrow-right-s-line ri-xl" ></i>: <i className="ri-arrow-down-s-line ri-xl" ></i> }
                   
                    
                  </div>
                )}
              </div>
              {hasChildren && isSubMenuOpen && (
                <ul> 
                  {parent.children.map((firstChild, indexFirstChild) => {
                    const hasFirstChildren = firstChild.children && firstChild.children.length > 0;
                    const isFirstSubMenuOpen = showHelpFirstSubMenu === indexFirstChild;
                    if (firstChild.menu_show_image !== 1) {
                      return (
                        <li className={hasFirstChildren ? 'sub-menu' : ''} key={firstChild.menu_slug}>
                          <div className='sub-menu-inner'   onClick={clearSession}  >
                            {firstChild.menu_categoryid > 0 ?
                              <a href={"/collection/category/" + firstChild.menu_slug} >
                                {firstChild.menu_name}
                              </a> : firstChild.menu_collectionid?  <a href={"/collection/collection/" + firstChild.menu_slug} >
                                {firstChild.menu_name}
                              </a>:firstChild.menu_pageid > 0 ? <a href={"/" + firstChild.pages.page_url} >
                                {firstChild.menu_name}
                              </a> : <a href={firstChild.menu_customlink} >
                                {firstChild.menu_name}
                              </a>}
                            {hasFirstChildren && (
                              <div className='right' onClick={() => toggleHelpFirstSubMenu(indexFirstChild)}>
                                {/* <FontAwesomeIcon icon={isFirstSubMenuOpen ? faCaretUp : faCaretDown} /> */}
                                {isFirstSubMenuOpen?<i className="ri-arrow-right-s-line ri-xl" ></i> : <i className="ri-arrow-down-s-line ri-xl" ></i> }
                              </div>
                            )}
                          </div>
                          {hasFirstChildren && isFirstSubMenuOpen && (
                            <ul>
                              {firstChild.children.map((thirdChild,indexThirdChild) => (
                                <li key={thirdChild.cat_slug}>
                                   <div className='sub-menu-inner'   onClick={clearSession}  >
                                  {thirdChild.menu_categoryid > 0 ?
                                  <a href={"/collection/category/" + thirdChild.menu_slug} >
                                    {thirdChild.menu_name}
                                  </a> : thirdChild.menu_collectionid>0?  <a href={"/collection/collection/" + thirdChild.menu_slug} >
                                    {thirdChild.menu_name}
                                  </a>:
                                  thirdChild.menu_pageid > 0 ? <a href={"/" + thirdChild.pages.page_url} >
                                    {thirdChild.menu_name}
                                  </a> : <a href={thirdChild.menu_customlink} >
                                    {thirdChild.menu_name}
                                      </a>}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      );
                    }
                    else{
                      return (
                        <li className={hasFirstChildren ? 'sub-menu' : ''} key={firstChild.menu_slug}>
                          <div className='sub-menu-inner'   onClick={clearSession}  >
                            {firstChild.menu_categoryid > 0 ?
                              <a href={"/collection/category/" + firstChild.menu_slug} >
                                {firstChild.menu_name}
                              </a> :
                              firstChild.menu_collectionid > 0 ?<a href={"/collection/collection/" + firstChild.menu_slug} >
                              {firstChild.menu_name}
                            </a> :
                              firstChild.menu_pageid > 0 ? <a href={"/" + firstChild.pages.page_url} >
                                {firstChild.menu_name}
                              </a> : <a href={firstChild.menu_customlink} >
                                {firstChild.menu_name}
                              </a>}
                            {hasFirstChildren && (
                              <div className='right' onClick={() => toggleHelpFirstSubMenu(indexFirstChild)}>
                                {/* <FontAwesomeIcon icon={isFirstSubMenuOpen ? faCaretUp : faCaretDown} /> */}
                                {isFirstSubMenuOpen?<i className="ri-arrow-right-s-line ri-xl" ></i> : <i className="ri-arrow-down-s-line ri-xl" ></i> }
                              </div>
                            )}
                          </div>
                          {hasFirstChildren && isFirstSubMenuOpen && (
                            <ul>
                              {firstChild.children.map((thirdChild,indexThirdChild) => (
                                <li key={thirdChild.cat_slug}>
                                   <div className='sub-menu-inner'   onClick={clearSession}  >
                                  {thirdChild.menu_categoryid > 0 ?
                                  <a href={"/collection/category/" + thirdChild.menu_slug} >
                                    {thirdChild.menu_name}
                                  </a> : thirdChild.menu_collectionid > 0 ?
                                  <a href={"/collection/collection/" + thirdChild.menu_slug} >
                                    {thirdChild.menu_name}
                                  </a> :
                                  
                                  thirdChild.menu_pageid > 0 ? <a href={"/" + thirdChild.pages.page_url} >
                                    {thirdChild.menu_name}
                                  </a> : <a href={thirdChild.menu_customlink} >
                                    {thirdChild.menu_name}
                                      </a>}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      );
                    }
                  })}
                </ul>
              )}
            </li>
          );
        })
        )}
      </ul>
      </div>
      <hr></hr>
      <div className="mslist">
        <ul>
          {sessionToken ? (
            <>
              <li onClick={(e) => goToPage("/account/my-order")}>My Orders</li>
              <li onClick={(e) => goToPage("/account/my-address")}>Addresses</li>
              <li onClick={(e) => goToPage("/account/wishlist")}>
                Collection & Wishlist
              </li>
            </>
          ) : (
            <>
              <li onClick={()=>{accountModalopen('/account/my-order')}}>My Orders</li>
              <li onClick={()=>{accountModalopen('/account/my-address')}}>Addresses</li>
              <li onClick={()=>{accountModalopen('/account/wishlist')}}>Collection & Wishlist</li>
            </>
          )}

          <li onClick={(e) => goToPage("/account/help-support")}>
            Help & Support
          </li>
        </ul>
      </div>
    </Modal>
  </>)
}

export default SideMenu