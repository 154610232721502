import { BrowserView, MobileView } from "react-device-detect";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
const ProductTabs = ({ producttabs }) => {
    const firstValidTab = producttabs.find(item => item.tab_description);
    const firstValidTabIndex = producttabs.findIndex(item => item.tab_description); 
    return (<>
        <BrowserView>
            {producttabs && producttabs.length > 0 ?
                <section className="section-gap-small">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="productTabs">
                                    <Tabs id="uncontrolled-tab-example" className="mb-3" defaultActiveKey={firstValidTab ? firstValidTab.tab_name : ''}>
                                        {producttabs && producttabs.map((item, index) => (
                                            item.tab_description && (
                                                <Tab key={index} eventKey={item.tab_name} title={item.tab_name}>
                                                   {item.tab_name=='Additional Info' ?<>
                                                    {item.tab_description}
                                                   </>:<>
                                                   <div dangerouslySetInnerHTML={{ __html: item.tab_description }} />
                                                   
                                                   </>}
                                                   
                                                </Tab>
                                            )
                                        ))}
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> : null
            }
        </BrowserView>
        <MobileView>
            {producttabs && producttabs.length > 0 ?
                <div className="maccrod">
                    <Accordion defaultActiveKey={firstValidTabIndex !== -1 ? firstValidTabIndex.toString() : ''}>
                        {producttabs && producttabs.map((item, index) => (
                            item.tab_description && (
                                <Accordion.Item eventKey={index.toString()} key={index}>
                                    <Accordion.Header>{item.tab_name}</Accordion.Header>
                                    <Accordion.Body>
                                    {item.tab_name=='Additional Info' ?<>
                                                    {item.tab_description}
                                                   </>:<>
                                                   <div dangerouslySetInnerHTML={{ __html: item.tab_description }} />
                                                   
                                                   </>}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        ))}
                    </Accordion>
                </div>
                : null
            }
        </MobileView>
    </>)
}

export default ProductTabs