import { BrowserView, MobileView } from "react-device-detect"
import MobileaccountHeader from "../../Component/Header/mobile-header/account"
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import { useState, useEffect, useRef } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import constant from "../../Component/Services/constant";
import Loader from "react-js-loader";
import Accountsidebar from "./account-sidebar";
import multiCurrency from "../../Component/Elements/multi_currrency";
const Wishlist = () => {
  const [isloading, setisloading] = useState(true)
  const [spinnerloading, setspinnerloading] = useState('')
  const [favproductdata, setFavproductdata] = useState([])
  const didMountRef = useRef(true);
  const skeletonArray = Array.from({ length: 4 });
  useEffect(() => {
    if (didMountRef.current) {
      getWishlistProducts()

    }
    didMountRef.current = false;

  }, [])
  const getWishlistProducts = () => {
    ApiService.fetchData('/user-fav-data').then((res) => {
      if (res.status == 'success') {
        setFavproductdata(res.favdata)
        setisloading(false)
      }
      else {
        setisloading(false)
      }

    }).catch((err) => {
      setisloading(false)
    })
  }
  const discountprice = (productdetail) => {
    const discoutn = (productdetail?.product_price - productdetail?.product_selling_price) / productdetail?.product_price
    const discountper = discoutn * 100
    return Math.round(discountper)
  }
  const Removeaddtofav = (productId) => {
    setspinnerloading(productId?.product_slug)
    console.log(productId?.product_slug, "productId.product_slug")
    const dataString = {
      product_id: productId,
    };

    ApiService.postData("remove-fav-wishlist", dataString).then((res) => {
      if (res.data.status == "success") {
        getWishlistProducts()
        setspinnerloading('')
      }
      else {
        setspinnerloading('')
      }
    }).catch(() => {
      setspinnerloading('')
    });
  };
  return (<>

    <BrowserView>
      <WebsiteInnerheader></WebsiteInnerheader>
      <div className="subheader smallsubheader">
        <Container>
          <Row>
            <Col lg={12}>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-account">My Account</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Wishlist
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>
      <section className="section-gap-small">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Accountsidebar></Accountsidebar>
            </div>

            <div className="col-lg-9">

              <div className="acpanel">
                <div className="acpanel-header">
                  <h4>My Wishlist</h4>
                </div>
                <div className="acpanel-body">
                  {isloading ? <>
                    <div className="row g-3 cols-xl-4 cols-sm-3 cols-md-4">
                      {skeletonArray && skeletonArray.map((_, index) => {
                        return (<>
                          <div className="product text-center" >
                            <figure className="product-media">
                              <Skeleton height={'300px'}></Skeleton>
                            </figure>
                            <div className="product-details">
                              <h2 className="product-name"> <Skeleton></Skeleton></h2>
                              <div className="product-price">
                                <ins className="new-price"> <Skeleton width={'100px'}></Skeleton></ins>
                                <del className="old-price"> <Skeleton width={'100px'}></Skeleton></del>
                              </div>
                              <Skeleton></Skeleton>
                            </div>
                          </div>
                        </>)
                      })}
                    </div>
                  </> :
                    <>
                      {favproductdata && favproductdata.length > 0 ?
                        <div className="row g-3 cols-xl-4 cols-sm-3 cols-md-4">
                          {favproductdata && favproductdata.map((item, index) => {
                            return (<>
                              <div className="product text-center" >
                                <figure className="product-media">
                                  <a href={`/product/${item?.product?.product_slug}`}><img src={item?.product?.product_image ? item?.product?.product_image : constant.DEFAULT_IMAGE} /></a>
                                  <div className="product-label-group">
                                    <span className="product-label label-sale">{discountprice(item?.product)}% off</span>
                                  </div>
                                  <div className="product-action-vertical">
                                    <a
                                      href="javascript:void(0)"
                                      className="btn-product-icon btn-wishlist"
                                      title="Add to wishlists"
                                      onClick={(e) =>
                                        Removeaddtofav(item?.product)
                                      }
                                    >
                                      {spinnerloading == item?.product?.product_slug ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> : <i
                                        className="ri-delete-bin-7-fill"
                                        id={
                                          "wishlisticon" +
                                          item?.product?.product_slug
                                        }
                                      ></i>}
                                    </a>
                                  </div>


                                </figure>
                                <div className="product-details">

                                  <h2 className="product-name">{item?.product?.product_name}</h2>
                                  <div className="product-price">
                                    <ins className="new-price">{multiCurrency(item?.product?.product_selling_price)}</ins>
                                    <del className="old-price">{multiCurrency(item?.product?.product_price)}</del>
                                  </div>

                                </div>

                              </div>
                            </>)
                          })}
                        </div> : <div className='noimg'>
                          <img src='/img/empty-cart.webp' className='wd-200 mb-20'></img>
                          <h6>Your wishlist is empty!</h6>
                          <p className='tx-gray tx-14'>There is nothing in your wishlist. Let's add some items</p>
                          <a href='/' className='btn-line'><span>Start Shopping</span></a>
                        </div>}


                    </>
                  }
                </div>
              </div>

            </div>


          </div>


        </div>
      </section>
      <Footer></Footer>
    </BrowserView>
    <MobileView>
      <MobileaccountHeader heardername={'Collection & Wishlist'} backroute={'/my-account'}></MobileaccountHeader>
      <main>
        <div className="acpanel">
          <div className="acpanel-body">
            {isloading ? <>
              <div className="row g-3 ">

                {skeletonArray && skeletonArray.map((_, index) => {
                  return (<>
                    <div className="col-6">
                      <div className="product text-center" >
                        <figure className="product-media">
                          <Skeleton height={'300px'}></Skeleton>
                        </figure>
                        <div className="product-details">
                          <h2 className="product-name"> <Skeleton></Skeleton></h2>
                          <div className="product-price">
                            <ins className="new-price"> <Skeleton width={'100px'}></Skeleton></ins>
                            <del className="old-price"> <Skeleton width={'100px'}></Skeleton></del>
                          </div>
                          <Skeleton></Skeleton>
                        </div>
                      </div>
                    </div>
                  </>)
                })}


              </div>
            </> :
              <>
                {favproductdata && favproductdata.length > 0 ?
                  <div className="row g-3">


                    {favproductdata && favproductdata.map((item, index) => {
                      return (<>
                        <div className="col-6">
                          <div className="product text-center" >
                            <figure className="product-media">
                              <a href={`/product/${item.product?.product_slug}`}><img src={item.product?.product_image ? item.product?.product_image : constant.DEFAULT_IMAGE} /></a>
                              <div className="product-label-group">
                                <span className="product-label label-sale">{discountprice(item?.product)}% off</span>
                              </div>
                              <div className="product-action-vertical">
                                <a
                                  href="javascript:void(0)"
                                  className="btn-product-icon btn-wishlist"
                                  title="Add to wishlists"
                                  onClick={(e) =>
                                    Removeaddtofav(item?.product)
                                  }
                                >
                                  {spinnerloading == item?.product?.product_slug ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> : <i
                                    className="ri-delete-bin-7-fill"
                                    id={
                                      "wishlisticon" +
                                      item?.product?.product_slug
                                    }
                                  ></i>}
                                </a>
                              </div>


                            </figure>
                            <div className="product-details">

                              <h2 className="product-name">{item?.product?.product_name}</h2>
                              <div className="product-price">
                                <ins className="new-price">{multiCurrency(item?.product?.product_selling_price)}</ins>
                                <del className="old-price">{multiCurrency(item?.product?.product_price)}</del>
                              </div>

                            </div>

                          </div>
                        </div>
                      </>)
                    })}

                  </div>

                  : <div className='noimg'>
                    <img src='/img/empty-cart.webp' className='wd-200 mb-20'></img>
                    <h6>Your wishlist is empty!</h6>
                    <p className='tx-gray tx-14'>There is nothing in your wishlist. Let's add some items</p>
                    <a href='/' className='btn-line'><span>Start Shopping</span></a>
                  </div>}


              </>
            }
          </div>
        </div>
      </main>




    </MobileView>


  </>)
}

export default Wishlist