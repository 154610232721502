import { useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../Component/Services/apiservices";
import { useContext } from "react";
import DataContext from "../../Component/Elements/context";
import React , {useRef, useEffect} from 'react'
import { toast } from "react-toastify";
const Accountsidebar=()=>{
const contextValues = useContext(DataContext)
const location = useLocation()
const didMountRef = useRef(true);
const navigate = useNavigate()
const logoutProcess = () => {
const confirmed = window.confirm("Are you sure you want to log out");
if (confirmed) {
ApiService.fetchData("logout").then((res) => {
if (res.status === "success") {
localStorage.removeItem("USER_TOKEN")
navigate('/')
window.location.reload();
}
})
}
}
useEffect(() => {
if (didMountRef.current) {
getUserdata()
}
didMountRef.current = false;
}, []);
const getUserdata = () => {
ApiService.fetchData('get-user-data').then((res) => {
if (res.status == 'success') {
contextValues.setRowUserData(res.rowUserData)
}
else if (res.status=='session_expired'){
localStorage.removeItem('USER_TOKEN')
localStorage.removeItem('TEMP_USER_TOKEN')
navigate('/')
}
else{
toast.error(res.message)
}
}).catch((error) => {

})
}

return(<>
<div className="account-sidebar">
<div className="account-user mb-3">
<div className="au-imag"><img src="/img/user.png" /></div>
<a href="/account/profile">
<div className="au-content">
<h6 className="mb-0">Hi! {contextValues.rowUserData.user_fname}</h6>
<p className="mb-0">{contextValues.rowUserData.user_email}</p>
</div>
</a>
</div>
<div className="aclist mb-3">
<ul>
<li className={location.pathname=='/my-account' ? 'active':''}>
<a href="/my-account">Account Overview<i class="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/profile' ? 'active':''}>
<a href="/account/profile">Profile<i class="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/my-address' ? 'active':''}>
<a href="/account/my-address">Address<i class="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/my-order' ? 'active':''}>
<a href="/account/my-order">My Orders<i class="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/wishlist' ? 'active':''}>
<a href="/account/wishlist">My Wishlist<i class="ri-arrow-right-s-line"></i></a>
</li>
<li className={location.pathname=='/account/help-support' ? 'active':''}>
<a href="/account/help-support">Help &amp; Support<i class="ri-arrow-right-s-line"></i></a>
</li>
</ul>
</div>
<div className="aclist">
<ul>
<li onClick={()=>{logoutProcess()}}>
<a href="javascript:void(0)" className="pb-0 pt-0">Logout<i class="ri-arrow-right-s-line"></i></a>
</li>
</ul>
</div>
</div>

</>)
}
export default Accountsidebar