import React, { useState, useRef, useContext, useEffect } from "react";
import sessionCartData from '../../Elements/cart_session_data';
import DataContext from '../../Elements/context';
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Services/apiservices";
import CartModal from "../../modals/cart-modal";
import LoginModal from "../../modals/login-modal";
import QuickviewModal from "../../modals/quickviewmodal";
import constant from "../../Services/constant";
import ReactPixel from "../../Services/FacebookPixel";

function WebsiteHomeHeader() {
    const dataArray = sessionCartData();
    const contextValues = useContext(DataContext);
    useEffect(() => {
        contextValues.setAddressSession(dataArray[0])
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCouponSession(dataArray[2])
        contextValues.setCartSummary(dataArray[3])
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setUserToken(dataArray[5])
    }, []);
    const [showInput, setShowInput] = useState(false);
    const [marqueetxt, setmarqueetxt] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate()
    const searchContainerRef = useRef(null);
    const inputRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (searchContainerRef.current && inputRef.current &&
                !searchContainerRef.current.contains(event.target) &&
                !inputRef.current.contains(event.target)
            ) {
                setSearchResults([]);
                setSearchTerm('')
                setShowInput(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length >= 2) {
                fetchSearchResults();
            }
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm])

    const fetchSearchResults = () => {
        const dataString = {
            query: searchTerm,
        };
        ApiService.postData("getsearchdata", dataString).then((res) => {
            if (res.status === "success") {
                setSearchResults(res.data);
            } else {
            }
        });
    };
    const handleInputChange = (event) => {
        event.stopPropagation()
        setSearchTerm(event.target.value);
    };
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    const accountModal = () => {
        let sessionToken = localStorage.getItem("USER_TOKEN")
        if (!sessionToken || sessionToken == null) {
            contextValues.setLoginRedirect('/my-account')
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
        } else {
            navigate('/my-account')
        }
    }
    const wishlistModal = () => {
        let sessionToken = localStorage.getItem("USER_TOKEN")
        if (!sessionToken || sessionToken == null) {
            contextValues.setLoginRedirect('/account/wishlist')
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
        } else {
            navigate('/account/wishlist')
        }
    }
    function opensearchbox(e) {
        e.stopPropagation();
        setShowInput(prevState => !prevState);
        setSearchResults([]);
        setSearchTerm('')

    }

    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {

            getHeaderdata()
            let sessionToken = localStorage.getItem("USER_TOKEN")
            if (sessionToken == null || sessionToken == '') {
                getLoginImage()
            }

        }
        didMountRef.current = false;
    }, [])
    const getLoginImage = () => {
        ApiService.fetchData('login-popup-banner-list').then((res) => {
            if (res.status === 'success') {
                contextValues.setsliderurl(res.slider_image_path)
                contextValues.setbannerImage(res.resTopBannerData)
            }
        })
    }
    const getHeaderdata = () => {
        ApiService.fetchData('/header-data').then((res) => {
            if (res.status == 'success') {
                setmarqueetxt(res.headerdata.header_top)
            }
        })
    }

    const setsearchConverson = (value) => {
        ReactPixel.track('UserSearch', {
            name: value.name,
            pageUrl : value.redirect
          });
    }
    return (
        <>
            <div className="topHeader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <marquee>{marqueetxt}</marquee>
                        </div>
                    </div>
                </div>
            </div>
            {showInput && (
             <div className="HHHeaderSearch" onClick={(e) => e.stopPropagation()} ref={inputRef}>
                <div className="HHHeaderSearchInner">
                                            <input type="text" placeholder="Search for bedsheets, bedding Set, comforter..."
                                                value={searchTerm}
                                                onChange={(e) => { handleInputChange(e) }} />
                                            <button onClick={(e) => { opensearchbox(e) }} className="closeSearch"><i class="ri-close-line"></i></button>
                                            <div className="searchIcon"><i class="ri-search-line"></i></div>
                                            </div>
                                            {searchResults &&
                                                searchResults.length > 0 &&
                                                searchTerm.trim() !== "" ? (
                                                <div className="header-search-list" id="search_input">
                                                    <ul>
                                                        {searchResults.map((value, index) => (
                                                            <a href={value.redirect} onClick={(e)=>setsearchConverson(value)} key={index}>
                                                                <li key={index}>{value.name}</li>
                                                            </a>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
          
            <header className="header">
                <div className="headerTop">
                    <div className="container">
                        <div className="header-left">
                            <ul className="leftHeaderlist">
                                <li onClick={(e) => { opensearchbox(e) }} ref={searchContainerRef}><a href='javascript:void(0)'><i className="ri-search-line"></i></a>
                                    
                                </li>
                                <li><a href="/book-an-appointment"><i className="ri-calendar-schedule-line mr-10"></i> Book An Appointment</a></li>
                            </ul>
                        </div>

                        <div className="header-center">
                            <a href="/" className="logo"><img src="/img/logowhite.png" height="76px" width="150px"></img></a>
                            <a href="/" className="hoverlogo"><img src="/img/logo.png" height="76px" width="150px"></img></a>
                        </div>
                        <div className="header-right justify-content-end">
                            <ul className="rightHeaderlist">
                                <li onClick={() => { wishlistModal() }}>
                                    <a href="javascript:void(0)"><i className="ri-heart-3-line"></i></a>
                                </li>
                                <li onClick={() => { accountModal() }}>
                                    <a href="javascript:void(0)"><i className="ri-user-line"></i></a>
                                </li>
                                <li className="cart-toggle" onClick={() => { cartModal() }}>
                                    <a href="javascript:void(0)">
                                        <i className="ri-shopping-bag-3-line"></i>
                                        <div className="cart-count"><span>{contextValues.cartCount}</span></div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="headerBottom">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <ul className="navbar-nav mx-auto">
                                {contextValues.menuList.length > 0
                                    ? contextValues.menuList.map((parent, indexParent) => {
                                        if (parent.menu_mega === 0) {
                                            if (parent.children.length > 0) {
                                                if (parent.menu_categoryid > 0) {
                                                    return (
                                                        <li
                                                            className="nav-item dropdown dropdown-hover"
                                                            key={indexParent}
                                                        >
                                                            <a
                                                                className="nav-link" 
                                                                href={
                                                                    parent.categories && parent.categories.cat_slug
                                                                        ? "/collection/category/" + parent.categories.cat_slug
                                                                        : "javascript:void(0)"
                                                                }
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu submenu"
                                                                aria-labelledby="navbarDropdown"
                                                            >
                                                                {parent.children.map(
                                                                    (firstChild, indexFirstChild) => {
                                                                        if (firstChild.menu_categoryid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.categories && firstChild.categories.cat_slug
                                                                                                ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else if (firstChild.menu_collectionid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.collection && firstChild.collection.cat_slug
                                                                                                ? "/collection/collection/" + firstChild.collection.cat_slug
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else if (firstChild.menu_pageid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.pages && firstChild.pages.page_url
                                                                                                ? "/" + firstChild.pages.page_url
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={firstChild.menu_customlink}
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                            </ul>
                                                        </li>
                                                    );
                                                } else if (parent.menu_collectionid > 0) {
                                                    return (
                                                        <li
                                                            className="nav-item dropdown dropdown-hover"
                                                            key={indexParent}
                                                        >
                                                            <a
                                                                className="nav-link"
                                                                href={
                                                                    parent.collection && parent.collection.cat_slug
                                                                        ? "/collection/collection/" + parent.collection.cat_slug
                                                                        : "javascript:void(0)"
                                                                }
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu submenu"
                                                                aria-labelledby="navbarDropdown"
                                                            >
                                                                {parent.children.map(
                                                                    (firstChild, indexFirstChild) => {
                                                                        if (firstChild.menu_categoryid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item" 
                                                                                        href={
                                                                                            firstChild.categories && firstChild.categories.cat_slug
                                                                                                ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else if (firstChild.menu_collectionid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.collection && firstChild.collection.cat_slug
                                                                                                ? "/collection/collection/" + firstChild.collection.cat_slug
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else if (firstChild.menu_pageid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.pages && firstChild.pages.page_url
                                                                                                ? "/" + firstChild.pages.page_url
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={firstChild.menu_customlink}
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                            </ul>
                                                        </li>
                                                    );
                                                } else if (parent.menu_pageid > 0) {
                                                    return (
                                                        <li
                                                            className="nav-item dropdown dropdown-hover"
                                                            key={indexParent}
                                                        >
                                                            <a
                                                                className="nav-link"
                                                                href={
                                                                    parent.pages && parent.pages.page_url
                                                                        ? "/" + parent.pages.page_url
                                                                        : "javascript:void(0)"
                                                                }
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu submenu"
                                                                aria-labelledby="navbarDropdown"
                                                            >
                                                                {parent.children.map(
                                                                    (firstChild, indexFirstChild) => {
                                                                        if (firstChild.menu_categoryid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.categories && firstChild.categories.cat_slug
                                                                                                ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else if (firstChild.menu_collectionid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.collection && firstChild.collection.cat_slug
                                                                                                ? "/collection/collection/" + firstChild.collection.cat_slug
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else if (firstChild.menu_pageid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.pages && firstChild.pages.page_url
                                                                                                ? "/" + firstChild.pages.page_url
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={firstChild.menu_customlink}
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                            </ul>
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li
                                                            className="nav-item dropdown dropdown-hover"
                                                            key={indexParent}
                                                        >
                                                            <a
                                                                className="nav-link"
                                                                href={parent.menu_customlink}
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu submenu"
                                                                aria-labelledby="navbarDropdown"
                                                            >
                                                                {parent.children.map(
                                                                    (firstChild, indexFirstChild) => {
                                                                        if (firstChild.menu_categoryid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.categories && firstChild.categories.cat_slug
                                                                                                ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else if (firstChild.menu_collectionid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.collection && firstChild.collection.cat_slug
                                                                                                ? "/collection/collection/" + firstChild.collection.cat_slug
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else if (firstChild.menu_pageid > 0) {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={
                                                                                            firstChild.pages && firstChild.pages.page_url
                                                                                                ? "/" + firstChild.pages.page_url
                                                                                                : "javascript:void(0)"
                                                                                        }
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <li key={indexFirstChild}>
                                                                                    <a
                                                                                        className="dropdown-item"
                                                                                        href={firstChild.menu_customlink}
                                                                                    >
                                                                                        {firstChild.menu_name}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                            </ul>
                                                        </li>
                                                    );
                                                }
                                            } else {
                                                if (parent.menu_categoryid > 0) {
                                                    return (
                                                        <li className="nav-item" key={indexParent}>
                                                            <a
                                                                className="nav-link"
                                                                aria-current="page" 
                                                                href={
                                                                    parent.categories && parent.categories.cat_slug
                                                                        ? "/collection/category/" + parent.categories.cat_slug
                                                                        : "javascript:void(0)"
                                                                }
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                        </li>
                                                    );
                                                } else if (parent.menu_collectionid > 0) {
                                                    return (
                                                        <li className="nav-item" key={indexParent}>
                                                            <a
                                                                className="nav-link"
                                                                aria-current="page"
                                                                href={
                                                                    parent.collection && parent.collection.cat_slug
                                                                        ? "/collection/collection/" + parent.collection.cat_slug
                                                                        : "javascript:void(0)"
                                                                }
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                        </li>
                                                    );
                                                } else if (parent.menu_pageid > 0) {
                                                    return (
                                                        <li className="nav-item" key={indexParent}>
                                                            <a
                                                                className="nav-link"
                                                                aria-current="page"
                                                                href={
                                                                    parent.pages && parent.pages.page_url
                                                                        ? "/" + parent.pages.page_url
                                                                        : "javascript:void(0)"
                                                                }
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li className="nav-item" key={indexParent}>
                                                            <a
                                                                className="nav-link"
                                                                aria-current="page"
                                                                href={parent.menu_customlink}
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                        </li>
                                                    );
                                                }
                                            }
                                        } else {
                                            if (parent.children.length > 0) {
                                                if (parent.menu_categoryid > 0) {
                                                    return (
                                                        <li
                                                            className="nav-item dropdown mega-dropdown dropdown-hover"
                                                            key={indexParent}
                                                        >
                                                            <a
                                                                className="nav-link" 
                                                                href={
                                                                    parent.categories && parent.categories.cat_slug
                                                                        ? "/collection/category/" + parent.categories.cat_slug
                                                                        : "javascript:void(0)"
                                                                }
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                            <div
                                                                className="mega-menu dropdown-menu"
                                                                aria-labelledby="navbarDropdown"
                                                            >
                                                                <div className="container">
                                                                    <div className="row g-3">
                                                                        {parent.children.map(
                                                                            (firstChild, indexFirstChild) => {
                                                                                if (firstChild.menu_categoryid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a 
                                                                                                        href={
                                                                                                            firstChild.categories && firstChild.categories.cat_slug
                                                                                                                ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                                : "javascript:void(0)"
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.categories && firstChild.categories.cat_slug
                                                                                                                    ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                } else if (firstChild.menu_pageid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a 
                                                                                                        href={
                                                                                                            firstChild.pages && firstChild.pages.page_url
                                                                                                                ? "/" + firstChild.pages.page_url
                                                                                                                : "javascript:void(0)"
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.pages && firstChild.pages.page_url
                                                                                                                    ? "/" + firstChild.pages.page_url
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                else if (firstChild.menu_collectionid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a
                                                                                                        href={
                                                                                                            "/collection/" +
                                                                                                            firstChild.collection.cat_slug
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.pages && firstChild.pages.page_url
                                                                                                                    ? "/" + firstChild.pages.page_url
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a
                                                                                                        href={
                                                                                                            firstChild.menu_customlink
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a
                                                                                                            href={
                                                                                                                firstChild.menu_customlink
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }


                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                } else if (parent.menu_pageid > 0) {
                                                    return (
                                                        <li
                                                            className="nav-item dropdown mega-dropdown dropdown-hover"
                                                            key={indexParent}
                                                        >
                                                            <a
                                                                className="nav-link "
                                                                href={
                                                                    parent.pages && parent.pages.page_url
                                                                        ? "/" + parent.pages.page_url
                                                                        : "javascript:void(0)"
                                                                }
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                            <div
                                                                className="mega-menu dropdown-menu"
                                                                aria-labelledby="navbarDropdown"
                                                            >
                                                                <div className="container">
                                                                    <div className="row g-3">
                                                                        {parent.children.map(
                                                                            (firstChild, indexFirstChild) => {
                                                                                if (firstChild.menu_categoryid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a 
                                                                                                        href={
                                                                                                            firstChild.categories && firstChild.categories.cat_slug
                                                                                                                ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                                : "javascript:void(0)"
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.categories && firstChild.categories.cat_slug
                                                                                                                    ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }

                                                                                } else if (firstChild.menu_pageid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a 
                                                                                                        href={
                                                                                                            firstChild.pages && firstChild.pages.page_url
                                                                                                                ? "/" + firstChild.pages.page_url
                                                                                                                : "javascript:void(0)"
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.pages && firstChild.pages.page_url
                                                                                                                    ? "/" + firstChild.pages.page_url
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                else if (firstChild.menu_collectionid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a
                                                                                                        href={
                                                                                                            "/collection/" +
                                                                                                            firstChild.collection.cat_slug
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.pages && firstChild.pages.page_url
                                                                                                                    ? "/" + firstChild.pages.page_url
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a
                                                                                                        href={
                                                                                                            firstChild.menu_customlink
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a
                                                                                                            href={
                                                                                                                firstChild.menu_customlink
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }

                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }

                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                } else if (parent.menu_collectionid > 0) {
                                                    return (
                                                        <li
                                                            className="nav-item dropdown mega-dropdown dropdown-hover"
                                                            key={indexParent}
                                                        >
                                                            <a
                                                                className="nav-link "
                                                                href={
                                                                    parent.collection && parent.collection.cat_slug
                                                                        ? "/collection/collection/" + parent.collection.cat_slug
                                                                        : "javascript:void(0)"
                                                                }
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                            <div
                                                                className="mega-menu dropdown-menu"
                                                                aria-labelledby="navbarDropdown"
                                                            >
                                                                <div className="container">
                                                                    <div className="row g-3">
                                                                        {parent.children.map(
                                                                            (firstChild, indexFirstChild) => {
                                                                                if (firstChild.menu_categoryid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a 
                                                                                                        href={
                                                                                                            firstChild.categories && firstChild.categories.cat_slug
                                                                                                                ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                                : "javascript:void(0)"
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.categories && firstChild.categories.cat_slug
                                                                                                                    ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                else if (firstChild.menu_collectionid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a
                                                                                                        href={
                                                                                                            "/collection/" +
                                                                                                            firstChild.collection.cat_slug
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a
                                                                                                            href={
                                                                                                                "/collection/" +
                                                                                                                firstChild.collection.cat_slug
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                else if (firstChild.menu_pageid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a 
                                                                                                        href={
                                                                                                            firstChild.pages && firstChild.pages.page_url
                                                                                                                ? "/" + firstChild.pages.page_url
                                                                                                                : "javascript:void(0)"
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.pages && firstChild.pages.page_url
                                                                                                                    ? "/" + firstChild.pages.page_url
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a
                                                                                                        href={
                                                                                                            firstChild.menu_customlink
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a
                                                                                                            href={
                                                                                                                firstChild.menu_customlink
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }

                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                } else {
                                                    return (
                                                        <li
                                                            className="nav-item dropdown mega-dropdown dropdown-hover"
                                                            key={indexParent}
                                                        >
                                                            <a
                                                                className="nav-link"
                                                                href={parent.menu_customlink}
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                {parent.menu_name}
                                                            </a>
                                                            <div
                                                                className="mega-menu dropdown-menu"
                                                                aria-labelledby="navbarDropdown"
                                                            >
                                                                <div className="container">
                                                                    <div className="row g-3">
                                                                        {parent.children.map(
                                                                            (firstChild, indexFirstChild) => {
                                                                                if (firstChild.menu_categoryid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a 
                                                                                                        href={
                                                                                                            firstChild.categories && firstChild.categories.cat_slug
                                                                                                                ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                                : "javascript:void(0)"
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.categories && firstChild.categories.cat_slug
                                                                                                                    ? "/collection/category/" + firstChild.categories.cat_slug
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                                else if (firstChild.menu_collectionid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a
                                                                                                        href={
                                                                                                            "/collection/" +
                                                                                                            firstChild.collection.cat_slug
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a
                                                                                                            href={
                                                                                                                "/collection/" +
                                                                                                                firstChild.collection.cat_slug
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }

                                                                                else if (firstChild.menu_pageid > 0) {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a 
                                                                                                        href={
                                                                                                            firstChild.pages && firstChild.pages.page_url
                                                                                                                ? "/" + firstChild.pages.page_url
                                                                                                                : "javascript:void(0)"
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a 
                                                                                                            href={
                                                                                                                firstChild.pages && firstChild.pages.page_url
                                                                                                                    ? "/" + firstChild.pages.page_url
                                                                                                                    : "javascript:void(0)"
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }

                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    if (firstChild.menu_show_image === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mega-menu-imgbox">
                                                                                                    <a
                                                                                                        href={
                                                                                                            firstChild.menu_customlink
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={
                                                                                                                firstChild.menu_image !=
                                                                                                                    null
                                                                                                                    ? contextValues.menuimgUrl +
                                                                                                                    firstChild.menu_image
                                                                                                                    : constant.DEFAULT_IMAGE
                                                                                                            }
                                                                                                            width="100%"
                                                                                                            height="100%"
                                                                                                        />
                                                                                                        {firstChild.menu_desc ? (
                                                                                                            <div
                                                                                                                className="mega-menu-imgbox-content"
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html:
                                                                                                                        firstChild.menu_desc,
                                                                                                                }}
                                                                                                            ></div>
                                                                                                        ) : null}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="col"
                                                                                                key={indexFirstChild}
                                                                                            >
                                                                                                <div className="mmlist">
                                                                                                    <div className="mmlist-title">
                                                                                                        <a
                                                                                                            href={
                                                                                                                firstChild.menu_customlink
                                                                                                            }
                                                                                                        >
                                                                                                            {firstChild.menu_name}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        {firstChild.children.map(
                                                                                                            (
                                                                                                                thirdChild,
                                                                                                                indexThirdChild
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    thirdChild.menu_categoryid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a 
                                                                                                                                href={
                                                                                                                                    thirdChild.categories && thirdChild.categories.cat_slug
                                                                                                                                        ? "/collection/category/" + thirdChild.categories.cat_slug
                                                                                                                                        : "javascript:void(0)"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }

                                                                                                                else if (
                                                                                                                    thirdChild.menu_collectionid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/collection/" +
                                                                                                                                    thirdChild.collection.cat_slug
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                                else if (
                                                                                                                    thirdChild.menu_pageid >
                                                                                                                    0
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    "/" +
                                                                                                                                    thirdChild.pages
                                                                                                                                        .page_url
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                indexThirdChild
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <a
                                                                                                                                href={
                                                                                                                                    thirdChild.menu_customlink
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    thirdChild.menu_name
                                                                                                                                }
                                                                                                                            </a>
                                                                                                                        </li>
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            }
                                        }
                                    })
                                    : null}
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
            <CartModal ></CartModal>
            <LoginModal ></LoginModal>
            {contextValues.toggleQuickViewModal && <> <QuickviewModal /></>}
        </>
    )
}
export default WebsiteHomeHeader;