/* import React, { useState, useContext, useEffect, useRef } from "react";

import DataContext from '../Elements/context';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../Services/apiservices";
import constant from "../Services/constant";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import multiCurrency from "../Elements/multi_currrency";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import 'swiper/css/scrollbar';
import { FreeMode, Navigation, Thumbs, Autoplay, Pagination } from "swiper/modules";
import Loader from "react-js-loader";
import { addToCartDetailPage, buyNowDetailPage } from "../../Component/Elements/add_to_cart"; */
import Modal from "react-bootstrap/Modal";
import Footer from "../../Component/Footer"
import WebsiteInnerheader from "../../Component/Header/webite-header/inner"
import MobileInnerheader from "../../Component/Header/mobile-header/inner"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import React, { useEffect, useState, useRef, useContext } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Ratingreviews from "../../Component/Rating/rating-reviews";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';
import ProductBox from "../../Component/product-box";
import ReactImageMagnify from 'react-image-magnify';
import constant from "../../Component/Services/constant";
import DataContext from "../../Component/Elements/context";
import SizeguideModal from "../../Component/modals/size-guide-modal";
import { addToCartDetailPage, buyNowDetailPage } from "../../Component/Elements/add_to_cart";
import { BrowserView, MobileView } from "react-device-detect";
import ProductTabs from "../../Component/product-tabs";
import Loader from "react-js-loader";
import Accordion from 'react-bootstrap/Accordion';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
} from "react-share";
import multiCurrency from "../../Component/Elements/multi_currrency";

function QuickviewModal() {
  const contextValues = useContext(DataContext)
  const sessiontoken = contextValues.userToken
  const navigate = useNavigate()
  const skeletonArray = Array.from({ length: 6 });
  const { slug } = useParams()
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      if (contextValues.productData && Object.keys(contextValues.productData).length > 0) {
        getproductdetail()
      }
    }
    didMountRef.current = false;

  }, [])
  const [rowProductsData, setProductsData] = useState({})
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [galleryItems, setGalleryItems] = useState([])
  const [wishlisttag, setwishlisttag] = useState('')
  const [producttabs, setproducttabs] = useState([])
  const [sizechart, setsizechart] = useState([])
  const [recomendedproduct, setrecomendedproduct] = useState([])
  const [recentlyviewproduct, setrecentlyviewproduct] = useState([])
  const [PercentageData, setPercentageData] = useState([]);
  const [ratingdata, setratingdata] = useState([])
  const [spinnerloading, setspinnerloading] = useState('')
  const [loading, setloading] = useState(false)
  const [stockAvailibility, setStockAvailibility] = useState(true)
  const [arySelectedData, setArySelectedData] = useState([]);
  const [variationData, setVariationData] = useState([]);
  const [selvararray, setSelvararray] = useState([]);

  /* const getproductdetail = () => {
    setspinnerLoading(true)
    const dataString = {
      slug: contextValues.productData.product_slug
    }
    ApiService.postData('/product-details', dataString).then((res) => {
      if (res.status === 'success') {
        setProductsData(res.rowProductData)
        setVariationData(res.variationData);
        updateRecentViews(res.rowProductData)
        setSelvararray(res.selvararray);
        setReviewData(res.review_data);
        const galleryItems = [
          {
            original:
              res.rowProductData.product_image != null
                ? res.rowProductData.product_image
                : constant.DEFAULT_IMAGE
          },
        ];

        res.rowProductData.gallery?.map((value) => {
          galleryItems.push({
            original:
              value.gallery_image != null
                ? value.gallery_image
                : constant.DEFAULT_IMAGE
          });
        });
        setGalleryItems(galleryItems);



        let parentcounter = 0;
        let childcounter = 0;
        res.variationData.map((parent) => {
          if (parent.attributes && parent.attributes.attribute_type === 3) {
            parent.attr_terms.map((child) => {
              parentcounter++;
              if (parentcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            parentcounter = 0;
          } else if (
            parent.attributes &&
            parent.attributes.attribute_type === 2
          ) {
            parent.attr_terms.map((child) => {
              childcounter++;
              if (childcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            childcounter = 0;
          } else if (
            parent.attributes &&
            parent.attributes.attribute_type === 1
          ) {
            parent.attr_terms.map((child) => {
              childcounter++;
              if (childcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            childcounter = 0;
          }
        });
        if (Number(res.rowProductData.product_type) == 0) {
          if (Number(res.rowProductData.product_inventory) == 1) {
            if (Number(res.rowProductData.product_stock) == 0) {
              setStockAvailibility(false)
            }
          }
        } else {
          if (Number(res.rowProductData.default_variation_first.pv_quantity) == 0) {
            setStockAvailibility(false)
          }
        }
        setspinnerLoading(false)

      } else {
        setspinnerLoading(false)
      }
    }).catch((error) => {
      setspinnerLoading(false)
    })
  } */

  const getproductdetail = () => {
    setloading(true)
    const dataString = {
      slug: contextValues.productData.product_slug
    }
    ApiService.postData('/product-details', dataString).then((res) => {
      if (res.status === 'success') {
        setProductsData(res.rowProductData)
        const productcatarray = res.rowProductData.product_category_id.split(',')
        getsizechart(productcatarray)
        setratingdata(res.review_data)
        setPercentageData(res.percentageData);
        updateRecentViews(res.rowProductData)
        setVariationData(res.variationData);
        setSelvararray(res.selvararray);
        setrecomendedproduct(res.relatedProducts)
        const galleryItems = [
          {
            original:
              res.rowProductData.product_image != null
                ? res.rowProductData.product_image
                : constant.DEFAULT_IMAGE
          },
        ];

        res.rowProductData.gallery?.map((value) => {
          galleryItems.push({
            original:
              value.gallery_image != null
                ? value.gallery_image
                : constant.DEFAULT_IMAGE
          });
        });
        setGalleryItems(galleryItems);
        if (res.rowProductData.product_description !== null) {
          const producttabs = [

            {
              tab_name: 'Description',
              tab_description:
                res.rowProductData.product_description

            },
          ];

          res.rowProductData.product_tabs && res.rowProductData.product_tabs?.map((value) => {
            producttabs.push(value);
          });
          setproducttabs(producttabs)
        }
        else {
          const producttabs =
            res.rowProductData.product_tabs?.map((value) => {
              producttabs.push(value);
            });
          setproducttabs(producttabs)
        }



        let parentcounter = 0;
        let childcounter = 0;
        res.variationData.map((parent) => {
          if (parent.attributes && parent.attributes.attribute_type === 3) {
            parent.attr_terms.map((child) => {
              parentcounter++;
              if (parentcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            parentcounter = 0;
          } else if (
            parent.attributes &&
            parent.attributes.attribute_type === 2
          ) {
            parent.attr_terms.map((child) => {
              childcounter++;
              if (childcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            childcounter = 0;
          } else if (
            parent.attributes &&
            parent.attributes.attribute_type === 1
          ) {
            parent.attr_terms.map((child) => {
              childcounter++;
              if (childcounter === 1) {
                arySelectedData.push(child.terms_name);
              }
            });
            childcounter = 0;
          }
        });
        if (Number(res.rowProductData.product_type) == 0) {
          if (Number(res.rowProductData.product_inventory) == 1) {
            if (Number(res.rowProductData.product_stock) == 0) {
              setStockAvailibility(false)
            }
          }
        } else {
          if (Number(res.rowProductData.default_variation_first.pv_quantity) == 0) {
            setStockAvailibility(false)
          }
        }
        setloading(false)

      } else {
        setloading(false)
      }
    }).catch((error) => {
      setloading(false)
    })
  }

  const getsizechart = (cat_id) => {

    const dataString = {
      product_category: cat_id
    }
    ApiService.postData('sizeChart', dataString).then((res) => {
      if (res.status == 'success') {
        setsizechart(res.data)
      }
    })
  }
  let mrpValue = 0;
  let sellingPriceValue = 0;
  let discount = 0;
  const [quantity, setQuantity] = useState(1);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const variationSelect = (item, index) => {
    const updatedSelectedData = [...arySelectedData];
    updatedSelectedData[index] = item.terms_name;
    const selvararrayselect = updatedSelectedData;
    const dataString = {
      variation: selvararrayselect,
      product_id: rowProductsData.product_id,
    };
    ApiService.postData("variation-wise-price", dataString).then((res) => {
      setArySelectedData(selvararrayselect)
      setSelvararray(selvararrayselect);
      if (Number(res.data.pv_quantity) == 0) {
        setStockAvailibility(false)
      } else {
        setStockAvailibility(true)
      }
      rowProductsData.product_sku = res.data.pv_sku;
      rowProductsData.product_selling_price = res.data.pv_sellingprice;
      rowProductsData.product_price = res.data.pv_price;
      rowProductsData.product_stock = res.data.pv_quantity;
      rowProductsData.product_moq = res.data.pv_moq;
      rowProductsData.product_discount = res.data.pv_discount;
      if (item.variation_images) {
        rowProductsData.product_image =
          item.variation_images.pti_image != null
            ? item.variation_images.pti_image
            : constant.DEFAULT_IMAGE;
      } else {
        rowProductsData.product_image =
          rowProductsData.product_image != null
            ? rowProductsData.product_image
            : constant.DEFAULT_IMAGE;
      }
      setProductsData(rowProductsData);
      mrpValue = parseFloat(res.data.pv_price);
      sellingPriceValue = parseFloat(res.data.pv_sellingprice);
      if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
        discount = ((mrpValue - sellingPriceValue) / mrpValue) * 100;
      }
      setDiscountPercentage(discount.toFixed(2));
      setQuantity(1);
    });
  };

  const accountModal = () => {
    let sessionToken = localStorage.getItem("USER_TOKEN")
    if (!sessionToken || sessionToken == null) {
      contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
    }
  }
  // const firstValidTab = producttabs.find(item => item.tab_description);
  const firstValidTabIndex = producttabs.findIndex(item => item.tab_description);
  const addtofav = (productId) => {
    setspinnerloading(productId.product_slug)
    const dataString = {
      product_id: productId.product_id,
    };
    ApiService.postData("add-to-fav", dataString).then((res) => {
      if (res.data.status == "success") {
        setspinnerloading('')
        setTimeout(() => {
          var element = document.getElementById("wishlisticon" + productId.product_slug);
          element.classList.remove("ri-heart-fill", "ri-heart-line");
          if (res.data.notification === "d-icon-heart-full") {
            element.classList.add('ri-heart-fill');
            setwishlisttag('Remove from wishlist')
            //toast.success("Added to Wishlist");
          } else {
            //toast.success("Removed from wishlist");
            element.classList.add('ri-heart-line');
            setwishlisttag('Add to  wishlist')
          }
        }, 100)
      } else {
        setspinnerloading('')
      }
    }).catch(() => {
      setspinnerloading('')
    });
  };

  const handleAddToCart = async () => {
    contextValues.setSpinnerCubLoader(rowProductsData.product_id)
    const productData = {
      product_id: Number(rowProductsData.product_id),
      product_name: rowProductsData.product_name,
      product_slug: rowProductsData.product_slug,
      product_image: rowProductsData.product_image
        ? rowProductsData.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(rowProductsData.product_type),
      product_price: Number(rowProductsData.product_price),
      product_selling_price: Number(rowProductsData.product_selling_price),
      product_discount: rowProductsData.product_discount,
      product_variation: arySelectedData,
      product_category_id: rowProductsData.product_category_id,
    };
    const updateStatus = await addToCartDetailPage(productData, quantity, contextValues);
    if (updateStatus) {
      contextValues.setSpinnerCubLoader(0)
    } else {
      contextValues.setSpinnerCubLoader(0)
    }
  };

  const handleBuyNow = async () => {
    contextValues.setSpinnerCubLoader(rowProductsData.product_id)
    const productData = {
      product_id: Number(rowProductsData.product_id),
      product_name: rowProductsData.product_name,
      product_slug: rowProductsData.product_slug,
      product_image: rowProductsData.product_image
        ? rowProductsData.product_image
        : constant.DEFAULT_IMAGE,
      product_type: Number(rowProductsData.product_type),
      product_price: Number(rowProductsData.product_price),
      product_selling_price: Number(rowProductsData.product_selling_price),
      product_discount: rowProductsData.product_discount,
      product_variation: arySelectedData,
      product_category_id: rowProductsData.product_category_id,
    };
    const updateStatus = await buyNowDetailPage(productData, quantity, contextValues);
    if (updateStatus) {
      contextValues.setSpinnerCubLoader(0)
      navigate('/cart')
    } else {
      contextValues.setSpinnerCubLoader(0)
      navigate('/cart')
    }
  };


  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  async function updateRecentViews(item) {
    let recentViewsItem = JSON.parse(localStorage.getItem('recentViewsItem')) || [];
    const isProductAlreadyExists = recentViewsItem.some(product => product.product_slug === item.product_slug);
    if (!isProductAlreadyExists) {
      recentViewsItem.push(item);
    } else {
      recentViewsItem = recentViewsItem.filter(product => product.product_slug !== item.product_slug);
      recentViewsItem.push(item);
    }
    localStorage.setItem('recentViewsItem', JSON.stringify(recentViewsItem));
    getrecentviewsdata();
  }
  const sizeguideModal = () => {
    contextValues.setTogglesizeguideModal(!contextValues.togglesizeguideModal)

  }
  const getrecentviewsdata = () => {
    let recentViewsItems = JSON.parse(localStorage.getItem('recentViewsItem')) || [];
    if (recentViewsItems !== null) {
      setrecentlyviewproduct(recentViewsItems.reverse())
    }
  }
  const watsapnavigate = () => {
    const whatsappURL = `https://api.whatsapp.com/send?phone=${contextValues.settingData.admin_whatsapp_no}`;
    window.open(whatsappURL, '_blank');
  }
  const getdiscountper = () => {
    const price = rowProductsData.product_price
    const sellingprice = rowProductsData.product_selling_price
    const discount = ((price - sellingprice) / price) * 100
    return Math.round(discount) + "%"
  }
  const quickviewmodal = () => { 
    contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal) 
  }
  return (
    <>
      <Modal show={contextValues.toggleQuickViewModal} onHide={(e) => quickviewmodal()} className='quickviewModal'>
        <div className="quickviewModal-content">
          <button type="button" className="modalCloseButton" onClick={(e) => quickviewmodal()}><i class="ri-close-line ri-2x"></i></button>
          <div className="product-single mproduct-single">
          
              <div className="mproduct-single-slider">
                <Swiper
                  slidesPerView={1}
                  navigation={false}
                  loop={true}
                  pagination={{ clickable: true }}

                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                >
                  {rowProductsData.product_video ? (
                    <SwiperSlide key={0}>
                      <video
                        src={rowProductsData.product_video}
                        autoPlay="autoplay"
                        loop
                        muted
                        playsInline
                      ></video>
                    </SwiperSlide>
                  ) : (
                    <></>
                  )}
                  {galleryItems && galleryItems.map((items, index) => {
                    return (<>
                      <SwiperSlide key={index}>
                        <img src={items.original} alt={`Thumbnail ${index}`} key={index} />
                      </SwiperSlide>
                    </>)
                  })}

                </Swiper>
              </div>
              <div className="p-15">
           
                  <h1 className="product-name">{rowProductsData?.product_name}</h1>
                  <div className="product-meta mb-15">
                    <ul>
                      {rowProductsData.product_brand_name ? <li><span>Brand:</span> {rowProductsData.product_brand_name}</li> : null}
                      {rowProductsData.product_category_name ?
                        <li>
                          <span>Category: </span>
                          {rowProductsData.product_category_name.split(',')[0]}
                        </li>
                        : null}
                    </ul>
                  </div>
                  <div className="product-price mb-15">
                    {rowProductsData.product_selling_price ? <ins className="new-price">{multiCurrency(rowProductsData.product_selling_price)}</ins> : null}
                    <div className="inner-product-price">
                      {rowProductsData.product_price ? <del className="old-price">{multiCurrency(rowProductsData.product_price)}</del> : null}
                      <span className="saveprice">You Save: {multiCurrency(rowProductsData.product_price - rowProductsData.product_selling_price)} ({getdiscountper()})</span>
                    </div>
                  </div> 
                  {/* Variation */}
                  <div className="product-details-size mt-4">
                    {variationData.map((valueVariation, indexVariation) => {
                      if (
                        valueVariation.attributes &&
                        valueVariation.attributes.attribute_type === 1
                      ) {
                        // COLOR
                        return (
                          <div className="mt-20" key={indexVariation}>
                            <h6>
                              {valueVariation.attributes.attribute_name}:
                            </h6>
                            <ul>
                              {valueVariation.attr_terms.map(
                                (
                                  valueVariationAttr,
                                  indexvalueVariationAttr
                                ) => {
                                  const stringIncluded = selvararray.includes(
                                    valueVariationAttr.terms_name
                                  );
                                  const className = stringIncluded
                                    ? "color active"
                                    : "color";
                                  return (
                                    <li
                                      onClick={() =>
                                        variationSelect(
                                          valueVariationAttr,
                                          indexVariation
                                        )
                                      }
                                      className={className}
                                      key={indexvalueVariationAttr}
                                      style={{
                                        backgroundColor:
                                          valueVariationAttr.terms_value,
                                        display: "block",
                                      }}
                                    ></li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        );
                      } else if (
                        valueVariation.attributes &&
                        valueVariation.attributes.attribute_type === 2
                      ) {
                        // IMAGE
                        return (
                          <div className="mt-20" key={indexVariation}>
                            <h6>
                              {valueVariation.attributes.attribute_name}:
                            </h6>
                            <ul>
                              {valueVariation.attr_terms.map(
                                (
                                  valueVariationAttr,
                                  indexvalueVariationAttr
                                ) => {
                                  const stringIncluded = selvararray.includes(
                                    valueVariationAttr.terms_name
                                  );
                                  const className = stringIncluded
                                    ? "img active"
                                    : "img";
                                  return (
                                    <li
                                      onClick={() =>
                                        variationSelect(
                                          valueVariationAttr,
                                          indexVariation
                                        )
                                      }
                                      className={className}
                                      key={indexvalueVariationAttr}
                                    >
                                      <img
                                        src={
                                          valueVariationAttr.variation_images !=
                                            null
                                            ? valueVariationAttr
                                              .variation_images.pti_image
                                            : constant.DEFAULT_IMAGE
                                        }
                                        alt={rowProductsData.product_name}
                                        width="100"
                                        height="100"
                                      />
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        );
                      } else if (
                        valueVariation.attributes &&
                        valueVariation.attributes.attribute_type === 3
                      ) {
                        // TEXT
                        return (
                          <div className="mt-20" key={indexVariation}>
                            <h6>
                              {valueVariation.attributes.attribute_name}:
                            </h6>

                            <ul>
                              {valueVariation.attr_terms.map(
                                (
                                  valueVariationAttr,
                                  indexvalueVariationAttr
                                ) => {
                                  const stringIncluded = selvararray.includes(
                                    valueVariationAttr.terms_name
                                  );
                                  const className = stringIncluded
                                    ? "size active"
                                    : "size";
                                  return (
                                    <li
                                      onClick={() =>
                                        variationSelect(
                                          valueVariationAttr,
                                          indexVariation
                                        )
                                      }
                                      className={className}
                                      href="javascript:void(0)"
                                      key={indexvalueVariationAttr}
                                    >
                                      {valueVariationAttr.terms_name}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div> 
                  {stockAvailibility ?
                  <div className="product-single-footer">
                      <div className="singleqty">
                          <span onClick={(e) => handleDecrease()}><i className="ri-subtract-line"></i></span>
                          <span>{quantity}</span>
                          <span onClick={(e) => handleIncrease()}><i className="ri-add-line"></i></span>
                      </div>
                      <button className="btn-theme btncart" onClick={(e) => handleAddToCart()}><span>Add to Cart</span></button>
                      <button className="btn-line btncart" onClick={(e) => handleBuyNow()}><span>Buy Now</span></button>
                  </div>
                  : null}
               
              </div>
             
          </div>
        </div>
      </Modal>
    </>
  );
}
export default QuickviewModal;
