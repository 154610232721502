import { BrowserView, MobileView } from "react-device-detect"
import MobileaccountHeader from "../../Component/Header/mobile-header/account"
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useContext, useState, useEffect } from "react";
import DataContext from "../../Component/Elements/context";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import Accountsidebar from "./account-sidebar";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
const HelpSupport = () => {
  const navigate = useNavigate()
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const contextValues = useContext(DataContext)
  useEffect(() => {
    setTimeout(() => {
      setSpinnerLoading(false)
    }, 500)
  }, [])
  return (<>

    <BrowserView>

      <WebsiteInnerheader></WebsiteInnerheader>
      <div className="subheader smallsubheader">
        <Container>
          <Row>
            <Col lg={12}>

              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-account">My Account</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Help & Support
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>
      <section className="section-gap-medium">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Accountsidebar></Accountsidebar>

            </div>
            <div className="col-lg-9">
              <div className="acpanel">
                <div className="acpanel-header">
                  <h4>Help & Support</h4>
                </div>
                <div className="acpanel-body">
                  {spinnerLoading ? <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} /> : <>
                    <div className="p-5 helpbox-main text-center">
                      <img className="img-fluid mb-2" src="/img/support.png" style={{ width: '200px' }}></img>
                      <div>
                      <h6>How can we help you today?</h6>
                      <p className="tx-13 mb-0"> Our customer service team will be able to assist you with any order or query</p>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-lg-4">
                        <a href={"mailto:" + contextValues.settingData.admin_email} className="helpbox" target="new">
                          <div className="helpbox-icon">
                          <img src="/img/mail.png"></img>
                          </div>
                          <div className="helpbox-content">
                          <h6>Email Us</h6>
                              <p className="mb-0 tx-12 tx-gray line18"> Write to Sparsh directly for any query (Order, etc)</p>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-4">
                        <a href={"tel:" + contextValues.settingData.admin_mobile} className="helpbox" target="new">
                          <div className="helpbox-icon">
                          <img src="/img/call.png"></img>
                          </div>
                          <div className="helpbox-content">
                          <h6>Call Us</h6>
                              <p className="mb-0 tx-12 tx-gray line18">Get in touch and we will be happy to help you</p>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-4">
                        <a href={"https://wa.me/" + contextValues.settingData.admin_whatsapp_no} className="helpbox" target="new">
                          <div className="helpbox-icon">
                          <img src="/img/whatsapp.png"></img>
                          </div>
                          <div className="helpbox-content">
                          <h6>Whatsapp</h6>
                              <p className="mb-0 tx-12 tx-gray line18">Get in touch and we will be happy to help you</p>
                          </div>
                        </a>
                      </div>
                    </div>
                   




                  </>}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </BrowserView>
    <MobileView>
     
      <header className="mheader mheader-inner">
                <div className="mheaderTop">
                    <div className="container">
                        <div className="mheader-left" onClick={()=>{navigate('/my-account')}}>
                            <div className="mheader-title">
                                <a href='javascript:void(0)'>
                                    <i className="ri-arrow-left-line"></i>
                                </a>
                                <h1>Help & Support</h1> 
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </header>
      <main>
      {spinnerLoading ? <Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} /> :
                <>

                  <div className="p-5 helpbox-main text-center">
                    <img className="img-fluid mb-2" src="/img/support.png" style={{ width: '200px' }}></img>
                    <div>
                      <h6>How can we help you today?</h6>
                      <p className="tx-13 mb-0"> Our customer service team will be able to assist you with any order or query</p>
                      </div>
                  </div>
                  <div className="container">
                  <div className="row">
                      <div className="col-lg-4">
                        <a href={"mailto:" + contextValues.settingData.admin_email} className="helpbox" target="new">
                          <div className="helpbox-icon">
                          <img src="/img/mail.png"></img>
                          </div>
                          <div className="helpbox-content">
                          <h6 className="mb-0">Email Us</h6>
                              <p className="mb-0 tx-12 tx-gray line18"> Write to Sparsh directly for any query (Order, etc)</p>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-4">
                        <a href={"tel:" + contextValues.settingData.admin_mobile} className="helpbox" target='new'>
                          <div className="helpbox-icon">
                          <img src="/img/call.png"></img>
                          </div>
                          <div className="helpbox-content">
                          <h6 className="mb-0">Call Us</h6>
                              <p className="mb-0 tx-12 tx-gray line18">Get in touch and we will be happy to help you</p>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-4">
                        <a href={"https://wa.me/" + contextValues.settingData.admin_whatsapp_no} className="helpbox" target='new'>
                          <div className="helpbox-icon">
                          <img src="/img/whatsapp.png"></img>
                          </div>
                          <div className="helpbox-content">
                          <h6 className="mb-0">Whatsapp</h6>
                              <p className="mb-0 tx-12 tx-gray line18">Get in touch and we will be happy to help you</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    </div>
                </>
              }
      </main>

    </MobileView>
  </>)
}

export default HelpSupport