import React, { useState, useContext, useEffect, useRef } from "react"
import DataContext from "../Elements/context";
import ReviewModal from "../modals/reviewmodal";
import LoginModal from "../modals/login-modal";
import moment from "moment";
const Ratingreviews = ({ ratingdata, productdetail, PercentageData }) => {
    const contextValues = useContext(DataContext);
    const didMountRef = useRef(true);
    const [sessiondata, setsessiondata] = useState('')

    // useEffect(() => {
    //     if (didMountRef.current) {
    //         setsessiondata(localStorage.getItem("USER_TOKEN"));
    //     }
    //     didMountRef.current = false;
    // }, []);
    const reviewModal = (e) => {
        e.preventDefault()
        const sessiondata=localStorage.getItem("USER_TOKEN")
        console.log(sessiondata , "sessiondata")
        if (sessiondata) {
            contextValues.setToggleReviewModal(!contextValues.toggleReviewModal)
        } else {
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
        }

    }

    const RatingSection = () => {
        const ratings = [5, 4, 3, 2, 1];
        return (
            <div>
                {ratings.map((stars, index) => (
                    <div key={index} className="ratings-item">
                        <div className="ratings-container mb-0">
                            {Array.from({ length: stars }).map((_, index) => {
                                return (<i className="ri-star-fill fill" key={index}></i>)
                            })
                            }
                            {Array.from({ length: 5 - stars }).map((_, index) => (
                                <i key={index + stars} className="ri-star-fill"></i>
                            ))}('Review')
                        </div>
                        <div className="rating-percent">
                            <span style={{ width: Math.round(PercentageData[stars]) + "%" }}></span>
                        </div>
                        <div className="rating-value">{Math.round(PercentageData[stars])}%</div>
                    </div>
                ))}
            </div>
        );
    };
    return (<>
            <section className="section-gap-small">
                <div className="container">
                    <div className="section-title section-center">
                        <h2>Rating & Reviews</h2>
                        <p>Without love, connecting rural India to the world would just be a dream. Read what Jaipur Rugs really is, and what we stand for.</p>
                        <a href='javascript:void(0)' className="explorebutton" onClick={(e) => { reviewModal(e) }}>Write a Review</a> 
                    </div>
                    {ratingdata && ratingdata.length > 0 ? <>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="reviewsec">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="avg-rating-container">
                                            <mark>{productdetail.product_rating}</mark>
                                            <div className="avg-rating">
                                                <span className="avg-rating-title mb-1">Average Rating</span>
                                                <div className="ratings-container mb-0">
                                                    {productdetail && productdetail.length > 0 &&
                                                        productdetail.product_rating &&
                                                        productdetail.product_rating > 0 ? <>
                                                        {Array.from({ length: Number(productdetail.product_rating) }).map((_, index) => {
                                                            return (<i className="ri-star-fill fill" key={index}></i>)
                                                        })
                                                        }
                                                        {Array.from({ length: 5 - Number(productdetail.product_rating) }).map((_, index) => (
                                                            <i key={index + productdetail.product_rating} className="ri-star-fill"></i>
                                                        ))}
                                                    </>
                                                        : ''}
                                                    <span> ( Reviews)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ratings-list mb-4">
                                            <RatingSection></RatingSection>
                                        </div>
                                        {/* <button className="btn btn-primary btn-medium">Submit Review</button> */}
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="comentlist">
                                            <ul>
                                                {ratingdata && ratingdata.map((value, index) => {
                                                    return (<>
                                                        <li key={index}>
                                                            <div className="comment">
                                                                <figure className="comment-media"><img src="/img/userr.png" alt="avatar" /></figure>
                                                                <div className="comment-body">
                                                                    {value.pr_rating &&
                                                                        value.pr_rating > 0 ? (
                                                                        <div className="ratings-container mb-2">
                                                                            {
                                                                                Array.from({ length: value.pr_rating }, (_, index) => (
                                                                                    <i className="ri-star-fill fill" key={index}></i>
                                                                                ))
                                                                            }
                                                                            
                                                                            {Array.from({ length: 5 - value.pr_rating }).map((_, index) => (
                                                                                <i key={index + value.pr_rating} className="ri-star-fill"></i>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}

                                                                    <div className="comment-rating"></div>
                                                                    <div className="comment-user">
                                                                        <span className="comment-meta">by <span className="comment-name">{value.pr_title}</span> on<span className="comment-date">{moment(value.pr_created).format("MMM DD , YYYY")}</span></span>
                                                                    </div>
                                                                    <div className="comment-content">
                                                                        <p>
                                                                            <p>{value.pr_review}</p>
                                                                        </p>
                                                                    </div>
                                                                    <div className="comment-images">
                                                                        {value.pr_image1 && value.pr_image1.split(',').length > 0 && (
                                                                            <div className="comment-images">
                                                                                {value.pr_image1.split(',').map((imageUrl, index) => (
                                                                                    <img key={index} src={imageUrl} alt={`Image ${index + 1}`} width={50} />
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>)
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </> : null}

                </div>
            </section>
        <ReviewModal product_slug={productdetail.product_slug}></ReviewModal>
    </>)
}

export default Ratingreviews