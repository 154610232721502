
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import React, { useState, useEffect, useRef } from 'react'
import constant from "../../Component/Services/constant";
import { ApiService } from "../../Component/Services/apiservices";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import { BrowserView, MobileView } from "react-device-detect";
import MobileInnerheader from "../../Component/Header/mobile-header/inner";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
const Explore = () => {
  const navigate = useNavigate()
  const didMountRef = useRef(true);
  const [categorydata, setcategorydata] = useState([])
  const [catimgpath, setcatimgpath] = useState('')
  const [loading, setloading] = useState(false)
  useEffect(() => {
    if (didMountRef.current) {
      getcategorydata()
    }
    didMountRef.current = false;
  }, []);

  const getcategorydata = () => {
    setloading(true)
    ApiService.fetchData(
      "all-categories"
    ).then((res) => {
      if (res.status === "success") {
        setcategorydata(res.resCategory);
        setcatimgpath(res.category_image_path)
        setloading(false)
      } else {
        setloading(false)
      }
    }).catch((error) => {
      console.log(error)
      setloading(false)
    });
  }
  return (<>
    <BrowserView>
      <WebsiteInnerheader></WebsiteInnerheader>
      <div className="subheader">

        <Container>
          <Row>
            <Col lg={12}>
              <h1>Explore by Categories</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                <Breadcrumb.Item active>
                  Explore
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>

      {loading === true ? (
        <>
          <section className="section-gap-large">
            <div className="container">

              <div className="row g-3 cols-xl-6 cols-sm-3 cols-md-4">
                {[...Array(8)].map((_, index) => {
                  return (<>
                    <div>
                      <div className="category">
                        <Skeleton circle={true} height={200} />
                        <div className="category-content">
                          <Skeleton />
                        </div>
                      </div>
                    </div>
                  </>)
                })}
              </div>
            </div>
          </section>
        </>
      ) : <>



        <section className="section-gap-large">
          <div className="container">

            <div className="row g-3 cols-xl-6 cols-sm-3 cols-md-4">
              {categorydata && categorydata.map((item, index) => {
                return (<>
                  <div key={index}>
                    <div className="category">
                      <div className="category-inner overlay-zoom">
                        <a href={`/collection/category/${item.cat_slug}`}>
                          <figure className="category-media">
                            <img src={item.cat_image ? catimgpath + item.cat_image : constant.DEFAULT_IMAGE}></img>
                          </figure>
                        </a>
                      </div>
                      <div className="category-content">
                        <h5><a href={`/collection/category/${item.cat_slug}`}>{item.cat_name}</a></h5>
                      </div>
                    </div>
                  </div>
                </>)
              })}
            </div>
          </div>
        </section>

      </>}



      <Footer></Footer>


    </BrowserView>
    <MobileView>
      <MobileInnerheader headertitle={'Explore'} gobackroute={'/'}></MobileInnerheader>
      {loading ? <>
        <section className="section-gap-small">
          <div className="container">

            <div className="row g-3 cols-3 pt-5">
              {[...Array(8)].map((_, index) => {
                return (<>
                  <div>
                    <div className="category">
                      <Skeleton height={100} width={100} circle={true} />
                      <div className="category-content">
                        <Skeleton />
                      </div>
                    </div>
                  </div>
                </>)
              })}
            </div>
          </div>
        </section>
      </> : <>
        {categorydata && categorydata.length > 0 ? <>
          <section className="section-gap-small">
            <div className="container">

              <div className="row g-3 cols-3 pt-5">
                {categorydata && categorydata.map((item, index) => {
                  return (<>
                    <div key={index} onClick={() => { navigate(`/collection/category/${item.cat_slug}`) }}>
                      <div className="category">
                        <div className="category-inner overlay-zoom">
                          <a href={`/collection/category/${item.cat_slug}`}>
                            <figure className="category-media">
                              <img src={item.cat_image ? catimgpath + item.cat_image : constant.DEFAULT_IMAGE}></img>
                            </figure>
                          </a>
                        </div>
                        <div className="category-content">
                          <h6><a href={`/collection/category/${item.cat_slug}`}>{item.cat_name}</a></h6>
                        </div>
                      </div>
                    </div>
                  </>)
                })}
              </div>
            </div>
          </section>
        </> : null}
      </>}
    </MobileView>

  </>)
}

export default Explore