import React, { useContext, useState, useEffect, useRef } from 'react';
import { BrowserView, MobileView } from "react-device-detect"
import DataContext from '../../Component/Elements/context';
import { ApiService } from '../../Component/Services/apiservices';
import Checkoutheader from '../../Component/Header/checkout-header';
import Collapse from 'react-bootstrap/Collapse';
import sessionCartData from '../../Component/Elements/cart_session_data';
import multiCurrency from '../../Component/Elements/multi_currrency';
import { useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import { showToast } from '../../Component/Elements/toast';
import SpinnerLoader from '../../Component/Elements/spinner_loader';
import CheckoutAddressModal from '../../Component/modals/checkout-address-modal';
import Footer from '../../Component/Footer';
const CartAddress = () => {
    const navigate = useNavigate();
    const contextValues = useContext(DataContext);
    const dataArray = sessionCartData();
    const didMountRef = useRef(true);
    const [openAddress, setOpenAddress] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [showstatecityselect, setshowstateselect] = useState(false);
    const [statesData, setstatesData] = useState([]);
    const [cityData, setcityData] = useState([]);
    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_name: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_house_no: "",
        ua_area: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_address_type: "home",
        ua_address_type_other: "",
        ua_state_id: "",
        ua_city_id: "",
        ua_default_address: "",
        ua_country_id: 101,
    });
    const [spinnerLoading, setspinnerLoading] = useState(true);
    const [fullSpinnerLoading, setFullSpinnerLoading] = useState(false);

    useEffect(() => {
        if (didMountRef.current) {
            getUserAddress()
            setTimeout(() => {
                if (dataArray[5] && dataArray[1].length > 0 && dataArray[3]) {

                } else {
                    navigate('/')
                }
            }, 500);
        }
        didMountRef.current = false;
    }, []);

    const getStateData = () => {
        ApiService.fetchData("get-states-data").then((res) => {
            if (res.status == "success") {
                setstatesData(res.data);
            }
        });
    };

    const getallcityData = () => {
        ApiService.fetchData("getallcitydata").then((res) => {
            if (res.status == "success") {
                setcityData(res.data);
            }
        });
    };

    const isValidMobileNumber = (mobileNumber) => {
        const mobileNumberPattern = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        return mobileNumberPattern.test(mobileNumber);
    };

    const onTodoAddChange = (e) => {
        const { name, value } = e.target;
        setUserAddressDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));


        if (name === 'ua_state_id') {
            getcityData(value);
        }
        if (name === 'ua_pincode') {
            setshowstateselect(false)
            setUserAddressDetails(prevState => ({
                ...prevState,
                ua_state_id: "",
                ua_city_id: "",
                ua_state_name: '',
                ua_city_name: ''
            }));
            if (value.length === 6) {
                checkPincode(value)
            } else {

            }
        }

    };

    const getcityData = (value) => {
        const dataString = {
            stateid: value
        }
        ApiService.postData("get-city-data", dataString).then(
            (res) => {
                if (res.status == "success") {
                    setcityData(res.data)
                }
            }
        );
    }

    const checkPincode = (value) => {

        const dataString = {
            pincode: value
        }
        setFullSpinnerLoading(true);
        ApiService.postData("check-pincode", dataString).then(
            (res) => {
                if (res.status == "success") {
                    setUserAddressDetails(prevState => ({
                        ...prevState,
                        ua_state_id: res.data.pin_state_id,
                        ua_city_id: res.data.pin_city_id,
                        ua_state_name: res.data.State,
                        ua_city_name: res.data.City
                    }));
                    setFullSpinnerLoading(false);
                } else {
                    setshowstateselect(true)
                    setUserAddressDetails(prevState => ({
                        ...prevState,
                        ua_state_id: "",
                        ua_city_id: "",
                        ua_state_name: '',
                        ua_city_name: ''
                    }));
                    getStateData();
                    getallcityData();
                    setFullSpinnerLoading(false);
                }
            }
        );
    }

    const handleAddressProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("required");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {
                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }
        if (counter === 0) {
            setErrorMessage("");
            if (userAddressDetails.ua_name == '') {
                setErrorMessage('Please enter name');
                return;
            }
            if (userAddressDetails.ua_mobile == '') {
                setErrorMessage('Please enter mobile number');
                return;
            }

            if (!isValidMobileNumber(userAddressDetails.ua_mobile)) {
                setErrorMessage('Please enter a valid mobile number');
                return;
            }
            if (userAddressDetails.ua_pincode == '') {
                setErrorMessage('Please enter post code');
                return;
            }

            if (userAddressDetails.ua_state_id == "" && showstatecityselect) {
                setErrorMessage('Plese Select State');
                return false;
            }

            if (userAddressDetails.ua_house_no == '') {
                setErrorMessage('Plese enter house no , building or street');
                return false;
            }
            if (userAddressDetails.ua_area == '') {
                setErrorMessage('Please enter locality , town');
                return;
            }
            if (userAddressDetails.ua_city_id == "" && showstatecityselect) {
                setErrorMessage('Plese Select City');
                return false;
            }
            if (userAddressDetails.ua_address_type == "") {
                setErrorMessage('Plese Select address type');
                return false;
            }
            if (userAddressDetails.ua_address_type == "other") {
                if (userAddressDetails.ua_address_type_other == "" || userAddressDetails.ua_address_type_other == null) {
                    setErrorMessage('Plese enter other address type');
                    return false;
                }
            }
            setFullSpinnerLoading(true);
            ApiService.postData("/userCartAddressProcess", userAddressDetails).then(
                (res) => {
                    if (res.status == "success") {
                        setSuccessMessage(res.message);
                        setFullSpinnerLoading(false);
                        localStorage.setItem("ADDRESS_SESSION", JSON.stringify(res.rowUserAddress));
                        navigate('/checkout')
                    } else {
                        setErrorMessage(res.message);
                        setFullSpinnerLoading(false);
                    }
                }
            );
        }
    };

    const checkoutAddressModal = () => {
        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
    }

    const selectAddress = (addressValue) => {
        const productData = {
            ua_id: addressValue.ua_id,
            ua_pincode: addressValue.ua_pincode,
        };
        setFullSpinnerLoading(true)
        ApiService.postData("selectAddress", productData).then((res) => {
            if (res.status === 'success') {
                getUserAddress();
                setTimeout(() => {
                    setFullSpinnerLoading(false)
                }, 500);

            } else {
                showToast('error', res.message, 2000)
                setTimeout(() => {
                    setFullSpinnerLoading(false)
                }, 500);
            }
        }).catch((error) => {

        });
    }

    const getUserAddress = () => {
        ApiService.fetchData("get-user-address").then((res) => {
            if (res.status === "success") {
                contextValues.setUserAddressList(res.resUserAddress);
                if (res.resUserAddress.length == 0) {
                    localStorage.removeItem("ADDRESS_SESSION");
                    contextValues.setToggleCheckoutAddress(false)
                } else {
                    const defaultAddress = res.resUserAddress.find(address => address.ua_default_address === 1);
                    if (defaultAddress) {
                        localStorage.setItem("ADDRESS_SESSION", JSON.stringify(defaultAddress));
                    }
                    contextValues.setToggleCheckoutAddress(true)
                    getShippingCharges();
                }
                setspinnerLoading(false);
            } else if(res.message === "Session expired" && res.status === "session_expired") {
                localStorage.removeItem("USER_TOKEN")
                setspinnerLoading(false)
                navigate('/')
            }else {
                setspinnerLoading(false)
            }
        });
    };

    const getShippingCharges = () => {
        const addressSession = localStorage.getItem("ADDRESS_SESSION");
        const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
        const productData = {
            itemtotal: dataArray[3] && dataArray[3].mrpTotal ? dataArray[3].mrpTotal : 0,
            ua_id: parsedAddressSession.ua_id,
            cart_data: dataArray[3],
        };
        ApiService.postData("calculateShippingAmount", productData).then((res) => {
            if (res.status === 'success') {
                contextValues.setShippingAmount(res.shipping_amount)
            } else {
                showToast('error', res.message, 2000)
            }
        }).catch((error) => {

        });
    }

    const processToCheckout = () => {
        const addressSession = localStorage.getItem("ADDRESS_SESSION");
        const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
        const productData = {
            ua_id: parsedAddressSession.ua_id,
            ua_pincode: parsedAddressSession.ua_pincode,
        };
        setFullSpinnerLoading(true)
        ApiService.postData("selectAddress", productData).then((res) => {
            if (res.status === 'success') {
                setTimeout(() => {
                    setFullSpinnerLoading(false)
                    navigate('/checkout')
                }, 500);
            } else {
                showToast('error', res.message, 2000)
                setTimeout(() => {
                    setFullSpinnerLoading(false)
                }, 500);
            }
        }).catch((error) => {

        });
    }

    const openAddressModal = () => {
        contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
    }

    return (<>
        <Checkoutheader backRedirect="/cart" step="2" title="Address" />
        <BrowserView>
            <div className='sectioncart'>
                {fullSpinnerLoading && <SpinnerLoader />}
                {spinnerLoading ?
                    <Loader type="spinner-cub" bgColor={'#e5097f'} color={'#e5097f'} size={50} />
                    :
                    <>
                        <div className='sectioncart-leftblock'>
                            {contextValues.toggleCheckoutAddress ?
                                <>
                                    <h5 className='pageTitle-inner'>Select Delivery Address</h5>
                                    <div className="addressselbox mt-20" style={{ padding: '0px' }}>
                                        {errorMessage && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <button type="button" className="addnewaddress" onClick={(e) => openAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                                        {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                                            <div className={`selectaddress ${value.ua_default_address > 0 ? 'active' : ''} mt-10`} key={index} onClick={(e) => selectAddress(value)}>
                                                <div className='saddresslable'>{value.ua_address_type == "Other" ? value.ua_address_type_other : value.ua_address_type}</div>
                                                <h6 className='mb-0 tx-14'>{value.ua_name}</h6>
                                                <p className='mb-0 tx-13'>{value.ua_house_no}, {value.ua_city_name}, {value.ua_state_name}, {value.ua_pincode}</p>
                                                <p className='tx-gray mb-0 tx-12'>+91 {value.ua_mobile} . {value.ua_email}</p>
                                                <span className='selectad'></span>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                :
                                <div className='addresssec'>
                                    <div className='addresssecHeader' style={{ padding: "0px 0px 15px" }}>
                                        <h5 className="mb-0">Add New Address</h5>
                                    </div>
                                    <div className='addresssecBody' style={{ padding: "15px 0px" }}>
                                        {errorMessage && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>
                                        )}
                                        {successMessage && (
                                            <div className="alert alert-success" role="alert">
                                                {successMessage}
                                            </div>
                                        )}
                                        <div className='row g-3'>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>Name</label>
                                                    <input type='text' name="ua_name" value={userAddressDetails.ua_name}
                                                        onChange={(e) => onTodoAddChange(e)}></input>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>Mobile Number</label>
                                                    <input type='number' name="ua_mobile" value={userAddressDetails.ua_mobile}
                                                        onChange={(e) => onTodoAddChange(e)}></input>
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className='form-group'>
                                                    <label>Postcode</label>
                                                    <input type='number' name="ua_pincode" value={userAddressDetails.ua_pincode}
                                                        onChange={(e) => onTodoAddChange(e)}></input>
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>Address (House No, Building, Street, Area)</label>
                                                    <input type='text' value={userAddressDetails.ua_house_no}
                                                        onChange={(e) => onTodoAddChange(e)} name="ua_house_no"></input>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>Locality / Town</label>
                                                    <input type='text' value={userAddressDetails.ua_area}
                                                        onChange={(e) => onTodoAddChange(e)} name="ua_area"></input>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>State</label>
                                                    {showstatecityselect ?
                                                        <select name="ua_state_id" className="form-control  ua_state_id" value={userAddressDetails.ua_state_id} onChange={(e) => onTodoAddChange(e)}>
                                                            <option value="">Select State</option>
                                                            {statesData.length > 0 && statesData.map((value) => (
                                                                <option value={value.state_id}>{value.state_name}</option>
                                                            ))}
                                                        </select>
                                                        :
                                                        <input
                                                            type="text"
                                                            name="ua_state_name"
                                                            className="form-control ua_state_name"
                                                            value={userAddressDetails.ua_state_name}
                                                            readOnly
                                                            disabled={true}
                                                            onChange={(e) => onTodoAddChange(e)}
                                                            placeholder="State"
                                                        />
                                                    }

                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>City / District</label>
                                                    {showstatecityselect ?
                                                        <select name="ua_city_id" className="form-control ua_city_id" value={userAddressDetails.ua_city_id} onChange={(e) => onTodoAddChange(e)}>
                                                            <option value="">Select City</option>
                                                            {cityData.length > 0 && cityData.map((value) => (
                                                                <option value={value.cities_id}>{value.cities_name}</option>
                                                            ))}
                                                        </select>
                                                        :
                                                        <input
                                                            type="text"
                                                            name="ua_city_name"
                                                            className=""
                                                            value={userAddressDetails.ua_city_name}
                                                            onChange={(e) => onTodoAddChange(e)}
                                                            disabled={true}
                                                            readOnly
                                                            placeholder="City"
                                                        />
                                                    }

                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className="tx-14 fw600 mb-2">Save address as</div>
                                                <div className="saveaddresslist">
                                                    <ul>
                                                        <li className={userAddressDetails.ua_address_type === 'home' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                                                            ...prevState,
                                                            ua_address_type: 'home',
                                                        }))}>Home</li>
                                                        <li className={userAddressDetails.ua_address_type === 'work' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                                                            ...prevState,
                                                            ua_address_type: 'work',
                                                        }))}>Work</li>
                                                        <li className={userAddressDetails.ua_address_type === 'other' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                                                            ...prevState,
                                                            ua_address_type: 'other',
                                                        }))}>Other</li>
                                                    </ul>

                                                </div>
                                            </div>
                                            {userAddressDetails.ua_address_type === 'other' ?
                                                <div className='col-lg-12'>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="ua_address_type_other"
                                                            value={userAddressDetails.ua_address_type_other}
                                                            onChange={(e) => onTodoAddChange(e)}
                                                            placeholder="Enter name"
                                                        />
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            <div className='col-lg-12'>
                                                <button type="button" className="btn-theme btn-full btn-theme-lg mt-2" onClick={(e) => { handleAddressProcess(e) }}><span>Save & Continue</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='sectioncart-rightblock'>
                            <div className='cardbox mb-15'>
                                <div className="cardboxHeader mb-10">
                                    <h6 className="mb-0">Price Details</h6>
                                </div>
                                <div className="cardboxBody">
                                    <ul className="priceList">
                                        <li><span>Total MRP</span><span className="pric">{contextValues.cartSummary && contextValues.cartSummary.mrpTotal ? multiCurrency(contextValues.cartSummary.mrpTotal) : multiCurrency(0)}</span></li>
                                        <li><span>Discount on MRP</span><span className="pric tx-green">-{contextValues.cartSummary && contextValues.cartSummary.discount ? multiCurrency(contextValues.cartSummary.discount) : multiCurrency(0)}</span></li>
                                        <li><span>Coupon Discount</span><span className="pric tx-green">-{multiCurrency(contextValues.couponSession.discount_amount)}</span></li>
                                        <li><span>Shipping</span><span className="pric">{multiCurrency(contextValues.shippingAmount)}</span></li>
                                        <div className="divider-dotted1"></div>
                                        <li className="fw600"><span>Total Amount</span><span className="pric">{contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? multiCurrency(contextValues.cartSummary.sellingTotal - Number(contextValues.couponSession.discount_amount) + Number(contextValues.shippingAmount)) : multiCurrency(0)}</span></li>
                                    </ul>
                                </div>
                            </div>
                            {contextValues.userAddressList && contextValues.userAddressList.length > 0 ? <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => processToCheckout()}><span>Continue</span></button> : null}
                        </div>
                    </>
                }
            </div>
            <Footer />
        </BrowserView>
        <MobileView>
            <main>
                {fullSpinnerLoading && <SpinnerLoader />}
                {spinnerLoading ?
                    <Loader type="spinner-cub" bgColor={'#e5097f'} color={'#e5097f'} size={50} />
                    :
                    <>
                        {contextValues.toggleCheckoutAddress ?
                            <>

                                <div className="addressselbox mt-20">
                                    {errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    <button type="button" className="addnewaddress" onClick={(e) => openAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                                    {contextValues.userAddressList.length > 0 && contextValues.userAddressList.map((value, index) => (
                                        <div className={`selectaddress ${value.ua_default_address > 0 ? 'active' : ''} mt-10`} key={index} onClick={(e) => selectAddress(value)}>
                                            <div className='saddresslable'>{value.ua_address_type == "Other" ? value.ua_address_type_other : value.ua_address_type}</div>
                                            <h6 className='mb-0 tx-14'>{value.ua_name}</h6>
                                            <p className='mb-0 tx-13'>{value.ua_house_no}, {value.ua_city_name}, {value.ua_state_name}, {value.ua_pincode}</p>
                                            <p className='tx-gray mb-0 tx-12'>+91 {value.ua_mobile} . {value.ua_email}</p>
                                            <span className='selectad'></span>
                                        </div>
                                    ))}
                                </div>
                                <div className="footer-checkout">
                                    {contextValues.userAddressList && contextValues.userAddressList.length > 0 ? <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => processToCheckout()}><span>Continue</span></button> : null}
                                </div>
                            </>
                            :
                            <>
                                <div className='addresssec'>
                                    <div className='addresssecHeader'>
                                        <h6 className="mb-0">Add New Address</h6>
                                    </div>
                                    <div className='addresssecBody'>
                                        {errorMessage && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>
                                        )}
                                        {successMessage && (
                                            <div className="alert alert-success" role="alert">
                                                {successMessage}
                                            </div>
                                        )}
                                        <div className='row g-3'>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>Name</label>
                                                    <input type='text' name="ua_name" value={userAddressDetails.ua_name}
                                                        onChange={(e) => onTodoAddChange(e)}></input>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>Mobile Number</label>
                                                    <input type='number' name="ua_mobile" value={userAddressDetails.ua_mobile}
                                                        onChange={(e) => onTodoAddChange(e)}></input>
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className='form-group'>
                                                    <label>Postcode</label>
                                                    <input type='number' name="ua_pincode" value={userAddressDetails.ua_pincode}
                                                        onChange={(e) => onTodoAddChange(e)}></input>
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>Address (House No, Building, Street, Area)</label>
                                                    <input type='text' value={userAddressDetails.ua_house_no}
                                                        onChange={(e) => onTodoAddChange(e)} name="ua_house_no"></input>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label>Locality / Town</label>
                                                    <input type='text' value={userAddressDetails.ua_area}
                                                        onChange={(e) => onTodoAddChange(e)} name="ua_area"></input>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-6'>
                                                <div className='form-group'>
                                                    <label>State</label>
                                                    {showstatecityselect ?
                                                        <select name="ua_state_id" className="form-control  ua_state_id" value={userAddressDetails.ua_state_id} onChange={(e) => onTodoAddChange(e)}>
                                                            <option value="">Select State</option>
                                                            {statesData.length > 0 && statesData.map((value) => (
                                                                <option value={value.state_id}>{value.state_name}</option>
                                                            ))}
                                                        </select>
                                                        :
                                                        <input
                                                            type="text"
                                                            name="ua_state_name"
                                                            className="form-control ua_state_name"
                                                            value={userAddressDetails.ua_state_name}
                                                            readOnly
                                                            disabled={true}
                                                            onChange={(e) => onTodoAddChange(e)}
                                                            placeholder="State"
                                                        />
                                                    }

                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-6'>
                                                <div className='form-group'>
                                                    <label>City / District</label>
                                                    {showstatecityselect ?
                                                        <select name="ua_city_id" className="form-control ua_city_id" value={userAddressDetails.ua_city_id} onChange={(e) => onTodoAddChange(e)}>
                                                            <option value="">Select City</option>
                                                            {cityData.length > 0 && cityData.map((value) => (
                                                                <option value={value.cities_id}>{value.cities_name}</option>
                                                            ))}
                                                        </select>
                                                        :
                                                        <input
                                                            type="text"
                                                            name="ua_city_name"
                                                            className=""
                                                            value={userAddressDetails.ua_city_name}
                                                            onChange={(e) => onTodoAddChange(e)}
                                                            disabled={true}
                                                            readOnly
                                                            placeholder="City"
                                                        />
                                                    }

                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className="tx-14 fw600 mb-2">Save address as</div>
                                                <div className="saveaddresslist">
                                                    <ul>
                                                        <li className={userAddressDetails.ua_address_type === 'home' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                                                            ...prevState,
                                                            ua_address_type: 'home',
                                                        }))}>Home</li>
                                                        <li className={userAddressDetails.ua_address_type === 'work' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                                                            ...prevState,
                                                            ua_address_type: 'work',
                                                        }))}>Work</li>
                                                        <li className={userAddressDetails.ua_address_type === 'other' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                                                            ...prevState,
                                                            ua_address_type: 'other',
                                                        }))}>Other</li>
                                                    </ul>

                                                </div>
                                            </div>
                                            {userAddressDetails.ua_address_type === 'other' ?
                                                <div className='col-lg-12'>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="ua_address_type_other"
                                                            value={userAddressDetails.ua_address_type_other}
                                                            onChange={(e) => onTodoAddChange(e)}
                                                            placeholder="Enter name"
                                                        />
                                                    </div>
                                                </div>
                                                : null
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className='footer-checkout'>
                                    <button type="button" className="btn-theme btn-full btn-theme-lg" onClick={(e) => { handleAddressProcess(e) }}><span>Save & Continue</span></button>
                                </div>
                            </>
                        }
                    </>
                }
            </main>
        </MobileView>
        <CheckoutAddressModal />
    </>)
}

export default CartAddress