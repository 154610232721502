import { showToast } from "./toast";
import constant from "../Services/constant";
import { ApiService } from "../Services/apiservices";
import { trackAddToCart } from "../Services/facebookTracking";

const addToCart = (productData, quantity, contextValues) => {
    return new Promise((resolve, reject) => {
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        //localStorage.removeItem("CART_SESSION");return false;
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.product_variation) ===
                JSON.stringify(productData.product_variation)
            );
        });
        if (existingProductIndex !== -1) {
            cartSession[existingProductIndex].quantity += quantity;
            quantity = cartSession[existingProductIndex].quantity
        } else {
            cartSession.push({ ...productData, quantity: 1 });
            quantity = 1
        }
        const dataString = {
            product_id: Number(productData.product_id),
            product_variation: productData.product_variation,
            quantity: quantity,
        };
        ApiService.postData("addToCart", dataString).then((res) => {
            if (res.status === 'success') { 
                /* const existingProductIndex = cartSession.findIndex((item) => {
                    return (
                        item.product_id === productData.product_id &&
                        JSON.stringify(item.product_variation) ===
                        JSON.stringify(productData.product_variation)
                    );
                });
                if (existingProductIndex !== -1) {
                    cartSession[existingProductIndex].quantity += 1;
                } else {
                    cartSession.push({ ...productData, quantity: 1 });
                } */
                localStorage.removeItem("COUPON_SESSION");
                localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
                let cartSessionCount = localStorage.getItem("CART_SESSION");
                cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
                contextValues.setCartSessionData(cartSessionCount)
                contextValues.setCartCount(cartSessionCount.length)
                showToast('success', res.message, 2000);
                resolve(true);
            } else {
                showToast('error', res.message, 2000);
                resolve(false);
            }
        }).catch((error) => {
            showToast('error', error, 2000);
            resolve(false);
        });
    });
};

const addToCartDetailPage = (productData, quantity, contextValues) => {
    return new Promise((resolve, reject) => {
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        //localStorage.removeItem("CART_SESSION");return false;
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.product_variation) ===
                JSON.stringify(productData.product_variation)
            );
        });
        if (existingProductIndex !== -1) {
            cartSession[existingProductIndex].quantity += quantity;
            quantity = cartSession[existingProductIndex].quantity
        } else {
            cartSession.push({ ...productData, quantity: 1 });
            quantity = 1
        }
        const dataString = {
            product_id: Number(productData.product_id),
            product_variation: productData.product_variation,
            quantity: quantity,
        };
        ApiService.postData("addToCart", dataString).then((res) => {
            if (res.status === 'success') { 
                localStorage.removeItem("COUPON_SESSION");
                localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
                let cartSessionCount = localStorage.getItem("CART_SESSION");
                cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
                contextValues.setCartSessionData(cartSessionCount)
                contextValues.setCartCount(cartSessionCount.length)
                showToast('success', res.message, 2000);
                resolve(true);
                trackAddToCart(cartSession)

            } else {
                showToast('error', res.message, 2000);
                resolve(false);
            }
        }).catch((error) => {
            showToast('error', error, 2000);
            resolve(false);
        });
    });
};

const buyNowDetailPage = (productData, quantity, contextValues) => {
    return new Promise((resolve, reject) => {
        let cartSession = localStorage.getItem("CART_SESSION");
        cartSession = cartSession ? JSON.parse(cartSession) : [];
        //localStorage.removeItem("CART_SESSION");return false;
        const existingProductIndex = cartSession.findIndex((item) => {
            return (
                item.product_id === productData.product_id &&
                JSON.stringify(item.product_variation) ===
                JSON.stringify(productData.product_variation)
            );
        });
        if (existingProductIndex !== -1) {
            cartSession[existingProductIndex].quantity += quantity;
            quantity = cartSession[existingProductIndex].quantity
        } else {
            cartSession.push({ ...productData, quantity: 1 });
            quantity = 1
        }
        const dataString = {
            product_id: Number(productData.product_id),
            product_variation: productData.product_variation,
            quantity: quantity,
        };
        ApiService.postData("addToCart", dataString).then((res) => {
            if (res.status === 'success') { 
                localStorage.removeItem("COUPON_SESSION");
                localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
                let cartSessionCount = localStorage.getItem("CART_SESSION");
                cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
                contextValues.setCartSessionData(cartSessionCount)
                contextValues.setCartCount(cartSessionCount.length)
                showToast('success', res.message, 2000);
                resolve(true);
            } else {
                showToast('error', res.message, 2000);
                resolve(false);
            }
        }).catch((error) => {
            showToast('error', error, 2000);
            resolve(false);
        });
    });
};

const minusToCart = (productData, selvararray, contextValues) => {
    let cartSession = localStorage.getItem("CART_SESSION");
    cartSession = cartSession ? JSON.parse(cartSession) : [];
    const existingProductIndex = cartSession.findIndex((item) => {
        return (
            item.product_id === productData.product_id &&
            JSON.stringify(item.product_variation) ===
            JSON.stringify(selvararray)
        );
    });

    if (existingProductIndex !== -1) {
        if (cartSession[existingProductIndex].quantity === 1) {
            cartSession.splice(existingProductIndex, 1);
        } else {
            cartSession[existingProductIndex].quantity -= 1;
        }
        localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
    }
    let cartSessionCount = localStorage.getItem("CART_SESSION");
    cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
    contextValues.setCartSessionData(cartSessionCount)
    contextValues.setCartCount(cartSessionCount.length)
    localStorage.removeItem("COUPON_SESSION");
    return true;
};

const removeToCart = (productData, selvararray, contextValues) => {
    let cartSession = localStorage.getItem("CART_SESSION");
    cartSession = cartSession ? JSON.parse(cartSession) : [];
    const existingProductIndex = cartSession.findIndex((item) => {
        return (
            item.product_id === productData.product_id &&
            JSON.stringify(item.product_variation) ===
            JSON.stringify(selvararray)
        );
    });

    if (existingProductIndex !== -1) {
        cartSession.splice(existingProductIndex, 1);
        localStorage.setItem("CART_SESSION", JSON.stringify(cartSession));
    }
    let cartSessionCount = localStorage.getItem("CART_SESSION");
    cartSessionCount = cartSessionCount ? JSON.parse(cartSessionCount) : [];
    contextValues.setCartSessionData(cartSessionCount)
    contextValues.setCartCount(cartSessionCount.length)
    localStorage.removeItem("COUPON_SESSION");
    return true;
};

export { addToCart, minusToCart, addToCartDetailPage, buyNowDetailPage, removeToCart };