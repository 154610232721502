import Modal from 'react-bootstrap/Modal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../Elements/context';
import { ApiService } from '../Services/apiservices';
import SpinnerLoader from '../Elements/spinner_loader';
const CheckoutAddressModal = () => {
  const contextValues = useContext(DataContext);
  const addressModal = () => {
    contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
  }
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showstatecityselect, setshowstateselect] = useState(false);
  const [statesData, setstatesData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [userAddressDetails, setUserAddressDetails] = useState({
    ua_id: 0,
    ua_name: "",
    ua_email: "",
    ua_mobile: "",
    ua_pincode: "",
    ua_house_no: "",
    ua_area: "",
    ua_state_name: "",
    ua_city_name: "",
    ua_address_type: "home",
    ua_address_type_other: "",
    ua_state_id: "",
    ua_city_id: "",
    ua_default_address: "",
    ua_country_id: 101,
  });
  const [spinnerLoading, setspinnerLoading] = useState(false);

  useEffect(() => {
     
  }, []);

  const getStateData = () => {
    ApiService.fetchData("get-states-data").then((res) => {
      if (res.status == "success") {
        setstatesData(res.data);
      }
    });
  };

  const getallcityData = () => {
    ApiService.fetchData("getallcitydata").then((res) => {
      if (res.status == "success") {
        setcityData(res.data);
      }
    });
  };
  const isValidMobileNumber = (mobileNumber) => {
    const mobileNumberPattern = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    return mobileNumberPattern.test(mobileNumber);
  };
  const onTodoAddChange = (e) => {
    const { name, value } = e.target;
    setUserAddressDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));


    if (name === 'ua_state_id') {
      getcityData(value);
    }
    if (name === 'ua_pincode') {
      setshowstateselect(false)
      setUserAddressDetails(prevState => ({
        ...prevState,
        ua_state_id: "",
        ua_city_id: "",
        ua_state_name: '',
        ua_city_name: ''
      }));
      if (value.length === 6) {
        checkPincode(value)
      } else {

      }
    }

  };

  const getcityData = (value) => {
    const dataString = {
      stateid: value
    }
    ApiService.postData("get-city-data", dataString).then(
      (res) => {
        if (res.status == "success") {
          setcityData(res.data)
        }
      }
    );
  }

  const checkPincode = (value) => {
    const dataString = {
      pincode: value
    }
    setspinnerLoading(true);
    ApiService.postData("check-pincode", dataString).then(
      (res) => {
        if (res.status == "success") {
          setUserAddressDetails(prevState => ({
            ...prevState,
            ua_state_id: res.data.pin_state_id,
            ua_city_id: res.data.pin_city_id,
            ua_state_name: res.data.State,
            ua_city_name: res.data.City
          }));
          setspinnerLoading(false);
        } else {
          setshowstateselect(true)
          setUserAddressDetails(prevState => ({
            ...prevState,
            ua_state_id: "",
            ua_city_id: "",
            ua_state_name: '',
            ua_city_name: ''
          }));
          getStateData();
          getallcityData();
          setspinnerLoading(false);
        }
      }
    );
  }

  const handleAddressProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("required");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === "") {
        myElements[i].style.border = "1px solid red";
        counter++;
      } else {
        myElements[i].style.border = "";
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (userAddressDetails.ua_name == '') {
        setErrorMessage('Please enter name');
        return;
      }
      if (userAddressDetails.ua_mobile == '') {
        setErrorMessage('Please enter mobile number');
        return;
      }

      if (!isValidMobileNumber(userAddressDetails.ua_mobile)) {
        setErrorMessage('Please enter a valid mobile number');
        return;
      }
      if (userAddressDetails.ua_pincode == '') {
        setErrorMessage('Please enter post code');
        return;
      }

      if (userAddressDetails.ua_state_id == "" && showstatecityselect) {
        setErrorMessage('Plese Select State');
        return false;
      }

      if (userAddressDetails.ua_house_no == '') {
        setErrorMessage('Plese enter house no , building or street');
        return false;
      }
      if (userAddressDetails.ua_area == '') {
        setErrorMessage('Please enter locality , town');
        return;
      }
      if (userAddressDetails.ua_city_id == "" && showstatecityselect) {
        setErrorMessage('Plese Select City');
        return false;
      }
      if (userAddressDetails.ua_address_type == "") {
        setErrorMessage('Plese Select address type');
        return false;
      }
      if (userAddressDetails.ua_address_type == "other") {
        if (userAddressDetails.ua_address_type_other == "" || userAddressDetails.ua_address_type_other == null) {
          setErrorMessage('Plese enter other address type');
          return false;
        }
      }
      setspinnerLoading(true);
      ApiService.postData("/userCartAddressProcess", userAddressDetails).then(
        (res) => {
          if (res.status == "success") {
            setSuccessMessage(res.message);
            setspinnerLoading(false);
            window.location.reload();
          } else {
            setErrorMessage(res.message);
            setspinnerLoading(false);
          }
        }
      );
    }
  };
  return (<>
    <Modal className="addressModal" show={contextValues.toggleCheckoutAddressModal} onHide={(e) => { addressModal() }}>
      {spinnerLoading && <SpinnerLoader />}
      <div className='addresssec'>
        <div className='addresssecHeader'>
          <h5 className="mb-0">Add New Address</h5>
          <button type="button" className="modalCloseButtonSecond" onClick={() => { addressModal() }}><i className="ri-close-line ri-xl"></i></button>
        </div>
        <div className='addresssecBody'>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
          <div className='row g-3'>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label>Name</label>
                <input type='text' name="ua_name" value={userAddressDetails.ua_name}
                  onChange={(e) => onTodoAddChange(e)}></input>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label>Mobile Number</label>
                <input type='number' name="ua_mobile" value={userAddressDetails.ua_mobile}
                  onChange={(e) => onTodoAddChange(e)}></input>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label>Postcode</label>
                <input type='number' name="ua_pincode" value={userAddressDetails.ua_pincode}
                  onChange={(e) => onTodoAddChange(e)}></input>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label>State</label>
                {showstatecityselect ?
                  <select name="ua_state_id" className="form-control  ua_state_id" value={userAddressDetails.ua_state_id} onChange={(e) => onTodoAddChange(e)}>
                    <option value="">Select State</option>
                    {statesData.length > 0 && statesData.map((value) => (
                      <option value={value.state_id}>{value.state_name}</option>
                    ))}
                  </select>
                  :
                  <input
                    type="text"
                    name="ua_state_name"
                    className="form-control ua_state_name"
                    value={userAddressDetails.ua_state_name}
                    readOnly
                    disabled={true}
                    onChange={(e) => onTodoAddChange(e)}
                    placeholder="State"
                  />
                }

              </div>
            </div>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label>Address (House No, Building, Street, Area)</label>
                <input type='text' value={userAddressDetails.ua_house_no}
                  onChange={(e) => onTodoAddChange(e)} name="ua_house_no"></input>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label>Locality / Town</label>
                <input type='text' value={userAddressDetails.ua_area}
                  onChange={(e) => onTodoAddChange(e)} name="ua_area"></input>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label>City / District</label>
                {showstatecityselect ?
                  <select name="ua_city_id" className="form-control ua_city_id" value={userAddressDetails.ua_city_id} onChange={(e) => onTodoAddChange(e)}>
                    <option value="">Select City</option>
                    {cityData.length > 0 && cityData.map((value) => (
                      <option value={value.cities_id}>{value.cities_name}</option>
                    ))}
                  </select>
                  :
                  <input
                    type="text"
                    name="ua_city_name"
                    className=""
                    value={userAddressDetails.ua_city_name}
                    onChange={(e) => onTodoAddChange(e)}
                    disabled={true}
                    readOnly
                    placeholder="City"
                  />
                }

              </div>
            </div>
            <div className='col-lg-12'>
              <div className="tx-16 fw600 mb-2">Save address as</div>
              <div className="saveaddresslist">
                <ul>
                  <li className={userAddressDetails.ua_address_type === 'home' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ua_address_type: 'home',
                  }))}>Home</li>
                  <li className={userAddressDetails.ua_address_type === 'work' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ua_address_type: 'work',
                  }))}>Work</li>
                  <li className={userAddressDetails.ua_address_type === 'other' ? 'active' : ''} onClick={(e) => setUserAddressDetails((prevState) => ({
                    ...prevState,
                    ua_address_type: 'other',
                  }))}>Other</li>
                </ul>

              </div>
            </div>
            {userAddressDetails.ua_address_type === 'other' ?
              <div className='col-lg-12'>
                <div className="form-group">
                  <input
                    type="text"
                    name="ua_address_type_other"
                    value={userAddressDetails.ua_address_type_other}
                    onChange={(e) => onTodoAddChange(e)}
                    placeholder="Enter name"
                  />
                </div>
              </div>
              : null
            }
            <div className='col-lg-12'>
               <button type="button" className="btn-theme btn-full btn-theme-lg mt-2" onClick={(e) => { handleAddressProcess(e) }}><span>Save</span></button> 
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>)
}

export default CheckoutAddressModal