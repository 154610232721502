import { BrowserView, MobileView } from "react-device-detect"
import MobileaccountHeader from "../../Component/Header/mobile-header/account"
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import { useContext } from "react";
import DataContext from "../../Component/Elements/context";
import multiCurrency from "../../Component/Elements/multi_currrency";
import { ApiService } from "../../Component/Services/apiservices";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment'
import Loader from "react-js-loader";
import Skeleton from "react-loading-skeleton";
import orderStatusComponent from '../../Component/Elements/order_status'
import OrderCancelModal from "../../Component/modals/cancel-modal";
import Accountsidebar from "./account-sidebar";
const Orderdetail = () => {
  const contextValues = useContext(DataContext)
  const cancelModal = () => {
    contextValues.setToggleCancelModal(!contextValues.togglesetCancelModal)
  }
  const didMountRef = useRef(true);
  const [orderData, setOrderData] = useState({});
  const [itemsData, setItemsData] = useState([]);
  const [transId, setTransId] = useState(0);
  const [tdId, setTdId] = useState(0);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [spinnerLoadingSec, setSpinnerLoadingSec] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
      getOrderData();
    }
    didMountRef.current = false;
  }, []);

  const getOrderData = () => {
    const getOrderDetail = {
      trans_id: id,
    };
    ApiService.postData("get-order-detail", getOrderDetail).then((res) => {
      if (res.status === "success") {
        setOrderData(res.row_orders_data);
        setItemsData(res.row_orders_data?.items);
        setTimeout(() => {
          setSpinnerLoading(false)
          setTimeout(() => {
            setSpinnerLoadingSec(false)
          }, 1000);
        }, 500);
      } else {
        setTimeout(() => {
          setSpinnerLoading(false)
          setTimeout(() => {
            setSpinnerLoadingSec(false)
          }, 1000);
        }, 500);
      }
    }).catch(() => {
      setSpinnerLoading(false)
      setSpinnerLoadingSec(false)
    });
  };


  const handleOpenModal = (transid) => { 
    setTransId(transid);
    setTimeout(() => {
      cancelModal()
    }, 500)

  };
  const getorderstatus = (status, transaccept) => {
    if (status == 1 && transaccept > 0) {
      return 'Confirmed'
    }
    else if (status == 1 && transaccept == 0) {
      return 'Processing'
    }
    else if (status == 2) {
      return 'Pending payment'
    }
    else if (status == 3) {
      return 'On hold'
    }
    else if (status == 4) {
      return 'Delivered'
    }
    else if (status == 5) {
      return 'Cancelled'
    }
    else if (status == 6) {
      return 'Shipped'
    }
    else if (status == 7) {
      return 'Item Picked Up'
    }

  }


  return (<>

    <BrowserView>
      <WebsiteInnerheader></WebsiteInnerheader>
      <div className="subheader smallsubheader">
        <Container>
          <Row>
            <Col lg={12}>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-account">My Account</Breadcrumb.Item>
                <Breadcrumb.Item href="/account/my-order">My Order</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Order Detail
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>
      <section className="section-gap-medium">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Accountsidebar></Accountsidebar>
            </div>

            <div className="col-lg-9">
              <div className="acpanel">
                <div className="acpanel-header">
                  <h4>
                    {spinnerLoading
                      ? <Skeleton width={300} />
                      : <span className="font-third">{`Order Details (${orderData.trans_order_number})`}</span>
                    }
                  </h4>
                </div>
                <div className="acpanel-body">
                  <div className="order-box">
                    <div className="info">
                      <div className="info-delivery">
                        <h6 className="mb-1"> {spinnerLoading
                          ? <Skeleton width={100} />
                          : `Delivery Address`
                        }</h6>
                        <p className="mb-0 mt-0">
                          <strong>
                            {spinnerLoading
                              ? <Skeleton width={150} />
                              : orderData.trans_user_name
                            }
                          </strong>
                        </p>
                        <p className="tx-12">
                          {spinnerLoading
                            ? <Skeleton width={300} />
                            : orderData.trans_delivery_address
                          }
                        </p>
                        <p className="mb-0 mt-0">
                          {spinnerLoading
                            ? <Skeleton width={200} />
                            : <>
                              <strong>Email Id: </strong>{orderData.trans_user_email}
                            </>
                          }
                        </p>
                        <p className="mb-0 mt-0">
                          {spinnerLoading
                            ? <Skeleton width={200} />
                            : <><strong>Mobile number : </strong> <span className="font-third">{orderData.trans_user_mobile}</span></>
                          }
                        </p>
                        <p className="mb-0 mt-0">
                          {spinnerLoading
                            ? <Skeleton width={150} />
                            : <><strong>Txn Id : </strong> <span className="font-third">{orderData.trans_order_number}</span></>
                          }
                        </p>
                      </div>
                      <div className="order-box-items-content">
                        {orderStatusComponent(orderData)}
                      </div>
                    </div>
                    <div className="bcode">
                      <div className="orderid-box mb-2">
                        <h6 className="mb-0">
                          {spinnerLoading
                            ? <Skeleton width={100} />
                            : 'ORDER ID'
                          }
                        </h6>
                        <p className="mb-0 tx-13">
                          {spinnerLoading
                            ? <Skeleton width={100} />
                            : orderData.trans_order_number
                          }
                        </p>
                      </div>
                      <p className="tx-color-03 mb-0 tx-13">
                        {spinnerLoading
                          ? <Skeleton width={80} />
                          : 'ORDER ON'
                        }
                      </p>
                      <p className="tx-12">
                        {spinnerLoading
                          ? <Skeleton width={120} />
                          : <span className="font-third">{moment(orderData.trans_datetime).format("ddd, DD MMM YYYY")}</span>
                        }
                        <br />
                        {spinnerLoading
                          ? <Skeleton width={80} />
                          : <span className="font-third">{moment(orderData.trans_datetime, "HH:mm").format("hh:mm A")}</span>
                        }
                      </p>
                      {orderData.trans_accept_status == 0 && orderData.trans_status == 1 ? <div className="order-box-items-center btn-theme" onClick={(e) =>
                        handleOpenModal(orderData.trans_id)
                      }>

                        <span>Cancel Order</span>

                      </div> : ''}


                    </div>

                  </div>
                  {!spinnerLoadingSec
                    ? <>

                      <div className="card-table">
                        <div className="card-table-section">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Image</th>
                                <th>Items</th>
                                <th >Variation</th>

                                <th className="text-center">QTY</th>
                                <th className="text-center">Price</th>
                                <th className="text-center">Amount</th>
                              </tr>
                            </thead>

                            <tbody>
                              {itemsData.map((value, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <img src={value.td_item_image} style={{ width: "50px", height: '50px', objectFit: "cover" }}></img>
                                    </td>
                                    <td>
                                      {value.td_item_title}
                                      <br />
                                    </td>
                                    <td className="text-center">
                                      {value.td_item_unit ? value.td_item_unit : "-"}
                                    </td>
                                    <td className="text-center">
                                      <span className="font-third">{value.td_item_qty}</span>
                                    </td>
                                    <td className="text-center">

                                      <span className="font-third">{multiCurrency(
                                        value.td_item_net_price
                                      )}</span>
                                    </td>
                                    <td className="text-center">
                                      <span className="font-third">{multiCurrency(
                                        value.td_item_net_price *
                                        value.td_item_qty
                                      )}</span>

                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan="1"></td>
                                <td colSpan=""></td>
                                <td colSpan=""></td>
                                <td colSpan=""></td>
                                <td>Sub Total</td>
                                <td className="text-center">
                                  <span className="font-third">{multiCurrency(orderData.item_sub_total)}</span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4"></td> 
                                <td>Discount</td>
                                <td className="text-center font-third">
                                  -{multiCurrency(
                                    orderData.trans_discount_amount
                                  )}

                                </td>
                              </tr>
                              {Number(orderData.trans_onlne_discount) > 0 && <tr>
                                <td colSpan="4"></td>
                                <td>Onilne Payment Discount</td>
                                <td className="text-center">
                                  <span className="font-third">-{multiCurrency(
                                    orderData.trans_onlne_discount
                                  )}</span>

                                </td>
                              </tr>
                              }
                              <tr>
                                <td colSpan="4"></td> 
                                <td>Coupon Discount</td>
                                <td className="text-center">

                                  <span className="font-third">-{multiCurrency(
                                    orderData.trans_coupon_dis_amt
                                  )}</span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td colSpan=""></td>
                                <td colSpan=""></td>

                                <td>Delivery Charges</td>

                                <td className="text-center">

                                  <span className="font-third"> {multiCurrency(
                                    orderData.trans_shipping_amount
                                  )}</span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td colSpan=""></td>
                                <td colSpan=""></td>

                                <td>
                                  <strong>Grand total</strong>
                                </td>
                                <td className="text-center">
                                  <span className="font-third"><strong>
                                    {orderData.trans_amt}
                                  </strong></span>

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                    : <Loader type="spinner-cub" bgColor={'#E5097F'} color={'#E5097F'} size={50} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </BrowserView>
    <MobileView>
      <MobileaccountHeader heardername={'Order Detail'} backroute={'/account/my-order'}></MobileaccountHeader>
      <section className="section-gap-medium">
        <div className="container">
          <div className="row">

            <div className="col-lg-9">
              <div className="acpanel">
                <div className="acpanel-header">
                  <h4>
                    {spinnerLoading
                      ? <Skeleton width={300} />
                      : `Order Details (${orderData.trans_order_number})`
                    }
                  </h4>
                </div>
                <div className="acpanel-body">
                  <div className="order-box">
                    <div className="info">
                      <div className="info-delivery">
                        <h6 className="mb-1"> {spinnerLoading
                          ? <Skeleton width={100} />
                          : `Delivery Address`
                        }</h6>
                        <p className="mb-0 mt-0">
                          <strong>
                            {spinnerLoading
                              ? <Skeleton width={150} />
                              : orderData.trans_user_name
                            }
                          </strong>
                        </p>
                        <p className="tx-12">
                          {spinnerLoading
                            ? <Skeleton width={300} />
                            : orderData.trans_delivery_address
                          }
                        </p>
                        <p className="mb-0 mt-0">
                          {spinnerLoading
                            ? <Skeleton width={200} />
                            : <>
                              <strong>Email Id: </strong>{orderData.trans_user_email}
                            </>
                          }
                        </p>
                        <p className="mb-0 mt-0">
                          {spinnerLoading
                            ? <Skeleton width={200} />
                            : <><strong>Mobile number : </strong><span className="font-third">{orderData.trans_user_mobile}</span> </>
                          }
                        </p>
                        <p className="mb-0 mt-0">
                          {spinnerLoading
                            ? <Skeleton width={150} />
                            : <><strong>Txn Id : </strong> <span className="font-third">{orderData.trans_order_number}</span></>
                          }
                        </p>
                        <p>
                          {spinnerLoading
                            ? <Skeleton width={150} />
                            : <>  <span className="badge bg-warning">{getorderstatus(orderData.trans_status, orderData.trans_accept_status)}</span></>
                          }
                        </p>
                      </div>
                    </div>
                    <div className="bcode">
                      <div className="orderid-box mb-2">
                        <h6 className="mb-0">
                          {spinnerLoading
                            ? <Skeleton width={50} />
                            : 'ORDER ID'
                          }
                        </h6>
                        <p className="mb-0 tx-13">
                          {spinnerLoading
                            ? <Skeleton width={50} />
                            : <span className="font-third">{orderData.trans_order_number}</span>
                          }
                        </p>
                      </div>
                      <p className="tx-color-03 mb-0 tx-13">
                        {spinnerLoading
                          ? <Skeleton width={80} />
                          : 'ORDER ON'
                        }
                      </p>
                      <p className="tx-12">
                        {spinnerLoading
                          ? <Skeleton width={85} />
                          : <span className="font-third">{moment(orderData.trans_datetime).format("ddd, DD MMM YYYY")}</span>
                        }
                        <br />
                        {spinnerLoading
                          ? <Skeleton width={80} />
                          : <span className="font-third">{moment(orderData.trans_datetime, "HH:mm").format("hh:mm A")}</span>
                        }
                      </p>
                      {orderData.trans_accept_status == 0 && orderData.trans_status == 1 ? <div className="order-box-items-center btn-theme" onClick={(e) =>
                        handleOpenModal(orderData.trans_id)
                      }>

                        <span>Cancel Order</span>

                      </div> : ''}
                    </div>
                  </div>
                  {!spinnerLoadingSec
                    ? <>
                      {itemsData.map((value, index) => {
                        return (
                          <div className="order-box-items mb-3" key={index}>
                            <div className="order-box-items-img">
                              <img src={value.td_item_image}></img>
                            </div>
                            <div className="order-box-items-center">
                              <h6 className="tx-14 mb-1">{value.td_item_title}</h6>
                              <p>Variation: {value.td_item_unit}</p>
                              {/* {value.td_item_status === 1 &&
                                value.td_accept_status==0 ? (
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-primary-outline btn-small"
                                  onClick={(e) =>
                                    handleOpenModal(true, value.td_trans_id, value.td_id)
                                  }
                                >
                                  Cancel Order
                                </a>
                              ) : null} */}

                            </div>

                          </div>
                        );
                      })}
                      <div className="card-table">
                        <div className="card-table-section">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Items</th>
                                <th className="text-center">QTY</th>
                                <th className="text-center">Price</th>
                                <th className="text-center">Amount</th>
                              </tr>
                            </thead>

                            <tbody>
                              {itemsData.map((value, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {value.td_item_title}
                                      <br />
                                    </td>
                                    <td className="text-center">
                                      <span className="font-third">{value.td_item_qty}</span>
                                    </td>
                                    <td className="text-center">

                                      <span className="font-third">{multiCurrency(
                                        value.td_item_net_price
                                      )}</span>
                                    </td>
                                    <td className="text-center">

                                      <span className="font-third">{multiCurrency(
                                        value.td_item_net_price *
                                        value.td_item_qty
                                      )}</span>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan="1"></td>
                                <td colSpan=""></td>
                                <td>Sub Total</td>
                                <td className="text-center">
                                  <span className="font-third">{multiCurrency(orderData.item_sub_total)}</span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td>Discount</td>
                                <td className="text-center">
                                  <span className="font-third">-{multiCurrency(
                                    orderData.trans_discount_amount
                                  )}</span>

                                </td>
                              </tr>
                              {Number(orderData.trans_onlne_discount) > 0 && <tr>
                                <td colSpan="2"></td>
                                <td>Onilne Payment Discount</td>
                                <td className="text-center">
                                  <span className="font-third">-{multiCurrency(
                                    orderData.trans_onlne_discount
                                  )}</span>

                                </td>
                              </tr>
                              }
                              <tr>
                                <td colSpan="2"></td>
                                <td>Coupon Discount</td>
                                <td className="text-center">
                                  <span className="font-third">-{multiCurrency(
                                    orderData.trans_coupon_dis_amt
                                  )}</span>

                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td>Delivery Charges</td>

                                <td className="text-center">
                                  <span className="font-third">{multiCurrency(
                                    orderData.trans_shipping_amount
                                  )}</span>

                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td>
                                  <strong>Grand total</strong>
                                </td>
                                <td className="text-center">
                                  <span className="font-third"> <strong>

                                    {orderData.trans_amt}
                                  </strong></span>

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                    : <Loader type="spinner-cub" bgColor={'#E5097F'} color={'#E5097F'} size={50} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MobileView>
    <OrderCancelModal
      transId={transId}
    />
  </>)
}

export default Orderdetail