import { BrowserView, MobileView } from "react-device-detect"
import MobileaccountHeader from "../../Component/Header/mobile-header/account"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { ApiService } from "../../Component/Services/apiservices";
import { useNavigate } from "react-router-dom";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import { useContext } from "react";
import DataContext from "../../Component/Elements/context";
import React, {useEffect, useRef } from "react";
import Accountsidebar from "./account-sidebar";
import Footer from "../../Component/Footer/index"


const Myaccount = () => {
const contextValues = useContext(DataContext)
const navigate = useNavigate()
const didMountRef = useRef(true);
useEffect(() => {
if (didMountRef.current) {

let sessionToken = localStorage.getItem("USER_TOKEN")
if (sessionToken == null || sessionToken == '') {
navigate('/')
}
}
didMountRef.current = false;
}, [])

const logoutProcess = () => {
const confirmed = window.confirm("Are you sure you want to log out");
if (confirmed) {
ApiService.fetchData("logout").then((res) => {
if (res.status === "success") {
localStorage.removeItem("USER_TOKEN")
navigate('/')
}
})
}
}
return (<>

<BrowserView>
<WebsiteInnerheader></WebsiteInnerheader>

  <div className="subheader smallsubheader">
        <Container>
          <Row>
            <Col lg={12}>

              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>My Account</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>
<section className="section-gap-medium">
<div className="container">
<div className="row">
<div className="col-lg-3">
<Accountsidebar></Accountsidebar>
</div>
<div className="col-lg-9">
<div className="row g-3">
<div className="col-lg-4">
<a href="/account/profile" className="aobox">
<img src="/img/profile.png" className="mb-3" />
<h6>Profile</h6>
<p className="mb-0 tx-13 tx-gray">Manage your account information</p>
</a>
</div>
<div className="col-lg-4">
<a href="/account/my-address" className="aobox">
<img src="/img/pin.png" className="mb-3" />
<h6>My Address</h6>
<p className="mb-0 tx-13 tx-gray">Saved addresses for hassle-free checkout</p>
</a>
</div>
<div className="col-lg-4">
<a href="/account/my-order" className="aobox">
<img src="/img/order.png" className="mb-3" />
<h6>My Orders</h6>
<p className="mb-0 tx-13 tx-gray">Check your order status & track here</p>
</a>
</div>
<div className="col-lg-4">
<a href="/account/wishlist" className="aobox">
<img src="/img/wishlist.png" className="mb-3" />
<h6>Wishlist</h6>
<p className="mb-0">Your most loved products here</p>
</a>
</div>
<div className="col-lg-4">
<a href="/account/help-support" className="aobox">
<img src="/img/helpsupport.png" className="mb-3" />
<h6>Help & Support</h6>
<p className="mb-0 tx-13 tx-gray">Help regarding recent purchase & other</p>
</a>
</div>

<div className="col-lg-4" onClick={() => { logoutProcess() }}>
<a className="aobox">
<img src="/img/logout.png" className="mb-3" />
<h6>Logout</h6>
<p className="mb-0 tx-13 tx-gray">Logout from sparsh</p>
</a>
</div>
</div>
</div>
</div>
</div>
</section>
<Footer></Footer>
</BrowserView>
<MobileView>
<MobileaccountHeader heardername={'My Account'} backroute={'/'}></MobileaccountHeader>
<main>
<div className="macc-profile">
<div className="macc-img"><img src="/img/profile.png" /></div>
<div className="macc-content">
<a href="/account/profile">
<h6 className="mb-0">Hi! {contextValues.rowUserData.user_fname}</h6>
<p className="mb-0">{contextValues.rowUserData.user_email}</p>
</a>
</div>
<i class="ri-arrow-right-s-line ri-xl"></i>
</div>
<div className="macc-list">
<ul>
<li onClick={() => { navigate('/account/my-order') }}>
<div className="macc-list-icon"><img src="/img/order.png" /></div>
<div className="line18">
<h6 className="mb-0 tx-14">Your Orders</h6>
<span className="tx-gray tx-12">Check your order status</span>
</div>
<i class="ri-arrow-right-s-line"></i>
</li>
<li onClick={() => { navigate('/account/my-address') }}>
<div className="macc-list-icon"><img src="/img/pin.png" /></div>
<div className="line18">
<h6 className="mb-0 tx-14">Address Book</h6>
<span className="tx-gray tx-12">Saved addresses for hassle-free checkout</span>
</div>
<i class="ri-arrow-right-s-line"></i>
</li>
<li onClick={() => { navigate('/account/wishlist') }}>
<div className="macc-list-icon"><img src="/img/wishlist.png" /></div>
<div className="line18">
<h6 className="mb-0 tx-14">Collections &amp; Wishlist</h6>
<span className="tx-gray tx-12">Your most loved products here</span>
</div>
<i class="ri-arrow-right-s-line"></i>
</li>
</ul>
</div>
<div className="macc-list mb-2">
<ul>
<li onClick={() => { navigate('/account/help-support') }}>
<div className="macc-list-icon"><img src="/img/helpsupport.png" /></div>
<div className="line18">
<h6 className="mb-0 tx-14">Help &amp; Support</h6>
<span className="tx-gray tx-12">Help regarding recent purchase &amp; other</span>
</div>
<i class="ri-arrow-right-s-line"></i>
</li>
<li onClick={() => { navigate('/account/about-us') }}>
<div className="macc-list-icon"><img src="/img/aboutus.png" /></div>
<div className="line18">
<h6 className="mb-0 tx-14">About Us</h6>
<span className="tx-gray tx-12">Policies, terms of use etc</span>
</div>
<i class="ri-arrow-right-s-line"></i>
</li>

</ul>
</div>
<div className="logout-button mb-3"><button className="btn-line" onClick={() => { logoutProcess() }}>Logout From Sparsh</button></div>
<div className="text-center">
<a href="/"><img src="/img/logo.png" className="logout-logo" /></a>
</div>
</main>
</MobileView>
</>)
}

export default Myaccount