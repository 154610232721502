import { BrowserView, MobileView } from "react-device-detect";
import React, { useContext, useEffect } from 'react';
import DataContext from "../Elements/context";

const HomeInstagram = () => {
    const contextValues= useContext(DataContext)
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//widget.tagembed.com/embed.min.js";
        script.type = "text/javascript";
        script.async = true;
        document.body.appendChild(script);
        setTimeout(() => {
            const element = document.querySelector('.tb_theme_container > div:first-child');
            if (element) {
                element.style.display = 'none';
            }
        }, 1500);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (<>
        <section className="instagram section-gap-medium">
            <div className="section-title section-center animate-box fadeInUp animated">
                <h2>Follow Us On Instagram</h2>
             {contextValues.settingData.instagram_url?<a href={contextValues.settingData.instagram_url} className="explorebutton">Follow Now</a>:''}   
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div class="tagembed-widget" style={{ width: '100%', height: '100%' }} data-widget-id="150684" view-url="https://widget.tagembed.com/150684"></div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default HomeInstagram