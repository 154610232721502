import { BrowserView, MobileView } from "react-device-detect"

const HomeWhoweare = () => {
    return (<>
        <BrowserView>
            <section className="whowerare">
                <div className="container-fluid px-0">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="whowerareContent">
                                <div className="section-title">
                                    <span className="pre-title">Who We Are</span>
                                    <h2>Curating handcrafted treasures from all over India</h2>
                                </div>
                                <p>Sparsh is a home grown brand , we are manufacturers of bedsheets, dohars , quilts , comforters.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="whowerareimg" style={{ background: 'url(https://indraprints.com/public/img/uploads/media/1675083899.jpg)' }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </BrowserView>
        <MobileView>
            <section className="whowerare">
                <div className="container-fluid px-0">
                    <div className="row g-0 align-items-center">
                        <div className="col-lg-6">
                            <div className="whowerareContent text-center">
                                <div className="section-title">
                                    <span className="pre-title">Who We Are</span>
                                    <h2>Curating handcrafted treasures from all over India</h2>
                                </div>
                                <p>Sparsh is a home grown brand , we are manufacturers of bedsheets, dohars , quilts , comforters.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="whowerareimg" style={{ background: 'url(https://indraprints.com/public/img/uploads/media/1675083899.jpg)' }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </MobileView>
    </>)
}

export default HomeWhoweare