import React, { useEffect, useContext, useRef, useParams, useState } from 'react';
import { BrowserView, MobileView } from "react-device-detect"
import { useNavigate, useLocation } from "react-router-dom"
import sessionCartData from '../../Elements/cart_session_data';
import DataContext from '../../Elements/context';
import LoginModal from '../../modals/login-modal';
import { ApiService } from '../../Services/apiservices';
import QuickviewModal from '../../modals/quickviewmodal';

const Checkoutheader = ({backRedirect="/", step="1",title='My Cart'}) => {
  const didMountRef = useRef(true);
  const contextValues = useContext(DataContext);
  const navigate = useNavigate()
  const dataArray = sessionCartData();
  const location = useLocation();
  let sessionToken = localStorage.getItem('USER_TOKEN') 
  useEffect(() => {
    if (didMountRef.current) {
      contextValues.setAddressSession(dataArray[0])
      contextValues.setCartSessionData(dataArray[1])
      contextValues.setCouponSession(dataArray[2])
      contextValues.setCartSummary(dataArray[3])
      contextValues.setCartCount(dataArray[1].length)
      contextValues.setUserToken(dataArray[5]) 
      let sessionToken = localStorage.getItem("USER_TOKEN")
      if (sessionToken == null || sessionToken == '') {
          getLoginImage()
      }
    }
    didMountRef.current = false;
  }, []);
  const navigatetoAddress = () => {
    if (sessionToken) {
      navigate('/address')
    }
  }
  const navigatetocheckout = () => {
    if (sessionToken) {
      navigate('/checkout')
    }
  }
  const getLoginImage = () => {
    ApiService.fetchData('login-popup-banner-list').then((res) => {
        if (res.status === 'success') {
            contextValues.setsliderurl(res.slider_image_path)
            contextValues.setbannerImage(res.resTopBannerData)
        }
    })
}
  return (<>
  <BrowserView>
    <header className="checkoutHeader">
      <div className="container">
        <div className="checkoutHeaderLeft">
          {/* <a href="#" className="checkoutBack"><i class="ri-arrow-left-line"></i></a> */}
          <a href="/" className="checkoutLogo"><img src="/img/logo.png" height="51px" width="100px"></img></a>
        </div>
        <div className="checkoutHeaderCenter">
          <div className="step-by">
            <div className={location.pathname.includes("/cart") ? 'title-simple title-step active' : 'title-simple title-step'}>
              <a href="/cart">My Cart</a>
            </div>
            <div className={location.pathname.includes("/address") ? 'title-simple title-step active' : 'title-simple title-step'} onClick={() => { navigatetoAddress() }}>
              <a href="javascript:void(0)">Address</a>
            </div>
            <div className={location.pathname.includes("/checkout") ? 'title-simple title-step active' : 'title-simple title-step'} onClick={() => { navigatetocheckout() }}>
              <a href="javascript:void(0)">Payment</a>
            </div>
          </div>
        </div>
        <div className="checkoutHeaderRight justify-content-end">
          <img src="/img/100SECURE.png" className="secure-img"></img>
        </div>
      </div>
    </header>
    </BrowserView>
    <MobileView>
    <header className="checkoutHeader mcheckoutHeader">
    <div className="container">
        <div className="checkoutHeaderLeft">
        <div className="mheader-title"><a href={backRedirect}><i className="ri-arrow-left-line"></i></a><h1>{title}</h1></div>
          </div>
          <div className="checkoutHeaderRight justify-content-end font-third">({step}/3)</div>
          </div>
    </header>
    </MobileView>
    <LoginModal />
    {contextValues.toggleQuickViewModal && <> <QuickviewModal /></>}
  </>)
}

export default Checkoutheader