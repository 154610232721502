
import Modal from 'react-bootstrap/Modal';
import React, { useContext } from 'react';
import DataContext from '../Elements/context';

const SizeguideModal = ({ sizechart }) => {

  const contextValues = useContext(DataContext);
  const sizeguideModal = () => {
    contextValues.setTogglesizeguideModal(!contextValues.togglesizeguideModal)
  }
  return (<>
    <Modal show={contextValues.togglesizeguideModal} onHide={(e) => { sizeguideModal() }} className='loginModal'>
      <div className="loginModalContent">
        <button type="button" className="modalCloseButton" onClick={() => { sizeguideModal() }}><i className="ri-close-line ri-xl"></i></button>
        {sizechart && sizechart.cat_size_chart ? <>
          <div dangerouslySetInnerHTML={{ __html: sizechart.cat_size_chart }}></div>
        </>

          : <>
            <div className='noimg'>
              <img src='/img/emplty-cart-removebg-preview.png' className='wd-200 mb-20'></img>
              <h6>No size variation available for this product</h6>
            </div>

          </>}

      </div>
    </Modal>

  </>)
}

export default SizeguideModal