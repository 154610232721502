
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataContext from '../Elements/context';
import { ApiService } from '../Services/apiservices';
import React, { useRef, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import ReactStars from "react-rating-stars-component";

const ReviewModal = ({ product_slug }) => {
  const contextValues = useContext(DataContext);
  const [rating, setRating] = useState('');
  const [Comment, setComment] = useState('');
  const [images, setImages] = useState([]);
  const ReviewimageRef = useRef(null);
  const reviewModalModal = () => {
    contextValues.setToggleReviewModal(!contextValues.toggleReviewModal)
  }
  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const removereviewimage = (image) => {
    setImages((prevImages) => prevImages.filter((img) => img !== image));
  };

  const handleUpload = () => {
    ReviewimageRef.current.click();
  };

  const handlereviewimage = (e) => {
    const files = e.target.files;
    const currentImageCount = images.length;
    const imageArray = [...images]; 
    if (currentImageCount + files.length > 3) {
      toast.error("You can only upload up to 3 images.");
      return;
    }
  
    // Add the files to the array
    for (let i = 0; i < files.length; i++) {
      imageArray.push(files[i]);
    }
    
    setImages(imageArray);
  };

  const handleSubmit = async () => {
    if (rating <= 0) {
      toast.error('Please select Ratings')

      return;
    }
    if (Comment === '' || Comment === null) {
      toast.error('Please  Write Review')

      return;
    }
    const formData = new FormData();
    formData.append('rating', rating);
    formData.append('review', Comment);
    formData.append('slug', product_slug);

    for (let i = 0; i < images.length; i++) {
      formData.append('images[]', images[i]); // Use 'images[]' to handle multiple image files on the backend
    }

    ApiService.postData("/submitreview", formData)
      .then((res) => {
        if (res.status === "success") {
          toast.success('Review Submitted Successfully')
          window.location.reload();
        } else {
          toast.error(res.message)
          return false;
        }
      })
      .catch((error) => {
        // Handle error
      });
  };


  return (<>
    <Modal show={contextValues.toggleReviewModal} onHide={(e) => reviewModalModal()} className='reviewModal right'>
      <div className="reviewModal-content">
        <button type="button" class="modalCloseButton" onClick={() => { reviewModalModal() }}><i class="ri-close-line ri-xl"></i></button>
        <div className="reviewModal-header">
          <div>
            <h4 className="reviewModal-title">Add a Review</h4>
            <p>Your email address will not be published. Required fields are marked * </p>
          </div>
        </div>
        <div className="reviewModal-body">
          <div className="review-form mb-3">
            <label className="mb-0">Your Rating <span>*</span></label>
            <ReactStars count={5} onChange={ratingChanged} size={24} activeColor="#ffd700" />
          </div>
          <div className="review-form mb-3">
            <label>Your Review <span>*</span></label>
            <textarea
              name="comment"
              className="required"

              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className="review-form mb-1">
            <div className="vi-box-section">
              <div className="vi-box">
                <div className="vi-box-inner">
                  <img
                    src="/img/reviewaddimg.png" // Always show the default image
                    onClick={handleUpload}
                    alt="Add Images"
                  />
                  <div className="tx-12">Add Images</div>
                </div>
              </div>

              <input
                type="file"
                ref={ReviewimageRef}
                style={{ display: "none" }}
                multiple // Allow multiple file selection
                onChange={handlereviewimage}
              />
              {
                images.map((image, index) => (

                  <div className="vi-box" key={index}>
                    <div className="vi-box-inner">
                      <div className="remove" onClick={(e) => removereviewimage(image)}><i class="ri-close-line"></i></div>
                      <img src={URL.createObjectURL(image)} alt="Review Image" />
                    </div>
                  </div>

                ))
              }
            </div>

          </div>
          <div className="review-form mb-3">
            <p className="tx-12 tx-color-02">  Upload images. Maximum count: 3, size: 2MB</p>
          </div>

          <div className="review-form mb-3">
            <button className="btn-theme" onClick={handleSubmit}><span>Submit</span></button>
          </div>
        </div>

      </div>
    </Modal>

  </>)
}

export default ReviewModal