
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../Services/apiservices";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import constant from "../Services/constant";
const Homebanner = () => {

    const [webbannerlist, setwebbannerlist] = useState([])
    const [mobannerlist, setmobbannerlist] = useState([])
    const [sliderimgPath, setsliderImgPath] = useState('')
    const [isloading, setisloading] = useState(true)
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getbannerlist()
            // resetFilter()
        }
        didMountRef.current = false;
    }, [])
   
    const navigate = useNavigate()
    const getbannerlist = () => {
        ApiService.fetchData('top-banner-list').then((res) => {
            if (res.status == 'success') {
                setisloading(false)
                setwebbannerlist(res.resTopBannerData)
                setmobbannerlist(res.resMobileBannerData)
                setsliderImgPath(res.slider_image_path)
               
            }
            else{
                setisloading(false)
            }
        }).catch((error) => {

        })
    }
    const clickShopNow = (item) => {
        if(item.cat_slug){
            navigate(`/collection/category/${item.cat_slug}`)  
        }
        else if (item.tag_slug){
            navigate(`/collection/tag/${item.tag_slug}`) 
        }
        else if (item.slider_url) {
            navigate(item.slider_url)
        }
        
    }
    // const resetFilter = () => {
    //     localStorage.removeItem('FILTER_SESSION')

    // }
    return (<>

        <BrowserView>
            {isloading ? <>
                <div className="heroSliderSection">
                    <Swiper
                        slidesPerView={1}
                        navigation={false}
                        loop={true}
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    >
                        <SwiperSlide >
                                <div className="content">
                                    <Skeleton height={'700px'}></Skeleton>
                                </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </> : <>

                <div className="heroSliderSection">
                    <Swiper
                        slidesPerView={1}
                        navigation={false}
                        loop={true}
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    >
                        {webbannerlist && webbannerlist.length > 0 && webbannerlist.map((item, index) => {
                            return (<>
                                <SwiperSlide key={item.slider_id}>
                                    <div className="heroSlidercont" style={{ background: `url(${item.slider_image ? sliderimgPath + '/' + item.slider_image : constant.DEFAULT_IMAGE})` }} key={index}>
                                        <div className="content" dangerouslySetInnerHTML={{ __html: item.slider_desc }} onClick={() => { clickShopNow(item) }}>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </>)
                        })}

                    </Swiper>
                </div>
            </>}
        </BrowserView>
        <MobileView>
            {isloading ? <>
                <div className="heroSliderSection">
                    <Swiper
                        slidesPerView={1}
                        navigation={false}
                        loop={true}
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    >
                        <SwiperSlide >
                                <div className="content">
                                    <Skeleton height={'600px'}></Skeleton>
                                </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </> : <>
                <div className="heroSliderSection">
                    <Swiper
                        slidesPerView={1}
                        navigation={false}
                        loop={true}
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    >
                        {mobannerlist && mobannerlist.length > 0 && mobannerlist.map((item, index) => {
                            return (<>
                                <SwiperSlide key={item.slider_id}>
                                    <div className="heroSlidercont" style={{ background: `url(${item.slider_image ? sliderimgPath + '/' + item.slider_image : constant.DEFAULT_IMAGE})` }}>
                                        <div className="content" dangerouslySetInnerHTML={{ __html: item.slider_desc }} onClick={() => { clickShopNow(item) }}>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </>)
                        })}

                    </Swiper>
                </div>
            </>}

        </MobileView>
    </>)
}
export default Homebanner