import React, { useState, useEffect, useContext, useRef } from "react";
import sessionCartData from '../../Elements/cart_session_data';
import DataContext from '../../Elements/context';
import { useNavigate } from "react-router-dom";
import CartModal from "../../modals/cart-modal";
import LoginModal from "../../modals/login-modal";
import SideMenu from "../../modals/side-menu";
import { ApiService } from "../../Services/apiservices";
import QuickviewModal from "../../modals/quickviewmodal";
import ReactPixel from "../../Services/FacebookPixel";
function MobileHomeHeader() {
    const dataArray = sessionCartData();
    const contextValues = useContext(DataContext);
    const [showInput, setShowInput] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [marqueetxt, setmarqueetxt] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const searchContainerRef = useRef(null);
    const inputRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (searchContainerRef.current && inputRef.current &&
                !searchContainerRef.current.contains(event.target) &&
                !inputRef.current.contains(event.target)
            ) {
                setSearchResults([]);
                setSearchTerm('')
                setShowInput(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length >= 2) {
                fetchSearchResults();
            }
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm])
    const fetchSearchResults = () => {
        const dataString = {
            query: searchTerm,
        };
        ApiService.postData("getsearchdata", dataString).then((res) => {
            if (res.status === "success") {
                setSearchResults(res.data);
            } else {
            }
        });
    };
    const handleInputChange = (event) => {
        event.stopPropagation()
        setSearchTerm(event.target.value);
    };
    function opensearchbox(e) {
        e.stopPropagation();
        setShowInput(prevState => !prevState);
        setSearchResults([]);
        setSearchTerm('')

    }

    useEffect(() => {
        contextValues.setAddressSession(dataArray[0])
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCouponSession(dataArray[2])
        contextValues.setCartSummary(dataArray[3])
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setUserToken(dataArray[5])
    }, []);
    const navigate = useNavigate()
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    const accountModal = () => {
        let sessionToken = localStorage.getItem("USER_TOKEN")
        if (!sessionToken || sessionToken == null) {
            contextValues.setLoginRedirect('/my-account')
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
        }else {
            navigate('/my-account')
        }
    }
    const sidemenuModal = () => {
        contextValues.setToggleSidemenuModal(!contextValues.toggleSidemenuModal)
    }
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getHeaderdata()
            let sessionToken = localStorage.getItem("USER_TOKEN")
            if(sessionToken == null || sessionToken == ''){
                getLoginImage()
            }
        }
        didMountRef.current = false;
    }, [])
    const getLoginImage =()=>{
        ApiService.fetchData('login-popup-banner-list').then((res)=>{
      if(res.status==='success'){
        contextValues.setsliderurl(res.slider_image_path)
        contextValues.setbannerImage(res.resTopBannerData)
      }
        })
      }

      const getHeaderdata=()=>{
        ApiService.fetchData('/header-data').then((res)=>{
            if(res.status=='success'){
                setmarqueetxt(res.headerdata.header_top)
            }
        })
      }
      const wishlistModal = () => {
        let sessionToken = localStorage.getItem("USER_TOKEN")
        if (!sessionToken || sessionToken == null) {
            contextValues.setLoginRedirect('/account/wishlist')
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
        } else {
            navigate('/account/wishlist')
        }
    }
    const setsearchConverson = (value) => {
        ReactPixel.track('UserSearch', {
            name: value.name,
            pageUrl : value.redirect
          });
    }
    
    return (
        <>
            <div className="topHeader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <marquee>{marqueetxt}</marquee>
                        </div>
                    </div>
                </div>
            </div>
            {showInput && (
             <div className="HHHeaderSearch" onClick={(e) => e.stopPropagation()} ref={inputRef}>
                <div className="HHHeaderSearchInner">
                                            <input type="text" placeholder="Search for bedsheets, bedding Set, comforter..."
                                                value={searchTerm}
                                                onChange={(e) => { handleInputChange(e) }} />
                                            <button onClick={(e) => { opensearchbox(e) }} className="closeSearch"><i class="ri-close-line"></i></button>
                                            <div className="searchIcon"><i class="ri-search-line"></i></div>
                                            </div>
                                            {searchResults &&
                                                searchResults.length > 0 &&
                                                searchTerm.trim() !== "" ? (
                                                <div className="header-search-list" id="search_input">
                                                    <ul>
                                                        {searchResults.map((value, index) => (
                                                            <a href={value.redirect} onClick={(e)=>setsearchConverson(value)} key={index}>
                                                                <li key={index}>{value.name}</li>
                                                            </a>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
            <header className="mheader">
                <div className="mheaderTop">
                    <div className="container">
                        <div className="mheader-left">
                            <ul className="leftHeaderlist">
                                <li ><a href="javascript:;" onClick={(e) => sidemenuModal()}><i className="ri-menu-line"></i></a></li>
                                <li><a href="/book-an-appointment"><i className="ri-phone-line"></i></a></li>
                                <li onClick={(e) => { opensearchbox(e) }} ref={searchContainerRef}><a href="javascript:void(0)"><i className="ri-search-line"></i></a></li>
                            </ul>
                        </div>
                        <div className="mheader-center">
                            <a href="/"><img src="/img/logowhite.png" height="37px" width="75px"></img></a>
                        </div>
                        <div className="mheader-right justify-content-end">
                            <ul className="rightHeaderlist">
                                <li onClick={()=>{wishlistModal()}}>
                                    <a href="javascript:void(0)"><i className="ri-heart-3-line"></i></a>
                                </li>
                                <li onClick={() => { accountModal() }}>
                                <a href="javascript:void(0)"> <i className="ri-user-line"></i></a> 
                                </li>
                                <li className="cart-toggle" onClick={() => { cartModal() }}>

                                    <a href="javascript:void(0)">
                                        <i className="ri-shopping-bag-3-line"></i>
                                        <div className="cart-count"><span>{contextValues.cartCount}</span></div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <CartModal ></CartModal>
            <LoginModal ></LoginModal>
            <SideMenu></SideMenu>
            {contextValues.toggleQuickViewModal && <> <QuickviewModal /></>}
        </>
    )
}
export default MobileHomeHeader;