
import React from "react";
import Footer from "../../Component/Footer";
import { BrowserView, MobileView } from "react-device-detect";
import Featuredcategory from "../../Component/featuredcateg";
import Featuredproduct from "../../Component/featuredproducts";
import Homebanner from "../../Component/homebanner";
import Homewhyus from "../../Component/homewhyus";
import Blogs from "../../Component/blogs";
import HomeInstagram from "../../Component/homeinstagram";
import HomeWhoweare from "../../Component/homewhoweare";
import WebsiteHomeHeader from "../../Component/Header/webite-header/home";
import MobileHomeHeader from "../../Component/Header/mobile-header/home";
import Recomendedcategory from "../../Component/recomendedcategory";
import {useRef , useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import { ApiService } from "../../Component/Services/apiservices";
import { useNavigate } from "react-router-dom";
import Watsappicon from "../../Component/Elements/watsappicon";
function Home() {
    const didMountRef = useRef(true);
    const [pagecontent, setpagecontent] = useState({})
    const [headerImageUrl, setHeaderImageUrl] = useState("")
  const navigate = useNavigate()
    useEffect(() => {
      if (didMountRef.current) {
        getpagescontent()
      }
      didMountRef.current = false;
    }, [])
  
    const getpagescontent = () => {
      const datastring = {
        slug: 'home'
      }
      ApiService.postData('/page-content', datastring).then((res) => {
        if (res.status == 'success') {
          setpagecontent(res.data)
          setHeaderImageUrl(res.header_image_path)
        }
        else if (res.status == 'error') {
          navigate('/page-not-found')
        }
      })
    }
    return (
        <>
        <Helmet>
        <title>{pagecontent.page_meta_title}</title>
        <meta
          name="description"
          itemprop="description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
          data-rh="true"
        />
        {pagecontent.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={pagecontent.page_meta_keyword}
            data-rh="true"
          />
        ) : (
          ""
        )}
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content={pagecontent.page_meta_title}
          data-rh="true"
        />
        
        <meta
          property="og:image"
          content={headerImageUrl + pagecontent.page_header_image}
          data-rh="true"
        />
        <meta property="og:url" content={window.location.href} data-rh="true" />

        <meta
          property="og:description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
          data-rh="true"
        />
         <meta name="twitter:url" content={window.location.href} data-rh="true"/> 
         <meta
          name="twitter:title"
          content={pagecontent.page_meta_title}
          data-rh="true"
        />

        <meta
          name="twitter:description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
          data-rh="true"
        />
        <meta
          property="twitter:image"
          content={headerImageUrl + pagecontent.page_header_image}
          data-rh="true"
        /> 
      </Helmet>

            <BrowserView>
                <WebsiteHomeHeader />
            </BrowserView>
            <MobileView>
                <MobileHomeHeader />
            </MobileView>
            <Homebanner/>
            <Recomendedcategory/>
            <Featuredcategory />
            <Homewhyus />
            <Featuredproduct />
            <HomeWhoweare />
            <HomeInstagram />
            <Blogs />
            <Footer />
            <Watsappicon/>
        </>
    )
}
export default Home