import { BrowserView, MobileView } from "react-device-detect"
import WebsiteInnerheader from "../../Component/Header/webite-header/inner"
import MobileInnerheader from "../../Component/Header/mobile-header/inner"
import Footer from "../../Component/Footer"



const PagenotFound=()=>{
    return(<>
    <BrowserView>
    <WebsiteInnerheader>
      
    </WebsiteInnerheader>
    <h1 className="text-center align-item-center pt-5">Page not Found</h1>
    <Footer></Footer>
    </BrowserView>
    <MobileView>
        <MobileInnerheader gobackroute={'/'}>
       
        </MobileInnerheader>
        <h1 className="text-center align-item-center pt-5">Page not Found</h1>
    </MobileView>
    </>)
}

export default PagenotFound