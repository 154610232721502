import { BrowserView, MobileView } from "react-device-detect"
import MobileaccountHeader from "../../Component/Header/mobile-header/account"
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useContext, useRef, useEffect } from "react";
import DataContext from "../../Component/Elements/context";
import React, { useState } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import { validEmail, validNumber } from "../../Component/Elements/Regex";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Accountsidebar from "./account-sidebar";
import Loader from "react-js-loader";

const Profile = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const contextValues = useContext(DataContext)
  const didMountRef = useRef(true);
  const navigate = useNavigate()
  useEffect(() => {
    if (didMountRef.current) {
      getUserdata()
    }
    didMountRef.current = false;
  }, [])
  const onTodoChange = (e) => {
    const { name, value } = e.target;
    contextValues.setRowUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateProfile = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("required");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === "") {
        myElements[i].style.border = "1px solid red";
        counter++;
      } else {
        myElements[i].style.border = "";
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (contextValues.rowUserData.user_fname === "") {
        setErrorMessage("Please enter Full Name");
        return false;
      } if (contextValues.rowUserData.user_email === "") {
        setErrorMessage("Please enter Email Id");
        return false;
      } if (!validEmail.test(contextValues.rowUserData.user_email)) {
        setErrorMessage("Please enter valid Email Id");
        return false;
      } if (contextValues.rowUserData.user_mobile === "") {
        setErrorMessage("Please enter Mobile Number");
        return false;
      } if (!validNumber.test(contextValues.rowUserData.user_mobile)) {
        setErrorMessage("Please enter valid Mobile Number");
        return false;
      }
      setSpinnerLoading(true);
      ApiService.postData("update-profile", contextValues.rowUserData).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message);
          setTimeout(() => {
            setSuccessMessage('');
            setSpinnerLoading(false);
          }, 1000);
        } else {
          setSuccessMessage(res.message);
          setTimeout(() => {
            setSuccessMessage('');
            setSpinnerLoading(false);
          }, 1000);
        }
      });
    }
  };

  const getUserdata = () => {

    ApiService.fetchData('get-user-data').then((res) => {
      if (res.status == 'success') {
        contextValues.setRowUserData(res.rowUserData)

      }
      else if (res.status == 'session_expired') {
        localStorage.removeItem('USER_TOKEN')
        localStorage.removeItem('TEMP_USER_TOKEN')
        navigate('/')

      }
      else {
        toast.error(res.message)

      }
    }).catch((error) => {

    })
  }

  return (<>

    <BrowserView>
      <WebsiteInnerheader></WebsiteInnerheader>
      <div className="subheader smallsubheader">
        <Container>
          <Row>
            <Col lg={12}>

              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-account">My Account</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Profile
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>
      <section className="section-gap-small">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Accountsidebar></Accountsidebar>
            </div>

            <div className="col-lg-9">

              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              <div className="acpanel">
                <div className="acpanel-header">
                  <h4>My Profile</h4>
                </div>
                <div className="acpanel-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label htmlFor="user_fname">Full Name</label>
                        <input
                          type="text"
                          name="user_fname"
                          className="form-control required"
                          value={contextValues.rowUserData.user_fname}
                          placeholder="Full Name"
                          onChange={(e) => onTodoChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label
                          htmlFor="user_email"
                          className="form-label"
                        >
                          Email Address
                        </label>
                        <input
                          type="text"
                          name="user_email"
                          className="form-control required"
                          value={contextValues.rowUserData.user_email}
                          placeholder="Email Address"
                          disabled={true}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-3">
                        <label
                          htmlFor="user_mobile"
                          className="form-label"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="number"
                          name="user_mobile"
                          className="form-control required"
                          value={contextValues.rowUserData.user_mobile}
                          placeholder="Mobile Number"
                          readOnly={true}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <button type="button" className="btn-theme mt-2" onClick={(e) => handleUpdateProfile()} disabled={spinnerLoading} >{spinnerLoading ? <img src="/img/loder01.gif" width="60px" height="11px" /> : <span>Update</span>}</button>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </section>

      <Footer></Footer>
    </BrowserView>
    <MobileView>

      <MobileaccountHeader heardername={'My Profile'} backroute={'/my-account'}></MobileaccountHeader>
      <main>


        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        <div className="acpanel">
          <div className="acpanel-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label htmlFor="user_fname">Full Name</label>
                  <input
                    type="text"
                    name="user_fname"
                    className="form-control required"
                    value={contextValues.rowUserData.user_fname}
                    placeholder="Full Name"
                    onChange={(e) => onTodoChange(e)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    htmlFor="user_email"
                    className="form-label"
                  >
                    Email Address
                  </label>
                  <input
                    type="text"
                    name="user_email"
                    className="form-control required"
                    value={contextValues.rowUserData.user_email}
                    placeholder="Email Address"
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label
                    htmlFor="user_mobile"
                    className="form-label"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    name="user_mobile"
                    className="form-control required"
                    value={contextValues.rowUserData.user_mobile}
                    placeholder="Mobile Number"
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <button type="button" className="btn-theme btn-full btn-theme-lg mt-2" onClick={(e) => handleUpdateProfile()} disabled={spinnerLoading} >{spinnerLoading ? <img src="/img/loder01.gif" width="60px" height="11px" /> : <span>Update</span>}</button>
              </div>
            </div>
          </div>
        </div>


      </main>

    </MobileView>
  </>)
}

export default Profile