const sessionCartData = () => {
  const addressSession = localStorage.getItem("ADDRESS_SESSION");
  const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};

  const couponSession = localStorage.getItem("COUPON_SESSION");
  const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
    discount_amount: 0.0,
    promo_id: 0,
    promo_code: "",
  };

  const cartSession = localStorage.getItem("CART_SESSION");
  const parsedCartSession = cartSession ? JSON.parse(cartSession) : [];
  const parsedCartSummary = {
    itemTotal: 0,
    discount: 0,
    total_amount: 0,
    shipping_charge: 0,
    sellingTotal: 0,
    mrpTotal: 0,
  };
  parsedCartSummary.itemTotal = parsedCartSession.reduce(
    (total, cartItem) => total + parseFloat(cartItem.product_price) * parseFloat(cartItem.quantity),
    0
  );

  parsedCartSummary.sellingTotal = parsedCartSession.reduce(
    (total, cartItem) => total + parseFloat(cartItem.product_selling_price) * parseFloat(cartItem.quantity),
    0
  );

  parsedCartSummary.mrpTotal = parsedCartSession.reduce(
    (total, cartItem) => total + parseFloat(cartItem.product_price) * parseFloat(cartItem.quantity),
    0
  );

  parsedCartSummary.discount = parsedCartSession.reduce(
    (total, cartItem) =>
      total +
      (parseFloat(cartItem.product_price) - parseFloat(cartItem.product_selling_price)) *
      parseFloat(cartItem.quantity),
    0
  );

  parsedCartSummary.total_amount = parsedCartSummary.itemTotal - parsedCartSummary.discount;


  const recentlyProductsSession = localStorage.getItem("RECENTLY_VIEWED");
  const parsedRecentlyProductsSession = recentlyProductsSession ? JSON.parse(recentlyProductsSession) : [];

  const userToken = localStorage.getItem("USER_TOKEN");

  const dataArr = [
    parsedAddressSession,
    parsedCartSession,
    parsedCouponSession,
    parsedCartSummary,
    parsedRecentlyProductsSession,
    userToken
  ];

  return dataArr;
};

export default sessionCartData;
