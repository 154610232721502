import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import { BrowserView, MobileView } from "react-device-detect";
import moment from "moment";
import constant from "../../Component/Services/constant";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import MobileInnerheader from "../../Component/Header/mobile-header/inner";
import MobileHomeHeader from "../../Component/Header/mobile-header/home";
import { Helmet } from "react-helmet";
const Blogdetail = () => {
    const { slug } = useParams()
    const [blogdetail, setblogdetail] = useState({})
    const [blogimgPath, setblogimgPath] = useState({})
    const [isloading, setisloading] = useState(true)

    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getblogdetail()
        }
        didMountRef.current = false;
    }, []);


    const getblogdetail = () => {
        const dataString = {
            blog_slug: slug
        }
        ApiService.postData('/blog-details', dataString).then((res) => {
            if (res.status == 'success') {
                setblogimgPath(res.blog_image_path)
                setblogdetail(res.data)
                setisloading(false)
            }
            else {
                setisloading(false)
            }
        }).catch(() => {
            setisloading(false)
        })
    }
    return (<>
    <Helmet>
        <title>{blogdetail.blog_meta_title}</title>
        <meta
          name="description"
          itemprop="description"
          content={
            blogdetail.blog_meta_desc != null
              ? blogdetail.blog_meta_desc
              : "Sparsh"
          }
        />
        {blogdetail.blog_meta_keyword != null ? (
          <meta
            name="keywords"
            content={blogdetail.blog_meta_keyword}
          />
        ) : (
          ""
        )}
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content={blogdetail.blog_meta_title}
        />
        
        <meta
          property="og:image"
          content={blogimgPath + blogdetail.blog_image}
        />
        <meta property="og:url" content={window.location.href} />

        <meta
          property="og:description"
          content={
            blogdetail.blog_meta_desc != null
              ? blogdetail.blog_meta_desc
              : "Sparsh"
          }
        />
         <meta name="twitter:url" content={window.location.href} /> 
         <meta
          name="twitter:title"
          content={blogdetail.blog_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            blogdetail.blog_meta_desc != null
              ? blogdetail.blog_meta_desc
              : "Sparsh"
          }
        />
        <meta
          property="twitter:image"
          content={blogimgPath + blogdetail.blog_image}
        /> 
      </Helmet>
        <BrowserView>
            <WebsiteInnerheader></WebsiteInnerheader>
            <div className="subheader">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <h1>{blogdetail.blog_name}</h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href="/blogs">News & Blogs</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {blogdetail.blog_name}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </div>
            <section class="section-gap-large">
                <div class="container">
                    <div class="row">
                        {isloading ? <>

                            <div class="col-lg-8">
                                <div class="listbog-details">
                                    <div class="thumbnail"><Skeleton height={'500px'}></Skeleton></div>
                                    <div class="content">
                                        <h1><Skeleton height={'20px'} width={'500px'}></Skeleton></h1>
                                        <ul class="blog-meta mb-3">
                                            <Skeleton height={'20px'} width={'200px'}></Skeleton>
                                            <Skeleton height={'20px'} width={'200px'}></Skeleton>
                                        </ul>
                                        <div class="desc">
                                            <div>
                                                <Skeleton height={'20px'} ></Skeleton>
                                                <Skeleton height={'20px'} ></Skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : <>

                            <div class="col-lg-8">
                                <div class="listbog-details">
                                    <div class="thumbnail"><img src={blogdetail.blog_image ? blogimgPath + '/' + blogdetail.blog_image : constant.DEFAULT_IMAGE} alt="17091107154515.webp" /></div>
                                    <div class="content">
                                        <h1>{blogdetail?.blog_name}</h1>
                                        <ul class="blog-meta mb-3">
                                            <li>< i class="ri-calendar-fill"></i> {moment(blogdetail.created_at).format('DD-MM-YYYY')}</li>
                                            {blogdetail.blog_written_by ? <li>< i class="ri-user-fill"></i>{blogdetail.blog_written_by}</li> : null}
                                        </ul>
                                        <div class="desc">
                                            <div>
                                                <p dangerouslySetInnerHTML={{ __html: blogdetail.blog_desc }}></p>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </section>
            <Footer></Footer>

        </BrowserView>
        <MobileView>
            <MobileHomeHeader ></MobileHomeHeader>
            <section class="section-gap-large">
                <div class="container">
                    <div class="row">
                        {isloading ? <>

                            <div class="col-lg-8">
                                <div class="listbog-details">
                                    <div class="thumbnail"><Skeleton height={'500px'}></Skeleton></div>
                                    <div class="content">
                                        <h1><Skeleton height={'20px'} width={'500px'}></Skeleton></h1>
                                        <ul class="blog-meta mb-3">
                                            <Skeleton height={'20px'} width={'200px'}></Skeleton>
                                            <Skeleton height={'20px'} width={'200px'}></Skeleton>
                                        </ul>
                                        <div class="desc">
                                            <div>
                                                <Skeleton height={'20px'} ></Skeleton>
                                                <Skeleton height={'20px'} ></Skeleton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : <>
                            <div class="col-lg-8">
                                <div class="listbog-details">
                                    <div class="thumbnail"><img src={blogdetail.blog_image ? blogimgPath + '/' + blogdetail.blog_image : constant.DEFAULT_IMAGE} alt="17091107154515.webp" /></div>
                                    <div class="content">
                                        <h1>{blogdetail?.blog_name}</h1>
                                        <ul class="blog-meta mb-3">
                                            <li>< i class="ri-calendar-fill"></i> {moment(blogdetail.created_at).format('DD-MM-YYYY')}</li>
                                            {blogdetail.blog_written_by ? <li>< i class="ri-user-fill"></i>{blogdetail.blog_written_by}</li> : null}

                                        </ul>
                                        <div class="desc">
                                            <div>
                                                <p dangerouslySetInnerHTML={{ __html: blogdetail.blog_desc }}></p>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </section>

        </MobileView>

    </>)
}

export default Blogdetail