
import Modal from 'react-bootstrap/Modal';
import React, {useContext,useState} from 'react';
import DataContext from '../Elements/context';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiService } from '../Services/apiservices';
import Alert from "react-bootstrap/Alert";

const OrderCancelModal=({transId})=>{
  console.log(transId,"tdIdtdIdtdIdtdId" )
  const [errorMessage, setErrorMessage] = useState("");
  const [orderReason, setOrderReason] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [spinnerLoading, setspinnerLoading] = useState(false);
  const contextValues = useContext(DataContext);
  const cancelModal = () => {
  
    contextValues.setToggleCancelModal(!contextValues.togglesetCancelModal)
}
const handleCancelOrder = () => {
  setErrorMessage('');
  setSuccessMessage('');
  if (orderReason === '') {
    setErrorMessage('Please choose cancel reason');
    return false;
  }
  const dataString = {
    trans_id: transId,
    order_reason:orderReason
  };
  setspinnerLoading(true);
  ApiService.postData("cancel-order", dataString).then((res) => {
    if (res.data.status === "success") {
     toast.success(res.data.notification);
      setTimeout(() => {
        setspinnerLoading(false)
        window.location.reload()
        cancelModal()
        
      }, 1000);

    } else {
      setspinnerLoading(false);

      toast.error(res.data.notification);
      setTimeout(() => {
       
        cancelModal()
      }, 1000);
     }
  });
};
    return(<>
    
      <Modal show={contextValues.togglesetCancelModal} onHide={(e)=>{cancelModal()}} className='loginModal'>
    
        <button type="button" className="modalCloseButton" onClick={()=>{cancelModal()}}><i className="ri-close-line ri-xl"></i></button>
        <Modal.Body>
         <h5 className="tx-theme mb-1">Reason For Cancellation</h5>
            <p className="tx-color-02 tx-12">
              Please choose cancellation reason
          </p>
      
       <div className="cancleform">
        <ul>
          <li><input type="radio" value="Order Created by Mistake" name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Order Created by Mistake</span></li>
          <li><input type="radio" value="Item(s) Would Not Arrive on Time" name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Item(s) Would Not Arrive on Time</span></li>
          <li><input type="radio" value="Shipping Cost too high"name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Shipping Cost too high</span></li>
          <li><input type="radio" value="Item Price Too High" name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Item Price Too High</span></li>
          <li><input type="radio" value="Found Cheaper Somewhere Else" name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Found Cheaper Somewhere Else</span></li>
          <li><input type="radio" value="Need to change Shipping Address" name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Need to change Shipping Address</span></li>
          <li><input type="radio" value="Need to change Shipping Speed" name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Need to change Shipping Speed</span></li>
          <li><input type="radio" value="Need to change Billing Address" name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Need to change Billing Address</span></li>
          <li><input type="radio" value="Need to change Payment Method" name="cancel_reason" onChange={(e) => setOrderReason(e.target.value)}></input><span>Need to change Payment Method</span></li>

        </ul>
       </div>
          
        </Modal.Body>

        <Modal.Footer>
          <button type="button" className="btn-theme mt-2" onClick={(e) => handleCancelOrder()} disabled={spinnerLoading} >{spinnerLoading ? <img src="/img/loder01.gif" width="60px" height="11px" /> : <span>Submit</span>}</button>
        </Modal.Footer>

   
    </Modal>
    
    </>)
}

export default OrderCancelModal