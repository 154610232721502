
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import { BrowserView, MobileView } from "react-device-detect";
import MobileInnerheader from "../../Component/Header/mobile-header/inner";
import { useState, useRef, useEffect, useContext } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import { validEmail, validNumber } from "../../Component/Elements/Regex";
import DataContext from "../../Component/Elements/context";
import Alert from 'react-bootstrap/Alert';
import ReactPixel from "../../Component/Services/FacebookPixel";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
const ContactUS = () => {
  const navigate = useNavigate()
const didMountRef = useRef(true);
const contextValues = useContext(DataContext)
const [successMessage, setSuccessMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");
const [contactDetails, setContactDetails] = useState({
contact_name: "",
contact_email: "",
contact_mobile: "",
contact_message: "",
});
const [pagecontent, setpagecontent] = useState({})
const [headerImageUrl, setHeaderImageUrl] = useState("")

useEffect(() => {
  if (didMountRef.current) {
    getpagescontent()
  }
  didMountRef.current = false;
}, [])

const getpagescontent = () => {
  const datastring = {
    slug: 'contact-us'
  }
  ApiService.postData('/page-content', datastring).then((res) => {
    if (res.status == 'success') {
      setpagecontent(res.data)
      setHeaderImageUrl(res.header_image_path)
    }
    else if (res.status == 'error') {
      navigate('/page-not-found')
    }
  })
}



const onTodoChange = (e) => {
const { name, value } = e.target;
setContactDetails((prevState) => ({
...prevState,
[name]: value,
}));
};
const resetContactForm = () => {
setContactDetails({
contact_name: "",
contact_email: "",
contact_mobile: "",
contact_message: "",
});

};
const contactusProcess = () => {
let counter = 0;
const myElements = document.getElementsByClassName("required");
for (let i = 0; i < myElements.length; i++) {
if (myElements[i].value === "") {
myElements[i].style.border = "1px solid red";
counter++;
} else {
myElements[i].style.border = "";
}
}
if (counter === 0) {
setErrorMessage("");
if (!validEmail.test(contactDetails.contact_email)) {
setErrorMessage("Please enter valid Email Id");
return false;
}
ApiService.postData("contact-us-process", contactDetails).then((res) => {
if (res.status === "success") {
setSuccessMessage(res.message);
ReactPixel.track('ContactUs', {
    content_name: contactDetails.contact_name,
    em: contactDetails.contact_email,
    ph: contactDetails.contact_mobile,
    msg: contactDetails.contact_message,
  });
resetContactForm();
setTimeout(() => {
setSuccessMessage("");
}, 2000);
} else {
setErrorMessage(res.message);
setTimeout(() => {
setErrorMessage("");
}, 2000);
}
});
}
};
return (<>

<Helmet>
        <title>{pagecontent.page_meta_title}</title>
        <meta
          name="description"
          itemprop="description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
        />
        {pagecontent.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={pagecontent.page_meta_keyword}
          />
        ) : (
          ""
        )}
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content={pagecontent.page_meta_title}
        />
        
        <meta
          property="og:image"
          content={headerImageUrl + pagecontent.page_header_image}
        />
        <meta property="og:url" content={window.location.href} />

        <meta
          property="og:description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
        />
         <meta name="twitter:url" content={window.location.href} /> 
         <meta
          name="twitter:title"
          content={pagecontent.page_meta_title}
        />

        <meta
          name="twitter:description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
        />
        <meta
          property="twitter:image"
          content={headerImageUrl + pagecontent.page_header_image}
        /> 
      </Helmet>
<BrowserView>
<WebsiteInnerheader></WebsiteInnerheader>
<div className="subheader smallsubheader">
<Container>
<Row>
<Col lg={12}>
<Breadcrumb>
<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
<Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
</Breadcrumb>
</Col>
</Row>
</Container>
</div>
<section className="section-gap-medium contact">
<div className="container">
<div className="row justify-content-between">
<div className="col-lg-6">
<div className="section-title">
<h1>Get In Touch With Us</h1>
<p className="tx-13">If you have any questions or enquiries please feel free to contact us alternatively you can complete our online enquiry form located below and we will get back to you as soon as possible.</p>
</div>
{errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
{successMessage && (<Alert variant="success">{successMessage}</Alert>)}
<div className="row g-3">
<div className="col-lg-12">
<div className="form-group">
<label>Your Name</label>
<input
type="text"
name="contact_name"
className="form-control required"
value={contactDetails.contact_name}
onChange={(e) => onTodoChange(e)}
placeholder="Name"
/>
</div>
</div>
<div className="col-lg-6">
<div className="form-group"><label>Email Address</label>
<input
type="text"
name="contact_email"
className="form-control required"
value={contactDetails.contact_email}
onChange={(e) => onTodoChange(e)}
placeholder="Email"
/></div>
</div>
<div className="col-lg-6">
<div className="form-group"><label>Mobile</label>
<input
type="text"
name="contact_mobile"
className="form-control required"
value={contactDetails.contact_mobile}
onChange={(e) => onTodoChange(e)}
placeholder="Mobile"
/></div>
</div>
<div className="col-lg-12">
<div className="form-group"><label>Message</label>
<textarea
name="contact_message"
className="form-control required"
value={contactDetails.contact_message}
onChange={(e) => onTodoChange(e)}
/></div>
</div>
<div className="col-lg-6"><button type="button" className="btn-theme" onClick={contactusProcess}><span>Submit</span></button></div>
</div>
</div>
<div className="col-lg-5">
<div className="contact-address-section mb-3">
<div className="section-title">
<h3>Registered Office Address</h3>
</div> 
<div className="address-contact">
<ul>
<li>
<a href="https://maps.app.goo.gl/iaykwF8bMy1XiTMQ9" target="new"><i className="ri-map-pin-2-line mr-5"></i><span className="font-third">{contextValues.settingData.address}</span> </a>
</li>
<li>
<a href={"mailto:" + contextValues.settingData.admin_support_email}><i className="ri-mail-line mr-5"></i><span>{contextValues.settingData.admin_support_email}</span> </a>
</li>
<li>
<a href={"tel:" + contextValues.settingData.admin_mobile}><i className="ri-phone-line mr-5"></i><span className="font-third">{contextValues.settingData.admin_mobile}</span></a>
</li>
</ul>
</div>
</div>

</div>
</div>
</div>
</section>
<Footer></Footer>


</BrowserView>
<MobileView>
<MobileInnerheader headertitle={'Contact Us'} gobackroute={'/'}></MobileInnerheader>

<section className="section-gap-medium">
<div className="container">
<div className="row">
<div className="col-lg-7">
<div className="section-title">
<h2>Get In Touch With Us</h2>
<p>If you have any questions or enquiries please feel free to contact us alternatively you can complete our online enquiry form located below and we will get back to you as soon as possible.</p>
</div>
{errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
{successMessage && (<Alert variant="success">{successMessage}</Alert>)}
<div className="row g-3">
<div className="col-lg-12">
<div className="form-group"><label>Your Name</label>
<input
type="text"
name="contact_name"
className="form-control required"
value={contactDetails.contact_name}
onChange={(e) => onTodoChange(e)}
placeholder="Name"
/>
</div>
</div>
<div className="col-lg-6">
<div className="form-group"><label>Email Address</label>
<input
type="text"
name="contact_email"
className="form-control required"
value={contactDetails.contact_email}
onChange={(e) => onTodoChange(e)}
placeholder="Email"
/></div>
</div>
<div className="col-lg-6">
<div className="form-group"><label>Mobile</label>
<input
type="text"
name="contact_mobile"
className="form-control required"
value={contactDetails.contact_mobile}
onChange={(e) => onTodoChange(e)}
placeholder="Mobile"
/></div>
</div>
<div className="col-lg-12">
<div className="form-group"><label>Message</label>
<textarea
name="contact_message"
className="form-control required"
value={contactDetails.contact_message}
onChange={(e) => onTodoChange(e)}
/></div>
</div>
<div className="col-lg-6"><button type="button" className="btn btn-primary btn-medium btn btn-primary" onClick={contactusProcess}>Submit</button></div>
</div>
</div>
<div className="col-lg-5">
<div className="contact-address-section mb-3">
<div className="section-title">
<h2>Sparsh</h2>
</div>
{errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
{successMessage && (<Alert variant="success">{successMessage}</Alert>)}


<div className="address-contact">
<ul>
<li>
<a href="https://maps.app.goo.gl/iaykwF8bMy1XiTMQ9" target="new"><i className="ri-map-pin-2-line mr-5"></i><span className="font-third">{contextValues.settingData.address}</span> </a>
</li>
<li>
<a href={"mailto:" + contextValues.settingData.admin_support_email}><i className="ri-mail-line mr-5"></i><span>{contextValues.settingData.admin_support_email}</span> </a>
</li>
<li>
<a href={"tel:" + contextValues.settingData.admin_mobile}><i className="ri-phone-line mr-5"></i><span className="font-third">{contextValues.settingData.admin_mobile}</span></a>
</li>
</ul>
</div>
</div>

</div>
</div>
</div>
</section>
</MobileView>

</>)
}

export default ContactUS