import { BrowserView, MobileView } from "react-device-detect"
import React, { useEffect, useContext, useRef, useState } from 'react';
import DataContext from '../../Component/Elements/context';
import { useNavigate } from 'react-router-dom';
import sessionCartData from '../../Component/Elements/cart_session_data';
import multiCurrency from '../../Component/Elements/multi_currrency';
import constant from '../../Component/Services/constant';
import { addToCart, minusToCart, removeToCart } from '../../Component/Elements/add_to_cart';
import Loader from "react-js-loader";
import { ApiService } from '../../Component/Services/apiservices';
import Footer from "../../Component/Footer"
import Checkoutheader from "../../Component/Header/checkout-header"
import { showToast } from '../../Component/Elements/toast';
import { ViewCart } from "../../Component/Services/facebookTracking";
import { trackInitiateCheckout } from "../../Component/Services/facebookTracking";
import CouponModal from "../../Component/modals/coupon_modal";
import ProductBox from "../../Component/product-box";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import Skeleton from "react-loading-skeleton";
import "swiper/css";

const Cart = () => {
    const navigate = useNavigate()
    const contextValues = useContext(DataContext);
    const [recomendedproduct, setrecomendedproduct] = useState([])
    const [isloading, setisloading] = useState(false)
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    const parsedCartSummary = dataArray[3];
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            ViewCart(parsedCartSession);
            getrecomendedProducts()
        }
        didMountRef.current = false;
    }, [dataArray])

    const plusToCart = async (productValue, index) => {
        contextValues.setSpinnerCubLoader(index)
        const productData = {
            product_id: Number(productValue.product_id),
            product_name: productValue.product_name,
            product_slug: productValue.product_slug,
            product_image: productValue.product_image
                ? productValue.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(productValue.product_type),
            product_price: Number(productValue.product_price),
            product_selling_price: Number(productValue.product_selling_price),
            product_discount: productValue.product_discount,
            product_variation: productValue.product_variation,
            product_category_id: productValue.product_category_id,
        };
        const updateStatus = await addToCart(productData, 1, contextValues);
        if (updateStatus) {
            contextValues.setSpinnerCubLoader(0)
        } else {
            contextValues.setSpinnerCubLoader(0)
        }
    };
    const minusProduct = (productValue, index) => {
        contextValues.setSpinnerCubLoader(index)
        if (minusToCart(productValue, productValue.product_variation, contextValues)) {
            setTimeout(() => {
                contextValues.setSpinnerCubLoader(0)
            }, 500);
        } else {
            setTimeout(() => {
                contextValues.setSpinnerCubLoader(0)
            }, 500);
        }
    };

    const removeProduct = (productValue) => {
        if (removeToCart(productValue, productValue.product_variation, contextValues)) {

        } else {

        }
    };

    const checkoutModal = () => {
        // localStorage.removeItem("COUPON_SESSION");
        localStorage.removeItem("ADDRESS_SESSION");
        checkoutOrderSummary()
    }

    const checkoutOrderSummary = () => {
        const productData = {
            sessionItemsSummary: parsedCartSession
        };
        ApiService.postData("checkOrderSummary", productData).then((res) => {
            if (res.status === 'success') {
                localStorage.removeItem("CART_SESSION");
                localStorage.setItem("CART_SESSION", JSON.stringify(res.updatedSessionData));
                contextValues.setAddressSession(dataArray[0])
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCouponSession(dataArray[2])
                contextValues.setCartSummary(dataArray[3])
                contextValues.setCartCount(dataArray[1].length)
                trackInitiateCheckout(res.updatedSessionData)
                const hasItemWithStatusTrue = res.updatedSessionData.some((item) => {
                    return (item.product_item_status === true);
                });
                if (!hasItemWithStatusTrue) {
                    navigate('/address')
                }
            } else {
            }
        }).catch((error) => {
        });
    }

    const loginModal = () => {
        contextValues.setLoginRedirect('/address')
        contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
    }

    const addtofav = (productId) => {
        const userToken = localStorage.getItem("USER_TOKEN");
        if(userToken){
            const dataString = {
                product_id: productId.product_id,
            };
            ApiService.postData("add-to-fav", dataString).then((res) => {
                if (res.data.status == "success") {
                    setTimeout(() => {
                        if (res.data.notification === "d-icon-heart-full") {
                            removeProduct(productId)
                            showToast('success', "Added to Wishlist", 2000);
                        } else {
                            removeProduct(productId)
                            showToast('success', "Added to Wishlist", 2000);
                        }
                    }, 100)
                } else {
                }
            }).catch(() => {
            });
        }
        else{
            contextValues.setLoginRedirect('/cart')
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
        }
      
    };
    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        const couponSession = localStorage.getItem("COUPON_SESSION");
        const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
            discount_amount: 0.0,
            promo_id: 0,
            promo_code: "",
        };
        contextValues.setCouponSession(parsedCouponSession)
    }

    const couponModal = () => {
        contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
    }
    const getrecomendedProducts = () => {
        setisloading(true)
        ApiService.fetchData('recommended-products-list').then((res) => {
            if (res.status == 'success') {
                setrecomendedproduct(res.recommendedproducts)
                setisloading(false)
            }
            else{
                setisloading(false)
            }
        }).catch(()=>{
            setisloading(false)
        })
    }


    return (<>
        <Checkoutheader backRedirect="/" step="1" title="My Cart"></Checkoutheader>
        <BrowserView>
            <section>
                <div className="sectioncart">
                    {parsedCartSession.length > 0 ? (
                        <>
                            <div className="sectioncart-leftblock">
                                {parsedCartSession.map((value, index) => {
                                    return (
                                        <div className="cartproductbx" key={index}>
                                            <div className="cartproductbxMedia">
                                                <a href={"/product/" + value.product_slug}>
                                                    <img src={value.product_image}></img>
                                                </a>
                                            </div>
                                            <div className="cartproductbxContent">
                                                <h2 className="title">{value.product_name}</h2>
                                                {value.product_variation && value.product_variation.length > 0 ? <div className='cartProductSize'>{value.product_variation.join(", ")}</div> : null}
                                                <div className="cartproductbx-price mt-10"><ins className="new-price">{multiCurrency(value.product_selling_price)}</ins><del className="old-price">{multiCurrency(value.product_price)}</del></div>
                                                <div className="cartproductbxFooter">
                                                    <div>
                                                        <div className="qty">
                                                            {contextValues.spinnerCubLoader == index + Number(1) ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> :
                                                                <>
                                                                    <span onClick={(e) => minusProduct(value, index + Number(1))}><i className="ri-subtract-line"></i></span>
                                                                    <span>{value.quantity}</span>
                                                                    <span onClick={(e) => plusToCart(value, index + Number(1))}><i className="ri-add-line"></i></span>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li><a href="javascript:void(0);" onClick={(e) => addtofav(value)}><i className="ri-heart-3-line"></i></a></li>
                                                        <li><a href="javascript:void(0);" onClick={(e) => removeProduct(value)}><i className="ri-delete-bin-6-line"></i></a></li>
                                                    </ul>
                                                </div>
                                                {value.product_item_status ? <div className="mt-10" style={{ 'color': 'red' }}>{value.product_item_status_message}</div> : null}
                                            </div>
                                        </div>
                                    );
                                })}


                                {isloading?<>
                                    <Swiper
                                        spaceBetween={15}
                                        slidesPerView={2.7}
                                        navigation={false}
                                        loop={false}
                                        pagination={{ clickable: true }}
                                        modules={[Autoplay]}
                                        
                                    >
                                        {Array.from({ length: 6 }).map((_, index) => {
                                            return (<>
                                                <SwiperSlide key={index}>
                                                    <div className="product text-center" key={index}>
                                                        <figure className="product-media">
                                                            <a href="#">
                                                                <Skeleton height={315} />
                                                            </a>
                                                        </figure>
                                                        <div className="product-details">
                                                            <Skeleton />
                                                            <div className="product-price">
                                                                <Skeleton width={50} />
                                                                <Skeleton width={50} />
                                                            </div>
                                                            <Skeleton />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </>)
                                        })}
                                    </Swiper>
                                </>:<> {recomendedproduct && recomendedproduct.length > 0 ? <>
                                <h5 className="mb-20 mt-30">You May Also Like</h5>
                                    <Swiper
                                                        spaceBetween={15}
                                                        slidesPerView={2.7}
                                                        navigation={false}
                                                        loop={false}
                                                        autoplay={{
                                                            delay: 2500,
                                                            disableOnInteraction: false,
                                                        }}
                                                       
                                                        modules={[Autoplay]}
                                                       
                                                    >
                                                        {recomendedproduct && recomendedproduct.map((items, index) => {
                                                            return (<>
                                                                <SwiperSlide key={index}>
                                                                    <ProductBox productdetail={items} key={index} ></ProductBox>
                                                                </SwiperSlide>
                                                            </>)
                                                        })}
                                                    </Swiper>
                                </> : null}</>}


                               
                            </div>
                            <div className="sectioncart-rightblock">
                                {contextValues.couponSession.promo_id > 0 ?
                                    <div className="applycouponbox mb-15">
                                        <div className="d-flex align-items-center">
                                            <i className="ri-coupon-3-line ri-xl"></i>
                                            <div className="ml-15" style={{ lineHeight: '10px' }}>
                                                <h6 className="mb-2 tx-14">{contextValues.couponSession.promo_code} Applied </h6>
                                                <span className="tx-12 tx-green">You saved additional {multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                            </div>
                                        </div>
                                        <div className='applycouponbox-arrow' style={{ color: 'red' }} onClick={(e) => removeCoupon()}> <i className="ri-delete-bin-5-line"></i></div>
                                    </div>
                                    : <div className="applycouponbox mb-15" onClick={(e) => couponModal()}>
                                        <div className="d-flex align-items-center">
                                            <i className="ri-coupon-3-line ri-xl"></i>
                                            <div className="ml-15" style={{ lineHeight: '14px' }}>
                                                <h6 className="mb-0">Apply Coupons</h6>
                                                <span className="tx-12 tx-green">{contextValues.couponsList.length}+ available </span>
                                            </div>
                                        </div>
                                        <i className="ri-arrow-right-double-fill"></i>
                                    </div>
                                }
                                <div className="cardbox mb-15">
                                    <div className="cardboxHeader mb-10">
                                        <h6 className="mb-0">Price Details</h6>
                                    </div>
                                    <div className="cardboxBody">
                                        <ul className="priceList">
                                            <li><span>Total MRP</span><span className="pric">{parsedCartSummary && parsedCartSummary.mrpTotal ? multiCurrency(parsedCartSummary.mrpTotal) : multiCurrency(0)}</span></li>
                                            <li><span>Discount on MRP</span><span className="pric tx-green">-{parsedCartSummary && parsedCartSummary.discount ? multiCurrency(parsedCartSummary.discount) : multiCurrency(0)}</span></li>
                                            <li><span>Coupon Discount</span><span className="pric tx-green">-{multiCurrency(parseFloat(contextValues.couponSession.discount_amount))}</span></li>
                                            <li><span>Shipping</span><span className="pric">Shipping will calculated at checkout.</span></li>
                                            <div className="divider-dotted1"></div>
                                            <li className="fw600"><span>Total Amount</span><span className="pric">{parsedCartSummary && parsedCartSummary.sellingTotal ? multiCurrency(parsedCartSummary.sellingTotal - parseFloat(contextValues.couponSession.discount_amount)) : multiCurrency(0)}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                {contextValues.userToken ? <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => checkoutModal()}><span>Process To Checkout</span></button> : <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => loginModal()}><span>Process To Checkout</span></button>}
                            </div>
                        </>
                    ) : (
                        <div className='noimg'>
                            <img src='/img/emplty-cart-removebg-preview.png' className='wd-200 mb-20'></img>
                            <h6>No Products in Cart</h6>
                            <p className='tx-gray tx-14'>Look like you have not added anythings to you cart. Go agead & explore top categories</p>
                            <a href='/' className='btn-line'><span>Start Shopping</span></a>
                        </div>
                    )}
                </div>
            </section>
            <Footer></Footer>
        </BrowserView>
        <MobileView>
            <main>

                {parsedCartSession.length > 0 ? (
                    <>
                        <div className="p-15">
                            {parsedCartSession.map((value, index) => {
                                return (
                                    <div className="cartproductbx" key={index}>
                                        <div className="cartproductbxMedia">
                                            <a href={"/product/" + value.product_slug}>
                                                <img src={value.product_image}></img>
                                            </a>
                                        </div>
                                        
                                        <div className="cartproductbxContent">
                                            <h2 className="title">{value.product_name}</h2>
                                            {value.product_variation && value.product_variation.length > 0 ? <div className='cartProductSize'>{value.product_variation.join(", ")}</div> : null}
                                            <div className="cartproductbx-price mt-1"><ins className="new-price">{multiCurrency(value.product_selling_price)}</ins><del className="old-price">{multiCurrency(value.product_price)}</del></div>
                                            <div className="cartproductbxFooter">
                                                <div>
                                                    <div className="qty">
                                                        {contextValues.spinnerCubLoader == index + Number(1) ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> :
                                                            <>
                                                                <span onClick={(e) => minusProduct(value, index + Number(1))}><i className="ri-subtract-line"></i></span>
                                                                <span>{value.quantity}</span>
                                                                <span onClick={(e) => plusToCart(value, index + Number(1))}><i className="ri-add-line"></i></span>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <ul>
                                                    <li><a href="javascript:void(0);" onClick={(e) => addtofav(value)}><i className="ri-heart-3-line"></i></a></li>
                                                    <li><a href="javascript:void(0);" onClick={(e) => removeProduct(value)}><i className="ri-delete-bin-6-line"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                             {isloading ? <>
                                <Swiper
                                            spaceBetween={15}
                                            slidesPerView={2}
                                            navigation={false}
                                            loop={false}
                                            pagination={{ clickable: true }}
                                            
                                            modules={[Autoplay]}
                                            
                                        >
                                            {Array.from({ length: 6 }).map((_, index) => {
                                                return (<>
                                                    <SwiperSlide key={index}>
                                                        <div className="product text-center" key={index}>
                                                            <figure className="product-media">
                                                                <a href="#">
                                                                    <Skeleton height={315} />
                                                                </a>
                                                            </figure>
                                                            <div className="product-details">
                                                                <Skeleton />
                                                                <div className="product-price">
                                                                    <Skeleton width={50} />
                                                                    <Skeleton width={50} />
                                                                </div>
                                                                <Skeleton />
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </>)
                                            })}
                                        </Swiper>
                    </> : <>
                        {recomendedproduct && recomendedproduct.length > 0 ?
                        <div className="mb-20">
                            <h6 className="mb-20 mt-20">You May Also Like</h6>
                               <Swiper
                               spaceBetween={10}
                               slidesPerView={1.7}
                               navigation={false}
                               loop={false}
                               pagination={{ clickable: true }}
                               autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                           
                               modules={[Autoplay]}
                               
                           >
                               {recomendedproduct && recomendedproduct.map((items, index) => {
                                   return (<>
                                       <SwiperSlide key={index}>
                                           <ProductBox productdetail={items} key={index} ></ProductBox>
                                       </SwiperSlide>
                                   </>)
                               })}
                           </Swiper>
                           </div>
                            : null}
                        
                    </>}
                            {contextValues.couponSession.promo_id > 0 ?
                                <div className="applycouponbox mb-15">
                                    <div className="d-flex align-items-center">
                                        <i className="ri-coupon-3-line ri-xl"></i>
                                        <div className="ml-15" style={{ lineHeight: '10px' }}>
                                            <h6 className="mb-2 tx-14">{contextValues.couponSession.promo_code} Applied </h6>
                                            <span className="tx-12 tx-green">You saved additional {multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                        </div>
                                    </div>
                                    <div className='applycouponbox-arrow' style={{ color: 'red' }} onClick={(e) => removeCoupon()}> <i className="ri-delete-bin-5-line"></i></div>
                                </div>
                                : <div className="applycouponbox mb-15" onClick={(e) => couponModal()}>
                                    <div className="d-flex align-items-center">
                                        <i className="ri-coupon-3-line ri-xl"></i>
                                        <div className="ml-15" style={{ lineHeight: '14px' }}>
                                            <h6 className="mb-0">Apply Coupons</h6>
                                            <span className="tx-12 tx-green">{contextValues.couponsList.length}+ available </span>
                                        </div>
                                    </div>
                                    <i className="ri-arrow-right-double-fill"></i>
                                </div>
                            }
                            <div className="cardbox pricede mb-15">
                                <div className="cardboxHeader mb-10">
                                    <h6 className="mb-0">Price Details</h6>
                                </div>
                                <div className="cardboxBody">
                                    <ul className="priceList">
                                        <li><span>Total MRP</span><span className="pric">{parsedCartSummary && parsedCartSummary.mrpTotal ? multiCurrency(parsedCartSummary.mrpTotal) : multiCurrency(0)}</span></li>
                                        <li><span>Discount on MRP</span><span className="pric tx-green">-{parsedCartSummary && parsedCartSummary.discount ? multiCurrency(parsedCartSummary.discount) : multiCurrency(0)}</span></li>
                                        <li><span>Coupon Discount</span><span className="pric tx-green">-{multiCurrency(parseFloat(contextValues.couponSession.discount_amount))}</span></li>
                                        <li><span>Shipping</span><span className="pric tx-12">Shipping will calculated at checkout</span></li>
                                        <div className="divider-dotted1"></div>
                                        <li className="fw600"><span>Total Amount</span><span className="pric">{parsedCartSummary && parsedCartSummary.sellingTotal ? multiCurrency(parsedCartSummary.sellingTotal - parseFloat(contextValues.couponSession.discount_amount)) : multiCurrency(0)}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                       
                        <div className="footer-checkout">
                            {contextValues.userToken ? <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => checkoutModal()}>Place Order</button> : <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => loginModal()}>Place Order</button>}
                        </div>
                    </>
                ) : (
                    <div className='noimg'>
                        <img src='/img/emplty-cart-removebg-preview.png' className='wd-200 mb-20'></img>
                        <h6>No Products in Cart</h6>
                        <p className='tx-gray tx-14'>Look like you have not added anythings to you cart. Go agead & explore top categories</p>
                        <a href='/' className='btn-line'><span>Start Shopping</span></a>
                    </div>
                )}

            </main>
        </MobileView>
        <CouponModal />
    </>)
}

export default Cart