import { BrowserView, MobileView } from "react-device-detect"
import React, { useState, useRef, useEffect } from 'react'
import MobileaccountHeader from "../../Component/Header/mobile-header/account"
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import moment from 'moment'
import { ApiService } from "../../Component/Services/apiservices";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom"
import WebsiteInnerheader from "../../Component/Header/webite-header/inner"
import Accountsidebar from "./account-sidebar";
import multiCurrency from "../../Component/Elements/multi_currrency";
import orderStatusComponent from "../../Component/Elements/order_status";


const MyOrder = () => {
  const didMountRef = useRef(true);
  const [orderData, setOrderData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    if (didMountRef.current) {
      getorderData()
    }
    didMountRef.current = false;
  }, []);
  const getorderData = () => {
    ApiService.fetchData("get-order-data").then((res) => {
      if (res.status == "success") {
        setOrderData(res.orderList);
        setItemsData(res.orderList);
        setTimeout(() => {
          setSpinnerLoading(false);
        }, 500);
      } else {
        setTimeout(() => {
          setSpinnerLoading(false);
        }, 500);
      }
    });
  };
  const handleShopNow = () => {
    navigate("/");
  }

  const getorderstatus = (status , transaccept) => {
    if(status==1 && transaccept>0){
      return 'Confirmed'
    }
    else if(status==1 && transaccept==0){
      return 'Processing'
    }
    else if(status==2){
      return 'Pending payment'
    }
    else if(status==3){
      return 'On hold'
    }
    else if (status == 4){
      return 'Delivered'
    }
    else if(status == 5){
      return 'Cancelled'
    }
    else if(status==6){
      return 'Shipped'
    }
    else if (status ==7){
      return 'Item Picked Up'
    }
   
  }
  return (<>

    <BrowserView>
      <WebsiteInnerheader></WebsiteInnerheader>
      <div className="subheader smallsubheader">
        <Container>
          <Row>
            <Col lg={12}>
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-account">My Account</Breadcrumb.Item>
                <Breadcrumb.Item active>My Order</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>
      <section className="section-gap-small">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Accountsidebar></Accountsidebar>

            </div>
            <div className="col-lg-9">
              <div className="acpanel">
                <div className="acpanel-header">
                  <h4>My Orders</h4>
                </div>
                <div className="acpanel-body" >
                  {spinnerLoading === false ?
                    orderData.length > 0 ? (
                      orderData.map((value, index) => (
                        <div className="order-box" key={index}>
                          <div className="info">
                            <div className="info-delivery">
                              <h5 className="mb-10">Delivery Address</h5>
                              <p className="mb-0 tx-16">{value.trans_user_name}</p>
                              <p className="mb-2">
                                {value.trans_delivery_address}
                              </p>
                              <p className="mb-0">
                                <strong>Email Id :</strong> {value.trans_user_email}
                              </p>
                              <p className="mb-0">
                                <strong>Phone number :</strong> <span className="font-third">{value.trans_user_mobile}</span>
                              </p>
                              <div className="orderitems">
                                <ul>
                                  {value.items.map((itemsValue, itemsIndex) => {
                                    return (
                                      <li key={itemsIndex}><img src={itemsValue.td_item_image}></img></li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                            <div className="order-box-items-content">
                                 {orderStatusComponent(value)}
                                </div>
                          </div>
                          <div className="bcode">
                            <div className="orderid-box mb-2">
                              <h6 className="mb-0">ORDER ID</h6>
                              <p className="mb-0 tx-13"><span className="font-third">{value.trans_order_number}</span></p>
                            </div>
                            <p className="tx-color-03 mb-0 tx-13">ORDER ON</p>
                            <p className="tx-12">
                              <span className="font-third">{moment(value.trans_datetime).format("ddd, DD MMM YYYY")}</span>
                              <br />
                              <span className="font-third">{moment(value.trans_datetime, "HH:mm").format("hh:mm A")}</span>
                            </p>
                            <a
                              href={`/account/order-details/${value.trans_order_number}`}
                              className="btn-line"
                            ><span>View Details</span>

                            </a>
                          </div>
                        </div>
                      ))
                    ) :
                      <div className="noimg text-center">
                        <img
                          src="/img/no-orders.webp"
                          alt="No Orders"
                          style={{ width: '250px' }}
                          className="mb-3"
                        />
                        <h6>No orders found!</h6>
                        <p>Look like you haven't made your order yet</p>
                        <button type="button" className="btn-line" onClick={handleShopNow}>
                          <span>Shop Now</span>
                        </button>
                      </div>
                    : <Loader type="spinner-cub" bgColor={'#E5097F'} color={'#E5097F'} size={50} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </BrowserView>
    <MobileView>
      <MobileaccountHeader heardername={'My Order'} backroute={'/my-account'}></MobileaccountHeader>
      <main>
        {spinnerLoading === false ?
          orderData.length > 0 ? (
            orderData.map((value, index) => (
              <div className="morderbox" key={index} onClick={() => { navigate(`/account/order-details/${value.trans_order_number}`) }}>
                <div className="morderbox-body">
                  <div className="row">
                    <div className="col-7">
                      <h6 className="mb-2 tx-14 ">Order Id: <span className="font-third">{value.trans_order_number}</span> </h6>
                      <p className="mb-1 tx-13">Total MRP: <span className="font-third">{multiCurrency(value.trans_amt)}</span></p>
                      <p className="mb-0 tx-13 tx-gray">Total Items: <span className="font-third">{value.itemscount}</span></p>
                    </div>
                    <div className="col-5 tx-right">
                      <p className="mb-1  tx-gray">Placed On</p>
                      <p className="mb-0 tx-12 tx-gray font-third">{moment(value.trans_datetime).format("ddd, DD MMM YYYY")} @  {moment(value.trans_datetime, "HH:mm").format("hh:mm A")}</p>
                    </div>
                  </div>
                </div>
                <div className="morderbox-footer">
                  <span className="badge bg-warning">{getorderstatus(value.trans_status , value.trans_accept_status)}</span>
                  <a href={`/account/order-details/${value.trans_order_number}`}>View Details<i className="ri-arrow-right-line ml-10"></i></a>
                </div>
              </div>
            ))
          ) :
            <div className="noimg text-center">
              <img
                src="/img/no-orders.webp"
                alt="No Orders"
                style={{ width: '250px' }}
                className="mb-3"
              />
              <h6>No orders found!</h6>
              <p>Look like you haven't made your order yet</p>
              <div className="btn-line" onClick={handleShopNow}>
                Shop Now
              </div>
            </div>
          : <Loader type="spinner-cub" bgColor={'#E5097F'} color={'#E5097F'} size={50} />}
      </main>
    </MobileView>
  </>)
}

export default MyOrder