import { useEffect, useState, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import constant from '../Services/constant';
import { ApiService } from "../Services/apiservices";
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from "react-router-dom";

const Featuredcategory = () => {
    const [featuredcatedata, setfeaturedcatedata] = useState([])
    const [cateImgurl, setcatImgurl] = useState([])
    const [loading, setloading] = useState(true)
    const didMountRef = useRef(true);
    const skeletonArray = Array.from({ length: 6 });
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedcategdata()
        }
        didMountRef.current = false;
    }, [])
    const navigate = useNavigate()
    const getfeaturedcategdata = () => {
        try {
            ApiService.fetchData('featured-category').then((res) => {
                if (res?.status == 'success') {
                    setfeaturedcatedata(res.resCategory);
                    setcatImgurl(res.category_image_path)
                    setloading(false)
                } else {
                    setloading(false)
                }
            });
        } catch (error) {
            setloading(false)
           
        }
    };

    return (<>
        <BrowserView>
            {loading ? <>
                <section className="section-gap-large">
                    <div className="container">
                        <div className="section-title section-center animate-box fadeInUp animated">
                            <Skeleton width={300} />
                            <Skeleton width={400} />
                        </div>
                        <div className="row g-3 cols-xl-6 cols-sm-3 cols-md-4">
                            {skeletonArray.map((_, index) => {
                                return (<>
                                    <div key={index}>
                                        <div className="category">
                                            <Skeleton circle={true} height={200} />
                                            <div className="category-content">
                                                <Skeleton />
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                </section>
            </> : <>
                {featuredcatedata && featuredcatedata.length > 0 ? <>
                    <section className="section-gap-large">
                        <div className="container">
                            <div className="section-title section-center animate-box fadeInUp animated">
                                <h2>Shop By Categories</h2>
                                <p>Without love, connecting rural India to the world would just be a dream. Read what Jaipur Rugs really is, and what we stand for.</p>
                                <a href="javascript:void(0)" onClick={(e) => { e.preventDefault(); navigate('/explore') }} className="explorebutton">Explore Categories</a>
                            </div>
                            <div className="row g-3 cols-xl-6 cols-sm-3 cols-md-4 justify-content-center">
                                {featuredcatedata && featuredcatedata.map((items, index) => {
                                    return (<>
                                        <div key={index}>
                                            <div className="category">
                                                <div className="category-inner overlay-zoom">
                                                    <a href={`/collection/category/${items.cat_slug}`}>
                                                        <figure className="category-media">
                                                            <img src={items.cat_image ? cateImgurl + items.cat_image : constant.DEFAULT_IMAGE}></img>
                                                        </figure>
                                                    </a>
                                                </div>
                                                <div className="category-content">
                                                    <h5><a href={`/collection/category/${items.cat_slug}`}>{items.cat_name}</a></h5>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                })}
                            </div>
                        </div>
                    </section>
                </> : null}
            </>}
        </BrowserView>
        <MobileView>
            {loading ? <>
                <section className="section-gap-small">
                    <div className="container">
                        <div className="section-title section-center">
                            <Skeleton width={200} />
                            <Skeleton width={300} />
                        </div>
                        <div className="row g-3 cols-3">
                            {skeletonArray.map((_, index) => {
                                return (<>
                                    <div key={index}>
                                        <div className="category">
                                            <Skeleton height={150} circle={true} />
                                            <div className="category-content">
                                                <Skeleton />
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                </section>
            </> : <>
                {featuredcatedata && featuredcatedata.length > 0 ? <>
                    <section className="section-gap-small">
                        <div className="container">
                            <div className="section-title section-center">
                                <h2>Shop By Categories</h2>
                                <p>Without love, connecting rural India to the world would just be a dream. Read what Jaipur Rugs really is, and what we stand for.</p>
                                <a className="explorebutton" onClick={(e) => { e.preventDefault(); navigate('/explore') }}>Explore Categories</a>
                            </div>
                            <div className="row g-3 cols-3 justify-content-center">
                                {featuredcatedata && featuredcatedata.map((item, index) => {
                                    return (<>
                                        <div key={index} onClick={() => { navigate(`/collection/category/${item.cat_slug}`) }}>
                                            <div className="category">
                                                <div className="category-inner overlay-zoom">
                                                    <a href={`/collection/category/${item.cat_slug}`}>
                                                        <figure className="category-media">
                                                            <img src={item.cat_image ? cateImgurl + item.cat_image : constant.DEFAULT_IMAGE}></img>
                                                        </figure>
                                                    </a>
                                                </div>
                                                <div className="category-content">
                                                    <h6><a href={`/collection/category/${item.cat_slug}`}>{item.cat_name}</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                })} 
                            </div>
                        </div>
                    </section>
                </> : null} 
            </>} 
        </MobileView> 
    </>)
}

export default Featuredcategory