import { useContext } from "react"
import DataContext from "./context"

const Watsappicon = () => {
    const contextValues = useContext(DataContext)
    return (<>
        {contextValues.settingData?.admin_whatsapp_no ? <>
            <div className="whatsapp">
                <a href={"https://wa.me/" + contextValues.settingData.admin_whatsapp_no} target="new">
                    <div >
                        <img src="/img/watsap.png"></img>
                    </div>
                </a>
            </div>


        </> : ''}

    </>)
}

export default Watsappicon