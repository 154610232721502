import React, { useState, useRef, useContext, useEffect } from "react";
import sessionCartData from '../../Elements/cart_session_data';
import QuickviewModal from "../../modals/quickviewmodal";
import DataContext from '../../Elements/context'; 
import CartModal from "../../modals/cart-modal";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../modals/login-modal";
import { ApiService } from "../../Services/apiservices";
import ReactPixel from "../../Services/FacebookPixel";
const MobileInnerheader = ({ headertitle, gobackroute }) => {
    const dataArray = sessionCartData();
    const navigate=useNavigate()
    const contextValues = useContext(DataContext);
    const [showInput, setShowInput] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [marqueetxt, setmarqueetxt] = useState('');
    const [searchResults, setSearchResults] = useState([]);
  
    const searchContainerRef = useRef(null);
    const inputRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (searchContainerRef.current && inputRef.current &&
                !searchContainerRef.current.contains(event.target) &&
                !inputRef.current.contains(event.target)
            ) {
                setSearchResults([]);
                setSearchTerm('')
                setShowInput(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length >= 2) {
                fetchSearchResults();
            }
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm])
    const fetchSearchResults = () => {
        const dataString = {
            query: searchTerm,
        };
        ApiService.postData("getsearchdata", dataString).then((res) => {
            if (res.status === "success") {
                setSearchResults(res.data);
            } else {
            }
        });
    };
    const handleInputChange = (event) => {
        event.stopPropagation()
        setSearchTerm(event.target.value);
    };
    function opensearchbox(e) {
        e.stopPropagation();
        setShowInput(prevState => !prevState);
        setSearchResults([]);
        setSearchTerm('')

    }
    useEffect(() => {
        contextValues.setAddressSession(dataArray[0])
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCouponSession(dataArray[2])
        contextValues.setCartSummary(dataArray[3])
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setUserToken(dataArray[5])
    }, []);
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    const navigatetoback=()=>{
        navigate(gobackroute)
    }

    const setsearchConverson = (value) => {
        ReactPixel.track('UserSearch', {
            name: value.name,
            pageUrl : value.redirect
          });
    }

    return (
        <>
            <header className="mheader mheader-inner">
                <div className="mheaderTop">
                    <div className="container">
                        <div className="mheader-left" onClick={()=>{navigatetoback()}}>
                            <div className="mheader-title">
                                <a href='javascript:void(0)'>
                                    <i className="ri-arrow-left-line"></i>
                                </a>
                                {headertitle? <h1>{headertitle}</h1> : ''}
                                
                            </div>
                        </div>
                        <div className="mheader-right justify-content-end">
                            <ul className="rightHeaderlist">
                            <li onClick={(e) => { opensearchbox(e) }} ref={searchContainerRef}><a href="javascript:void(0)"><i className="ri-search-line"></i></a></li>
                                <li className="cart-toggle" onClick={() => { cartModal() }}>
                                    <a >
                                        <i className="ri-shopping-bag-3-line"></i>
                                        <div className="cart-count"><span>{contextValues.cartCount}</span></div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {showInput && (
             <div className="HHHeaderSearch" onClick={(e) => e.stopPropagation()} ref={inputRef}>
                <div className="HHHeaderSearchInner">
                                            <input type="text" placeholder="Search for bedsheets, bedding Set, comforter..."
                                                value={searchTerm}
                                                onChange={(e) => { handleInputChange(e) }} />
                                            <button onClick={(e) => { opensearchbox(e) }} className="closeSearch"><i class="ri-close-line"></i></button>
                                            <div className="searchIcon"><i class="ri-search-line"></i></div>
                                            </div>
                                            {searchResults &&
                                                searchResults.length > 0 &&
                                                searchTerm.trim() !== "" ? (
                                                <div className="header-search-list" id="search_input">
                                                    <ul>
                                                        {searchResults.map((value, index) => (
                                                            <a href={value.redirect} onClick={(e)=>setsearchConverson(value)} key={index}>
                                                                <li key={index}>{value.name}</li>
                                                            </a>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
            </header>
            <CartModal></CartModal>
            <LoginModal></LoginModal>
            {contextValues.toggleQuickViewModal && <> <QuickviewModal /></>}
        </>
    )
}

export default MobileInnerheader