import Footer from "../../Component/Footer"
import Checkoutheader from "../../Component/Header/checkout-header"
import { BrowserView, MobileView } from "react-device-detect";
import React, { useEffect, useContext, useRef, useState } from 'react';
import DataContext from '../../Component/Elements/context';
import { useNavigate } from 'react-router-dom';
import sessionCartData from '../../Component/Elements/cart_session_data';
import multiCurrency from '../../Component/Elements/multi_currrency';
import { ApiService } from '../../Component/Services/apiservices';
import useRazorpay from "react-razorpay";
import Collapse from 'react-bootstrap/Collapse';
import CartAddress from "./cart-address";
import CouponModal from "../../Component/modals/coupon_modal";
import { showToast } from "../../Component/Elements/toast";
import SpinnerLoader from "../../Component/Elements/spinner_loader";
import { trackPurchase } from "../../Component/Services/facebookTracking";
import Paytring from 'js_checkout';
const Checkout = () => { 
  const dataArray = sessionCartData();
  const didMountRef = useRef(true);
  const [Razorpay] = useRazorpay();
  const contextValues = useContext(DataContext);
  const navigate = useNavigate();
  const [proccessLoader, setProccessLoader] = useState(false);
  const [checkOutStatus, setCheckOutStatus] = useState(false);
  const [paytringOrderID, setPaytringOrderID] = useState(0);
  const [onlneDiscount, setOnlineDiscount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const parsedCartSession = dataArray[1];
  const cartSummary = dataArray[3];
  useEffect(() => {
    if (didMountRef.current) {
      getUserAddress()
      const addressSession = localStorage.getItem("ADDRESS_SESSION");
      const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
      contextValues.setAddressSession(parsedAddressSession)
      setTimeout(() => {
        if (dataArray[5] && dataArray[1].length > 0 && dataArray[3] && parsedAddressSession) {

        } else {
          navigate('/')
        }
      }, 500);
    }
    didMountRef.current = false;
  }, []);

  const getUserAddress = () => {
    ApiService.fetchData("get-user-address").then((res) => {
      if (res.status === "success") {
        contextValues.setUserAddressList(res.resUserAddress);
        if (res.resUserAddress.length == 0) {
          localStorage.removeItem("ADDRESS_SESSION");
          checkoutAddressModal()
        } else {
          const defaultAddress = res.resUserAddress.find(address => address.ua_default_address === 1);
          if (defaultAddress) {
            localStorage.setItem("ADDRESS_SESSION", JSON.stringify(defaultAddress));
            const addressSession = localStorage.getItem("ADDRESS_SESSION");
            const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
            contextValues.setAddressSession(parsedAddressSession);
          }
          getShippingCharges();
        }
      } else if (res.message === "Session expired" && res.status === "session_expired") {
        localStorage.removeItem("USER_TOKEN")
        navigate('/')
      } else {
      }
    });
  };

  const getShippingCharges = () => {
    const addressSession = localStorage.getItem("ADDRESS_SESSION");
    const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
    const dataArray = sessionCartData();
    const productData = {
      itemtotal: dataArray[3] && dataArray[3].mrpTotal ? dataArray[3].mrpTotal : 0,
      ua_id: parsedAddressSession.ua_id,
      cart_data: dataArray[3],
    };
    ApiService.postData("calculateShippingAmount", productData).then((res) => {
      if (res.status === 'success') {
        contextValues.setShippingAmount(res.shipping_amount)
      } else {
        setErrorMessage(res.message)
        setTimeout(() => {
          setErrorMessage('')
          setProccessLoader(false)
        }, 2000);
      }
    }).catch((error) => {

    });
  }

  const removeCoupon = () => {
    localStorage.removeItem("COUPON_SESSION");
    const couponSession = localStorage.getItem("COUPON_SESSION");
    const parsedCouponSession = couponSession ? JSON.parse(couponSession) : {
      discount_amount: 0.0,
      promo_id: 0,
      promo_code: "",
    };
    contextValues.setCouponSession(parsedCouponSession)
  }

  const couponModal = () => {
    contextValues.setToggleCouponModal(!contextValues.toggleCouponModal)
  }

  const checkoutAddressModal = () => {
    contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
  }

  const selectPaymentMethod = (type) => {
    var mrp = 0 ;
    var discountPer = 0;
    var onlineDiscountAmount = 0;
    if(type !== "COD"){
      mrp = dataArray[3] && dataArray[3].sellingTotal ? dataArray[3].sellingTotal : 0;
      discountPer = contextValues.settingData.admin_online_discount;
      onlineDiscountAmount = Math.round((parseFloat(mrp) * Number(discountPer)) / 100);
    } else{
      setOnlineDiscount(0); 
    }
    const addressSession = localStorage.getItem("ADDRESS_SESSION");
    const parsedAddressSession = addressSession ? JSON.parse(addressSession) : {};
    const productData = {
      ua_id: parsedAddressSession.ua_id,
      ua_pincode: parsedAddressSession.ua_pincode,
    };
    setProccessLoader(true)
    ApiService.postData("checkShippingAvailibility", productData).then((res) => {
      if (res.status === 'success') {
        setProccessLoader(false)
        if (type === 'COD') {
          if (res.cod_status) {
            setPaymentMethod(type)
          } else {
            showToast('error', 'COD is not available on this order', 2000);
          }
        } else {
          setOnlineDiscount(onlineDiscountAmount); 
          setPaymentMethod(type)
        }
      } else {
        setProccessLoader(false)
        showToast('error', res.message, 2000);
      }
    }).catch((error) => {
      setProccessLoader(false)
    });
  }

  const checkoutOrderSummary = () => {
    const productData = {
      sessionItemsSummary: parsedCartSession
    };
    ApiService.postData("checkOrderSummary", productData).then((res) => {
      if (res.status === 'success') {
        localStorage.removeItem("CART_SESSION");
        localStorage.setItem("CART_SESSION", JSON.stringify(res.updatedSessionData));
        contextValues.setAddressSession(dataArray[0])
        contextValues.setCartSessionData(dataArray[1])
        contextValues.setCouponSession(dataArray[2])
        contextValues.setCartSummary(dataArray[3])
        contextValues.setCartCount(dataArray[1].length)
        const hasItemWithStatusTrue = res.updatedSessionData.some((item) => {
          return (item.product_item_status === true);
        });
        if (!hasItemWithStatusTrue) {
          if (paymentMethod == '') {
            showToast('error', 'Please choose payment option', 2000);
            return false
          }
          if (paymentMethod == 'COD') {
            cashOnDelivery();
          } else {
            createTempOrder(paymentMethod);
          }
        } else {
          setOpenSmmary(true)
        }
      } else {
      }
    }).catch((error) => {
    });
  }

  const initiatePaymentProcess = () => {
    checkoutOrderSummary();
  }

  const cashOnDelivery = () => {
    const dataString = {
      cartSummary: cartSummary,
      parsedAddressSession: contextValues.addressSession,
      parsedCartSession: contextValues.cartSessionData,
      parsedCouponSession: contextValues.couponSession,
      paymentMethod: 'COD',
      shippingCharge: parseFloat(contextValues.shippingAmount),
      amount: parseFloat(cartSummary.itemTotal) - Number(cartSummary.discount) - parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + parseFloat(contextValues.shippingAmount)
    }; 
    setProccessLoader(true)
    ApiService.postData("makecodorder", dataString).then((res) => {
      if (res.status === "success") {
        trackPurchase(contextValues.cartSessionData)
        localStorage.removeItem("CART_SESSION")
        localStorage.removeItem("ADDRESS_SESSION")
        localStorage.removeItem("COUPON_SESSION")
        navigate('/thankyou/' + res.order_number)
      } else {
        setProccessLoader(false)
      }
    });
  }

  const createTempOrder = (type) => {
    const dataString = {
      cartSummary: contextValues.cartSummary,
      parsedAddressSession: contextValues.addressSession,
      parsedCartSession: contextValues.cartSessionData,
      parsedCouponSession: contextValues.couponSession,
      paymentMethod: type,
      onlneDiscount: onlneDiscount,
      shippingCharge: parseFloat(contextValues.shippingAmount),
      amount: parseFloat(cartSummary.itemTotal) - Number(cartSummary.discount)- parseFloat(contextValues.couponSession && contextValues.couponSession.discount_amount ? contextValues.couponSession.discount_amount : 0) + parseFloat(contextValues.shippingAmount) - parseFloat(onlneDiscount) 
    }; 
    setProccessLoader(true)
    ApiService.postData("createTempOrderpaytrin", dataString).then((res) => {
      if (res.status === "success") {
        setProccessLoader(false)
        // handlePayment(type, res)
        paytringCheckout(res)
      } else {
        setProccessLoader(false);
      }
    });
  }; 

  const paytringCheckout = (res) => {
    setPaytringOrderID(res.row_temp_trans.temp_razorpay_order_id)
    openPaytring(res.row_temp_trans.temp_razorpay_order_id,res.row_temp_trans.temp_trans_order_id);
    setTimeout(() => {
      setCheckOutStatus(true)
    }, 100);
  }
  const openPaytring = (PaymentRefID,orderId) => {
    const paytring = new Paytring({
        order_id: PaymentRefID,
        // success: function (PaymentRefID) {
        //   PaytringProcess(PaymentRefID,orderId)
        // },
        // failed: (PaymentRefID) =>  PaytringProcess(PaymentRefID,orderId),
        onClose: () => PaytringProcess(PaymentRefID,orderId),
        events: (event) => console.log(`Event: ${event.event_name}, Value: ${event.event_value}`),
      });

    paytring.open();
}

  const handlePayment = async (type, res) => {
    const options = {
      key: "rzp_live_YIyjzxPxP8FgAK",
      amount: res.row_temp_trans.total_amount,
      currency: "INR",
      name: "Sparsh",
      description: "where comfort belongs",
      image: contextValues.settingImageBaseUrl + contextValues.settingData.logo,
      order_id: res.row_temp_trans.temp_razorpay_order_id,
      handler: function (response) {
        RazorPayProcess(response, res.row_temp_trans.temp_trans_order_id, type);
      },
      prefill: {
        name: contextValues.addressSession.ua_name,
        email: contextValues.addressSession.ua_email,
        contact: contextValues.addressSession.ua_mobile,
      },
      notes: {
        address: contextValues.settingData.address,
      },
      theme: {
        color: "#e5097f",
      },
      method: {
        netbanking: type == 'Net Banking' ? true : false,
        card: type == 'Debit/Credit Cards' ? true : false,
        upi: type == 'UPI' ? true : false,
        wallet: type == 'Wallet' ? true : false,
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };


  const PaytringProcess = (PaymentRefID, orderId) => {
    const paymentData = {
      orderId: orderId,
      paymentId: PaymentRefID
    };
    setProccessLoader(true)
    ApiService.postData("paymentprocessingpaytrin", paymentData).then((result) => {
      if (result.status === "success") {
        trackPurchase(contextValues.cartSessionData)
        localStorage.removeItem("CART_SESSION")
        localStorage.removeItem("ADDRESS_SESSION")
        localStorage.removeItem("COUPON_SESSION")
        setProccessLoader(false);
        navigate('/thankyou/' + result.order_number)
      } else {
        alert('Transaction Failed, Please Try Again !');
        setProccessLoader(false);
      }
    });
  };

  const RazorPayProcess = (response, orderId, type) => {
    const paymentData = {
      orderId: orderId,
      paymentId: response.razorpay_payment_id
    };
    setProccessLoader(true)
    ApiService.postData("paymentprocessing", paymentData).then((result) => {
      if (result.status === "success") {
        trackPurchase(contextValues.cartSessionData)
        localStorage.removeItem("CART_SESSION")
        localStorage.removeItem("ADDRESS_SESSION")
        localStorage.removeItem("COUPON_SESSION")
        setProccessLoader(false);
        navigate('/thankyou/' + result.order_number)
      } else {
        setProccessLoader(false);
      }
    });
  };

  const [openSmmary, setOpenSmmary] = useState(false);
  const [openAddress, setOpenAddress] = useState(true);
  return (<>
    <Checkoutheader backRedirect="/address" step="3" title="Payment"></Checkoutheader>
    {proccessLoader && <SpinnerLoader />}
    <BrowserView>
      {/* {checkOutStatus && (<PaytringCheckout paytringOrderID={paytringOrderID} />)} */}
      <div className="sectioncart">
        <div className="sectioncart-leftblock">
          {/* ADDRESS SECTION */}
          <div className='ckboxbo mb-15'>
            <div className='ckboxbo-inner'>
              <div className="ckboxbo-icon"><i className="ri-map-pin-line ri-xl mr-10"></i><h6 className="mb-0">Shipping &amp; Billing Address</h6></div>
              <div className="ckboxbo-itemscount" onClick={(e) => navigate('/address')}> <i className="ri-edit-box-line ri-lg ml-5"></i></div>
            </div>
            <Collapse in={openAddress}>
              <div className="addressselbox">
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                {contextValues.toggleCheckoutAddressModal ? <CartAddress /> :
                  <>
                    {contextValues.addressSession && contextValues.addressSession.ua_id > 0 ?
                      <div className={`selectaddress ${contextValues.addressSession.ua_default_address > 0 ? 'active' : ''} mt-10`}>
                        <div className='saddresslable'>{contextValues.addressSession.ua_address_type == "Other" ? contextValues.addressSession.ua_address_type_other : contextValues.addressSession.ua_address_type}</div>
                        <h6 className='mb-0 tx-14'>{contextValues.addressSession.ua_name}</h6>
                        <p className='mb-0 tx-13'>{contextValues.addressSession.ua_house_no}, {contextValues.addressSession.ua_city_name}, {contextValues.addressSession.ua_state_name}, {contextValues.addressSession.ua_pincode}</p>
                        <p className='tx-gray mb-0 tx-12'>+91 {contextValues.addressSession.ua_mobile} . {contextValues.addressSession.ua_email}</p>
                        <span className='selectad'></span>
                      </div>
                      : null}
                  </>
                }
              </div>
            </Collapse>
          </div>
          {/* ITEM SUMMARY */}
          <div className="ckboxbo mb-15">
            <div className='ckboxbo-inner' onClick={() => setOpenSmmary(!openSmmary)}>
              <div className="ckboxbo-icon"><i className="ri-shopping-bag-3-line ri-xl mr-10"></i><h6 className="mb-0">Order Summary</h6></div>
              <div className="ckboxbo-itemscount">{contextValues.cartSessionData.length} Items <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
            </div>
            <Collapse in={openSmmary}>
              <div className="collapseCheckOrderContent">
                {contextValues.cartSessionData.map((value, index) => {
                  return (
                    <div className='ccocbox' key={index}>
                      <div className='ccocbox-product-media'>
                        <a href={"/product/" + value.product_slug}>
                          <img src={value.product_image} />
                        </a>
                      </div>
                      <div className='ccocbox-product-details'>
                        <h6><a href={"/product/" + value.product_slug} className='ccocbox-product-name'>{value.product_name}</a></h6>
                        {value.product_variation && value.product_variation.length > 0 ? <div className='cartProductSize'>{value.product_variation.join(", ")}</div> : null}
                        <p className='mb-0 fw700 tx-12'>QTY : {value.quantity}</p>
                        {value.product_item_status ? <p className='mb-0 fw700 tx-12' style={{ 'color': 'red' }}>{value.product_item_status_message}</p> : null}
                      </div>
                      <div className='ccocbox-product-price'>
                        <ins className="new-price"> {multiCurrency(value.product_selling_price)}</ins>
                        <del className="old-price"> {multiCurrency(value.product_price)}</del>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Collapse >
          </div>
          {/* PAYMENT METHODS */}

          <div className={`ckboxbo mb-15 ${paymentMethod === 'COD' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('COD')}>
            <div className='ckboxbo-inner' >
              <div className='ckboxbo-icon'>
                <img className="mr-10" src="/img/delivery.png" style={{ width: '16%' }} />
                <div style={{ lineHeight: '20px' }}>
                  <p className='tx-14 mb-0'>Cash on delivery</p>
                  <p className='tx-12 tx-gray mb-0'>Pay with cash</p>
                </div>
                <span className='selectad'></span>
              </div>
              <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
            </div>
          </div>
          <div className={`ckboxbo mb-15 ${paymentMethod === 'UPI' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('UPI')}>
            <div className='ckboxbo-inner' >
              <div className='ckboxbo-icon'>
                <img className="mr-10" src="/img/phonepe.png" style={{ width: '13%' }} />
                <div style={{ lineHeight: '20px' }}>
                  <p className='tx-14 mb-0'>Pay via UPI</p>
                  <p className='tx-12 tx-gray mb-0'>Use any registered UPI ID</p>
                </div>
                <span className='selectad'></span>
              </div>
              <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
            </div>
          </div>
          <div className={`ckboxbo mb-15 ${paymentMethod === 'Debit/Credit Cards' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Debit/Credit Cards')}>
            <div className='ckboxbo-inner' >
              <div className='ckboxbo-icon'>
                <img className="mr-10" src="/img/creditcard.png" style={{ width: '11%' }} />
                <div>
                  <p className='tx-14 mb-0'>Debit/Credit cards</p>
                  <p className='tx-12 tx-gray mb-0'>Visa, Mastercard, RuPay & more</p>
                </div>
                <span className='selectad'></span>
              </div>
              <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
            </div>
          </div>
          <div className={`ckboxbo mb-15 ${paymentMethod === 'Wallet' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Wallet')}>
            <div className='ckboxbo-inner'>
              <div className='ckboxbo-icon'>
                <img className="mr-10" src="/img/paytm.png" style={{ width: '11%' }} />
                <div style={{ lineHeight: '20px' }}>
                  <p className='tx-14 mb-0'>Wallets</p>
                  <p className='tx-12 tx-gray mb-0'>Paypal, Airtel, PayZapp & more</p>
                </div>
                <span className='selectad'></span>
              </div>
              <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
            </div>
          </div>
          <div className={`ckboxbo mb-15 ${paymentMethod === 'Net Banking' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Net Banking')}>
            <div className='ckboxbo-inner' >
              <div className='ckboxbo-icon'>
                <img className="mr-10" src="/img/netbanking.png" style={{ width: '13%' }} />
                <div style={{ lineHeight: '20px' }}>
                  <p className='tx-14 mb-0'>Netbanking</p>
                  <p className='tx-12 tx-gray mb-0'>Select from a list of banks</p>
                </div>
                <span className='selectad'></span>
              </div>
              <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
            </div>
          </div>
        </div>
        <div className="sectioncart-rightblock">
          {contextValues.couponSession.promo_id > 0 ?
            <div className="applycouponbox mb-15">
              <div className="d-flex align-items-center">
                <i className="ri-coupon-3-line ri-xl"></i>
                <div className="ml-15" style={{ lineHeight: '10px' }}>
                  <h6 className="mb-2 tx-14">{contextValues.couponSession.promo_code} Applied </h6>
                  <span className="tx-12 tx-green">You saved additional {multiCurrency(contextValues.couponSession.discount_amount)}</span>
                </div>
              </div>
              <div className='applycouponbox-arrow' style={{ color: 'red' }} onClick={(e) => removeCoupon()}> <i className="ri-delete-bin-5-line"></i></div>
            </div>
            : <div className="applycouponbox mb-15" onClick={(e) => couponModal()}>
              <div className="d-flex align-items-center">
                <i className="ri-coupon-3-line ri-xl"></i>
                <div className="ml-15" style={{ lineHeight: '14px' }}>
                  <h6 className="mb-0">Apply Coupons</h6>
                  <span className="tx-12 tx-green">{contextValues.couponsList.length}+ available </span>
                </div>
              </div>
              <i className="ri-arrow-right-double-fill"></i>
            </div>
          }
          <div className='cardbox mb-15'>
            <div className="cardboxHeader mb-10">
              <h6 className="mb-0">Price Details</h6>
            </div>
            <div className="cardboxBody">
              <ul className="priceList">
                <li><span>Total MRP</span><span className="pric">{contextValues.cartSummary && contextValues.cartSummary.mrpTotal ? multiCurrency(contextValues.cartSummary.mrpTotal) : multiCurrency(0)}</span></li>
                <li><span>Discount on MRP</span><span className="pric tx-green">-{contextValues.cartSummary && contextValues.cartSummary.discount ? multiCurrency(contextValues.cartSummary.discount) : multiCurrency(0)}</span></li>
                {paymentMethod !== 'COD' && Number(onlneDiscount) > 0 && <li><span>Onilne Payment Discount</span><span className="pric tx-green">-{onlneDiscount ? multiCurrency(onlneDiscount) : multiCurrency(0)}</span></li>}
                <li><span>Coupon Discount</span><span className="pric tx-green">-{multiCurrency(parseFloat(contextValues.couponSession.discount_amount))}</span></li>
                <li><span>Shipping</span><span className="pric">{multiCurrency(parseFloat(contextValues.shippingAmount))}</span></li>
                <div className="divider-dotted1"></div>
                <li className="fw600"><span>Total Amount</span><span className="pric">{contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? multiCurrency(contextValues.cartSummary.sellingTotal - parseFloat(contextValues.couponSession.discount_amount) + parseFloat(contextValues.shippingAmount) - parseFloat(onlneDiscount)) : multiCurrency(0)}</span></li>
              </ul>
            </div>
          </div>
          <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => initiatePaymentProcess()}><span>Pay Now</span></button>
        </div>
      </div>
      <Footer></Footer>
    </BrowserView>
    <MobileView>
      <main>
        <div className="p-15">
          {/* ADDRESS SECTION */}
          <div className='ckboxbo mb-10'>
            <div className='ckboxbo-inner' onClick={() => setOpenAddress(!openAddress)}>
              <div className="ckboxbo-icon"><i className="ri-map-pin-line ri-xl mr-10"></i><h6 className="mb-0">Shipping &amp; Billing Address</h6></div>
              <div className="ckboxbo-itemscount" onClick={(e) => navigate('/address')}> <i className="ri-edit-box-line ri-lg ml-5"></i></div>
            </div>
            <Collapse in={openAddress}>
              <div className="addressselbox">
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                {contextValues.toggleCheckoutAddressModal ? <CartAddress /> :
                  <>
                    {/* <button type="button" className="addnewaddress" onClick={(e) => checkoutAddressModal()}><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button> */}
                    {contextValues.addressSession && contextValues.addressSession.ua_id > 0 ?
                      <div className={`selectaddress ${contextValues.addressSession.ua_default_address > 0 ? '' : ''}`}>
                        <p className='mb-0 tx-13'>{contextValues.addressSession.ua_name} - {contextValues.addressSession.ua_house_no}, {contextValues.addressSession.ua_city_name}, {contextValues.addressSession.ua_state_name}, {contextValues.addressSession.ua_pincode}</p>
                      </div>
                      : null}
                  </>
                }
              </div>
            </Collapse>
          </div>
          {/* ITEM SUMMARY */}
          <div className="ckboxbo mb-10">
            <div className='ckboxbo-inner' onClick={() => setOpenSmmary(!openSmmary)}>
              <div className="ckboxbo-icon"><i className="ri-shopping-bag-3-line ri-xl mr-10"></i><h6 className="mb-0">Order Summary</h6></div>
              <div className="ckboxbo-itemscount">{contextValues.cartSessionData.length} Items <i className="ri-arrow-down-s-line ri-lg ml-5"></i></div>
            </div>
            <Collapse in={openSmmary}>
              <div className="collapseCheckOrderContent">
                {contextValues.cartSessionData.map((value, index) => {
                  return (
                    <div className='ccocbox' key={index}>
                      <div className='ccocbox-product-media'>
                        <a href={"/product/" + value.product_slug}>
                          <img src={value.product_image} />
                        </a>
                      </div>
                      <div className='ccocbox-product-details'>
                        <h6><a href={"/product/" + value.product_slug} className='ccocbox-product-name'>{value.product_name}</a></h6>
                        {value.product_variation && value.product_variation.length > 0 ? <div className='cartProductSize'>{value.product_variation.join(", ")}</div> : null}
                        <p className='mb-0 fw700 tx-12'>QTY : {value.quantity}</p>
                        {value.product_item_status ? <p className='mb-0 fw700 tx-12' style={{ 'color': 'red' }}>{value.product_item_status_message}</p> : null}
                      </div>
                      <div className='ccocbox-product-price'>
                        <ins className="new-price"> {multiCurrency(value.product_selling_price)}</ins>
                        <del className="old-price"> {multiCurrency(value.product_price)}</del>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Collapse >
          </div>
          {/* COUPONS */}
          {contextValues.couponSession.promo_id > 0 ?
            <div className="applycouponbox mb-10">
              <div className="d-flex align-items-center">
                <i className="ri-coupon-3-line ri-xl"></i>
                <div className="ml-15" style={{ lineHeight: '10px' }}>
                  <h6 className="mb-2 tx-14">{contextValues.couponSession.promo_code} Applied </h6>
                  <span className="tx-12 tx-green">You saved additional {multiCurrency(contextValues.couponSession.discount_amount)}</span>
                </div>
              </div>
              <div className='applycouponbox-arrow' style={{ color: 'red' }} onClick={(e) => removeCoupon()}> <i className="ri-delete-bin-5-line"></i></div>
            </div>
            : <div className="applycouponbox mb-10" onClick={(e) => couponModal()}>
              <div className="d-flex align-items-center">
                <i className="ri-coupon-3-line ri-xl"></i>
                <div className="ml-15" style={{ lineHeight: '14px' }}>
                  <h6 className="mb-0">Apply Coupons</h6>
                  <span className="tx-12 tx-green">{contextValues.couponsList.length}+ available </span>
                </div>
              </div>
              <i className="ri-arrow-right-double-fill"></i>
            </div>
          }
          {/* PAYMENT METHODS */}
          <div>
            <div className={`ckboxbo mb-15 ${paymentMethod === 'COD' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('COD')}>
              <div className='ckboxbo-inner' >
                <div className='ckboxbo-icon'>
                  <img className="mr-10" src="/img/delivery.png" style={{ width: '16%' }} />
                  <div style={{ lineHeight: '20px' }}>
                    <p className='tx-14 mb-0'>Cash on delivery</p>
                    <p className='tx-12 tx-gray mb-0'>Pay with cash</p>
                  </div>
                  <span className='selectad'></span>
                </div>
                <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
              </div>
            </div>
            <div className={`ckboxbo mb-15 ${paymentMethod === 'UPI' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('UPI')}>
              <div className='ckboxbo-inner' >
                <div className='ckboxbo-icon'>
                  <img className="mr-10" src="/img/phonepe.png" style={{ width: '13%' }} />
                  <div style={{ lineHeight: '20px' }}>
                    <p className='tx-14 mb-0'>Pay via UPI</p>
                    <p className='tx-12 tx-gray mb-0'>Use any registered UPI ID</p>
                  </div>
                  <span className='selectad'></span>
                </div>
                <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
              </div>
            </div>
            <div className={`ckboxbo mb-15 ${paymentMethod === 'Debit/Credit Cards' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Debit/Credit Cards')}>
              <div className='ckboxbo-inner' >
                <div className='ckboxbo-icon'>
                  <img className="mr-10" src="/img/creditcard.png" style={{ width: '11%' }} />
                  <div>
                    <p className='tx-14 mb-0'>Debit/Credit cards</p>
                    <p className='tx-12 tx-gray mb-0'>Visa, Mastercard, RuPay & more</p>
                  </div>
                  <span className='selectad'></span>
                </div>
                <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
              </div>
            </div>
            <div className={`ckboxbo mb-15 ${paymentMethod === 'Wallet' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Wallet')}>
              <div className='ckboxbo-inner'>
                <div className='ckboxbo-icon'>
                  <img className="mr-10" src="/img/paytm.png" style={{ width: '11%' }} />
                  <div style={{ lineHeight: '20px' }}>
                    <p className='tx-14 mb-0'>Wallets</p>
                    <p className='tx-12 tx-gray mb-0'>Paypal, Airtel, PayZapp & more</p>
                  </div>
                  <span className='selectad'></span>
                </div>
                <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
              </div>
            </div>
            <div className={`ckboxbo mb-15 ${paymentMethod === 'Net Banking' ? 'active' : ''}`} onClick={(e) => selectPaymentMethod('Net Banking')}>
              <div className='ckboxbo-inner' >
                <div className='ckboxbo-icon'>
                  <img className="mr-10" src="/img/netbanking.png" style={{ width: '13%' }} />
                  <div style={{ lineHeight: '20px' }}>
                    <p className='tx-14 mb-0'>Netbanking</p>
                    <p className='tx-12 tx-gray mb-0'>Select from a list of banks</p>
                  </div>
                  <span className='selectad'></span>
                </div>
                <div className='ckboxbo-itemscount tx-14'><span className="paymentselect"></span></div>
              </div>
            </div>
          </div>
          <div className='cardbox mb-15'>
            <div className="cardboxHeader mb-10">
              <h6 className="mb-0">Price Details</h6>
            </div>
            <div className="cardboxBody">
              <ul className="priceList">
                <li><span>Total MRP</span><span className="pric">{contextValues.cartSummary && contextValues.cartSummary.mrpTotal ? multiCurrency(contextValues.cartSummary.mrpTotal) : multiCurrency(0)}</span></li>
                <li><span>Discount on MRP</span><span className="pric tx-green">-{contextValues.cartSummary && contextValues.cartSummary.discount ? multiCurrency(contextValues.cartSummary.discount) : multiCurrency(0)}</span></li>
                {paymentMethod !== 'COD' && Number(onlneDiscount) > 0 && <li><span>Onilne Payment Discount</span><span className="pric tx-green">-{onlneDiscount ? multiCurrency(onlneDiscount) : multiCurrency(0)}</span></li>}
                <li><span>Coupon Discount</span><span className="pric tx-green">-{multiCurrency(parseFloat(contextValues.couponSession.discount_amount))}</span></li>
                <li><span>Shipping</span><span className="pric">{multiCurrency(parseFloat(contextValues.shippingAmount))}</span></li>
                <div className="divider-dotted1"></div>
                <li className="fw600"><span>Total Amount</span><span className="pric">{contextValues.cartSummary && contextValues.cartSummary.sellingTotal ? multiCurrency(contextValues.cartSummary.sellingTotal - parseFloat(contextValues.couponSession.discount_amount) + parseFloat(contextValues.shippingAmount) - parseFloat(onlneDiscount)) : multiCurrency(0)}</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-checkout">
          <button type='button' className="btn-theme btn-full btn-theme-lg" onClick={(e) => initiatePaymentProcess()}><span>Pay Now</span></button>
        </div>
      </main>
    </MobileView>
    <CouponModal />
  </>)
}

export default Checkout