import { BrowserView, MobileView } from "react-device-detect"
import WebsiteInnerheader from '../../Component/Header/webite-header/inner'
import MobileaccountHeader from "../../Component/Header/mobile-header/account"
import Footer from "../../Component/Footer"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useContext, useRef, useState, useEffect } from "react";
import DataContext from "../../Component/Elements/context";
import AddressModal from "../../Component/modals/address-modal";
import { ApiService } from "../../Component/Services/apiservices";
import Loader from "react-js-loader";
import Accountsidebar from "./account-sidebar";


const Address = () => {
  const contextValues = useContext(DataContext)
  const addressModal = () => {
    contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
  }
  const didMountRef = useRef(true);
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [userAddressDetails, setUserAddressDetails] = useState({
    ua_id: 0,
    ua_name: "",
    ua_email: "",
    ua_mobile: "",
    ua_pincode: "",
    ua_house_no: "",
    ua_area: "",
    ua_state_name: "",
    ua_city_name: "",
    ua_address_type: "home",
    ua_address_type_other: "",
    ua_state_id: "",
    ua_city_id: "",
    ua_default_address: "",
    ua_country_id: 101,
  });
  useEffect(() => {
    if (didMountRef.current) {
      getUserAddress();
    }
    didMountRef.current = false;
  }, []);

  const getUserAddress = () => {
    ApiService.fetchData("/get-user-address").then((res) => {
      if (res.status === "success") {
        contextValues.setUserAddressList(res.resUserAddress);
        setTimeout(() => {
          setSpinnerLoading(false);
        }, 500);
      } else {
        setTimeout(() => {
          setSpinnerLoading(false);
        }, 500);
      }
    }).catch((error) => {
      setSpinnerLoading(false);
    });
  };
  const deleteaddress = (value) => {
    if (window.confirm("Are you sure?")) {
      const dataString = {
        addrid: value,
      };
      ApiService.postData("removeAddress", dataString).then((res) => {
        if (res.status == "success") {
          getUserAddress();
        }
      });
    } else {
    }
  };

  const editaddress = (value) => {
    setUserAddressDetails({
      ua_id: value.ua_id,
      ua_name: value.ua_name,
      ua_email: value.ua_email,
      ua_mobile: value.ua_mobile,
      ua_pincode: value.ua_pincode,
      ua_house_no: value.ua_house_no,
      ua_area: value.ua_area,
      ua_state_name: value.ua_state_name,
      ua_city_name: value.ua_city_name,
      ua_address_type: value.ua_address_type,
      ua_address_type_other: value.ua_address_type_other,
      ua_state_id: value.ua_state_id,
      ua_city_id: value.ua_city_id,
      ua_default_address: "",
      ua_country_id: 101,
    })
    contextValues.setToggleCheckoutAddressModal(!contextValues.toggleCheckoutAddressModal)
  };

  return (<>

    <BrowserView>

      <WebsiteInnerheader></WebsiteInnerheader>

      <div className="subheader smallsubheader">
        <Container>
          <Row>
            <Col lg={12}>

              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-account">My Account</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Address
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

      </div>
      <section className="section-gap-medium">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <Accountsidebar></Accountsidebar>
            </div>
            <div className="col-lg-9">
              <div className="acpanel">
                <div className="acpanel-header">
                  <h4>My Address</h4>
                </div>
                <div className="acpanel-body">
                  <div className="row">
                    {spinnerLoading ? (
                      <Loader type="spinner-cub" bgColor={'#E5097F'} color={'#E5097F'} size={50} />
                    ) : <>
                      {contextValues.userAddressList.length > 0 ? <>

                        {contextValues.userAddressList.map((value, index) => (
                          <div className="col-lg-6" key={index}>
                            <div className="addressbox mb-2">
                              <div className="addressbox-body">
                                <h6 className="mb-1">
                                  {value.ua_name}
                                  {value.ua_default_address == 1
                                    ? "(Default)"
                                    : ""}
                                </h6>
                                <p className="mb-1">
                                  {value.ua_house_no}, {value.ua_area},
                                  {value.ua_city_name},
                                  {value.ua_state_name} {value.ua_pincode}
                                </p>
                                <p className="mb-0">
                                  Mobile No: <span className="font-third">{value.ua_mobile}</span>
                                </p>
                                <div className="addressbox-type">
                                  {value.ua_address_type == "Other"
                                    ? value.ua_address_type_other
                                    : value.ua_address_type}
                                </div>
                              </div>
                              <div className="addressbox-footer">
                                <a
                                  href="javascript:;"
                                  onClick={(e) =>
                                    editaddress(value)
                                  }
                                >
                                  Edit
                                </a>
                                <a
                                  href="javascript:;"
                                  onClick={(e) =>
                                    deleteaddress(value.ua_id)
                                  }
                                >
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>

                        ))}
                        <div
                        >
                          <button className="btn-theme" onClick={() => { addressModal() }}>
                            <span>
                            Add New Address
                            </span>
                           
                          </button>

                        </div>
                      </> : <>

                        <div className="noimg text-center">
                          <img
                            src="/img/noaddress.png"
                            style={{ width: "250px" }}
                            className="mb-3"
                          />
                          <h6>Save Your Address Now!</h6>
                          <p>
                            Add your home and office addresses and enjoy
                            faster checkout
                          </p>
                          <a href="javascript:void(0)" className="btn-theme" onClick={() => { addressModal() }}>
                            <span>Add New Address</span>
                          </a>
                        </div>
                      </>}
                    </>}
                  </div>
                </div></div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </BrowserView>
    <MobileView>
      <MobileaccountHeader heardername={'My Address'} backroute={'/my-account'}></MobileaccountHeader>
      <main>
    
         
              {spinnerLoading ? (
                <Loader type="spinner-cub" bgColor={'#E5097F'} color={'#E5097F'} size={50} />
              ) : <>
               
                {contextValues.userAddressList.length > 0 && !spinnerLoading ? <>
                  <div className="addressfooterbottom">
                  <button
                    type="button"
                    className="btn-line btn-full btn-line-lg"
                    onClick={(e) => addressModal()}
                  >
                    <span>Add New Address</span>
                  </button>
                </div>
                { contextValues.userAddressList.map((value, index) => (
                    <div key={index}>
                      <div className="addressbox mb-2">
                        <div className="addressbox-body">
                          <h6 className="mb-1">
                            {value.ua_name}{" "}
                            {value.ua_default_address === 1 ? "(Default)" : ""}
                          </h6>
                          <p className="mb-1">
                            {value.ua_house_no}, {value.ua_area},{" "}
                            {value.ua_city_name},{" "}
                            {value.ua_state_name} {value.ua_pincode}
                          </p>
                          <p className="mb-0">Mobile No: <span className="font-third">{value.ua_mobile}</span></p>
                          <div className="addressbox-type">
                            {value.ua_address_type === "Other"
                              ? value.ua_address_type_other
                              : value.ua_address_type}
                          </div>
                        </div>
                        <div className="addressbox-footer">
                          <a href="javascript:;" onClick={(e) => editaddress(value)}>
                            Edit
                          </a>
                          <a href="#" onClick={(e) => deleteaddress(value.ua_id)}>
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                 
              </>   : (
                  <>
                    
                    <div className="noimg text-center">
                      <img
                        src="/img/noaddress.png"
                        style={{ width: "250px" }}
                        className="mb-3"
                        alt="No Address"
                      />
                      <h6>Save Your Address Now!</h6>
                      <p>Add your home and office addresses and enjoy faster checkout</p>
                      <button
                        type="button"
                        className="btn-line btn-full"
                        onClick={(e) => addressModal()}
                      >
                        <span>Add New Address</span>
                      </button>
                    </div>
                  </>
                )}
              </>}
          
            
      </main>

    </MobileView>
    <AddressModal editAddDetails={userAddressDetails}></AddressModal>
  </>)
}

export default Address