
import { ApiService } from "../Services/apiservices";
import constant from "../Services/constant";
import { useContext, useState } from "react";
import DataContext from "../Elements/context";
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import multiCurrency from "../Elements/multi_currrency";
const ProductBox = ({ productdetail }) => {
    const [spinnerloading, setspinnerloading] = useState('')
    const navigate= useNavigate()
    const contextValues = useContext(DataContext)
    const discountprice = () => {
        const discoutn = (productdetail.product_price - productdetail.product_selling_price) / productdetail.product_price
        const discountper = discoutn * 100
        return Math.round(discountper)
    }
    const quickviewModal = () => {
        contextValues.setProductData(productdetail)
        setTimeout(()=>{
            contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal)
        },100)
    }
    const sessiontoken = localStorage.getItem('USER_TOKEN')
    const addtofav = (productId) => {
        setspinnerloading(productId.product_slug)
        const dataString = {
            product_id: productId.product_id,
        };
        ApiService.postData("add-to-fav", dataString).then((res) => {
            if (res.data.status == "success") {
                setspinnerloading('')
                setTimeout(() => {
                    var element = document.getElementById("wishlisticon" + productId.product_slug);
                    element.classList.remove("ri-heart-fill", "ri-heart-line");
                    if (res.data.notification === "d-icon-heart-full") {
                        element.classList.add('ri-heart-fill');
                        //toast.success("Added to Wishlist");
                    } else {
                        //toast.success("Removed from wishlist");
                        element.classList.add('ri-heart-line');
                    } 
                }, 100)
            } else {
                setspinnerloading('')
            }
        }).catch(() => {
            setspinnerloading('')
        });
    };
    const accountModal = () => {
        let sessionToken = localStorage.getItem("USER_TOKEN")
        if (!sessionToken || sessionToken == null) {
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
        }
    } 
    return (<>
        {productdetail !== '' &&
            <div className="product text-center" >
                <figure className="product-media">
                    <a href={`/product/${productdetail.product_slug}`}><img src={productdetail.product_image ? productdetail.product_image : constant.DEFAULT_IMAGE} /></a>
                    {discountprice()>0 ? <div className="product-label-group">
                        <span className="product-label label-sale">{discountprice()}% off</span>
                    </div> :''}
                   
                    <div className="product-action-vertical">
                        {sessiontoken ? (
                            productdetail.ufp_id > 0 ? (
                                <a
                                    href="javascript:void(0)"
                                    className="btn-product-icon btn-wishlist"
                                    title="Add to wishlists"
                                    onClick={(e) =>
                                        addtofav(productdetail)
                                    }
                                >
                                    {spinnerloading == productdetail.product_slug ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> : <i
                                        className="ri-heart-fill"
                                        id={
                                            "wishlisticon" +
                                            productdetail.product_slug
                                        }
                                    ></i>}
                                </a>
                            ) : (
                                <a
                                    href="javascript:void(0)"
                                    className="btn-product-icon btn-wishlist"
                                    title="Add to wishlist"
                                    onClick={(e) =>
                                        addtofav(productdetail)
                                    }
                                >
                                    {spinnerloading == productdetail.product_slug ? <Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={20} /> : <i
                                        className="ri-heart-line"
                                        id={
                                            "wishlisticon" +
                                            productdetail.product_slug
                                        }
                                    ></i>}
                                </a>
                            )
                        ) : (
                            <a
                                href="javascript:void(0)"
                                className="btn-product-icon btn-wishlist"
                                title="Add to wishlist"
                                onClick={() => { accountModal() }}
                            >
                                <i className="ri-heart-line"></i>
                            </a>
                        )} 
                    </div>

                    <div className="product-action" onClick={() => { quickviewModal(productdetail.product_slug) }}>
                        <a href="javascript:void(0)" className="btn-product btn-quickview">Quick View</a>
                    </div>
                </figure>
                <div className="product-details">

                    <h2 className="product-name">{productdetail.product_name}</h2>
                    <div className="product-price">
                        <ins className="new-price">{multiCurrency(productdetail.product_selling_price)}</ins>
                        <del className="old-price">{multiCurrency(productdetail.product_price)}</del>
                    </div>
                    <button className="addcart" onClick={()=>{navigate(`/product/${productdetail.product_slug}`);window.location.reload()}}>Buy Now</button>
                </div>
               
            </div>
           
        }
    </>)
}

export default ProductBox