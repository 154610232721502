
import Footer from "../../Component/Footer"
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import { BrowserView, MobileView } from "react-device-detect";
import MobileInnerheader from "../../Component/Header/mobile-header/inner";
import { useState, useRef, useEffect, useContext } from "react";
import { validEmail } from "../../Component/Elements/Regex";
import { ApiService } from "../../Component/Services/apiservices";
import Alert from 'react-bootstrap/Alert';
import ReactPixel from "../../Component/Services/FacebookPixel";
const BookAppointment = () => {
    const didMountRef = useRef(true);

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [contactDetails, setContactDetails] = useState({
        appointment_name: "",
        appointment_email: "",
        appointment_mobile: "",
        appointment_city: "",
        appointment_state: "",
    });
    useEffect(() => {
        if (didMountRef.current) {


        }
        didMountRef.current = false;
    })



    const onTodoChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const resetContactForm = () => {
        setContactDetails({
            appointment_name: "",
            appointment_email: "",
            appointment_city: "",
            appointment_mobile: "",
            appointment_state: "",
            
        });
    };
    const contactusProcess = () => {
        let counter = 0;
        const myElements = document.getElementsByClassName("required");
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === "") {
                myElements[i].style.border = "1px solid red";
                counter++;
            } else {
                myElements[i].style.border = "";
            }
        }
        if (counter === 0) {
            setErrorMessage("");
            if (!validEmail.test(contactDetails.appointment_email)) {
                setErrorMessage("Please enter valid Email Id");
                return false;
            }
           
            ApiService.postData("book-an-appointment-process", contactDetails).then((res) => {
                if (res.status === "success") {
                    setSuccessMessage(res.message);
                    resetContactForm();
                    setTimeout(() => {
                        setSuccessMessage("");
                    }, 2000);
                    ReactPixel.track('BookAppoinment', {
                        content_name: contactDetails.contact_name,
                        em: contactDetails.contact_email,
                        ph: contactDetails.contact_mobile,
                        ct: contactDetails.contact_city,
                        st: contactDetails.contact_state,
                      });
                } else {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        setErrorMessage("");
                    }, 2000);
                }
            });
        }
    };
    return (<>





        <BrowserView>
            <WebsiteInnerheader></WebsiteInnerheader>
            <div className="subheader smallsubheader">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>Book an Appointment</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </div>
            <section className="section-gap-medium contact">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title">
                                <h1>Book an Appointment</h1>
                                {/* <p className="tx-13">If you have any questions or enquiries please feel free to contact us alternatively you can complete our online enquiry form located below and we will get back to you as soon as possible.</p> */}
                            </div>
                            {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                            {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                            <div className="row g-3">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>Your Name</label>
                                        <input
                                            type="text"
                                            name="appointment_name"
                                            className="form-control required"
                                            value={contactDetails.appointment_name}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group"><label>City</label>
                                        <input
                                            type="text"
                                            name="appointment_city"
                                            className="form-control required"
                                            value={contactDetails.appointment_city}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="City"
                                        /></div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group"><label>State</label>
                                        <input
                                            type="text"
                                            name="appointment_state"
                                            className="form-control required"
                                            value={contactDetails.appointment_state}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="State"
                                        /></div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group"><label>Email Address</label>
                                        <input
                                            type="text"
                                            name="appointment_email"
                                            className="form-control required"
                                            value={contactDetails.appointment_email}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Email"
                                        /></div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group"><label>Mobile</label>
                                        <input
                                            type="number"
                                            name="appointment_mobile"
                                            className="form-control required"
                                            value={contactDetails.appointment_mobile}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Mobile"
                                        /></div>
                                </div>

                                <div className="col-lg-6"><button type="button" className="btn-theme" onClick={() => { contactusProcess() }}><span>Submit</span></button></div>
                            </div>
                        </div>
                        {/* <div className="col-lg-5">
<div className="contact-address-section mb-3">
<div className="section-title">
<h3>Registered Office Address</h3>
</div>
<div className="address-contact">
<ul>
<li>
<a href="https://maps.app.goo.gl/iaykwF8bMy1XiTMQ9" target="new"><i className="ri-map-pin-2-line mr-5"></i><span>{contextValues.settingData.address}</span> </a>
</li>
<li>
<a href={"mailto:" + contextValues.settingData.admin_support_email}><i className="ri-mail-line mr-5"></i><span>{contextValues.settingData.admin_support_email}</span> </a>
</li>
<li>
<a href={"tel:" + contextValues.settingData.admin_mobile}><i className="ri-phone-line mr-5"></i><span>{contextValues.settingData.admin_mobile}</span></a>
</li>
</ul>
</div>
</div>

</div> */}
                    </div>
                </div>
            </section>
            <Footer></Footer>


        </BrowserView>
        <MobileView>
            <MobileInnerheader headertitle={'Book an Appointment'} gobackroute={'/'}></MobileInnerheader>

            <section className="section-gap-medium">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="section-title">
                                {/* <h2>Book an Appointment </h2> */}
                                {/* <p>If you have any questions or enquiries please feel free to contact us alternatively you can complete our online enquiry form located below and we will get back to you as soon as possible.</p> */}
                            </div>
                            {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                            {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                            <div className="row g-3">
                                <div className="col-lg-12">
                                    <div className="form-group"><label>Your Name</label>
                                        <input
                                            type="text"
                                            name="appointment_name"
                                            className="form-control required"
                                            value={contactDetails.appointment_name}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group"><label>City</label>
                                        <input
                                            type="text"
                                            name="appointment_city"
                                            className="form-control required"
                                            value={contactDetails.appointment_city}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="City"
                                        /></div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group"><label>State</label>
                                        <input
                                            type="text"
                                            name="appointment_state"
                                            className="form-control required"
                                            value={contactDetails.appointment_state}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="State"
                                        /></div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group"><label>Email Address</label>
                                        <input
                                            type="text"
                                            name="appointment_email"
                                            className="form-control required"
                                            value={contactDetails.appointment_email}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Email"
                                        /></div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group"><label>Mobile</label>
                                        <input
                                            type="number"
                                            name="appointment_mobile"
                                            className="form-control required"
                                            value={contactDetails.appointment_mobile}
                                            onChange={(e) => onTodoChange(e)}
                                            placeholder="Mobile"
                                        /></div>
                                </div>

                                <div className="col-lg-6"><button type="button" className="btn-theme " onClick={() => { contactusProcess() }}>Submit</button></div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </MobileView>
    </>)
}

export default BookAppointment