import React, { createContext, useEffect, useState, useRef } from 'react';
import { ApiService } from '../Services/apiservices';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [Data, setData] = useState("");
  const [cartdata, setcartdata] = useState([]) // Initialize your global data here
  const [toggleCartModal, setToggleCartModal] = useState(false);
  const [togglesizeguideModal, setTogglesizeguideModal] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [quickViewData, setQuickViewData] = useState({});
  const [quickModalStatus, setQuickModalStatus] = useState(false);
  const [settingData, setSettingData] = useState({});
  const [settingImageBaseUrl, setSettingImageBaseUrl] = useState('');
  const [sliderurl, setsliderurl] = useState("");
    const [bannerImage, setbannerImage] = useState({});
  const [spinnerCubLoader, setSpinnerCubLoader] = useState(0);
  const [cartSpinnerCubLoader, setCartSpinnerCubLoader] = useState('');
  const [cartSessionData, setCartSessionData] = useState([]);
  const [toggleCheckoutModal, setToggleCheckoutModal] = useState(false);
  const [togglesetCancelModal,setToggleCancelModal ] = useState(false)
  const [toggleAddressModal, setToggleAddressModal] = useState(false);
  const [toggleVariationModal, setToggleVariationModal] = useState(false);
  const [toggleCouponModal, setToggleCouponModal] = useState(false);
  const [togglePaymentModal, setTogglePaymentModal] = useState(false);
  const [toggleLoginModal, setToggleLoginModal] = useState(false);
  const [toggleReviewModal, setToggleReviewModal] = useState(false);
  const [toggleSidemenuModal, setToggleSidemenuModal] = useState(false);
  const [toggleQuickViewModal, setToggleQuickViewModal] = useState(false);
  const [toggleReviewListModal, setToggleReviewListModal] = useState(false);
  const [toggleFeedbackModal, setToggleFeedbackModal] = useState(false);
  const [toggleCheckoutAddressModal, setToggleCheckoutAddressModal] = useState(false);
  const [toggleCheckoutAddress, setToggleCheckoutAddress] = useState(false);
  const [menuimgUrl, setmenuimgUrl]=useState('')
  const [cartSummary, setCartSummary] = useState({});
  const [couponSession, setCouponSession] = useState({});
  const [addressSession, setAddressSession] = useState({});
  const [userToken, setUserToken] = useState('');
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [deliveryLocationData, setDeliveryLocationData] = useState({});
  const [productData, setProductData] = useState({});
  const [userAddressList, setUserAddressList] = useState([]);
  const [shippingAmount, setShippingAmount] = useState(0);
  const [newAddress, setNewAddress] = useState({ ua_id: 0, ua_house_no: '', ua_area: '', ua_address_type: 'Home', ua_name: '', ua_email: '', ua_address_type_other: '' });
  const [rowProductsData, setProductsData] = useState({});
  const [rowUserData, setRowUserData] = useState({});
  const [menuList, setMenuList] = useState([]);
  const [loginRedirect, setLoginRedirect] = useState('');
  const [couponsList, setCouponsList] = useState([]);
  const [recomendedproduct, setrecomendedproduct] = useState([])
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      getSettingData()
      getMenudata()
    }
    didMountRef.current = false;
  }, [])
  const getSettingData = () => {
    try {
      ApiService.fetchData('/settings').then((res) => {
        if (res.status === 'success') {
          setSettingData(res.sitesettings)
          setSettingImageBaseUrl(res.setting_image_path)
        }
      })
    } catch (error) {
      console.error('An error occurred while fetching blog data:', error);
    }
  }
     const getMenudata=()=>{
        ApiService.fetchData('/menue-list').then((res)=>{
            if(res.status=='success'){
                setMenuList(res.data)
                setmenuimgUrl(res.imageUrl)
            }
        })
      }
  return (
    <DataContext.Provider value={
      {
        togglesizeguideModal, setTogglesizeguideModal,
        toggleSidemenuModal,setToggleSidemenuModal,
        cartCount, setCartCount,
        menuList,setMenuList,
        menuimgUrl,setmenuimgUrl,
        quickViewData, setQuickViewData,
        togglesetCancelModal,setToggleCancelModal,
        quickModalStatus, setQuickModalStatus,
        settingData, setSettingData,
        settingImageBaseUrl, setSettingImageBaseUrl,
        spinnerCubLoader, setSpinnerCubLoader,
        cartSpinnerCubLoader, setCartSpinnerCubLoader,
        cartSessionData, setCartSessionData,
        toggleCheckoutModal, setToggleCheckoutModal,
        toggleCartModal, setToggleCartModal,
        toggleFeedbackModal,setToggleFeedbackModal,
        toggleCouponModal, setToggleCouponModal,
        togglePaymentModal, setTogglePaymentModal,
        cartSummary, setCartSummary,
        toggleAddressModal , setToggleAddressModal,
        couponSession, setCouponSession,
        addressSession, setAddressSession,
        userToken, setUserToken,
        toggleCheckoutAddressModal, setToggleCheckoutAddressModal,
        showLocationModal, setShowLocationModal,
        deliveryLocationData, setDeliveryLocationData,
        userAddressList, setUserAddressList,
        shippingAmount, setShippingAmount,
        toggleVariationModal, setToggleVariationModal,
        productData, setProductData,
        bannerImage,setbannerImage,
        sliderurl,setsliderurl,
        toggleLoginModal, setToggleLoginModal,
        toggleReviewModal, setToggleReviewModal,
        toggleReviewListModal, setToggleReviewListModal,
        newAddress, setNewAddress,
        rowProductsData, setProductsData,
       recomendedproduct, setrecomendedproduct,
        rowUserData, setRowUserData,
        toggleQuickViewModal, setToggleQuickViewModal,
        loginRedirect, setLoginRedirect,
        couponsList, setCouponsList,
        toggleCheckoutAddress, setToggleCheckoutAddress
      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;