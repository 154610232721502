import Footer from "../../Component/Footer"
import WebsiteInnerheader from "../../Component/Header/webite-header/inner"
import MobileInnerheader from "../../Component/Header/mobile-header/inner"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { ApiService } from "../../Component/Services/apiservices";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Ratingreviews from "../../Component/Rating/rating-reviews";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';
import ProductBox from "../../Component/product-box";
import ReactImageMagnify from 'react-image-magnify';
import constant from "../../Component/Services/constant";
import DataContext from "../../Component/Elements/context";
import SizeguideModal from "../../Component/modals/size-guide-modal";
import { addToCartDetailPage, buyNowDetailPage } from "../../Component/Elements/add_to_cart";
import { BrowserView, MobileView } from "react-device-detect";
import ProductTabs from "../../Component/product-tabs";
import Loader from "react-js-loader";
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
} from "react-share";
import multiCurrency from "../../Component/Elements/multi_currrency";
import HomeInstagram from "../../Component/homeinstagram";
import { Helmet } from "react-helmet";
import ReactPixel from "../../Component/Services/FacebookPixel";
const Productdetail = () => {
    const contextValues = useContext(DataContext)
    const sessiontoken = contextValues.userToken
    const navigate = useNavigate()
    const skeletonArray = Array.from({ length: 6 });
    const { slug } = useParams()
    const didMountRef = useRef(true);
    const [isHelmetLoaded, setIsHelmetLoaded] = useState(false);

    useEffect(() => {
        if (didMountRef.current) {
            getproductdetail();
            setTimeout(() => {
                setIsHelmetLoaded(true);
            }, 1500);
        }
        didMountRef.current = false;
    }, [slug])
    const [rowProductsData, setProductsData] = useState({})
    // const [rowProductsShortDesc, setrowProductsShortDesc] = useState('')
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [galleryItems, setGalleryItems] = useState([])
    const [wishlisttag, setwishlisttag] = useState('')
    const [producttabs, setproducttabs] = useState([])
    const [coupanbuttonText, setcoupanbuttonText] = useState('');
    const [sizechart, setsizechart] = useState([])
    const [recomendedproduct, setrecomendedproduct] = useState([])
    const [recentlyviewproduct, setrecentlyviewproduct] = useState([])
    const [PercentageData, setPercentageData] = useState([]);
    const [ratingdata, setratingdata] = useState([])
    const [spinnerloading, setspinnerloading] = useState('')
    const [loading, setloading] = useState(false)
    const [stockAvailibility, setStockAvailibility] = useState(true)
    const [OfferData, setOfferData] = useState([])
    const [variationData, setVariationData] = useState([]);
    const [selvararray, setSelvararray] = useState([]);
    const getproductdetail = () => {
        setloading(true)
        const dataString = {
            slug: slug
        }
        ApiService.postData('/product-details', dataString).then((res) => {
            if (res.status === 'success') {

                const script = document.createElement('script');
                script.type = 'application/ld+json';
                const json = {
                    "@context": "https://schema.org",
                    "@type": "Product",
                    "productID": res.rowProductData?.product_id,
                    "name": res.rowProductData?.product_name,
                    "description": res.rowProductData?.product_name,
                    "url": window.location.href,
                    "image": res.rowProductData?.product_image,
                    "brand": "facebook",
                    "offers": [
                        {
                            "@type": "Offer",
                            "price": res.rowProductData?.product_selling_price,
                            "priceCurrency": "INR",
                            "itemCondition": "https://schema.org/NewCondition",
                            "availability": "https://schema.org/InStock"
                        }
                    ],
                    "additionalProperty": [{
                        "@type": "PropertyValue",
                        "propertyID": res.rowProductData?.product_category_id,
                        "value": res.rowProductData?.product_category_name
                    }]
                };

                script.innerHTML = JSON.stringify(json);
                document.head.appendChild(script); 
                ReactPixel.track('ViewContent', {
                    content_ids: [res.rowProductData?.product_id], // 'REQUIRED': array of product IDs
                    content_name: res.rowProductData?.product_name,
                    content_url: window.location.href,
                    content_type: 'product',
                    product_catalog_id: 272998379229694,

                });

                setProductsData(res.rowProductData)
                const productcatarray = res.rowProductData.product_category_id.split(',')
                getsizechart(productcatarray)
                setratingdata(res.review_data)
                setPercentageData(res.percentageData);
                setOfferData(res.offerData)

                updateRecentViews(res.rowProductData)
                setVariationData(res.variationData);
                setSelvararray(res.selvararray);
                setrecomendedproduct(res.relatedProducts)
                const galleryItems = [
                    {
                        original:
                            res.rowProductData.product_image != null
                                ? res.rowProductData.product_image
                                : constant.DEFAULT_IMAGE
                    },
                ];

                res.rowProductData.gallery?.map((value) => {
                    galleryItems.push({
                        original:
                            value.gallery_image != null
                                ? value.gallery_image
                                : constant.DEFAULT_IMAGE
                    });
                });
                setGalleryItems(galleryItems);
                if (res.rowProductData.product_description !== null) {
                    const producttabs = [
                        {
                            tab_name: 'Description',
                            tab_description: res.rowProductData.product_description
                        },
                    ];

                    res.rowProductData.product_tabs && res.rowProductData.product_tabs?.map((value) => {
                        producttabs.push(value);
                    });
                       // Add the additional tab
                       producttabs.push({
                        tab_name: 'Additional Info',
                        tab_description: AdditionInfoDescr()
                    });
                
                  
                    setproducttabs(producttabs)
                } else {
                    const producttabs =
                        res.rowProductData.product_tabs?.map((value) => {
                            producttabs.push(value);
                        });
                          // Add the additional tab
                    producttabs.push({
                        tab_name: 'Additional Info',
                        tab_description: AdditionInfoDescr()
                    });
                
                   
                    setproducttabs(producttabs)
                }
 
                if (Number(res.rowProductData.product_type) == 0) {
                    if (Number(res.rowProductData.product_inventory) == 1) {
                        if (Number(res.rowProductData.product_stock) == 0) {
                            setStockAvailibility(false)
                        }
                    }
                } else {
                    if (Number(res.rowProductData.default_variation_first.pv_quantity) == 0) {
                        setStockAvailibility(false)
                    }
                }
                setloading(false)

            } else {
                setloading(false)
            }
        }).catch((error) => {
            setloading(false)
        })
    }

    const fallbackCopyToClipboard = (code, index) => {

        const textArea = document.createElement('textarea');
        textArea.value = code;
        // Avoid scrolling to bottom
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');

            setcoupanbuttonText(code)
            setTimeout(() => {
                setcoupanbuttonText('')
            }, 2000);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);

        }
        document.body.removeChild(textArea);
    };

    const AdditionInfoDescr=()=>{
        return(
            <div>
                <p>Seller Name:
                Aashiyana</p>
                <p>Seller Address:
                92/231, Gokhale Marg, Manasarovar, Jaipur (Rajasthan), 302020</p>
                <p>Marketed by :
                Aashiyana</p>
            </div>
        )
      }

    const getsizechart = (cat_id) => {

        const dataString = {
            product_category: cat_id
        }
        ApiService.postData('sizeChart', dataString).then((res) => {
            if (res.status == 'success') {
                setsizechart(res.data)
            }
        })
    }

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, [])

    let mrpValue = 0;
    let sellingPriceValue = 0;
    let discount = 0;
    const [quantity, setQuantity] = useState(1);
    const [discountPercentage, setDiscountPercentage] = useState(0);

    const variationSelect = (item, index) => {
        const updatedSelectedData = [...selvararray];
        updatedSelectedData[index] = item.terms_name;
        const variationNewArray = updatedSelectedData;
        const dataString = {
        variation: variationNewArray,
        product_id: rowProductsData.product_id,
        };
        ApiService.postData("variation-wise-price", dataString).then((res) => {
            setSelvararray(variationNewArray);
            if (Number(res.data.pv_quantity) == 0) {
                setStockAvailibility(false)
            } else {
                setStockAvailibility(true)
            }
            rowProductsData.product_sku = res.data.pv_sku;
            rowProductsData.product_selling_price = res.data.pv_sellingprice;
            rowProductsData.product_price = res.data.pv_price;
            rowProductsData.product_stock = res.data.pv_quantity;
            rowProductsData.product_moq = res.data.pv_moq;
            rowProductsData.product_discount = res.data.pv_discount;
            if (item.variation_images) {
                rowProductsData.product_image =
                    item.variation_images.pti_image != null
                        ? item.variation_images.pti_image
                        : constant.DEFAULT_IMAGE;
            } else {
                rowProductsData.product_image =
                    rowProductsData.product_image != null
                        ? rowProductsData.product_image
                        : constant.DEFAULT_IMAGE;
            }
            setProductsData(rowProductsData);
            mrpValue = parseFloat(res.data.pv_price);
            sellingPriceValue = parseFloat(res.data.pv_sellingprice);
            if (!isNaN(mrpValue) && !isNaN(sellingPriceValue)) {
                discount = ((mrpValue - sellingPriceValue) / mrpValue) * 100;
            }
            setDiscountPercentage(discount.toFixed(2));
            setQuantity(1);
        });
    };

    const accountModal = () => {
        let sessionToken = localStorage.getItem("USER_TOKEN")
        if (!sessionToken || sessionToken == null) {
            contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
        }
    }
    // const firstValidTab = producttabs.find(item => item.tab_description);
    const firstValidTabIndex = producttabs.findIndex(item => item.tab_description);

    const addtofav = (productId) => {
        setspinnerloading(productId.product_slug)
        const dataString = {
            product_id: productId.product_id,
        };
        ApiService.postData("add-to-fav", dataString).then((res) => {
            if (res.data.status == "success") {
                setspinnerloading('')
                setTimeout(() => {
                    var element = document.getElementById("wishlisticon" + productId.product_slug);
                    element.classList.remove("ri-heart-fill", "ri-heart-line");
                    if (res.data.notification === "d-icon-heart-full") {
                        element.classList.add('ri-heart-fill');
                        setwishlisttag('Remove from wishlist')
                        //toast.success("Added to Wishlist");
                    } else {
                        //toast.success("Removed from wishlist");
                        element.classList.add('ri-heart-line');
                        setwishlisttag('Add to  wishlist')
                    }
                }, 100)
            } else {
                setspinnerloading('')
            }
        }).catch(() => {
            setspinnerloading('')
        });
    };

    const handleAddToCart = async () => {
        contextValues.setSpinnerCubLoader(rowProductsData.product_id)
        const productData = {
            product_id: Number(rowProductsData.product_id),
            product_name: rowProductsData.product_name,
            product_slug: rowProductsData.product_slug,
            product_image: rowProductsData.product_image
                ? rowProductsData.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(rowProductsData.product_type),
            product_price: Number(rowProductsData.product_price),
            product_selling_price: Number(rowProductsData.product_selling_price),
            product_discount: rowProductsData.product_discount,
            product_variation: selvararray,
            product_category_id: rowProductsData.product_category_id,
        };
        const updateStatus = await addToCartDetailPage(productData, quantity, contextValues);
        if (updateStatus) {
            contextValues.setSpinnerCubLoader(0)
        } else {
            contextValues.setSpinnerCubLoader(0)
        }
    };

    const handleBuyNow = async () => {
        contextValues.setSpinnerCubLoader(rowProductsData.product_id)
        const productData = {
            product_id: Number(rowProductsData.product_id),
            product_name: rowProductsData.product_name,
            product_slug: rowProductsData.product_slug,
            product_image: rowProductsData.product_image
                ? rowProductsData.product_image
                : constant.DEFAULT_IMAGE,
            product_type: Number(rowProductsData.product_type),
            product_price: Number(rowProductsData.product_price),
            product_selling_price: Number(rowProductsData.product_selling_price),
            product_discount: rowProductsData.product_discount,
            product_variation: selvararray,
            product_category_id: rowProductsData.product_category_id,
        };
        const updateStatus = await buyNowDetailPage(productData, quantity, contextValues);
        if (updateStatus) {
            contextValues.setSpinnerCubLoader(0)
            navigate('/cart')
        } else {
            contextValues.setSpinnerCubLoader(0)
            navigate('/cart')
        }
    };

    const handleIncrease = () => {
        setQuantity((prevQuantity) => prevQuantity + 1);
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity((prevQuantity) => prevQuantity - 1);
        }
    };

    async function updateRecentViews(item) {
        let recentViewsItem = JSON.parse(localStorage.getItem('recentViewsItem')) || [];
        const isProductAlreadyExists = recentViewsItem.some(product => product.product_slug === item.product_slug);
        if (!isProductAlreadyExists) {
            recentViewsItem.push(item);
        } else {
            recentViewsItem = recentViewsItem.filter(product => product.product_slug !== item.product_slug);
            recentViewsItem.push(item);
        }
        localStorage.setItem('recentViewsItem', JSON.stringify(recentViewsItem));
        getrecentviewsdata();
    }

    const sizeguideModal = () => {
        contextValues.setTogglesizeguideModal(!contextValues.togglesizeguideModal)
    }

    const getrecentviewsdata = () => {
        let recentViewsItems = JSON.parse(localStorage.getItem('recentViewsItem')) || [];
        if (recentViewsItems !== null) {
            setrecentlyviewproduct(recentViewsItems.reverse())
        }
    }

    const watsapnavigate = () => {
        const whatsappURL = `https://api.whatsapp.com/send?phone=${contextValues.settingData.admin_whatsapp_no}`;
        window.open(whatsappURL, '_blank');
    }

    const getdiscountper = () => {
        const price = rowProductsData.product_price
        const sellingprice = rowProductsData.product_selling_price
        const discount = ((price - sellingprice) / price) * 100
        return Math.round(discount) + "%" + 'OFF'
    }

    return (
        <>
            {isHelmetLoaded && (
                <Helmet>
                    <title>{rowProductsData?.product_name}</title>
                    <meta name="description" content={rowProductsData?.product_name} />
                    <meta property="og:title" content={rowProductsData?.product_name} />
                    <meta property="og:description" content={rowProductsData?.product_name} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:image" content={rowProductsData?.product_image} />
                    <meta property="product:brand" content="Facebook" />
                    <meta property="product:availability" content="in stock" />
                    <meta property="product:condition" content="new" />
                    <meta property="product:price:amount" content={rowProductsData?.product_selling_price} />
                    <meta property="product:price:currency" content="INR" />
                    <meta property="product:retailer_item_id" content={rowProductsData?.product_id} />
                    <meta property="product:item_group_id" content={rowProductsData?.product_category_id} />
                </Helmet>
            )}
            <BrowserView>
                <WebsiteInnerheader />
                <section className="section-gap-small product-single">
                    <div className="container">
                        <div className="row">
                            {loading ? <>
                                <div className="col-lg-6" >
                                    <div className='product-gallery'>
                                        <Swiper
                                            style={{
                                                '--swiper-navigation-color': '#fff',
                                                '--swiper-pagination-color': '#fff',
                                            }}
                                            loop={true}
                                            spaceBetween={10}
                                            navigation={true}
                                            thumbs={{
                                                swiper:
                                                    thumbsSwiper && !thumbsSwiper.destroyed
                                                        ? thumbsSwiper
                                                        : null,
                                            }}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="product-galleries"
                                        >
                                            <SwiperSlide >
                                                <Skeleton
                                                    variant="text"
                                                    width="100%"
                                                    height={600} />
                                            </SwiperSlide>

                                        </Swiper>
                                        <Swiper
                                            onSwiper={setThumbsSwiper}
                                            loop={true}
                                            direction="vertical"
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            freeMode={false}
                                            watchSlidesProgress={true}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="product-thumbs-wrap"
                                        >
                                            <SwiperSlide >
                                                <Skeleton
                                                    variant="text"
                                                    height={142.5}
                                                />
                                            </SwiperSlide>
                                            <SwiperSlide >
                                                <Skeleton
                                                    variant="text"
                                                    height={142.5}
                                                />
                                            </SwiperSlide>
                                            <SwiperSlide >
                                                <Skeleton
                                                    variant="text"
                                                    height={142.5}
                                                />
                                            </SwiperSlide>
                                            <SwiperSlide >
                                                <Skeleton
                                                    variant="text"
                                                    height={142.5}
                                                />
                                            </SwiperSlide>

                                        </Swiper>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="pagesubheader mb-10">
                                        <Breadcrumb>
                                            <Breadcrumb.Item ><Skeleton width={100}></Skeleton></Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                <Skeleton width={100}></Skeleton>
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </div>
                                    <h1 className="product-name"><Skeleton width={400}></Skeleton></h1>
                                    <div className="product-meta mb-10">
                                        <ul>
                                            <li><Skeleton width={200}></Skeleton ></li>
                                        </ul>
                                    </div>
                                    <div className="product-price">
                                        <ins className="new-price"><Skeleton width={150}></Skeleton></ins>
                                        <del className="old-price"><Skeleton width={150}></Skeleton></del>
                                    </div>
                                </div>
                            </> : <>
                                <div className="col-lg-6" >
                                    <div className='product-gallery'>
                                        <Swiper
                                            style={{
                                                '--swiper-navigation-color': '#fff',
                                                '--swiper-pagination-color': '#fff',
                                            }}
                                            loop={true}
                                            spaceBetween={10}
                                            navigation={true}
                                            thumbs={{
                                                swiper:
                                                    thumbsSwiper && !thumbsSwiper.destroyed
                                                        ? thumbsSwiper
                                                        : null,
                                            }}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="product-galleries"
                                        >
                                            {rowProductsData.product_video ? (
                                                <SwiperSlide key={0}>
                                                    <video
                                                        src={rowProductsData.product_video}
                                                        autoPlay="autoplay"
                                                        loop
                                                        muted
                                                        playsInline
                                                    ></video>
                                                </SwiperSlide>
                                            ) : (
                                                <></>
                                            )}
                                            {/* {rowProductsData.product_video_link ? (
                                            <SwiperSlide key={0}>
                                                <video
                                                    src={rowProductsData.product_video_link}
                                                    autoPlay="autoplay"
                                                    loop
                                                    muted
                                                    playsInline
                                                ></video>
                                            </SwiperSlide>
                                        ) : (
                                            <></>
                                        )} */}
                                            {galleryItems && galleryItems.map((items, index) => {
                                                return (<>
                                                    <SwiperSlide key={index}>
                                                        <ReactImageMagnify {...{
                                                            smallImage: {
                                                                alt: '',
                                                                isFluidWidth: true,
                                                                src: `${items.original ? items.original : constant.DEFAULT_IMAGE}`,
                                                            },
                                                            largeImage: {
                                                                src: `${items.original ? items.original : constant.DEFAULT_IMAGE}`,
                                                                width: 1200,
                                                                height: 1800
                                                            },
                                                            enlargedImagePosition: 'beside',
                                                            enlargedImagePortalId: 'portal',
                                                        }} key={index} />
                                                    </SwiperSlide>
                                                </>)
                                            })}
                                        </Swiper>
                                        <Swiper
                                            onSwiper={setThumbsSwiper}
                                            loop={true}
                                            direction="vertical"
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            freeMode={false}
                                            watchSlidesProgress={true}
                                            modules={[FreeMode, Navigation, Thumbs]}
                                            className="product-thumbs-wrap"
                                        >
                                            {rowProductsData.product_video ? (
                                                <SwiperSlide key={0}>
                                                    <video
                                                        src={rowProductsData.product_video}
                                                        autoPlay="autoplay"
                                                        loop
                                                        muted
                                                        playsInline
                                                    ></video>
                                                </SwiperSlide>
                                            ) : (
                                                <></>
                                            )}
                                            {/* {rowProductsData.product_video_link ? (
                                            <SwiperSlide key={0}>
                                                <video
                                                    src={rowProductsData.product_video_link}
                                                    autoPlay="autoplay"
                                                    loop
                                                    muted
                                                    playsInline
                                                ></video>
                                            </SwiperSlide>
                                        ) : (
                                            <></>
                                        )} */}
                                            {galleryItems && galleryItems.map((items, index) => {
                                                return (<>
                                                    <SwiperSlide key={index}>
                                                        <img src={items.original ? items.original : constant.DEFAULT_IMAGE} alt={`Thumbnail ${index}`} key={index} />
                                                    </SwiperSlide>
                                                </>)
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div id="portal"></div>
                                    <div className="pagesubheader mb-10">
                                        <Breadcrumb>
                                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                {rowProductsData?.product_name}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </div>
                                    <h1 className="product-name">{rowProductsData?.product_name}</h1>
                                    {rowProductsData.product_brand_name || rowProductsData.product_category_name &&
                                        <div className="product-meta mb-15">
                                            <ul>
                                                {rowProductsData.product_brand_name ? <li><span>Brand: </span>{rowProductsData.product_brand_name}</li> : null}
                                                {rowProductsData.product_category_name ? <li><span>Category: </span>{rowProductsData.product_category_name.split(',')[0]}</li> : null}
                                            </ul>
                                        </div>}
                                    {/* <div className="product-price mb-15">
                                        {rowProductsData.product_selling_price ? <ins className="new-price">{multiCurrency(rowProductsData.product_selling_price)}</ins> : null}
                                        <div className="inner-product-price">
                                            {rowProductsData.product_price ? <del className="old-price">{multiCurrency(rowProductsData.product_price)}</del> : null}
                                            <span className="saveprice">You Save: {multiCurrency(rowProductsData.product_price - rowProductsData.product_selling_price)} ({getdiscountper()})</span>
                                        </div>
                                       
                                    </div>
                                    <p>Inclusive of all taxes</p> */}
                                    <div className="product-price mb-10">
                                        {rowProductsData.product_selling_price ? <ins className="new-price">{multiCurrency(rowProductsData.product_selling_price)}</ins> : null}
                                        <div className="inner-product-price">
                                            <span style={{ color: '#aaa', fontSize: '1rem', fontWeight: '500', marginRight: '5px' }}>MRP</span>
                                            {rowProductsData.product_price ? <del className="old-price">{multiCurrency(rowProductsData.product_price)}</del> : null}
                                            <span className="saveprice ms-2">{getdiscountper()}</span>
                                        </div>
                                    </div>
                                    <p>Inclusive of all taxes</p>
                                    <div className="stock-text mt-3">Availability:{stockAvailibility ? <span className="instock">In Stock</span> : <span className="outofdtock">Out of Stock</span>} </div>
                                    {rowProductsData.product_highlight && rowProductsData.product_highlight && rowProductsData.product_highlight.split(',').length > 0 ? <>
                                        <div className="product-highlight mb-15">
                                            <ul>
                                                {rowProductsData.product_highlight.split('##').map((item, index) => {
                                                    return (<>
                                                        <li key={index}>{item}</li>
                                                    </>)
                                                })}
                                            </ul>
                                        </div>
                                    </> : ''}

                                    {/* apply offers  */}
                                    {OfferData && OfferData.length > 0 ? <>
                                        <div className="avaivable0ffers">
                                            <div className="avaivable0ffersHeader">
                                                <h6 className="mb-0">All Available Offers</h6>
                                                <div className='swiper-arrow'>
                                                    <div className="prev-arrow" onClick={handlePrev} ><img src="/img/back-arrow-white.svg" /></div>
                                                    <div className="next-arrow" onClick={handleNext}><img src="/img/next-arrow-white.svg" /></div>
                                                </div>
                                            </div>
                                            <Swiper
                                                slidesPerView={1.8}
                                                spaceBetween={10}
                                                navigation={false}
                                                ref={sliderRef}
                                                loop={true}
                                                modules={[Navigation, Scrollbar, A11y, Autoplay]}
                                            >
                                                {OfferData.map((value, index) => {
                                                    return (<>
                                                        <SwiperSlide key={index + Number(100)}>
                                                            <div className="avaivable0ffersbcox" key={index}>
                                                                <div className="avaivable0ffersbcoxLeft">
                                                                    <h6 className="tx-14">{value.promo_name}</h6>
                                                                    <p className="font-third mb-1 tx-12 line18">{value.promo_description}</p>
                                                                    {value.promo_max_amount > 0 && <p className="tx-primary fw600 mb-0 tx-12 font-third">Save {multiCurrency(value.promo_max_amount)} on this order!</p>}
                                                                </div>
                                                                <div className="avaivable0ffersbcoxRight">
                                                                    <div className="avaivable0ffersbcoxRight-inner">
                                                                        <h6 className="font-third">{value.promo_coupon_code}</h6>
                                                                        <button type="button" className="copybutton" onClick={() => { fallbackCopyToClipboard(value.promo_coupon_code, index) }}><i class="ri-file-copy-line"></i>  {coupanbuttonText == value.promo_coupon_code ? 'Copied' : "Copy Code"}</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </SwiperSlide>

                                                    </>)
                                                })}

                                            </Swiper>


                                        </div>
                                    </> : null}


                                    {/* Variation */}
                                    {variationData && variationData.length > 0 &&
                                        <div className="product-details-size mt-4">
                                            {variationData.map((valueVariation, indexVariation) => {
                                                if (valueVariation.attributes && valueVariation.attributes.attribute_type === 1) {
                                                    // COLOR
                                                    return (
                                                        <div className="mt-20" key={indexVariation}>
                                                            <h6>{valueVariation.attributes.attribute_name}:</h6>
                                                            <ul>
                                                                {valueVariation.attr_terms.map((valueVariationAttr,indexvalueVariationAttr) => {
                                                                        const stringIncluded = selvararray.includes(valueVariationAttr.terms_name);
                                                                        const className = stringIncluded ? "color active": "color";
                                                                        return (
                                                                            <li onClick={() => variationSelect( valueVariationAttr, indexVariation)}
                                                                                className={className}
                                                                                key={indexvalueVariationAttr}
                                                                                style={{backgroundColor:valueVariationAttr.terms_value,display: "block",}}
                                                                            ></li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>
                                                    );
                                                } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 2 ) {
                                                    // IMAGE
                                                    return (
                                                        <div className="mt-20" key={indexVariation}>
                                                            <h6>{valueVariation.attributes.attribute_name}:</h6>
                                                            <ul>
                                                                {valueVariation.attr_terms.map((valueVariationAttr,indexvalueVariationAttr) => {
                                                                        const stringIncluded = selvararray.includes(valueVariationAttr.terms_name);
                                                                        const className = stringIncluded ? "img active" : "img";
                                                                        return (
                                                                            <li
                                                                                onClick={() => variationSelect(valueVariationAttr,indexVariation)}
                                                                                className={className}
                                                                                key={indexvalueVariationAttr}
                                                                            >
                                                                                <img
                                                                                    src={valueVariationAttr.variation_images != null ? valueVariationAttr.variation_images.pti_image : constant.DEFAULT_IMAGE}
                                                                                    alt={rowProductsData.product_name}
                                                                                    width="100"
                                                                                    height="100"
                                                                                />
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>
                                                    );
                                                } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 3 ) {
                                                    // TEXT
                                                    return (
                                                        <div className="mt-20" key={indexVariation}>
                                                            <h6>{valueVariation.attributes.attribute_name}:</h6>
                                                            <ul>
                                                                {valueVariation.attr_terms.map((valueVariationAttr,indexvalueVariationAttr) => {
                                                                        const stringIncluded = selvararray.includes(valueVariationAttr.terms_name);
                                                                        const className = stringIncluded ? "size active" : "size";
                                                                        return (
                                                                            <li
                                                                                onClick={() => variationSelect(valueVariationAttr,indexVariation)}
                                                                                className={className}
                                                                                href="javascript:void(0)"
                                                                                key={indexvalueVariationAttr}
                                                                            >
                                                                                {valueVariationAttr.terms_name}
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>}
                                    {stockAvailibility ?
                                        <div className="product-single-footer">
                                            <div className="singleqty">
                                                <span onClick={(e) => handleDecrease()}><i className="ri-subtract-line"></i></span>
                                                <span>{quantity}</span>
                                                <span onClick={(e) => handleIncrease()}><i className="ri-add-line"></i></span>
                                            </div>
                                            <button className="btn-theme btncart" onClick={(e) => handleAddToCart()}><span>Add to Cart</span></button>
                                            <button className="btn-line btncart" onClick={(e) => handleBuyNow()}><span>Buy Now</span></button>
                                        </div>
                                        : null}

                                    <div className="psfsecond">
                                        <ul className="psfsecondSocila">
                                            <li><FacebookShareButton url={contextValues.settingData.site_address} quote={contextValues.settingData.site_title}>
                                                <FacebookIcon size={20} round />
                                            </FacebookShareButton></li>
                                            <li>     <WhatsappShareButton url={contextValues.settingData.site_address} quote={contextValues.settingData.site_title}>
                                                <WhatsappIcon size={20} round />
                                            </WhatsappShareButton></li>


                                        </ul>
                                        <ul className="psfsecondmeta">
                                            {sizechart ? <li onClick={() => { sizeguideModal() }}><i className="ri-bookmark-line"></i>Size Guide</li> : ''}
                                            {sessiontoken ? (
                                                rowProductsData.ufp_id > 0 ? (
                                                    <li onClick={(e) =>
                                                        addtofav(rowProductsData)
                                                    }> {spinnerloading == rowProductsData.product_slug ? <div className="smallLoder"><Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={15} /></div> : <i
                                                        className="ri-heart-fill"
                                                        id={
                                                            "wishlisticon" +
                                                            rowProductsData.product_slug
                                                        }
                                                    ></i>}{wishlisttag !== '' ? wishlisttag : 'Remove from wishlist'}</li>
                                                ) : (
                                                    <li onClick={(e) =>
                                                        addtofav(rowProductsData)
                                                    }> {spinnerloading == rowProductsData.product_slug ? <div className="smallLoder"><Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={15} /></div> : <i
                                                        className="ri-heart-line"
                                                        id={
                                                            "wishlisticon" +
                                                            rowProductsData.product_slug
                                                        }
                                                    ></i>}{wishlisttag !== '' ? wishlisttag : 'Add to wishlist'}</li>
                                                )
                                            ) : (
                                                <li onClick={() => { accountModal() }}> {spinnerloading == rowProductsData.product_slug ? <div className="smallLoder"><Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={15} /></div> : <i
                                                    className="ri-heart-line"
                                                    id={
                                                        "wishlisticon" +
                                                        rowProductsData.product_slug
                                                    }
                                                ></i>}Add to wishlist</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </section>

                {/* tabs strt */}
                {producttabs && producttabs.length > 0 ? <>
                    <ProductTabs producttabs={producttabs}></ProductTabs>
                </> : null}
                {/* tabs end */}

                {/* recomend product strt */}
                {loading ? <>
                    <section className="section-gap-small">
                        <div className="container">
                            <div className="section-title section-center">
                                <Skeleton width={300} />
                                <Skeleton width={400} />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Swiper
                                        spaceBetween={15}
                                        slidesPerView={4}
                                        navigation={false}
                                        loop={false}
                                        pagination={{ clickable: true }}
                                        modules={[Autoplay]}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 2.2,
                                            },
                                            768: {
                                                slidesPerView: 2.2,
                                            },
                                            992: {
                                                slidesPerView: 2.2,
                                            },
                                            1000: {
                                                slidesPerView: 4,
                                            },
                                        }}
                                    >
                                        {skeletonArray.map((_, index) => {
                                            return (<>
                                                <SwiperSlide key={index}>
                                                    <div className="product text-center" key={index}>
                                                        <figure className="product-media">
                                                            <a href="#">
                                                                <Skeleton height={315} />
                                                            </a>
                                                        </figure>
                                                        <div className="product-details">
                                                            <Skeleton />
                                                            <div className="product-price">
                                                                <Skeleton width={50} />
                                                                <Skeleton width={50} />
                                                            </div>
                                                            <Skeleton />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </>)
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </section>
                </> : <>
                    {recomendedproduct && recomendedproduct.length > 0 ? <>
                        <section className="section-gap-small">
                            <div className="container">
                                <div className="section-title section-center">
                                    <h2>You May Aslo Like</h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Swiper
                                            spaceBetween={15}
                                            slidesPerView={4}
                                            navigation={false}
                                            loop={false}
                                            pagination={{ clickable: true }}
                                            modules={[Autoplay]}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 2.2,
                                                },
                                                768: {
                                                    slidesPerView: 2.2,
                                                },
                                                992: {
                                                    slidesPerView: 2.2,
                                                },
                                                1000: {
                                                    slidesPerView: 4,
                                                },
                                            }}
                                        >
                                            {recomendedproduct && recomendedproduct.map((items, index) => {
                                                return (<>
                                                    <SwiperSlide key={index}>
                                                        <ProductBox productdetail={items} key={index}></ProductBox>
                                                    </SwiperSlide>
                                                </>)
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </> : null}

                    {/* recomend product end  */}

                    {/* rating review strt */}
                    <Ratingreviews ratingdata={ratingdata} productdetail={rowProductsData} PercentageData={PercentageData}></Ratingreviews>

                    {/* rating review end */}

                    {/* recentlyview strt */}
                    {recentlyviewproduct && recentlyviewproduct.length > 0 ? <>
                        <section className="section-gap-small">
                            <div className="container">
                                <div className="section-title section-center">
                                    <h2>Recently Viewed</h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Swiper
                                            spaceBetween={15}
                                            slidesPerView={4}
                                            navigation={false}
                                            loop={false}
                                            pagination={{ clickable: true }}
                                            modules={[Autoplay]}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 2.2,
                                                },
                                                768: {
                                                    slidesPerView: 2.2,
                                                },
                                                992: {
                                                    slidesPerView: 2.2,
                                                },
                                                1000: {
                                                    slidesPerView: 4,
                                                },
                                            }}
                                        >
                                            {recentlyviewproduct && recentlyviewproduct.map((items, index) => {
                                                return (<>
                                                    <SwiperSlide key={index}>
                                                        <ProductBox productdetail={items} key={index}></ProductBox>
                                                    </SwiperSlide>
                                                </>)

                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </> : null}
                    {/*  recentlyviewend */}

                    {/* follow us on insta */}
                    <HomeInstagram />
                </>}
                <Footer></Footer>
            </BrowserView>

            <MobileView>
                <MobileInnerheader gobackroute={'/'}></MobileInnerheader>
                <main>
                    <section className="product-single">
                        <div className="mproduct-single-slider">
                            <Swiper
                                slidesPerView={1}
                                navigation={false}
                                loop={true}
                                pagination={{ clickable: true }}

                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                            >
                                {rowProductsData.product_video ? (
                                    <SwiperSlide key={0}>
                                        <video
                                            src={rowProductsData.product_video}
                                            autoPlay="autoplay"
                                            loop
                                            muted
                                            playsInline
                                        ></video>
                                    </SwiperSlide>
                                ) : (
                                    <></>
                                )}
                                {/* {rowProductsData.product_video_link ? (
                            <SwiperSlide key={0}>
                            <video
                                src={rowProductsData.product_video_link}
                                autoPlay="autoplay"
                                loop
                                muted
                                playsInline
                            ></video>
                            </SwiperSlide>
                        ) : (
                            <></>
                        )} */}
                                {galleryItems && galleryItems.map((items, index) => {
                                    return (<>
                                        <SwiperSlide key={index}>
                                            <img src={items.original ? items.original : constant.DEFAULT_IMAGE} alt={`Thumbnail ${index}`} key={index} />
                                        </SwiperSlide>
                                    </>)
                                })}

                            </Swiper>
                        </div>
                        <div className="p-15">
                            <h1 className="product-name">{rowProductsData?.product_name}</h1>
                            {rowProductsData.product_brand_name || rowProductsData.product_category_name &&
                                <div className="product-meta mb-15">
                                    <ul>
                                        {rowProductsData.product_brand_name ? <li><span>Brand:</span> {rowProductsData.product_brand_name}</li> : null}
                                        {rowProductsData.product_category_name ? <li><span>Category: </span>{rowProductsData.product_category_name.split(',')[0]}</li> : null}
                                    </ul>
                                </div>}
                            {/* <div className="product-price mb-10">
                                {rowProductsData.product_selling_price ? <ins className="new-price">{multiCurrency(rowProductsData.product_selling_price)}</ins> : null}
                                <div className="inner-product-price">
                                    {rowProductsData.product_price ? <del className="old-price">{multiCurrency(rowProductsData.product_price)}</del> : null}
                                    <span className="saveprice">{multiCurrency(rowProductsData.product_price - rowProductsData.product_selling_price)} ({getdiscountper()})</span>
                                </div>
                               
                            </div>
                            <p>Inclusive of all taxes</p> */}
                            <div className="product-price mb-10">
                                {rowProductsData.product_selling_price ? <ins className="new-price">{multiCurrency(rowProductsData.product_selling_price)}</ins> : null}
                                <div className="inner-product-price">
                                    <span style={{ color: '#aaa', fontSize: '1rem', fontWeight: '500', marginRight: '5px' }}>MRP</span>  {rowProductsData.product_price ? <del className="old-price">{multiCurrency(rowProductsData.product_price)}</del> : null}
                                    <span className="saveprice ms-2">{getdiscountper()}</span>
                                </div>

                            </div>
                            <p>Inclusive of all taxes</p>
                            {rowProductsData.product_highlight && rowProductsData.product_highlight && rowProductsData.product_highlight.split(',').length > 0 ? <>
                                <div className="product-highlight mb-15">
                                    <ul>
                                        {rowProductsData.product_highlight.split('##').map((item, index) => {
                                            return (<>
                                                <li key={index}>{item}</li>
                                            </>)
                                        })}
                                    </ul>
                                </div>
                            </> : ''}

                            {/* apply offers  */}
                            {OfferData && OfferData.length > 0 ? <>
                                <div className="avaivable0ffers">
                                    <div className="avaivable0ffersHeader">
                                        <h6 className="mb-0">All Available Offers</h6>
                                        <div className='swiper-arrow'>
                                            <div className="prev-arrow" onClick={handlePrev} ><img src="/img/back-arrow-white.svg" /></div>
                                            <div className="next-arrow" onClick={handleNext}><img src="/img/next-arrow-white.svg" /></div>
                                        </div>
                                    </div>
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={15}
                                        navigation={false}
                                        ref={sliderRef}
                                        loop={true}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}

                                    >
                                        {OfferData.map((value, index) => {
                                            return (<>
                                                <SwiperSlide key={index + Number(100)}>
                                                    <div className="avaivable0ffersbcox" key={index}>
                                                        <div className="avaivable0ffersbcoxLeft">
                                                            <h6 className="tx-14">{value.promo_name}</h6>
                                                            <p className="font-third mb-1 tx-12 line18">{value.promo_description}</p>
                                                            {value.promo_max_amount > 0 && <p className="tx-primary fw600 mb-0 tx-12 font-third">Save {multiCurrency(value.promo_max_amount)} on this order!</p>}
                                                        </div>
                                                        <div className="avaivable0ffersbcoxRight">
                                                            <div className="avaivable0ffersbcoxRight-inner">
                                                                <h6 className="font-third">{value.promo_coupon_code}</h6>
                                                                <button type="button" className="copybutton" onClick={() => { fallbackCopyToClipboard(value.promo_coupon_code, index) }}><i class="ri-file-copy-line"></i>  {coupanbuttonText == value.promo_coupon_code ? 'Copied' : "Copy Code"}</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </SwiperSlide>



                                            </>)
                                        })}


                                    </Swiper>


                                </div>
                            </> : null}
                            {/* Variation */}
                            {variationData && variationData.length > 0 && <div className="product-details-size mt-4">
                                {variationData.map((valueVariation, indexVariation) => {
                                    if (
                                        valueVariation.attributes &&
                                        valueVariation.attributes.attribute_type === 1
                                    ) {
                                        // COLOR
                                        return (
                                            <div className="mt-20" key={indexVariation}>
                                                <h6>
                                                    {valueVariation.attributes.attribute_name}:
                                                </h6>
                                                <ul>
                                                    {valueVariation.attr_terms.map(
                                                        (
                                                            valueVariationAttr,
                                                            indexvalueVariationAttr
                                                        ) => {
                                                            const stringIncluded = selvararray.includes(
                                                                valueVariationAttr.terms_name
                                                            );
                                                            const className = stringIncluded
                                                                ? "color active"
                                                                : "color";
                                                            return (
                                                                <li
                                                                    onClick={() =>
                                                                        variationSelect(
                                                                            valueVariationAttr,
                                                                            indexVariation
                                                                        )
                                                                    }
                                                                    className={className}
                                                                    key={indexvalueVariationAttr}
                                                                    style={{
                                                                        backgroundColor:
                                                                            valueVariationAttr.terms_value,
                                                                        display: "block",
                                                                    }}
                                                                ></li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        );
                                    } else if (
                                        valueVariation.attributes &&
                                        valueVariation.attributes.attribute_type === 2
                                    ) {
                                        // IMAGE
                                        return (
                                            <div className="mt-20" key={indexVariation}>
                                                <h6>
                                                    {valueVariation.attributes.attribute_name}:
                                                </h6>
                                                <ul>
                                                    {valueVariation.attr_terms.map(
                                                        (
                                                            valueVariationAttr,
                                                            indexvalueVariationAttr
                                                        ) => {
                                                            const stringIncluded = selvararray.includes(
                                                                valueVariationAttr.terms_name
                                                            );
                                                            const className = stringIncluded
                                                                ? "img active"
                                                                : "img";
                                                            return (
                                                                <li
                                                                    onClick={() =>
                                                                        variationSelect(
                                                                            valueVariationAttr,
                                                                            indexVariation
                                                                        )
                                                                    }
                                                                    className={className}
                                                                    key={indexvalueVariationAttr}
                                                                >
                                                                    <img
                                                                        src={
                                                                            valueVariationAttr.variation_images !=
                                                                                null
                                                                                ? valueVariationAttr
                                                                                    .variation_images.pti_image
                                                                                : constant.DEFAULT_IMAGE
                                                                        }
                                                                        alt={rowProductsData.product_name}
                                                                        width="100"
                                                                        height="100"
                                                                    />
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        );
                                    } else if (
                                        valueVariation.attributes &&
                                        valueVariation.attributes.attribute_type === 3
                                    ) {
                                        // TEXT
                                        return (
                                            <div className="mt-20" key={indexVariation}>
                                                <h6>
                                                    {valueVariation.attributes.attribute_name}:
                                                </h6>

                                                <ul>
                                                    {valueVariation.attr_terms.map(
                                                        (
                                                            valueVariationAttr,
                                                            indexvalueVariationAttr
                                                        ) => {
                                                            const stringIncluded = selvararray.includes(
                                                                valueVariationAttr.terms_name
                                                            );
                                                            const className = stringIncluded
                                                                ? "size active"
                                                                : "size";
                                                            return (
                                                                <li
                                                                    onClick={() =>
                                                                        variationSelect(
                                                                            valueVariationAttr,
                                                                            indexVariation
                                                                        )
                                                                    }
                                                                    className={className}
                                                                    href="javascript:void(0)"
                                                                    key={indexvalueVariationAttr}
                                                                >
                                                                    {valueVariationAttr.terms_name}
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>}
                        </div>
                        <div className="psfsecond">
                            <ul className="psfsecondSocila">
                                <li> <FacebookShareButton url={contextValues.settingData.site_address} quote={contextValues.settingData.site_title}>
                                    <FacebookIcon size={20} round />
                                </FacebookShareButton></li>

                                <li><WhatsappShareButton url={contextValues.settingData.site_address} quote={contextValues.settingData.site_title}>
                                    <WhatsappIcon size={20} round />
                                </WhatsappShareButton></li>
                                {/* <TwitterShareButton url={contextValues.settingData.site_address} quote={contextValues.settingData.site_title}>
                                <TwitterIcon size={20} round />
                            </TwitterShareButton> */}
                            </ul>
                            <ul className="psfsecondmeta">
                                {sizechart ? <li onClick={() => { sizeguideModal() }}><i className="ri-bookmark-line"></i>Size Guide</li> : ''}
                                {sessiontoken ? (
                                    rowProductsData.ufp_id > 0 ? (
                                        <li onClick={(e) =>
                                            addtofav(rowProductsData)
                                        }> {spinnerloading == rowProductsData.product_slug ? <div className="smallLoder"><Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={15} /></div> : <i
                                            className="ri-heart-fill"
                                            id={
                                                "wishlisticon" +
                                                rowProductsData.product_slug
                                            }
                                        ></i>}{wishlisttag !== '' ? wishlisttag : 'Remove from wishlist'}</li>
                                    ) : (
                                        <li onClick={(e) =>
                                            addtofav(rowProductsData)
                                        }> {spinnerloading == rowProductsData.product_slug ? <div className="smallLoder"><Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={15} /></div> : <i
                                            className="ri-heart-line"
                                            id={
                                                "wishlisticon" +
                                                rowProductsData.product_slug
                                            }
                                        ></i>}{wishlisttag !== '' ? wishlisttag : 'Add to wishlist'}</li>
                                    )
                                ) : (
                                    <li onClick={() => { accountModal() }}> {spinnerloading == rowProductsData.product_slug ? <div className="smallLoder"><Loader type="spinner-cub" bgColor={'#000'} color={'#000'} size={15} /></div> : <i
                                        className="ri-heart-line"
                                        id={
                                            "wishlisticon" +
                                            rowProductsData.product_slug
                                        }
                                    ></i>}Add to wishlist</li>
                                )}

                            </ul>
                        </div>
                    </section>
                    {producttabs && producttabs.length > 0 ? <>
                        <ProductTabs producttabs={producttabs}></ProductTabs>
                    </> : null}
                    {loading ? <>
                        <section className="section-gap-small">
                            <div className="container">
                                <div className="section-title section-center">
                                    <Skeleton width={300} />
                                    <Skeleton width={400} />
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Swiper
                                            spaceBetween={15}
                                            slidesPerView={4}
                                            navigation={false}
                                            loop={false}
                                            pagination={{ clickable: true }}
                                            modules={[Autoplay]}
                                            breakpoints={{
                                                0: {
                                                    slidesPerView: 2.2,
                                                },
                                                768: {
                                                    slidesPerView: 2.2,
                                                },
                                                992: {
                                                    slidesPerView: 2.2,
                                                },
                                                1000: {
                                                    slidesPerView: 4,
                                                },
                                            }}
                                        >
                                            {skeletonArray.map((_, index) => {
                                                return (<>
                                                    <SwiperSlide key={index}>
                                                        <div className="product text-center" key={index}>
                                                            <figure className="product-media">
                                                                <a href="#">
                                                                    <Skeleton height={315} />
                                                                </a>
                                                            </figure>
                                                            <div className="product-details">
                                                                <Skeleton />
                                                                <div className="product-price">
                                                                    <Skeleton width={50} />
                                                                    <Skeleton width={50} />
                                                                </div>
                                                                <Skeleton />
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                </>)
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </> : <>
                        {recomendedproduct && recomendedproduct.length > 0 ?
                            <section className="section-gap-small">
                                <div className="container">
                                    <div className="section-title section-center">
                                        <h2>You May Aslo Like</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Swiper
                                                spaceBetween={15}
                                                slidesPerView={4}
                                                navigation={false}
                                                loop={false}
                                                pagination={{ clickable: true }}
                                                modules={[Autoplay]}
                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 2.2,
                                                    },
                                                    768: {
                                                        slidesPerView: 2.2,
                                                    },
                                                    992: {
                                                        slidesPerView: 2.2,
                                                    },
                                                    1000: {
                                                        slidesPerView: 4,
                                                    },
                                                }}
                                            >
                                                {recomendedproduct && recomendedproduct.map((items, index) => {
                                                    return (<>
                                                        <SwiperSlide key={index}>
                                                            <ProductBox productdetail={items} key={index}></ProductBox>
                                                        </SwiperSlide>
                                                    </>)
                                                })}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </section> : null}
                        <Ratingreviews ratingdata={ratingdata} productdetail={rowProductsData} PercentageData={PercentageData}></Ratingreviews>
                        {recentlyviewproduct && recentlyviewproduct.length > 0 ? <>
                            <section className="section-gap-small">
                                <div className="container">
                                    <div className="section-title section-center">
                                        <h2>Recently Viewed</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Swiper
                                                spaceBetween={15}
                                                slidesPerView={4}
                                                navigation={false}
                                                loop={false}
                                                pagination={{ clickable: true }}
                                                modules={[Autoplay]}
                                                breakpoints={{
                                                    0: {
                                                        slidesPerView: 2.2,
                                                    },
                                                    768: {
                                                        slidesPerView: 2.2,
                                                    },
                                                    992: {
                                                        slidesPerView: 2.2,
                                                    },
                                                    1000: {
                                                        slidesPerView: 4,
                                                    },
                                                }}
                                            >
                                                {recentlyviewproduct && recentlyviewproduct.map((items, index) => {
                                                    return (<>
                                                        <SwiperSlide key={index}>
                                                            <ProductBox productdetail={items} key={index}></ProductBox>
                                                        </SwiperSlide>
                                                    </>)

                                                })}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </> : null}
                        <HomeInstagram></HomeInstagram>
                    </>}
                    {stockAvailibility ?
                        <div className="mproduct-footer">
                            <button className="btn-theme btncart" onClick={(e) => handleAddToCart()}><span>Add to Cart</span></button>
                            <button className="btn-line btncart" onClick={(e) => handleBuyNow()}><span>Buy Now</span></button>
                        </div>
                        : null}
                </main>
            </MobileView>
            <SizeguideModal sizechart={sizechart}></SizeguideModal>
        </>)
}

export default Productdetail