import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { useState, useEffect, useRef } from "react";
import Skeleton from 'react-loading-skeleton'
import { ApiService } from "../Services/apiservices";
import ProductBox from "../product-box";

const Featuredproduct = () => {
    const [featurproddata, setfeaturedproddata] = useState([])
    const [loading, setloading] = useState(true)
    const didMountRef = useRef(true);
    const skeletonArray = Array.from({ length: 6 });
    useEffect(() => {
        if (didMountRef.current) {
            getfeaturedproductdata()
        }
        didMountRef.current = false;
    }, [])
    const getfeaturedproductdata = () => {
        try {
            setloading(true)
            ApiService.fetchData('/featured-product-list').then((res) => {
                if (res?.status == 'success') {
                    setfeaturedproddata(res.resProductsData);
                    setloading(false)
                } else {
                    setloading(false)
                }
            });
        } catch (error) {
            setloading(false)
            console.error('An error occurred while fetching blog data:', error);
        }
    };
    return (<>
        {loading ? <>
            <section className="section-gap-medium">
                <div className="container">
                    <div className="section-title section-center">
                        <Skeleton width={300} />
                        <Skeleton width={400} />
                        <Skeleton width={50} />
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Swiper
                                spaceBetween={15}
                                slidesPerView={4}
                                navigation={false}
                                loop={false}
                                pagination={{ clickable: true }}
                                modules={[Autoplay]}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 2.2,
                                    },
                                    768: {
                                        slidesPerView: 2.2,
                                    },
                                    992: {
                                        slidesPerView: 2.2,
                                    },
                                    1000: {
                                        slidesPerView: 4,
                                    },
                                }}
                            >
                                {skeletonArray.map((_, index) => {
                                    return (<>

                                        <SwiperSlide key={index}>
                                            <div className="product text-center" key={index}>
                                                <figure className="product-media">
                                                    <a href="javascript:void(0)">
                                                        <Skeleton height={315} />
                                                    </a>
                                                </figure>
                                                <div className="product-details">
                                                    <Skeleton width={200}/>
                                                    <div className="product-price">
                                                        <Skeleton width={60} />
                                                        <Skeleton width={60} />
                                                    </div>
                                                    <Skeleton width={80}/>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </>)
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </> : <>
            {featurproddata.length > 0 ? <>
                <section className="section-gap-medium">
                    <div className="container">
                        <div className="section-title section-center">
                            <h2>Featured Products</h2>
                            <p>Without love, connecting rural India to the world would just be a dream. Read what Jaipur Rugs really is, and what we stand for.</p>
                            <a href="/collection/featuredcategory/all" className="explorebutton">View More</a>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Swiper
                                    spaceBetween={15}
                                    slidesPerView={4}
                                    navigation={false}
                                    loop={false}
                                    pagination={{ clickable: true }}
                                    modules={[Autoplay]}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2.2,
                                        },
                                        768: {
                                            slidesPerView: 2.2,
                                        },
                                        992: {
                                            slidesPerView: 2.2,
                                        },
                                        1000: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {featurproddata && featurproddata.map((items, index) => {
                                        return (<>
                                            <SwiperSlide key={index}>
                                                <ProductBox productdetail={items} key={index}></ProductBox>
                                            </SwiperSlide>
                                        </>)
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>
            </> : null}
        </>}
    </>)
}

export default Featuredproduct