import { useContext, useRef, useEffect } from "react";
import { ApiService } from "../../Services/apiservices";
import DataContext from "../../Elements/context";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginModal from "../../modals/login-modal";


const MobileaccountHeader = ({ heardername, backroute }) => {
    const contextValues = useContext(DataContext)
    const navigate = useNavigate()
    const didMountRef = useRef(true);
    const getUserdata = () => {
        ApiService.fetchData('get-user-data').then((res) => {
          if (res.status == 'success') {
            contextValues.setRowUserData(res.rowUserData)
          }
          else if (res.status=='session_expired'){
            localStorage.removeItem('USER_TOKEN')
            localStorage.removeItem('TEMP_USER_TOKEN')
            navigate('/')
          }
          else{
            toast.error(res.message)
          }
        }).catch((error) => {
    
        })
      }
    useEffect(() => {
        if (didMountRef.current) {
            getUserdata()
        }
        didMountRef.current = false;
    }, []);
    const navigatetobckroute=()=>{
        navigate(backroute)
    }
    return (<>
        <header className="mheader mheader-inner">
                <div className="mheaderTop">
                    <div className="container">
                        <div className="mheader-left" onClick={()=>{navigatetobckroute()}}>
                            <div className="mheader-title">
                                <a href='javascript:void(0)'>
                                    <i className="ri-arrow-left-line"></i>
                                </a>
                                <h1>{heardername}</h1> 
                                
                            </div>
                        </div>
                       
                    </div>
                </div>
            </header>
<LoginModal></LoginModal>
    </>)
}

export default MobileaccountHeader