import React, { useContext, useEffect, useRef, useState } from 'react'
import { ApiService } from '../../Component/Services/apiservices';
import { useNavigate, useParams } from "react-router-dom";
import FeedbackModal from '../../Component/modals/feedback'
import Skeleton from "react-loading-skeleton";
import Loader from "react-js-loader";
import multiCurrency from "../../Component/Elements/multi_currrency";
import DataContext from '../../Component/Elements/context';

const Thankyou=()=>{
    const didMountRef = useRef(true);
    const contextValues = useContext(DataContext)
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState({});
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [spinnerLoadingSec, setSpinnerLoadingSec] = useState(true);
  const [existOrderStatus, setExistOrderStatus] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
      getOrderData()
    }
    didMountRef.current = false;
  }, []);
  const feedbackModal = () => {
    contextValues.setToggleFeedbackModal(!contextValues.toggleFeedbackModal)
}
  const getOrderData = () => {
    if (orderId) {
      const getOrderDetail = {
        trans_id: orderId,
      };
      ApiService.postData("get-order-detail", getOrderDetail).then((res) => {
        if (res.status === "success") {
          setOrderData(res.row_orders_data);
          setTimeout(() => {
            setSpinnerLoading(false)
            setTimeout(() => {
              setSpinnerLoadingSec(false)
            }, 1000);
          }, 500);
        } else if (res.status === "session_expired") {
          setSpinnerLoading(false);
          setSpinnerLoadingSec(false)
          localStorage.removeItem("USER_TOKEN");
          navigate("/");
        } else {
          setSpinnerLoading(false);
          setSpinnerLoadingSec(false)
          setExistOrderStatus(true)
          navigate('/error_404')
        }
      });
    } else {
      navigate("/");
    }
  };
    return(<>
    
    <div className="pyro">
        <div className="before"></div>
        <div className="after"></div>
      </div>
      <div className='ordersection'>
        <div className='ordersectionleft'>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              <h6>
                {spinnerLoading
                  ? <Skeleton width={150} />
                  : `Order Id: #${orderData.trans_order_number}`
                }</h6>
              <h3 className='mb-0'>
                {spinnerLoading
                  ? <Skeleton width={300} />
                  : `Thank You ${orderData.trans_user_name}!`
                }
              </h3>
            </div>
            <img src='/img/thanku.webp' className='wd-150'></img>
          </div>
          <div className='torderbox mt-0'>
            <h6>{spinnerLoading
              ? <Skeleton width={400} />
              : `Your order has been placed successfully`
            }</h6>
            <p className='mb-0'>
              {spinnerLoading
                ? <Skeleton width={900} />
                : <>
                  We have accepted your order and we are getting it ready. A confirmation mail has been sent to{' '}
                  <strong>{orderData.trans_user_email}</strong>
                </>
              }
            </p>
          </div>
          <div className='torderbox mt-20'>
            <h5 className='mb-30'>{spinnerLoading ? <Skeleton width={150} /> : `Customer Details`}</h5>
            <div className='row'>
              <div className='col-6'>
                <h6 className='mb-0'>{spinnerLoading ? <Skeleton width={100} /> : `Email Address`}</h6>
                <p className='tx-gray'>{spinnerLoading ? <Skeleton width={200} /> : orderData.trans_user_email}</p>
                <h6 className='mb-10 mt-20'>{spinnerLoading ? <Skeleton width={100} /> : `Billing Address`}</h6>
                <p>{spinnerLoading ? <Skeleton width={200} /> : orderData.trans_billing_address} </p>
                <h6 className='mb-10 mt-20'>{spinnerLoading ? <Skeleton width={100} /> : `Shipping Method`} </h6>
                <p>{spinnerLoading ? <Skeleton width={200} /> : orderData.trans_method == 'COD' ? 'Cash On Delivery': 'Prepaid'} </p>
              </div>
              <div className='col-6'>
                <h6 className='mb-0'> {spinnerLoading ? <Skeleton width={100} /> : `Phone Number`} </h6>
                <p className='tx-gray'> {spinnerLoading ? <Skeleton width={200} /> : `+91 ${orderData.trans_user_mobile}`} </p>
                <h6 className='mb-10 mt-20'> {spinnerLoading ? <Skeleton width={100} /> : `Shipping Address`} </h6>
                <p> {spinnerLoading ? <Skeleton width={200} /> : orderData.trans_delivery_address} </p>
                <h6 className='mb-10 mt-20'> {spinnerLoading ? <Skeleton width={100} /> : `Payment Method`} </h6>
                <p> {spinnerLoading ? <Skeleton width={200} /> : orderData.trans_method} </p>
              </div>
            </div>
          </div>
          <div className='mt-20 ordersectionfooter'>
            <div>
              <a href="/contact-us">{spinnerLoading ? <Skeleton width={200} /> : 'Need Help? Contact Us'}</a>
            </div>
            <div>
              <button type='button' className='  mr-10 btn-theme ' onClick={()=>{feedbackModal()}}>{spinnerLoading ? <Skeleton width={50} /> : <span>Feedback</span>}</button>
              <button type='button' className='btn-line' onClick={() => navigate("/")}>{spinnerLoading ? <Skeleton width={130} /> : <span>Continue Shopping</span>}</button>
            </div>
          </div>
        </div>
        {spinnerLoadingSec ? 
        <div className='ordersectionright'><Loader type="spinner-cub" bgColor={'#1b8057'} color={'#1b8057'} size={50} /> </div>:
          <div className='ordersectionright'>
            {orderData.items ?
              orderData.items.map((value, index) => {
                return (
                  <div className='thankproductbox mb-10' key={index}>
                    <div className='thankproductboxMedia'>
                      <img src={value.td_item_image}></img>
                    </div>
                    <div className='thankproductboxcontent'>
                      <h6>{value.td_item_title}</h6>
                      <ul>
                        {value.td_variation ? <li>{value.td_variation}</li> : ''}
                        <li>Qty:{value.td_item_qty}</li>
                      </ul>
                      <div className='price mt-10'>
                        <ins className='new-price font-third'>{multiCurrency(value.td_item_net_price)}</ins>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
            <div className='pricdetails mt-30'>
              <h5>Price Details</h5>
              <ul>
                <li>Sub Total<span className="ml-auto font-third">{multiCurrency(orderData.item_sub_total)}</span></li>
                <li>Discount<span className="ml-auto font-third tx-green">-{multiCurrency(orderData.trans_discount_amount)}</span></li>
                {Number(orderData.trans_onlne_discount) > 0 && <li>Onilne Payment Discount<span className="ml-auto font-third tx-green">-{multiCurrency(orderData.trans_onlne_discount)}</span></li>}
                <li>Coupon Discount<span className="ml-auto font-third tx-green">-{multiCurrency(orderData.trans_coupon_dis_amt)}</span></li>
                <li>Delivery Charges<span className="ml-auto font-third">{multiCurrency(orderData.trans_shipping_amount)}</span></li>
                <li><strong>Grand total</strong><span className="ml-auto font-third">{multiCurrency(orderData.trans_amt)}</span></li>
              </ul>
            </div>
          </div>
        }
      </div>
      {contextValues.toggleFeedbackModal && (
        <FeedbackModal/>
      )}
    
    </>)
}

export default Thankyou