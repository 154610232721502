
import Footer from "../../Component/Footer"
import { useEffect, useState, useRef } from "react"
import { ApiService } from "../../Component/Services/apiservices"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom"
import WebsiteInnerheader from "../../Component/Header/webite-header/inner";
import { BrowserView, MobileView } from "react-device-detect";
import MobileInnerheader from "../../Component/Header/mobile-header/inner";
import { Helmet } from "react-helmet";
const Pages = () => {
  const { slug } = useParams()
  const navigate = useNavigate()
  const didMountRef = useRef(true);
  const [pagecontent, setpagecontent] = useState({})
  const [headerImageUrl, setHeaderImageUrl] = useState("")

  useEffect(() => {
    if (didMountRef.current) {
      getpagescontent()
    }
    didMountRef.current = false;
  }, [])

  const getpagescontent = () => {
    const datastring = {
      slug: slug
    }
    ApiService.postData('/page-content', datastring).then((res) => {
      if (res.status == 'success') {
        setpagecontent(res.data)
        setHeaderImageUrl(res.header_image_path)
      }
      else if (res.status == 'error') {
        navigate('/page-not-found')
      }
    })
  }
  return (<>
     <Helmet>
        <title>{pagecontent.page_meta_title}</title>
        <meta
          name="description"
          content={
            pagecontent.page_meta_desc != null
              ? pagecontent.page_meta_desc
              : "Sparsh"
          }
        />
        {pagecontent.page_meta_keyword != null ? (
          <meta
            name="keywords"
            content={pagecontent.page_meta_keyword}
          />
        ) : (
          ""
        )}
        <link rel="canonical" href={window.location.href} /> 
      </Helmet>
    <BrowserView>
      <WebsiteInnerheader/>
     
      {pagecontent.page_header_image && (
      <div className="subheaderBg" style={{ backgroundImage: `url(${headerImageUrl + pagecontent.page_header_image})` }}>
      </div>
      )}
<div className={pagecontent.page_show_title === 1 ? "subheader" : " subheader smallsubheader"}>
  <Container>
    <Row>
      <Col lg={12}>
        <h1>{pagecontent.page_show_title === 1 ? pagecontent.page_header_title : ""}</h1>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>{pagecontent.page_name}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
  </Container>
</div>
      {pagecontent.page_content != null ? (
        <div dangerouslySetInnerHTML={{ __html: pagecontent.page_content }} ></div>
      ) : (
        ""
      )}
    <Footer></Footer>
    </BrowserView>
    <MobileView>
      <MobileInnerheader headertitle={pagecontent.page_name} gobackroute={'/'}></MobileInnerheader>
      <main>
      {pagecontent.page_header_image && (
      <div className="subheaderBg" style={{ backgroundImage: `url(${headerImageUrl + pagecontent.page_header_image})` }}>
      </div>
      )}
      {pagecontent.page_content != null ? (
        <div dangerouslySetInnerHTML={{ __html: pagecontent.page_content }} ></div>
      ) : (
        ""
      )}
      </main>
    </MobileView>

  </>)
}

export default Pages